import {
  AuthActionTypes,
  FilledInvite,
  InviteInfo,
  InviteToken,
  LoginAttempt,
  NewDistrictRegistrationCredential,
  OnboardingStates,
  RegistrationDistrict,
  UserCredential,
  UserInfo,
  UserRegistrationCredential,
} from "./types";
import { action } from "typesafe-actions";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Dispatch } from "redux";
import {
  API_ACCEPT_INVITE,
  API_AUTH_URL,
  API_CHANGE_PASSWORD,
  API_CHANGE_PASSWORD_BY_TOKEN,
  API_COACH_TRIAL_REGISTRATION,
  API_DISTRICT_TRIAL_REGISTRATION,
  API_INVITE_INFO,
  API_LOGOUT,
  API_REGISTRATION_DISTRICTS,
  API_RESET_PASSWORD,
  API_TOKEN_VALIDATION,
  API_UPDATE_USER_ONBOARDING_STEP,
  API_UPLOAD_USER_PROFILE_IMAGE,
  API_USER_INFO,
  API_USER_PROFILE,
  API_USER_REGISTRATION,
  IS_READY_COACH,
} from "../../constants";
import { ApplicationState, history } from "../index";
import { push } from "connected-react-router";
import { toastr } from "react-redux-toastr";

export const requestLogin = (user: UserCredential) =>
  action(AuthActionTypes.LOGIN, { user });
export const receiveLogin = (user: UserCredential) =>
  action(AuthActionTypes.LOGIN_SUCCESS, { user });
export const loginError = (message: string) =>
  action(AuthActionTypes.LOGIN_FAILURE, { error: message });
export const loginAttempts = (loginAttemptObj: LoginAttempt) =>
  action(AuthActionTypes.LOGIN_ATTEMPTS, loginAttemptObj);
export const resetLoginAttempts = () =>
  action(AuthActionTypes.RESET_LOGIN_ATTEMPTS);
export const logout = () => action(AuthActionTypes.LOGOUT);

export const loadUserInfoRequest = () =>
  action(AuthActionTypes.LOAD_USER_INFO_START);
export const loadUserInfoSuccess = (userInfo: UserInfo) =>
  action(AuthActionTypes.LOAD_USER_INFO_SUCCESS, { userInfo });
export const loadUserInfoFailure = (message: string) =>
  action(AuthActionTypes.LOAD_USER_INFO_FAILURE, { error: message });

export const registerUserRequest = () =>
  action(AuthActionTypes.REGISTER_USER_START);
export const registerUserSuccess = () =>
  action(AuthActionTypes.REGISTER_USER_SUCCESS);
export const registerUserFailure = (message: string) =>
  action(AuthActionTypes.REGISTER_USER_FAILURE, { error: message });

export const registerNewDistrictRequest = () =>
  action(AuthActionTypes.REGISTER_USER_START);
export const registerNewDistrictSuccess = () =>
  action(AuthActionTypes.REGISTER_USER_SUCCESS);
export const registerNewDistrictFailure = (message: string) =>
  action(AuthActionTypes.REGISTER_USER_FAILURE, { error: message });

export const loadRegistrationDistrictsRequest = () =>
  action(AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_START);
export const loadRegistrationDistrictsSuccess = (
  districts: Array<RegistrationDistrict>
) => action(AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_SUCCESS, { districts });
export const loadRegistrationDistrictsFailure = (message: string) =>
  action(AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_FAILURE, {
    error: message,
  });

export const uploadUserProfileImageRequest = () =>
  action(AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_START);
export const uploadUserProfileImageSuccess = (image: string) =>
  action(AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_SUCCESS, { image });
export const uploadUserProfileImageFailure = (message: string) =>
  action(AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_FAILURE, {
    error: message,
  });

export const updateUserOnboardingStepRequest = () =>
  action(AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_START);
export const updateUserOnboardingStepSuccess = (
  onboardingState: OnboardingStates
) =>
  action(AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_SUCCESS, {
    onboardingState,
  });
export const updateUserOnboardingStepFailure = (message: string) =>
  action(AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_FAILURE, {
    error: message,
  });

export const changePasswordRequest = () =>
  action(AuthActionTypes.CHANGE_PASSWORD_START);
export const changePasswordSuccess = () =>
  action(AuthActionTypes.CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailure = (message: string) =>
  action(AuthActionTypes.CHANGE_PASSWORD_FAILURE, {
    error: message,
  });

  export const tokenValidRequest = () =>
    action(AuthActionTypes.TOKEN_VALID_START);
  export const tokenValidSuccess = () =>
    action(AuthActionTypes.TOKEN_VALID_SUCCESS);
  export const tokenValidFailure = (message: string) =>
    action(AuthActionTypes.TOKEN_VALID_FAILURE, {
      error: message,
    });

export const openEditProfileModal = () =>
  action(AuthActionTypes.OPEN_EDIT_PROFILE_MODAL);
export const hideEditProfileModal = () =>
  action(AuthActionTypes.HIDE_EDIT_PROFILE_MODAL);

export const updateProfileRequest = () =>
  action(AuthActionTypes.UPDATE_PROFILE_START);
export const updateProfileSuccess = (userInfo: UserInfo) =>
  action(AuthActionTypes.UPDATE_PROFILE_SUCCESS, {
    userInfo,
  });
export const updateProfileFailure = (message: string) =>
  action(AuthActionTypes.UPDATE_PROFILE_FAILURE, {
    error: message,
  });

export const patchProfileRequest = () =>
  action(AuthActionTypes.PATCH_PROFILE_START);
export const patchProfileSuccess = (userInfo: UserInfo) =>
  action(AuthActionTypes.PATCH_PROFILE_SUCCESS, { userInfo });
export const patchProfileFailure = (message: string) =>
  action(AuthActionTypes.PATCH_PROFILE_FAILURE, {
    error: message,
  });

export const getInviteInfoRequest = () =>
  action(AuthActionTypes.GET_INVITE_INFO_START);
export const getInviteInfoSuccess = (inviteInfo: InviteInfo) =>
  action(AuthActionTypes.GET_INVITE_INFO_SUCCESS, { inviteInfo });
export const getInviteInfoFailure = (message: string) =>
  action(AuthActionTypes.GET_INVITE_INFO_FAILURE, { error: message });

export const acceptInviteRequest = () =>
  action(AuthActionTypes.ACCEPT_INVITE_START);
export const acceptInviteSuccess = () =>
  action(AuthActionTypes.ACCEPT_INVITE_SUCCESS);
export const acceptInviteFailure = (message: string) =>
  action(AuthActionTypes.ACCEPT_INVITE_FAILURE, { error: message });

export const loginUser = (user: UserCredential) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestLogin(user));
    const data = JSON.stringify(user);
    const prevUrl = (history.location.state as any)?.from?.pathname;
    return axios
      .post(API_AUTH_URL, data)
      .then((res) => {
        sessionStorage.setItem('loginStatus','true');
        dispatch(receiveLogin(user));
        // dispatch(push(IS_READY_COACH ? "/onboarding" : "/data"));
        if (!IS_READY_COACH) {
          dispatch(push("/data"));
        }
        return dispatch(getUserInfo());
      })
      .catch((err: AxiosError) => {
        if(err.response?.status == 429) {
          let  loginAttemptResponse = err.response.data as LoginAttempt;
          
          localStorage.setItem('hasMultiLoginAttempt', JSON.stringify(true));
          // localStorage.setItem('minutes', ''+(loginAttemptResponse.login_time_interval - 1));
          // localStorage.setItem('seconds', '59')
          dispatch(loginAttempts(loginAttemptResponse));
          return;
        } else {
          dispatch(loginError(err.message));
        }
      })
      .then(() => {
        // if (prevUrl) {
        //   dispatch(push(prevUrl));
        // }
      });
  };
};

export const logoutUser = () => {
  return (dispatch: Dispatch) => {
    return axios
      .post(API_LOGOUT)
      .then(() => {
        dispatch(logout());
        dispatch({ type: "RESET_APP" });
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 403) {
          dispatch(logout());
          dispatch({ type: "RESET_APP" });
        }
        toastr.error("Failed to logout", err.message);
      });
  };
};

export const registerUser = (user: UserRegistrationCredential) => {
  return (dispatch: Dispatch) => {
    dispatch(registerUserRequest());
    return axios
      .post(API_USER_REGISTRATION, user)
      .then(() => {
        dispatch(registerUserSuccess());
        const newUser = { username: user.email, password: user.password };
        loginUser(newUser)(dispatch);
      })
      .catch((err: AxiosError) => {
        if (err.response && (err.response?.data as { email: string }).email) {
          dispatch(
            registerUserFailure(
              "The email you entered is invalid or already registered."
            )
          );
        } else {
          dispatch(registerUserFailure(err.message));
        }
      });
  };
};

export const registerNewDistrict = (
  user: NewDistrictRegistrationCredential,
  trialType: "coach" | "district"
) => {
  return (dispatch: Dispatch) => {
    dispatch(registerUserRequest());
    const registerUrl =
      trialType === "district"
        ? API_DISTRICT_TRIAL_REGISTRATION
        : API_COACH_TRIAL_REGISTRATION;
    return axios
      .post(registerUrl, user)
      .then(() => {
        dispatch(registerUserSuccess());
        const newUser = { username: user.email, password: user.password };
        loginUser(newUser)(dispatch);
      })
      .catch((err: AxiosError) => {
        if (err.response && (err.response?.data as { email: string }).email) {
          dispatch(
            registerUserFailure(
              "The email you entered is invalid or already registered."
            )
          );
        } else {
          dispatch(registerUserFailure(err.message));
        }
      });
  };
};

export const getInviteInfo = (inviteToken: InviteToken) => {
  return (dispatch: Dispatch) => {
    dispatch(getInviteInfoRequest());
    return axios
      .get(API_INVITE_INFO(inviteToken))
      .then((res) => {
        const inviteInfo: InviteInfo = res.data;
        dispatch(getInviteInfoSuccess(inviteInfo));
      })
      .catch((err: AxiosError) => {
        dispatch(getInviteInfoFailure(err.message));
      });
  };
};

export const acceptInvite = (filledInvite: FilledInvite) => {
  return (dispatch: Dispatch) => {
    dispatch(acceptInviteRequest());
    return axios
      .put(API_ACCEPT_INVITE, filledInvite)
      .then(() => {
        dispatch(acceptInviteSuccess());
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          dispatch(
            acceptInviteFailure(
              Object.entries(err.response.data!)
                .map((key, value) => `${key}: ${value}`)
                .join(",")
            )
          );
        } else {
          dispatch(acceptInviteFailure(err.message));
        }
      });
  };
};

export const getUserInfo = () => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(loadUserInfoRequest());
    return axios
      .get(API_USER_INFO)
      .then((res) => {
        const userInfo: UserInfo = res.data;
        if (
          !getState().auth.userInfo ||
          userInfo.id === getState().auth.userInfo?.id
        ) {
          dispatch(loadUserInfoSuccess(userInfo));
        } else {
          dispatch(logoutUser());
        }
      })
      .catch((err: AxiosError) => {
        dispatch(loadUserInfoFailure(err.message));
      });
  };
};

export const getRegistrationDistricts = () => {
  return (dispatch: Dispatch) => {
    dispatch(loadRegistrationDistrictsRequest());
    return axios
      .get(API_REGISTRATION_DISTRICTS)
      .then((res) => {
        const registrationDistricts: Array<RegistrationDistrict> = res.data;
        dispatch(loadRegistrationDistrictsSuccess(registrationDistricts));
      })
      .catch((err: AxiosError) => {
        dispatch(loadRegistrationDistrictsFailure(err.message));
      });
  };
};

export const uploadUserProfileImage = (file: File) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(uploadUserProfileImageRequest());
    const formData = new FormData();
    formData.append("profile_image", file);
    const config: AxiosRequestConfig = {
      headers: {
        "content-type": file.type,
      },
    };
    return axios
      .put(
        API_UPLOAD_USER_PROFILE_IMAGE(getState().auth.userInfo!.id),
        formData,
        config
      )
      .then((res) => {
        const image: string = res.data.profile_image_url;
        dispatch(uploadUserProfileImageSuccess(image));
      })
      .catch((err: AxiosError) => {
        dispatch(uploadUserProfileImageFailure(err.message));
      });
  };
};

export const updateUserOnboardingStep = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(updateUserOnboardingStepRequest());
    const userInfo = getState().auth.userInfo;
    if (!userInfo) return dispatch(push("/onboarding"));
    return axios
      .put(API_UPDATE_USER_ONBOARDING_STEP(userInfo.profile.onboarding_state))
      .then((res) => {
        const new_onboarding_state: OnboardingStates =
          res.data.new_onboarding_state;
        dispatch(updateUserOnboardingStepSuccess(new_onboarding_state));
        dispatch(push("/onboarding"));
      })
      .catch((err: AxiosError) => {
        dispatch(updateUserOnboardingStepFailure(err.message));
      });
  };
};

export const changePassword = (
  current_password: string,
  new_password: string,
  new_password_confirmation: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(changePasswordRequest());
    return axios
      .post(API_CHANGE_PASSWORD(), {
        current_password,
        new_password,
        new_password_confirmation,
      })
      .then(() => {
        toastr.success("Success", "The password has been successfully updated. Please login again with the new password.",{
          timeOut: 3000,
          onHideComplete: () => {
            dispatch(changePasswordSuccess());
            dispatch(logoutUser());
            window.location.reload();
          }
        });
      })
      .catch((err: AxiosError) => {
        dispatch(changePasswordFailure(err.message));
      });
  };
};

export const changePasswordByToken = (
  token: string,
  email: string,
  password: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(changePasswordRequest());
    return axios
      .post(API_CHANGE_PASSWORD_BY_TOKEN, {
        token: token,
        email: email,
        password: password,
      })
      .then(() => {
        dispatch(changePasswordSuccess());
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 400) {
          const responseData = err.response.data as any;
          if (Object.prototype.hasOwnProperty.call(responseData, "error")) {
            dispatch(changePasswordFailure(responseData?.error));
            return Promise.reject(responseData?.error);
          } else {
            const passErrors: Array<string> = (err.response?.data as { password: string[] }).password || [];
            dispatch(changePasswordFailure(passErrors.join(" ")));
            return Promise.reject(passErrors.join(" "));
          }
        } else {
          dispatch(changePasswordFailure(err.message));
          return Promise.reject(err.message);
        }
      });
  };
};

export const isTokenValid = (
  token: string,
  email: string,
) => {
  return (dispatch: Dispatch) => {
    dispatch(tokenValidRequest());
    return axios
      .get(API_TOKEN_VALIDATION(token,email))
      .then(() => {
        dispatch(tokenValidSuccess());
      })
      .catch((err: AxiosError) => {
        const responseData = err.response?.data as any;
        dispatch(tokenValidFailure(responseData?.error));
        return Promise.reject(responseData?.error);
      });
  };
};

export const updateProfile = (userInfo: UserInfo) => {
  return (dispatch: Dispatch) => {
    dispatch(updateProfileRequest());
    return axios
      .put(API_USER_INFO, {
        userInfo,
      })
      .then((res) => {
        const userInfo: UserInfo = res.data;
        dispatch(updateProfileSuccess(userInfo));
      })
      .catch((err: AxiosError) => {
        dispatch(updateProfileFailure(err.message));
      });
  };
};

export const patchProfile = (
  userId: number,
  request: { agreed_to_data_sharing?: boolean }
) => {
  return (dispatch: Dispatch) => {
    dispatch(patchProfileRequest());
    return axios
      .patch(API_USER_PROFILE(userId), request)
      .then((res) => {
        const userInfo: UserInfo = res.data;
        dispatch(patchProfileSuccess(userInfo));
      })
      .catch((err: AxiosError) => {
        dispatch(patchProfileFailure(err.message));
      });
  };
};

export const resetPasswordRequest = () =>
  action(AuthActionTypes.RESET_PASSWORD_START);
export const resetPasswordSuccess = (responseData: any) =>
  action(AuthActionTypes.RESET_PASSWORD_SUCCESS, {responseData});
export const resetPasswordFailure = (message: string) =>
  action(AuthActionTypes.RESET_PASSWORD_FAILURE, {
    error: message,
  });

export const resetPassword = (email: string) => {
  return (dispatch: Dispatch) => {
    dispatch(resetPasswordRequest());
    return axios
      .post(API_RESET_PASSWORD, {
        email,
      })
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data));
      })
      .catch((err: AxiosError) => {
        dispatch(resetPasswordFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};
