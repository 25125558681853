import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import Modal from "react-bootstrap/Modal";
import {
  faFileDownload,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { hideChangePasswordModal } from "../../../../../../store/onboarding/cases/actions";
import { connect } from "react-redux";
import { changePassword } from "../../../../../../store/auth/actions";
import { toastr } from "react-redux-toastr";
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
} from "react-bootstrap";
import { IS_READY_COACH } from "../../../../../../constants";

library.add(faFileDownload, faPlusCircle);

type PropsFromState = {
  showModal: boolean;
  isLoading: {
    changePassword: boolean;
  };
  errors: {
    changePassword?: string;
  };
};

type DispatchProps = {
  hideChangePasswordModal: () => any;
  changePassword: (
    currentPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => any;
};

type Props = PropsFromState & DispatchProps;

type State = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  passwordsMatch: boolean;
};

class ChangePasswordModal extends Component<Props> {
  defState: Readonly<State> = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    passwordsMatch: true,
  };

  state: Readonly<State> = this.defState;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.isLoading.changePassword &&
      !this.props.isLoading.changePassword
    ) {
      if (this.props.errors.changePassword) {
        let errorMessage = this.props.errors.changePassword;
        if (this.props.errors.changePassword.includes('non_field_errors')) {
          errorMessage = this.props.errors.changePassword.split(':')[1]
        }
        toastr.error("Error", errorMessage);
      } else {
        this.props.hideChangePasswordModal();
      }
    }
  }

  onShowModal = () => {
    this.setState(this.defState);
  };

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState({
      [name]: value,
      passwordsMatch: true,
    });
  };

  handleSavePassword = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { currentPassword, newPassword, newPasswordConfirm } = this.state;
    if (newPassword !== newPasswordConfirm) {
      this.setState({ passwordsMatch: false });
    } else {
      this.props.changePassword(
        currentPassword,
        newPassword,
        newPasswordConfirm
      );
    }
  };

  render() {
    const { showModal } = this.props;
    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
      passwordsMatch,
    } = this.state;
    return (
      <Modal
        show={showModal}
        onShow={this.onShowModal}
        onHide={this.props.hideChangePasswordModal}
        animation={false}
        backdropClassName={"customDarkModalBackdrop in"}
        backdrop={'static'}
        keyboard={false}
        
      >
        <Modal.Header
          closeButton
          className={IS_READY_COACH ? "purpleModalHeader" : "orangeModalHeader"}
        >
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="font-weight-bold text-center mb-2">
            Please enter your current password and a new password.
          </h3>
          {/* <h4 className="text-center mb-3">
            Strong passwords should be 8+ characters with a mix of letters,
            numbers, and special characters.
          </h4> */}
          <Form onSubmit={this.handleSavePassword}>
            <FormGroup>
              <FormLabel column={false}>Current Password</FormLabel>
              <FormControl
                required
                autoFocus
                type="password"
                placeholder="Current Password"
                aria-label="Current Password"
                value={currentPassword}
                name="currentPassword"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel column={false}>New Password</FormLabel>
              <FormControl
                required
                type="password"
                placeholder="New Password"
                aria-label="New Password"
                value={newPassword}
                name="newPassword"
                onChange={this.handleChange}
                minLength={8}
                maxLength={20}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              />
              <small>Passwords must be 8 to 20 characters long and include at least one uppercase letter, 
                    number and special character. eg. Example@1</small>
            </FormGroup>
            <FormGroup>
              <FormLabel column={false}>Confirm New Password</FormLabel>
              <FormControl
                required
                type="password"
                placeholder="Confirm New Password"
                aria-label="Confirm New Password"
                aria-describedby="basic-addon1"
                value={newPasswordConfirm}
                name="newPasswordConfirm"
                onChange={this.handleChange}
                isInvalid={!passwordsMatch}
                minLength={8}
                maxLength={20}
              />
              <Form.Control.Feedback type="invalid">
                Passwords do not match.
              </Form.Control.Feedback>
            </FormGroup>
            <div className="text-center mt-4">
              <button className="blueBtnLg" type="submit" disabled={this.props.isLoading.changePassword}>
                Save New Password{" "}
                {this.props.isLoading.changePassword && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ cases, auth }: ApplicationState): PropsFromState => {
  return {
    showModal: cases.modalsState.changePasswordModal,
    isLoading: {
      changePassword: auth.isLoading.changePassword,
    },
    errors: {
      changePassword: auth.errors.changePassword,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      hideChangePasswordModal: hideChangePasswordModal,
      changePassword: changePassword,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);
