import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import Modal from "react-bootstrap/Modal";
import WorkspacesContainer from "./workspaces/WorkspacesContainer";
import {
  changeInterventionPlanFilters,
  hideInterventionPlanModal,
  printInterventionGroupReport,
  resetSelectedPDFInterventionPlanFilter,
  sendNotSharedWithData,
  sendSharedWithData,
} from "../../../../../../../store/onboarding/cases/actions";
import {
  getProgressOverviewData,
  openEndInterventionModal,
} from "../../../../../../../store/onboarding/meeting-module/actions";
import { Accordion, Alert, Button, Spinner, Tab, Tabs } from "react-bootstrap";
import StudentGoalTable from "../../../../../intervention-group-page/StudentGoalTable";
import usePrevProps from "../../../../../../../utils/hooks/usePrevProps";
import { useLoading } from "../../../../../../../utils/hooks/useLoading";
import InterventionGroupAssignmentsTable from "../../../../../intervention-group-page/InterventionGroupAssignmentsTable";
import InterventionStatsTab from "./workspaces/inteventions-stats/InterventionStatsTab";
import { openOverviewModel } from "../../../../../../../store/groups/actions";
import { hideGeneratePDFModal, openGeneratePDFModal } from "../../../../../../../store/onboarding/actions";
import GeneratePDFModal from "./GeneratePDFModal";
import { pdfTables, StudentInfo } from "../../../../../../../store/onboarding/types";
import { RouteComponentProps } from "react-router";
import useUserRole from "../../../../../../../utils/hooks/useUserRole";
import useUserAssignedToGroup from "../hooks/useUserAssignedToGroup";
import { AlertReason, StudentGoal } from "../../../../../../../store/onboarding/cases/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { EventKey } from "react-bootstrap/esm/types";


type Props = {
  studentUniqueId?: string
};

const InterventionPlanModal: React.FunctionComponent<Props> = ({
  studentUniqueId
}) => {
  const [openAlertData, setOpenAlertData] = useState<boolean>();
  const dispatch = useDispatch();
  const {isCoach, isTeacher, isDistrictOwner} = useUserRole();

  const currentUserInfo = useSelector((s: ApplicationState) =>  s.auth.userInfo);

  const isClickedFromAlert = useSelector((s: ApplicationState) => s.cases.isClickedFromAlert)

  const interventionGroup = useSelector(
    (s: ApplicationState) => s.cases.selectedInterventionGroup!
  );

  console.log(interventionGroup)

  const intGrpAccessObj =  useSelector(
    (s: ApplicationState) => s.onboarding.intGrpAccessObj
  );

  let isIntGrpIDsWithReadAccess = useMemo(() => {
    if(intGrpAccessObj && interventionGroup && intGrpAccessObj.intGrpIDsWithReadAccess.length) {
     let isReadonlyFlag = false;
      for (let i = 0; i < intGrpAccessObj.intGrpIDsWithReadAccess.length; i++) {
        const intGrpRead = intGrpAccessObj.intGrpIDsWithReadAccess[i];
        if(intGrpRead == interventionGroup.id) {
          isReadonlyFlag = true;
          break;
        }
      }
      return isReadonlyFlag;
    }
  },[intGrpAccessObj, interventionGroup])

  const showModal = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan.show
  );

  useEffect(() => {
    if(isClickedFromAlert == false) {
      document.getElementById("firstAlert")?.click(); 
    }
    setOpenAlertData(isClickedFromAlert)
  }, [showModal])

  const showGeneratePDFModal = useSelector(
    ({onboarding}: ApplicationState) => onboarding.modalsState.generatePDFModal
  );

  const isLoading = useSelector<ApplicationState, boolean>(
    (s) =>
      s.cases.isLoading.patchInterventionGroup ||
      s.cases.isLoading.addInterventionsToGroup
  );

  const shareDataWith = useSelector(
    (s: ApplicationState) => s.cases.shareDataWith!
  );

  const notShareDataWith = useSelector(
    (s: ApplicationState) => s.cases.notShareDataWith!
  );

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const isStudentDetailReducer = useSelector<
  ApplicationState
  >((s) => s.cases.isStudentDetail);

  const isGroupDetail =  useSelector(
    (s: ApplicationState) => s.cases.isGroupDetail
  );

  const listOrderSwitch = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.listOrderSwitch);


   const { isUserAssignedToGroup } = useUserAssignedToGroup(interventionGroup);
   const isUserAssignedToGroupLocal = useMemo(()=>{
    let loggedInUser = false;
    if(isDistrictOwner) {
      if(!listOrderSwitch) {
        loggedInUser = currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id 
      } else {
        loggedInUser = currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id 
      }
    } else if(isCoach) {
      loggedInUser = currentUserInfo?.id == interventionGroup?.coach_assignment?.user.id 
    } else if(isTeacher) {
      loggedInUser = currentUserInfo?.id == interventionGroup?.teacher_assignment?.user.id 
    }
    return loggedInUser;
  },[showModal])
 // console.log(!notShareDataWith,isUserAssignedToGroupLocal, currentUserInfo?.id, interventionGroup?.coach_assignment?.user.id, interventionGroup?.teacher_assignment?.user.id)

  const handleModalHide = () => {
    if (!isLoading) {
      dispatch(hideInterventionPlanModal());
      if(isStudentDetailReducer) {
        //dispatch(sendSharedWithData(!shareDataWith));
        dispatch(sendNotSharedWithData(!notShareDataWith));
      }
    }
  };

  const showSetGoalModal = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.cases.modalsState.setGoalModal
  );

  const showSetBehaviorGoalModal = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.cases.modalsState.setGoalsModal);

  const showEndInterventionModal = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.meetingModuleReducer.modalsState.endInterventionModal);
  const prev = usePrevProps({
    showSetGoalModal,
    showEndInterventionModal,
    showSetBehaviorGoalModal,
  });

  const studentById = useSelector<ApplicationState, StudentInfo | undefined>(
    (s) => s.onboarding.studentsRoster.find((st) => st.unique_id === studentUniqueId)
  );

  const handleModalShow = () => {
    if (
      (prev?.showSetGoalModal && !showSetGoalModal) ||
      (prev?.showSetBehaviorGoalModal && !showSetBehaviorGoalModal) ||
      (prev?.showEndInterventionModal && !showEndInterventionModal)
    ) {
      setActiveTab("manage");
    } else {
      setActiveTab("work");
    }

    if (interventionGroup && !interventionGroup.progress_overview) {
      if(studentById?.id!) {
        dispatch(changeInterventionPlanFilters({ selectedStudents: studentById ?? [] }));
      }
      dispatch(getProgressOverviewData(interventionGroup.id!, studentById?.id!));
    }
    //Rakesh Bhargava 6 april 2022
    dispatch(openOverviewModel("one_group"));
  };

  const show = useMemo(() => {
    return (
      showModal &&
      !showSetGoalModal &&
      !showEndInterventionModal &&
      !showSetBehaviorGoalModal
    );
  }, [
    showModal,
    showSetGoalModal,
    showEndInterventionModal,
    showSetBehaviorGoalModal,
  ]);

  const reportPending = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.cases.isLoading.printInterventionGroupReport
  );
  const reportError = useSelector<ApplicationState, string | undefined>(
    (s) => s.cases.errors.printInterventionGroupReport
  );
  useLoading(reportPending, reportError);

  const isStudentDetail =  useSelector(
    (s: ApplicationState) => s.cases.isStudentDetail
  );

  useEffect(()=>{
    //console.log('isStudentDetail', isStudentDetail)
  },[isStudentDetail])

  let {
    selectedStudents,
    selectedStudentForWorkSpacePDF,
    selectedPDFForWorkSpacePDF,
    showOnlyActiveInterventions,
    showGoalLines,
    showStudentTrendsLines,
  } = useSelector(
    (s: ApplicationState) => s.cases.modalsState.interventionPlan
  );

  const handlePrintReport = () => {
    if (interventionGroup) {
      // if(!selectedPDFForWorkSpacePDF.length) {
      //   selectedPDFForWorkSpacePDF = [...pdfTables];
      // }
       dispatch(
        printInterventionGroupReport(interventionGroup.id, {
          selected_students: (selectedStudentForWorkSpacePDF || selectedStudents).map((student) => student.id),
          selected_tables: selectedPDFForWorkSpacePDF
                            .filter((pdfTable) => 
                                isDistrictOwner 
                                ? !['my_notes_and_collaboration','logged_interventions'].includes(pdfTable.value)
                                : true)
                            .map((student) => student.value), 
          show_only_active_interventions: showOnlyActiveInterventions,
          show_goal_lines: showGoalLines,
          show_student_trends_lines: showStudentTrendsLines,
        })
      );
      if(!reportError) {
        setTimeout(() => {
            dispatch(hideGeneratePDFModal())
          }, 4000);
      }
    }
  };

  const showAlert = useMemo(() => {
    if(interventionGroup?.alert_reason != null) {
      return ([
        AlertReason.HighFidelityInsufficientGoal, 
        AlertReason.LowFidelityInsufficientGoal,
        AlertReason.InsufficientGoalProgress,
        AlertReason.MetGoal
      ].some((alert) => interventionGroup?.alert_reason.includes(alert)));
    } else {
      return false
    }
  },[interventionGroup]);

  const getAlertDetailCopy = () => {
    if (interventionGroup?.alert_reason) {
      let studentsListByInsuffficiantGoal: any[] = [];
      let studentsList: any[] = [];
        
      interventionGroup.student_goals.filter((sg:StudentGoal) => !sg.archived).forEach((s: any) => {
        if (
          interventionGroup?.alert_reason.includes(AlertReason.MetGoal) &&
          s.is_met_goal
        ) {
          studentsList.push({id: s.student.id, name: s.student.first_name + " " + s.student.last_name});
        } else if ([
            AlertReason.HighFidelityInsufficientGoal, 
            AlertReason.LowFidelityInsufficientGoal,
            AlertReason.InsufficientGoalProgress,
          ].some((alert) => interventionGroup?.alert_reason.includes(alert))
          &&
          s.insufficient_goal_progress
        ) {
          studentsListByInsuffficiantGoal.push({id: s.student.id, name: s.student.first_name + " " + s.student.last_name});
        }
      });
      //if student had met_goal and same student is exist in insufficiant goal then would be filter out from it.
      studentsListByInsuffficiantGoal.filter((student) => !studentsList.some((sl) => student.id == sl.id));

        let uniqueStudentListByInsufficiantGoal = Object.values(
          studentsListByInsuffficiantGoal.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        ).map((std:any) => (std.name.indexOf(',') > -1) ? std.name.replace(',','') : std.name);
  
        let uniqueStudentByInsufficiantGoalStr = '';
        if(uniqueStudentListByInsufficiantGoal.length == 1) {
          uniqueStudentByInsufficiantGoalStr = uniqueStudentListByInsufficiantGoal.join("")
        } else if(uniqueStudentListByInsufficiantGoal.length == 2) {
          uniqueStudentByInsufficiantGoalStr = uniqueStudentListByInsufficiantGoal.join(" and ")
        } else if(uniqueStudentListByInsufficiantGoal.length > 2) {
          uniqueStudentByInsufficiantGoalStr = uniqueStudentListByInsufficiantGoal.slice(0, -1).join(', ')+' and '+uniqueStudentListByInsufficiantGoal.slice(-1)
        }

        let uniqueStudentList = Object.values(
          studentsList.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        ).map((std:any) => (std.name.indexOf(',') > -1) ? std.name.replace(',','') : std.name);
  
        let uniqueStudentStr = '';
        if(uniqueStudentList.length == 1) {
          uniqueStudentStr = uniqueStudentList.join("")
        } else if(uniqueStudentList.length == 2) {
          uniqueStudentStr = uniqueStudentList.join(" and ")
        } else if(uniqueStudentList.length > 2) {
          uniqueStudentStr = uniqueStudentList.slice(0, -1).join(', ')+' and '+uniqueStudentList.slice(-1)
        }
  
        let alertArray = [];
        for (const key in interventionGroup.alert_reason_text) {
          if (Object.prototype.hasOwnProperty.call(interventionGroup.alert_reason_text, key)) {
              const alertData = {
                'order': key,
                'alert_reason_key': interventionGroup['alert_reason_text'][+key][0],
                'alert_reason_text': interventionGroup['alert_reason_text'][+key][1]
              };
              alertArray.push(alertData);
          }
        }
  
        alertArray = alertArray.sort((a, b) => a.order > b.order ? -1 : 1)
  
        let firstAlert;
  
        if (alertArray[0].alert_reason_key == AlertReason.HighFidelityInsufficientGoal) {
          firstAlert = <Accordion.Toggle className="w-100" as={'div'} eventKey="0" id="firstAlert"  onClick={() => setOpenAlertData(!openAlertData)}>
            <li style={{color: '#856404', fontWeight: '400'}}>{<>Given high intervention fidelity and insufficient progress for {uniqueStudentByInsufficiantGoalStr.trim()}, consider (1) whether student(s) attended all planned sessions and (2) adjustment(s) to the intervention plan.</>}
            {alertArray.length > 1 ? <span className="float-right">
              {openAlertData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
              </span> : ''}
            </li>
          </Accordion.Toggle>
        } else if (alertArray[0].alert_reason_key == AlertReason.LowFidelityInsufficientGoal) {
          firstAlert = <Accordion.Toggle className="w-100" as={'div'} eventKey="0" id="firstAlert"  onClick={() => setOpenAlertData(!openAlertData)}>
            <li style={{color: '#856404', fontWeight: '400'}}>{<>Given low intervention fidelity and insufficient goal progress for <strong>{uniqueStudentByInsufficiantGoalStr.trim()},</strong> consider addressing barriers to intervention fidelity.</>}
            {alertArray.length > 1 ? <span className="float-right">
              {openAlertData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
              </span> : ''}
            </li>
          </Accordion.Toggle>
        } else if (alertArray[0].alert_reason_key == AlertReason.MetGoal) {
          firstAlert = <Accordion.Toggle className="w-100" as={'div'} eventKey="0" id="firstAlert"  onClick={() => setOpenAlertData(!openAlertData)}>
            <li style={{color: '#856404', fontWeight: '400'}}>{<>Given goal attainment for <strong>{uniqueStudentStr.trim()}</strong> consider fading or discontinuing intervention.</>}
            {alertArray.length > 1 ? <span className="float-right">
              {openAlertData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
              </span> : ''}
            </li>
          </Accordion.Toggle>
        } else if (alertArray[0].alert_reason_key == AlertReason.InsufficientGoalProgress) {
          firstAlert = <Accordion.Toggle className="w-100" as={'div'} eventKey="0" id="firstAlert"  onClick={() => setOpenAlertData(!openAlertData)}>
            <li style={{color: '#856404', fontWeight: '400'}}>{<>Given insufficient goal progress for <strong>{uniqueStudentByInsufficiantGoalStr.trim()}</strong> and insufficient fidelity data it is important to first increase fidelity monitoring to address any implementation barriers before considering adjustments to the intervention plan.</>}
            {alertArray.length > 1 ? <span className="float-right">
              {openAlertData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
              </span> : ''}
            </li>
          </Accordion.Toggle>
        } else {
          firstAlert = <Accordion.Toggle className="w-100" as={'div'} eventKey="0" id="firstAlert"  onClick={() => setOpenAlertData(!openAlertData)}>
            <li style={{color: '#856404', fontWeight: '400'}}>{alertArray[0].alert_reason_text}
            {alertArray.length > 1 ? <span className="float-right">
              {openAlertData ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }
              </span> : ''}</li>
          </Accordion.Toggle>
        }
  
        return <Alert  variant="warning" className="pointer m-2">
                <Accordion defaultActiveKey="0">
                  <ul style={{paddingLeft: "15px"}}>
                  {firstAlert}
                  {alertArray.map((alertData, index) => {
                    if(index > 0) {
                      let isExist = [
                        AlertReason.HighFidelityInsufficientGoal, 
                        AlertReason.LowFidelityInsufficientGoal,
                        AlertReason.InsufficientGoalProgress,
                        AlertReason.MetGoal
                      ].some((alert) => alertData.alert_reason_key.includes(alert)); 
                      let accordionCollapse;
                      if (alertData.alert_reason_key == AlertReason.HighFidelityInsufficientGoal) {
                        accordionCollapse = <Accordion.Collapse eventKey="0"><li style={{color: '#856404', fontWeight: '400'}}>
                                  {"Given high intervention fidelity and insufficient progress for "+ uniqueStudentByInsufficiantGoalStr.trim() +" consider (1) whether student(s) attended all planned sessions and (2) adjustment(s) to the intervention plan."}
                                  </li></Accordion.Collapse>
                      }
                      if (alertData.alert_reason_key == AlertReason.LowFidelityInsufficientGoal) {
                        accordionCollapse = <Accordion.Collapse eventKey="0">
                                      <li style={{color: '#856404', fontWeight: '400'}}>
                                      {<>Given low intervention fidelity and insufficient goal progress for <strong>{uniqueStudentByInsufficiantGoalStr.trim()}</strong> consider addressing barriers to intervention fidelity.</>}
                                      </li>
                                    </Accordion.Collapse>
                      }
                      if (alertData.alert_reason_key == AlertReason.InsufficientGoalProgress) {
                        accordionCollapse = <Accordion.Collapse eventKey="0">
                                          <li style={{color: '#856404', fontWeight: '400'}}>
                                          {<>Given insufficient goal progress for <strong>{uniqueStudentByInsufficiantGoalStr.trim()}</strong> and insufficient fidelity data it is important to first increase fidelity monitoring to address any implementation barriers before considering adjustments to the intervention plan.</>}
                                          </li>
                                        </Accordion.Collapse>
                      }
                      if (alertData.alert_reason_key == AlertReason.MetGoal) {
                        accordionCollapse = <Accordion.Collapse eventKey="0">
                                        <li style={{color: '#856404', fontWeight: '400'}}>
                                        {<>Given goal attainment for <strong>{uniqueStudentStr.trim()}</strong> consider fading or discontinuing intervention.</>}
                                        </li>
                                      </Accordion.Collapse>
                      }
                      
                      if(!isExist) {
                        accordionCollapse = <Accordion.Collapse eventKey={"0"}>
                                        <li style={{color: '#856404', fontWeight: '400'}}>{alertData.alert_reason_text}</li>
                                      </Accordion.Collapse>
                      }

                      return accordionCollapse;
                    }
                      })
                    }
                  </ul>
              </Accordion>
              </Alert>
    }
  };

  const handleGeneratePDF = () => {
    dispatch(openGeneratePDFModal());
    dispatch(resetSelectedPDFInterventionPlanFilter())
  };


  return (
    <>
    {showModal && (
      <Modal
        enforceFocus={false}
        animation={false}
        show={show}
        onHide={handleModalHide}
        onShow={handleModalShow}
        className="intervention-plan-modal"
        backdropClassName="customDarkModalBackdrop in"
        size="lg"
      >
        <Modal.Header className="purpleModalHeader closeButton" closeButton>
          <Modal.Title>Group Workspace: {interventionGroup?.name}</Modal.Title>
        </Modal.Header>
        
          <Modal.Body>
          {!notShareDataWith ? 
            <button
              className="whiteBtnSm position-absolute"
              style={{ right: "2rem" }}
              onClick={handleGeneratePDF}
            >
              Generate PDF{" "}
              {/* {reportPending && <Spinner animation="border" size="sm" />} */}
            </button>
          : ''}
            <Tabs
              id="group-workspace-tabs"
              activeKey={activeTab as EventKey}
              onSelect={(k) => setActiveTab(k)}
            >
              <Tab eventKey="work" title="Work">
                {!interventionGroup.archived  ? getAlertDetailCopy() : ''}
                <div className="group-workspaces mt-2">
                  <WorkspacesContainer interventionGroup={interventionGroup} studentId={studentById?.id}/>
                </div>
              </Tab>
              <Tab eventKey="manage" title="Manage">
              <div className="mt-2">
                {!isStudentDetail && !isGroupDetail && !isIntGrpIDsWithReadAccess && isUserAssignedToGroupLocal ? 
                <>
                  {/* <button
                    className="blueBtnSm mb-2"
                    onClick={() => {
                      dispatch(openEndInterventionModal());
                    }}
                  >
                    Stop Intervention
                  </button>  */}
                  <InterventionGroupAssignmentsTable
                    interventionGroup={interventionGroup}
                    isStudentDetail = {isStudentDetail}
                    isGroupDetail = {isGroupDetail}
                  /> 
                  <hr />
                </>
                 : ''}
                <StudentGoalTable 
                showAddStudentAction
                isStudentDetail = {isStudentDetail} 
                isGroupDetail = {isGroupDetail}
                isUserAssignedToGroup = {isUserAssignedToGroupLocal}
                />
                
              </div>
            </Tab>
            {!notShareDataWith  && isUserAssignedToGroup ? 
              <Tab eventKey="intervention-stats" title={"Intervention Stats"}>
                {activeTab == "intervention-stats" && (
                  <InterventionStatsTab interventionGroup={interventionGroup} />
                )}
              </Tab>
              :
              ''
            }
            </Tabs>
          </Modal.Body>
      </Modal>
        )}
      {showGeneratePDFModal ? 
          <GeneratePDFModal 
            onHandleSubmit={handlePrintReport} 
            showGenerateModal={showGeneratePDFModal} 
            pdfTables = {
                pdfTables.filter((pdfTable) => 
                isDistrictOwner 
                ? !['my_notes_and_collaboration','logged_interventions'].includes(pdfTable.value)
                : true)
            } 
          /> 
        : ''}
    </>

    
  );
};

export default InterventionPlanModal;
