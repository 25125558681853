import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import {
  EvidencePeriodData,
  Gender,
  Race,
  RaceInfo,
  ReactSelectOption,
  demographicToggleList,
  genderList,
} from "../../../../../../../store/onboarding/types";
import { Grade } from "../../../../../../../store/groups/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { changeDataGradesFilter, changeELLFilter, changeFreeOrReducedLunchFilter, changeGenderFilter, changeRaceFilter, changeRaceOtherFilter, changeSLDFilter, changeSpecialEducationFilter } from "../../../../../../../store/dataFilter/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { USState } from "../../../../../States";
import { Form, FormControl } from "react-bootstrap";
import { getRaceInfo } from "../../../../../../../store/onboarding/actions";

type StateProps = {
  raceFilter: string;
  raceOtherFilter: string;
  genderFilter: string;
  specialEducationFilter: boolean | undefined;
  ellFilter: boolean | undefined;
  sldFilter: boolean | undefined;
  freeOrReducedLunchFilter: boolean | undefined;
  raceInfo: RaceInfo;
  isLoading: {
    getRaceInfo: boolean;
  };
  errors: {
    getRaceInfo?: string;
  };
};

type DispatchProps = {
  changeRaceFilter: (raceFilter?: string) => any;
  changeRaceOtherFilter: (raceOtherFilter?: string) => any;
  changeGenderFilter: (genderFiler?: string) => any;
  changeSpecialEducationFilter: (specialEducation:boolean | undefined) => any;
  changeELLFilter: (ELL:boolean | undefined) => any;
  changeSLDFilter: (SLD:boolean | undefined) => any;
  changeFreeOrReducedLunchFilter: (freeOrReducedLunch:boolean | undefined) => any;
};

type OwnProps = {
  fromDataFilter?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const DemographicsFilter: FunctionComponent<Props> = ({
  raceFilter,
  raceOtherFilter,
  genderFilter,
  specialEducationFilter,
  ellFilter,
  sldFilter,
  freeOrReducedLunchFilter,
  isLoading,
  raceInfo,
  changeRaceFilter,
  changeRaceOtherFilter,
  changeGenderFilter,
  changeSpecialEducationFilter,
  changeELLFilter,
  changeSLDFilter,
  changeFreeOrReducedLunchFilter,
  fromDataFilter
}) => {
  let timeout: any = null;
  const dispatch = useDispatch();
  const currentDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );
  const evidencePeriodData = useSelector<ApplicationState,EvidencePeriodData | undefined >(
    (s: any) => s.onboarding.evidencePeriodData);
  // const [race, setRace] = useState<number | undefined>();
  // const [raceOtherValue, setRaceOtherValue] = useState<string | undefined>();
  // const [gender, setGender] = useState<string | undefined>();
  const [specialEducation, setSpecialEducation] = useState<boolean | undefined>();
  const [ell, setELL] = useState<boolean | undefined>();
  const [sld, setSLD] = useState<boolean | undefined>();
  const [freeOrReducedLunch, setFreeOrReducedLunch] = useState<boolean | undefined>();

  
  useEffect(() => {
    setSpecialEducation(specialEducationFilter)
    setELL(ellFilter)
    setSLD(sldFilter)
    setFreeOrReducedLunch(freeOrReducedLunchFilter)
  },[])
  
  useEffect(() => {
    changeSpecialEducationFilter(specialEducation);
    changeELLFilter(ell);
    changeSLDFilter(sld);
    changeFreeOrReducedLunchFilter(freeOrReducedLunch);
  },[specialEducation,
    ell,
    sld,
    freeOrReducedLunch])

  useEffect(() => {
    if(currentDataPeriod){
      dispatch(getRaceInfo(currentDataPeriod.id))
    }
  },[currentDataPeriod])

  const handleRaceFilterChange = (
    values: ValueType<ReactSelectOption<any>, false>
  ) => {
        if (values) {
          if(raceInfo.races.includes(values.value)){
            changeRaceFilter(values.value);
            changeRaceOtherFilter();
          } else if(
                  raceInfo.race_other_values.length 
                  && raceInfo.race_other_values.includes(values.value)
          ) {
            changeRaceOtherFilter(values.value);
            changeRaceFilter();
          }
        } else {
          changeRaceFilter();
          changeRaceOtherFilter();
        }
    };
  
  const handleGenderFilterChange = (
    values: ValueType<ReactSelectOption<string>, false>
  ) => {
    if (values) {
      changeGenderFilter(
        (values as ReactSelectOption<Gender>).value
      );
    } else {
      changeGenderFilter();
    }
  }

  const handleSpecialEducationFilterChange = (
    values: ValueType<ReactSelectOption<boolean>, false>
  ) => {
    if (values) {
      setSpecialEducation(
        (values as ReactSelectOption<boolean>).value
      );
    } else {
      setSpecialEducation(undefined);
    }
  }

  const handleELLFilterChange = (
    values: ValueType<ReactSelectOption<boolean>, false>
  ) => {
    if (values) {
      setELL(
        (values as ReactSelectOption<boolean>).value
      );
    } else {
      setELL(undefined);
    }
  }

  const handleSLDFilterChange = (
    values: ValueType<ReactSelectOption<boolean>, false>
  ) => {
    if (values) {
      setSLD(
        (values as ReactSelectOption<boolean>).value
      );
    } else {
      setSLD(undefined);
    }
  }

  const handleFreeOrReduceLunchFilterChange = (
    values: ValueType<ReactSelectOption<boolean>, false>
  ) => {
    if (values) {
      setFreeOrReducedLunch(
        (values as ReactSelectOption<boolean>).value
      );
    } else {
      setFreeOrReducedLunch(undefined);
    }
  }

  const options: ReactSelectOption<any>[] = useMemo(() => {
    if(raceInfo?.races.length) {
      return raceInfo.races.concat(raceInfo.race_other_values)
        .map((race) => ({
          value: race,
          label: race,
        }));
    } else {
      return [];
    }
  }, [raceInfo]);
  const genderOptions: ReactSelectOption<string>[] = useMemo(() => genderList, []);
  const demographicToggleOptions: ReactSelectOption<any>[] = useMemo(() => demographicToggleList, []);

  return (
    <>
      {fromDataFilter ? 
        <>
          <div className="mb-2">
            <h4 className="font-weight-bold m-0">Race</h4>
            <Select
              isClearable
              className="flex-1"
              placeholder="Not Set"
              isLoading={isLoading.getRaceInfo}
              options={options}
              value={options.find((r) => (r.value === raceFilter) || (r.value === raceOtherFilter))}
              onChange={handleRaceFilterChange}
            />
          </div> 
          <div className="mb-2">
            <h4 className="font-weight-bold m-0">Gender</h4>
            <Select
                isClearable
                className="flex-1"
                placeholder="Not Set"
                options={genderOptions}
                value={genderOptions.find((g) => g.value === genderFilter)}
                onChange={handleGenderFilterChange}
              />
          </div>
        </>
      : "" }
      <div className="mb-2">
        <h4 className="font-weight-bold m-0">Special Education</h4>
        <Select
            isClearable
            className="flex-1"
            placeholder="Not Set"
            options={demographicToggleOptions}
            value={demographicToggleOptions.find((d) => d.value == specialEducation)}
            onChange={handleSpecialEducationFilterChange}
          />
      </div>
      {fromDataFilter ?
        <div className="mb-0">
          <h4 className="font-weight-bold m-0">Free or Reduced Lunch</h4>
          <Select
              isClearable
              className="flex-1"
              placeholder="Not Set"
              options={demographicToggleOptions}
              value={demographicToggleOptions.find((d) => d.value == freeOrReducedLunch)}
              onChange={handleFreeOrReduceLunchFilterChange}
            />
        </div> 
      : ""}
      <div className="mb-0 row">
        <div className={`${fromDataFilter ? "col-6 pr-0" : "col-12 pb-2"}`}>
          <h4 className="font-weight-bold m-0">ELL</h4>
          <Select
              isClearable
              placeholder="Not Set"
              options={demographicToggleOptions}
              value={demographicToggleOptions.find((d) => d.value == ell)}
              onChange={handleELLFilterChange}
            />
        </div>
        <div className={`${fromDataFilter ? "col-6 pl-1" : "col-12 pb-2"}`}>
        <h4 className="font-weight-bold m-0">SLD</h4>
        <Select
            isClearable
            placeholder="Not Set"
            options={demographicToggleOptions}
            value={demographicToggleOptions.find((d) => d.value == sld)}
            onChange={handleSLDFilterChange}
          />
          
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ dataFilter, onboarding }: ApplicationState): StateProps => {
  return {
    raceFilter: dataFilter.raceFilter,
    raceOtherFilter: dataFilter.raceOtherFilter,
    genderFilter: dataFilter.genderFilter,
    specialEducationFilter: dataFilter.specialEducationFilter,
    ellFilter: dataFilter.ellFilter,
    sldFilter: dataFilter.sldFilter,
    freeOrReducedLunchFilter: dataFilter.freeOrReducedLunchFilter,
    raceInfo: onboarding.raceInfo,
    isLoading: {
      getRaceInfo: onboarding.isLoading.getRaceInfo
    },
    errors: {
      getRaceInfo: onboarding.errors.getRaceInfo
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      changeRaceFilter : changeRaceFilter,
      changeRaceOtherFilter : changeRaceOtherFilter,
      changeGenderFilter : changeGenderFilter,
      changeSpecialEducationFilter : changeSpecialEducationFilter,
      changeELLFilter : changeELLFilter,
      changeSLDFilter : changeSLDFilter,
      changeFreeOrReducedLunchFilter : changeFreeOrReducedLunchFilter,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DemographicsFilter);
