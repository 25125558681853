import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Papa from "papaparse";
import { Dispatch } from "redux";
import { action, createAction, createAsyncAction } from "typesafe-actions";
import {
  API_ADD_STUDENT_TO_PARENT,
  API_ASSESSMENT,
  API_ASSESSMENT_SUGGESTION,
  API_ASSESSMENT_TO_DATA_PERIOD,
  API_CATEGORY_BEHAVIOR,
  API_COACHES_ROSTER,
  API_CUSTOM_BEHAVIORS,
  API_DATA_PERIODS,
  API_DATA_SHARING,
  API_DATA_STUDENT_ENTRY,
  API_DETECT_ASSESSMENT,
  API_DETECT_NON_EXISTING_STUDENT_IDS,
  API_DISTRICT_ACCESS_DATA_SETTINGS,
  API_DISTRICT_ALL_CLASSES,
  API_DISTRICT_ASSESSMENT,
  API_DISTRICT_RELATIONSHIPS,
  API_INTERVENTION_GROUP_LOGGING,
  API_DISTRICT_SCHOOLS,
  API_DISTRICT_STUDENT_CLASSES,
  API_DISTRICT_STUDENT_TEACHERS,
  API_DISTRICT_SUMMARY,
  API_DISTRICT_USERS,
  API_DISTRICTS,
  API_DOCUMENT_UPLOAD,
  API_DSE_TO_DATA_PERIOD,
  API_EVIDENCE_ASSESSMENTS,
  API_EVIDENCE_COLUMN_GROUPS,
  API_EVIDENCE_DATA_UPLOAD,
  API_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD,
  API_GET_EVIDENCE_IN_DATE_PERIOD,
  API_GET_TUTORIAL_VIDEOS,
  API_INTERVIEW,
  API_INTERVIEW_STUDENT,
  API_INVITE_PARENT,
  API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA,
  API_MERGE_SCHOOLS,
  API_MERGE_TEACHERS,
  API_PARENTS,
  API_READING_DIAGNOSTICS_ECG_TO_DATA_PERIOD,
  API_REMINDER,
  API_REQUEST_KEY,
  API_SEND_INVITE_TO_TEACHERS,
  API_STATIC_DATA_CATEGORY_DOMAINS,
  API_STATIC_DEFAULT_PROGRESS_MONITORING,
  API_STUDENT_BEHAVIOR_TO_DATA_PERIOD,
  API_STUDENT_DIAGNOSTIC_INFO,
  API_STUDENT_DSE_GRADE,
  API_STUDENTS_DATA,
  API_STUDENTS_DEMOGRAPHIC_DATA,
  API_STUDENTS_ROSTER,
  API_STUDENTS_TO_DATA_PERIOD,
  API_TARGET_BEHAVIOR_TO_DATA_PERIOD,
  API_TEACHERS_ROSTER,
  API_TEACHERS_ROSTER_CLASSES,
  API_TEACHERS_ROSTER_STUDENTS,
  API_TEACHERS_ROSTER_UPDATE_INFO,
  API_USER_STATUS,
  API_GET_UPDATED_AREA_OF_NEED,
  API_CHECK_EMAIL_EXIST,
  API_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD,
  API_STUDENTS_BY_INTERVENTION_GROUP,
  API_DATA_PERIOD_SCHOOLS,
  API_RACE_INFO,
  API_GET_RELEASE_NOTES,
} from "../../constants";
import {
  District,
  StaffRelationship,
  uploadedAssessment,
  UserAssignmentRole,
  UserInfo,
} from "../auth/types";
import { Grade, TimePeriod } from "../groups/types";
import { ApplicationState } from "../index";
import { AttachedResource, ConcernArea, GoalTypes, InterventionGroup } from "./cases/types";
import {
  AddSchoolRequest,
  AddUserToDistrictRequest,
  AssessmentResponse,
  BasicUserInfo,
  CategoryBehavior,
  CategoryBehaviorRequest,
  CoachInfo,
  CoachTeacherRelationshipRequest,
  ColorCriteria,
  DataPeriod,
  DataSharingTableData,
  DataStudentEntry,
  DiagnosticTag,
  DistrictAccessDataSetting,
  DistrictSummary,
  DseAffectsResponse,
  EmailCheckResponse,
  EvidenceAssessment,
  EvidenceAssessmentRequest,
  EvidenceColumnGroup,
  EvidenceData,
  EvidencePeriodData,
  EvidenceType,
  Interview,
  InterviewRequest,
  InterviewStudent,
  InterviewStudentRequest,
  InviteParentRequest,
  InvitesResponse,
  ManuallyAssessment,
  Measurement,
  OnboardingActionTypes,
  RaceInfo,
  Reminder,
  RenewedTeacherInfo,
  ScheduleReminderRequest,
  School,
  SendInviteRequest,
  Student,
  StudentClass,
  StudentEntry,
  StudentInfo,
  StudentRow,
  StudentsDataResponse,
  StudentsDisplaySettings,
  SupervisorEducatorRelationshipRequest,
  TargetBehavior,
  TeacherClass,
  TeacherInfo,
  TeacherStudentClass,
  TutorialVideo,
} from "./types";
import moment from "moment";
import { getUserInfo } from "../auth/actions";
import { toastr } from "react-redux-toastr";
import { LocalStorageKeys } from "../../utils/LocalStorageUtils";

export const addUserToDistrictRequest = () =>
  action(OnboardingActionTypes.ADD_USER_TO_DISTRICT_REQUEST);
export const addUserToDistrictSuccess = (users: TeacherInfo[]) =>
  action(OnboardingActionTypes.ADD_USER_TO_DISTRICT_SUCCESS, {
    users,
  });
export const addUserToDistrictFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_USER_TO_DISTRICT_FAILURE, {
    error: message,
  });

export const sendInviteToTeachersRequest = () =>
  action(OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_START);
export const sendInviteToTeachersSuccess = (invitesResponse: InvitesResponse) =>
  action(OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_SUCCESS, {
    invitesResponse,
  });
export const sendInviteToTeachersFailure = (message: string) =>
  action(OnboardingActionTypes.SEND_INVITE_TO_TEACHERS_FAILURE, {
    error: message,
  });

export const getRemindersRequest = () =>
  action(OnboardingActionTypes.GET_REMINDERS_START);
export const getRemindersSuccess = (reminders: Reminder[]) =>
  action(OnboardingActionTypes.GET_REMINDERS_SUCCESS, {
    reminders,
  });
export const getRemindersFailure = (message: string) =>
  action(OnboardingActionTypes.GET_REMINDERS_FAILURE, {
    error: message,
  });

export const updateReminderRequest = () =>
  action(OnboardingActionTypes.UPDATE_REMINDER_START);
export const updateReminderSuccess = (reminder: Reminder) =>
  action(OnboardingActionTypes.UPDATE_REMINDER_SUCCESS, {
    reminder,
  });
export const updateReminderFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_REMINDER_FAILURE, {
    error: message,
  });

export const deleteReminderRequest = () =>
  action(OnboardingActionTypes.DELETE_REMINDER_START);
export const deleteReminderSuccess = (reminderId: number) =>
  action(OnboardingActionTypes.DELETE_REMINDER_SUCCESS, {
    reminderId,
  });
export const deleteReminderFailure = (message: string) =>
  action(OnboardingActionTypes.DELETE_REMINDER_FAILURE, {
    error: message,
  });

export const scheduleReminderRequest = () =>
  action(OnboardingActionTypes.SCHEDULE_REMINDER_START);
export const scheduleReminderSuccess = (reminder: Reminder) =>
  action(OnboardingActionTypes.SCHEDULE_REMINDER_SUCCESS, {
    reminder,
  });
export const scheduleReminderFailure = (message: string) =>
  action(OnboardingActionTypes.SCHEDULE_REMINDER_FAILURE, {
    error: message,
  });

export const mergeTeachersRequest = () =>
  action(OnboardingActionTypes.MERGE_TEACHERS_START);
export const mergeTeachersSuccess = (
  keptTeacher: TeacherInfo,
  mergedTeacherId: number
) =>
  action(OnboardingActionTypes.MERGE_TEACHERS_SUCCESS, {
    keptTeacher,
    mergedTeacherId,
  });
export const mergeTeachersFailure = (message: string) =>
  action(OnboardingActionTypes.MERGE_TEACHERS_FAILURE, {
    error: message,
  });

export const mergeSchoolsRequest = () =>
  action(OnboardingActionTypes.MERGE_SCHOOLS_START);
export const mergeSchoolsSuccess = (
  keptSchool: School,
  mergedSchoolId: number
) =>
  action(OnboardingActionTypes.MERGE_SCHOOLS_SUCCESS, {
    keptSchool,
    mergedSchoolId,
  });
export const mergeSchoolsFailure = (message: string) =>
  action(OnboardingActionTypes.MERGE_SCHOOLS_FAILURE, {
    error: message,
  });

export const openUploadTeachersRosterModal = () =>
  action(OnboardingActionTypes.OPEN_UPLOAD_TEACHERS_ROSTER_MODAL);
export const hideUploadTeachersRosterModal = () =>
  action(OnboardingActionTypes.HIDE_UPLOAD_TEACHERS_ROSTER_MODAL);

export const openTeacherInviteIndividuallyModal = (
  newTeacher?: BasicUserInfo,
  callback?: Function
) =>
  action(OnboardingActionTypes.OPEN_TEACHERS_INVITE_INDIVIDUALLY_MODAL, {
    newTeacher,
    callback,
  });
export const hideTeacherInviteIndividuallyModal = () =>
  action(OnboardingActionTypes.HIDE_TEACHERS_INVITE_INDIVIDUALLY_MODAL);

export const openUploadStudentsRosterModal = () =>
  action(OnboardingActionTypes.OPEN_UPLOAD_STUDENTS_ROSTER_MODAL);
export const hideUploadStudentsRosterModal = () =>
  action(OnboardingActionTypes.HIDE_UPLOAD_STUDENTS_ROSTER_MODAL);

export const openSchoolModal = (school?: School) =>
  action(OnboardingActionTypes.OPEN_SCHOOL_MODAL, { school });
export const hideSchoolModal = () =>
  action(OnboardingActionTypes.HIDE_SCHOOL_MODAL);

export const openMergeSchoolsModal = (school: School) =>
  action(OnboardingActionTypes.OPEN_MERGE_SCHOOLS_MODAL, { school });
export const hideMergeSchoolsModal = () =>
  action(OnboardingActionTypes.HIDE_MERGE_SCHOOLS_MODAL);

export const openStudentInviteIndividuallyModal = () =>
  action(OnboardingActionTypes.OPEN_STUDENTS_INVITE_INDIVIDUALLY_MODAL);
export const hideStudentInviteIndividuallyModal = () =>
  action(OnboardingActionTypes.HIDE_STUDENTS_INVITE_INDIVIDUALLY_MODAL);

export const openTeacherDetailsModal = () =>
  action(OnboardingActionTypes.OPEN_TEACHER_DETAIL_MODAL);
export const hideTeacherDetailsModal = () =>
  action(OnboardingActionTypes.HIDE_TEACHER_DETAIL_MODAL);

export const openAssignEducatorsToEmployeeModal = () =>
  action(OnboardingActionTypes.OPEN_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL);
export const hideAssignEducatorsToEmployeeModal = () =>
  action(OnboardingActionTypes.HIDE_ASSIGN_EDUCATORS_TO_EMPLOYEE_MODAL);

export const openUploadStudentsToTeachersModal = () =>
  action(OnboardingActionTypes.OPEN_UPLOAD_STUDENTS_TO_TEACHER_MODAL);
export const hideUploadStudentsToTeachersModal = () =>
  action(OnboardingActionTypes.HIDE_UPLOAD_STUDENTS_TO_TEACHER_MODAL);

export const openScheduleReminderModal = () =>
  action(OnboardingActionTypes.OPEN_SCHEDULE_REMINDER_MODAL);
export const hideScheduleReminderModal = () =>
  action(OnboardingActionTypes.HIDE_SCHEDULE_REMINDER_MODAL);

export const openEditReminderModal = () =>
  action(OnboardingActionTypes.OPEN_EDIT_REMINDER_MODAL);
export const hideEditReminderModal = () =>
  action(OnboardingActionTypes.HIDE_EDIT_REMINDER_MODAL);

export const openMergeTeachersModal = () =>
  action(OnboardingActionTypes.OPEN_MERGE_TEACHERS_MODAL);
export const hideMergeTeachersModal = () =>
  action(OnboardingActionTypes.HIDE_MERGE_TEACHERS_MODAL);

export const openStudentEditForm = () =>
  action(OnboardingActionTypes.OPEN_EDIT_STUDENT_FORM);
export const hideStudentEditForm = () =>
  action(OnboardingActionTypes.HIDE_EDIT_STUDENT_FORM);

export const openCreateClassModal = () =>
  action(OnboardingActionTypes.OPEN_CREATE_CLASS_MODAL);
export const hideCreateClassModal = () =>
  action(OnboardingActionTypes.HIDE_CREATE_CLASS_MODAL);

export const hideFailedInvitationsAlert = () =>
  action(OnboardingActionTypes.HIDE_FAILED_INVITATIONS_ALERT);

export const getTeachersRequest = () =>
  action(OnboardingActionTypes.GET_TEACHERS_START);
export const getTeachersSuccess = (teachersRoster: Array<TeacherInfo>) =>
  action(OnboardingActionTypes.GET_TEACHERS_SUCCESS, { teachersRoster });
export const getTeachersFailure = (message: string) =>
  action(OnboardingActionTypes.GET_TEACHERS_FAILURE, { error: message });

export const getStudentsByInterventionGroupRequest = () =>
  action(OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_START);
export const getStudentsByInterventionGroupSuccess = (studentsByInterventionGroupRoster: Array<any>) =>
  action(OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_SUCCESS, { studentsByInterventionGroupRoster });
export const getStudentsByInterventionGroupFailure = (message: string) =>
  action(OnboardingActionTypes.GET_STUDENTS_BY_INTERVENTION_GROUP_FAILURE, { error: message });

export const getStudentsRequest = () =>
  action(OnboardingActionTypes.GET_STUDENTS_START);
export const getStudentsSuccess = (studentsRoster: Array<StudentInfo>) =>
  action(OnboardingActionTypes.GET_STUDENTS_SUCCESS, { studentsRoster });
export const getStudentsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_STUDENTS_FAILURE, { error: message });

export const addStudentsRequest = () =>
  action(OnboardingActionTypes.ADD_STUDENTS_START);
export const addStudentsSuccess = (studentsRoster: Array<Student>) =>
  action(OnboardingActionTypes.ADD_STUDENTS_SUCCESS, { studentsRoster });
export const addStudentsFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_STUDENTS_FAILURE, { error: message });

export const updateTeacherInfoRequest = () =>
  action(OnboardingActionTypes.UPDATE_TEACHER_INFO_START);
export const updateTeacherInfoSuccess = (
  id: number,
  updatedInfo: RenewedTeacherInfo
) =>
  action(OnboardingActionTypes.UPDATE_TEACHER_INFO_SUCCESS, {
    id,
    updatedInfo,
  });
export const updateTeacherInfoFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_TEACHER_INFO_FAILURE, { error: message });

export const assignClassToTeacherRequest = () =>
  action(OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_START);
export const assignClassToTeacherSuccess = (
  id: number,
  teacherClass: TeacherClass
) =>
  action(OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_SUCCESS, {
    id,
    teacherClass,
  });
export const assignClassToTeacherFailure = (message: string) =>
  action(OnboardingActionTypes.ASSIGN_CLASS_TO_TEACHER_FAILURE, {
    error: message,
  });

export const updateTeacherClassRequest = () =>
  action(OnboardingActionTypes.UPDATE_TEACHER_CLASS_START);
export const updateTeacherClassSuccess = () =>
  action(OnboardingActionTypes.UPDATE_TEACHER_CLASS_SUCCESS);
export const updateTeacherClassFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_TEACHER_CLASS_FAILURE, {
    error: message,
  });

export const getClassesAssignedToTeacherRequest = () =>
  action(OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_START);
export const getClassesAssignedToTeacherSuccess = (
  teachersClasses: Array<TeacherClass>
) =>
  action(OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_SUCCESS, {
    teachersClasses,
  });
export const getClassesAssignedToTeacherFailure = (message: string) =>
  action(OnboardingActionTypes.GET_CLASSES_ASSIGNED_TO_TEACHER_FAILURE, {
    error: message,
  });

export const clearClassesAssignedToTeacher = () =>
  action(OnboardingActionTypes.CLEAR_CLASSES_ASSIGNED_TO_TEACHER);

export const assignStudentsToTeacherRequest = (teacherId: number) =>
  action(OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_START, { teacherId });
export const assignStudentsToTeacherSuccess = (
  teacherId: number,
  students: Array<Student>,
  teacherInfo?: UserInfo,
  isNewStudentAdd?: boolean,
) =>
  action(OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_SUCCESS, {
    teacherId,
    students,
    teacherInfo,
    isNewStudentAdd
  });
export const assignStudentsToTeacherFailure = (
  teacherId: number,
  message: string
) =>
  action(OnboardingActionTypes.ASSIGN_STUDENTS_TO_TEACHER_FAILURE, {
    teacherId: teacherId,
    error: message,
  });

export const updateStudentDetailsRequest = () =>
  action(OnboardingActionTypes.UPDATE_STUDENT_DETAILS_START);
export const updateStudentDetailsSuccess = (id: number, student: Student, studentGrade?: Grade) =>
  action(OnboardingActionTypes.UPDATE_STUDENT_DETAILS_SUCCESS, {
    id,
    student,
    studentGrade
  });
export const updateStudentDetailsFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_STUDENT_DETAILS_FAILURE, {
    error: message,
  });

export const getStudentsAssignedToTeacherRequest = () =>
  action(OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_START);
export const getStudentsAssignedToTeacherSuccess = (
  teachersStudents: Array<Student>
) =>
  action(OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_SUCCESS, {
    teachersStudents,
  });
export const getStudentsAssignedToTeacherFailure = (message: string) =>
  action(OnboardingActionTypes.GET_STUDENTS_ASSIGNED_TO_TEACHER_FAILURE, {
    error: message,
  });

export const getSchoolsRequest = () =>
  action(OnboardingActionTypes.GET_SCHOOLS_LIST_START);
export const getSchoolsSuccess = (schools: Array<School>) =>
  action(OnboardingActionTypes.GET_SCHOOLS_LIST_SUCCESS, { schools });
export const getSchoolsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_SCHOOLS_LIST_FAILURE, { error: message });

export const getParentsRequest = () =>
  action(OnboardingActionTypes.GET_PARENTS_LIST_START);
export const getParentsSuccess = (parents: Array<UserInfo>) =>
  action(OnboardingActionTypes.GET_PARENTS_LIST_SUCCESS, { parents });
export const getParentsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_PARENTS_LIST_FAILURE, { error: message });

export const addSchoolRequest = () =>
  action(OnboardingActionTypes.ADD_SCHOOL_START);
export const addSchoolSuccess = (school: School) =>
  action(OnboardingActionTypes.ADD_SCHOOL_SUCCESS, { school });
export const addSchoolFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_SCHOOL_FAILURE, { error: message });

export const updateSchoolRequest = () =>
  action(OnboardingActionTypes.UPDATE_SCHOOL_START);
export const updateSchoolSuccess = (school: School) =>
  action(OnboardingActionTypes.UPDATE_SCHOOL_SUCCESS, { school });
export const updateSchoolFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_SCHOOL_FAILURE, { error: message });

export const clearNonExistingStudent = () =>
  action(OnboardingActionTypes.CLEAR_NON_EXISTING_STUDENTS);

export const openSaveKeyModal = () =>
  action(OnboardingActionTypes.OPEN_SAVE_KEY_MODAL);
export const hideSaveKeyModal = () =>
  action(OnboardingActionTypes.HIDE_SAVE_KEY_MODAL);

export const openLoadKeyModal = () =>
  action(OnboardingActionTypes.OPEN_LOAD_KEY_MODAL);
export const hideLoadKeyModal = () =>
  action(OnboardingActionTypes.HIDE_LOAD_KEY_MODAL);

export const openShareDataModal = (callFrom?: string) =>
  action(OnboardingActionTypes.OPEN_SHARE_DATA_MODAL,{callFrom});
export const hideShareDataModal = () =>
  action(OnboardingActionTypes.HIDE_SHARE_DATA_MODAL);

export const openCoachTeacherRelationModal = () =>
  action(OnboardingActionTypes.OPEN_COACH_TEACHER_RELATION_MODAL);
export const hideCoachTeacherRelationModal = () =>
  action(OnboardingActionTypes.HIDE_COACH_TEACHER_RELATION_MODAL);

export const openViewBenchmarksModal = () =>
  action(OnboardingActionTypes.OPEN_VIEW_BENCHMARKS_MODAL);
export const hideViewBenchmarksModal = () =>
  action(OnboardingActionTypes.HIDE_VIEW_BENCHMARKS_MODAL);

export const openEditMeasureColorCriteriaModal = () =>
  action(OnboardingActionTypes.OPEN_EDIT_MEASURE_COLOR_CRITERIA_MODAL);
export const hideEditMeasureColorCriteriaModal = () =>
  action(OnboardingActionTypes.HIDE_EDIT_MEASURE_COLOR_CRITERIA_MODAL);

export const openPickDataSourceModal = () =>
  action(OnboardingActionTypes.OPEN_PICK_DATA_SOURCE_MODAL);
export const hidePickDataSourceModal = () =>
  action(OnboardingActionTypes.HIDE_PICK_DATA_SOURCE_MODAL);

export const openConfirmRecommendModal = (hasAcademic?: boolean, hasBehavior?: boolean) =>
  action(OnboardingActionTypes.OPEN_CONFIRM_RECOMMEND_MODAL, {hasAcademic, hasBehavior});
export const hideConfirmRecommendModal = () =>
  action(OnboardingActionTypes.HIDE_CONFIRM_RECOMMEND_MODAL);

export const openConfirmSecondaryGoalCreateModal = (group: InterventionGroup) =>
  action(OnboardingActionTypes.OPEN_CONFIRM_SECONDARY_GOAL_CREATE_MODAL, {group});
export const hideConfirmSecondaryGoalCreateModal = () =>
  action(OnboardingActionTypes.HIDE_CONFIRM_SECONDARY_GOAL_CREATE_MODAL);

export const openUploadStudentDataModal = () =>
  action(OnboardingActionTypes.OPEN_UPLOAD_STUDENT_DATA_MODAL);
export const hideUploadStudentDataModal = () =>
  action(OnboardingActionTypes.HIDE_UPLOAD_STUDENT_DATA_MODAL);

export const openEnterDataManuallyModal = (type?: GoalTypes) =>
  action(OnboardingActionTypes.OPEN_ENTER_DATA_MANUALLY_MODAL, type);
export const hideEnterDataManuallyModal = () =>
  action(OnboardingActionTypes.HIDE_ENTER_DATA_MANUALLY_MODAL);

export const openAddAssessmentModal = (isNewProgressMonitoring?: boolean) =>
  action(OnboardingActionTypes.OPEN_ADD_ASSESSMENT_MODAL, {
    isNewProgressMonitoring
  });
export const hideAddAssessmentModal = () =>
  action(OnboardingActionTypes.HIDE_ADD_ASSESSMENT_MODAL);
export const hideAddProgressMonitorModal = () => 
  action(OnboardingActionTypes.HIDE_ADD_PROGRESS_MONITOR_MODAL);
export const resetRedirectToSetGoal = () => 
  action(OnboardingActionTypes.RESET_REDIRECT_TO_SET_GOAL);

export const openSearchForYourAssessmentModal = () =>
  action(OnboardingActionTypes.OPEN_SEARCH_FOR_YOUR_ASSESSMENT_MODAL);
export const hideSearchForYourAssessmentModal = () =>
  action(OnboardingActionTypes.HIDE_SEARCH_FOR_YOUR_ASSESSMENT_MODAL);

export const openConductInterviewModal = (selectedInterview?: Interview) =>
  action(OnboardingActionTypes.OPEN_CONDUCT_INTERVIEW_MODAL, {
    selectedInterview,
  });
export const hideConductInterviewModal = () =>
  action(OnboardingActionTypes.HIDE_CONDUCT_INTERVIEW_MODAL);

export const setNewInviteData = (newInviteData: SendInviteRequest) =>
  action(OnboardingActionTypes.SET_NEW_INVITE_DATA, {
    newInviteData,
  });

export const detectAssessmentRequest = () =>
  action(OnboardingActionTypes.DETECT_ASSESSMENT_START);
export const detectAssessmentSuccess = (
  assessmentResponse: AssessmentResponse
) =>
  action(OnboardingActionTypes.DETECT_ASSESSMENT_SUCCESS, {
    assessmentResponse,
  });
export const detectAssessmentFailure = (message: string) =>
  action(OnboardingActionTypes.DETECT_ASSESSMENT_FAILURE, {
    error: message,
  });

export const uploadEvidenceDataRequest = () =>
  action(OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_START);
export const uploadEvidenceDataSuccess = (data: any) =>
  action(OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_SUCCESS, {
    data,
  });
export const uploadEvidenceDataFailure = (message: string) =>
  action(OnboardingActionTypes.UPLOAD_EVIDENCE_DATA_FAILURE, {
    error: message,
  });

export const uploadAssessmentDataRequest = () =>
  action(OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_START);
export const uploadAssessmentDataSuccess = (data:uploadedAssessment) =>
  action(OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_SUCCESS, {
    data
  });
export const uploadAssessmentDataFailure = (message: string) =>
  action(OnboardingActionTypes.UPLOAD_ASSESSMENT_DATA_FAILURE, {
    error: message,
  });

export const uploadUndefinedAssessmentDataRequest = () =>
  action(OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_START);
export const uploadUndefinedAssessmentSetStudentId = (column: string) =>
  action(OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_SET_STUDENT_ID, {
    column,
  });

export const uploadUndefinedAssessmentDataSuccess = () =>
  action(OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_SUCCESS);
export const uploadUndefinedAssessmentDataFailure = (message: string) =>
  action(OnboardingActionTypes.UPLOAD_UNDEFINED_ASSESSMENT_DATA_FAILURE, {
    error: message,
  });

export const deleteEvidenceColumnsGroupRequest = () =>
  action(OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_START);
export const deleteEvidenceColumnsGroupSuccess = (evidenceColumnGroupId:number) =>
  action(OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_SUCCESS, {
    evidenceColumnGroupId
  });
export const deleteEvidenceColumnsGroupFailure = (message: string) =>
  action(OnboardingActionTypes.DELETE_EVIDENCE_COLUMNS_GROUP_FAILURE, {
    error: message,
  });

export const getEvidenceInDataPeriodRequest = () =>
  action(OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_START);
export const getEvidenceInDataPeriodSuccess = (
  evidencePeriodData: EvidencePeriodData
) =>
  action(OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_SUCCESS, {
    evidencePeriodData,
  });
export const getEvidenceInDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.GET_EVIDENCE_IN_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const getEvidenceAssessmentsRequest = () =>
  action(OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_START);
export const getEvidenceAssessmentsSuccess = (
  evidenceAssessments: Array<EvidenceAssessment>
) =>
  action(OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_SUCCESS, {
    evidenceAssessments,
  });
export const getEvidenceAssessmentsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_EVIDENCE_ASSESSMENTS_FAILURE, {
    error: message,
  });

export const getStaticDataCategoryDomainsRequest = () =>
  action(OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_START);
export const getStaticDataCategoryDomainsSuccess = (
  staticDataCategoryDomains: Array<CategoryBehavior>
) =>
  action(OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_SUCCESS, {
    staticDataCategoryDomains,
  });
export const getStaticDataCategoryDomainsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_STATIC_DATA_CATEGORY_DOMAINS_FAILURE, {
    error: message,
  });

export const addCategoryBehaviorStart = () =>
  action(OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_START);
export const addCategoryBehaviorSuccess = () =>
  action(OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_SUCCESS, {});
export const addCategoryBehaviorFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_CATEGORY_BEHAVIOR_FAILURE, {
    error: message,
  });

export const addTargetBehaviorToDataPeriodStart = () =>
  action(OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_START);
export const addTargetBehaviorToDataPeriodSuccess = (
  evidencePeriodDataChanges: EvidencePeriodData
) =>
  action(OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_SUCCESS, {
    evidencePeriodDataChanges,
  });
export const addTargetBehaviorToDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_TARGET_BEHAVIOR_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const addStudentsToDataPeriodRequest = () =>
  action(OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_START);
export const addStudentsToDataPeriodSuccess = (
  studentRows: Array<StudentRow>
) =>
  action(OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_SUCCESS, {
    studentRows,
  });
export const addStudentsToDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_STUDENTS_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const createAssessmentRequest = () =>
  action(OnboardingActionTypes.CREATE_ASSESSMENT_START);
export const createAssessmentSuccess = (assessment?: EvidenceAssessment) =>
  action(OnboardingActionTypes.CREATE_ASSESSMENT_SUCCESS,{
    assessment
  });
export const createAssessmentFailure = (message: string) =>
  action(OnboardingActionTypes.CREATE_ASSESSMENT_FAILURE, {
    error: message,
  });

export const addAssessmentToDataPeriodRequest = () =>
  action(OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_START);
export const addAssessmentToDataPeriodSuccess = (
  newEvidence: EvidencePeriodData
) =>
  action(OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_SUCCESS, {
    newEvidence,
  });
export const addAssessmentToDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_ASSESSMENT_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const setValueOfDSEtoDataPeriodRequest = () =>
  action(OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_START);
export const setValueOfDSEtoDataPeriodSuccess = (
  evidenceColumnGroupId: number,
  dseAffectsResponse: DseAffectsResponse
) =>
  action(OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS, {
    evidenceColumnGroupId,
    dseAffectsResponse,
  });
export const setValueOfDSEtoDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.SET_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const updateValueOfDSEtoDataPeriodRequest = () =>
  action(OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_START);
export const updateValueOfDSEtoDataPeriodSuccess = (
  evidenceColumnGroupId: number,
  dseAffectsResponse: DseAffectsResponse
) =>
  action(OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_SUCCESS, {
    evidenceColumnGroupId,
    dseAffectsResponse,
  });
export const updateValueOfDSEtoDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_VALUE_OF_DSE_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const detectNonExistingStudentIdsRequest = () =>
  action(OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_START);
export const detectNonExistingStudentIdsSuccess = (
  nonExistingIds: Array<string>
) =>
  action(OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_SUCCESS, {
    nonExistingIds,
  });
export const detectNonExistingStudentIdsFailure = (message: string) =>
  action(OnboardingActionTypes.DETECT_NON_EXISTING_STUDENT_IDS_FAILURE, {
    error: message,
  });

export const getDataPeriodsRequest = () =>
  action(OnboardingActionTypes.GET_DATA_PERIODS_START);
export const getDataPeriodsSuccess = (dataPeriods: Array<DataPeriod>) =>
  action(OnboardingActionTypes.GET_DATA_PERIODS_SUCCESS, {
    dataPeriods,
  });
export const getDataPeriodsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_DATA_PERIODS_FAILURE, {
    error: message,
  });

export const changeDataPeriod = (dataPeriod?: DataPeriod) =>
  action(OnboardingActionTypes.CHANGE_DATA_PERIOD, {
    dataPeriod,
  });

export const getCustomColorCriteriaRequest = () =>
  action(OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_START);
export const getCustomColorCriteriaSuccess = (
  colorCriteria: Array<ColorCriteria>
) =>
  action(OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_SUCCESS, {
    colorCriteria,
  });
export const getCustomColorCriteriaFailure = (message: string) =>
  action(OnboardingActionTypes.GET_CUSTOM_COLOR_CRITERIA_FAILURE, {
    error: message,
  });

export const updateCustomColorCriteriaRequest = () =>
  action(OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_START);
export const updateCustomColorCriteriaSuccess = () =>
  action(OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_SUCCESS);
export const updateCustomColorCriteriaFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_CUSTOM_COLOR_CRITERIA_FAILURE, {
    error: message,
  });

export const restoreDefaultBenchmarksRequest = () =>
  action(OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_START);
export const restoreDefaultBenchmarksSuccess = () =>
  action(OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_SUCCESS);
export const restoreDefaultBenchmarksFailure = (message: string) =>
  action(OnboardingActionTypes.RESTORE_DEFAULT_BENCHMARKS_FAILURE, {
    error: message,
  });

export const changeStudentDSEGradeRequest = () =>
  action(OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_START);
export const changeStudentDSEGradeSuccess = (studentId: number, grade: Grade) =>
  action(OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_SUCCESS, {
    studentId,
    grade,
  });
export const changeStudentDSEGradeFailure = (message: string) =>
  action(OnboardingActionTypes.CHANGE_STUDENT_DSE_GRADE_FAILURE, {
    error: message,
  });

export const addStudentSkillsRequest = () =>
  action(OnboardingActionTypes.ADD_STUDENT_SKILLS_START);
export const addStudentSkillsSuccess = (
  diagnosticTags: Array<DiagnosticTag>,
  studentId: number,
  grade?: Grade
) =>
  action(OnboardingActionTypes.ADD_STUDENT_SKILLS_SUCCESS, {
    diagnosticTags,
    studentId,
    grade,
  });
export const addStudentSkillsFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_STUDENT_SKILLS_FAILURE, {
    error: message,
  });

export const deleteDataStudentEntryRequest = () =>
  action(OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_START);
export const deleteDataStudentEntrySuccess = (studentEntryId: number) =>
  action(OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_SUCCESS, {
    studentEntryId,
  });
export const deleteDataStudentEntryFailure = (message: string) =>
  action(OnboardingActionTypes.DELETE_DATA_STUDENT_ENTRY_FAILURE, {
    error: message,
  });

export const getDataSharingTableDataRequest = () =>
  action(OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_START);
export const getDataSharingTableDataSuccess = (
  dataSharingTableData: Array<DataSharingTableData>
) =>
  action(OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_SUCCESS, {
    dataSharingData: dataSharingTableData,
  });
export const getDataSharingTableDataFailure = (message: string) =>
  action(OnboardingActionTypes.GET_DATA_SHARING_TABLE_DATA_FAILURE, {
    error: message,
  });

export const shareCoachTeacherRelationDataRequest = () =>
  action(OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_START);
export const shareCoachTeacherRelationDataSuccess = (
  coachTeacherRelationData: DataSharingTableData
) =>
  action(OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_SUCCESS, {
    coachTeacherRelationData,
  });
export const shareCoachTeacherRelationDataFailure = (message: string) =>
  action(OnboardingActionTypes.SHARE_COACH_TEACHER_RELATION_DATA_FAILURE, {
    error: message,
  });

export const updateDataSharingTableDataRequest = () =>
  action(OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_START);
export const updateDataSharingTableDataSuccess = (
  dataSharingTableData: DataSharingTableData
) =>
  action(OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_SUCCESS, {
    dataSharingData: dataSharingTableData,
  });
export const updateDataSharingTableDataFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_DATA_SHARING_TABLE_DATA_FAILURE, {
    error: message,
  });

export const deleteDataSharingTableDataRequest = () =>
  action(OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_START);
export const deleteDataSharingTableDataSuccess = (
  id: number,
  teacherId: number
) =>
  action(OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_SUCCESS, {
    id,
    teacherId,
  });
export const deleteDataSharingTableDataFailure = (message: string) =>
  action(OnboardingActionTypes.DELETE_DATA_SHARING_TABLE_DATA_FAILURE, {
    error: message,
  });

export const sendManagerKeyRequestRequest = () =>
  action(OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_START);
export const sendManagerKeyRequestSuccess = () =>
  action(OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_SUCCESS);
export const sendManagerKeyRequestFailure = (message: string) =>
  action(OnboardingActionTypes.SEND_MANAGER_KEY_REQUEST_FAILURE, {
    error: message,
  });

export const openDataSharingEditModal = () =>
  action(OnboardingActionTypes.OPEN_DATA_SHARING_EDIT_MODAL);
export const hideDataSharingEditModal = () =>
  action(OnboardingActionTypes.HIDE_DATA_SHARING_EDIT_MODAL);

export const openGeneratePDFModal = () =>
  action(OnboardingActionTypes.OPEN_GENERATE_PDF_MODAL);
export const hideGeneratePDFModal = () =>
  action(OnboardingActionTypes.HIDE_GENERATE_PDF_MODAL);


export const openInviteTeachersModal = () =>
  action(OnboardingActionTypes.OPEN_INVITE_TEACHERS_MODAL);
export const hideInviteTeachersModal = () =>
  action(OnboardingActionTypes.HIDE_INVITE_TEACHERS_MODAL);

export const changeSelectedTeacher = (selectedTeacher?: TeacherInfo) =>
  action(OnboardingActionTypes.SELECTED_TEACHER_CHANGED, { selectedTeacher });
export const changeSelectedStudent = (selectedStudent?: Student) =>
  action(OnboardingActionTypes.SELECTED_STUDENT_CHANGED, { selectedStudent });
export const changeSelectedClass = (selectedClass?: TeacherClass) =>
  action(OnboardingActionTypes.SELECTED_CLASS_CHANGED, { selectedClass });
export const changeSelectedReminder = (selectedReminder?: Reminder) =>
  action(OnboardingActionTypes.SELECTED_REMINDER_CHANGED, { selectedReminder });

export const uploadCsvFile = (
  fileName: string,
  columnsNames: Array<string>,
  rawData: Array<any>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(detectAssessment(columnsNames));
    dispatch(getCsvFile(fileName, columnsNames, rawData));
  };
};

export const uploadMultipleCsvFile = (
  uploadCSVArray:any
) => {
  let columnsObj = {
    year: uploadCSVArray.year,
    grade: uploadCSVArray.grade
  };
  for (const key in uploadCSVArray) {
    columnsObj = {
      ...columnsObj,
      [key] : uploadCSVArray[key].headers
    };
  }
  return (dispatch: Dispatch<any>) => {
    dispatch(detectMultiAssessment(columnsObj));
    dispatch(getMultiCsvFile(uploadCSVArray));
  };
};

export const getCsvFile = (
  fileName: string,
  columnsNames: Array<string>,
  rawData: Array<any>
) =>
  action(OnboardingActionTypes.UPLOAD_CSV_FILE, {
    fileName,
    columnsNames,
    rawData,
  });

export const getMultiCsvFile = (uploadCSVArray:any) =>
  action(OnboardingActionTypes.UPLOAD_MULTI_CSV_FILE, { uploadCSVArray });

export const clearStoredCsvFile = () =>
  action(OnboardingActionTypes.CLEAR_STORED_CSV_FILE);

export const updateCsvFileRawData = (rawData: Array<any>) =>
  action(OnboardingActionTypes.UPDATE_CSV_FILE_RAW_DATA, { rawData });

export const changeStudentsFilter = (studentFilter: string) =>
  action(OnboardingActionTypes.CHANGE_STUDENTS_FILTER, { studentFilter });

export const sendDataFilterMessage = (messageParts: any[]) => 
  action(OnboardingActionTypes.SEND_DATA_FILTER_MESSAGE, { messageParts });

export const changeTeacherSearchFilter = (teachersFilter: string) =>
  action(OnboardingActionTypes.CHANGE_TEACHER_SEARCH_FILTER, { teachersFilter });

export const changeStudentsDisplaySetting = (
  studentsDisplaySettings: StudentsDisplaySettings
) =>
  action(OnboardingActionTypes.CHANGE_STUDENTS_DISPLAY_SETTINGS, {
    studentsDisplaySettings,
  });

export const getTeachers = (withDisabled?: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(getTeachersRequest());
    return axios
      .get(API_TEACHERS_ROSTER(withDisabled))
      .then((res) => { 
        const teacherInfos: Array<TeacherInfo> = res.data;
        dispatch(getTeachersSuccess(teacherInfos));
        return Promise.resolve(teacherInfos);
      })
      .catch((err: AxiosError) => {
        dispatch(getTeachersFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getStudentsByInterventionGroup = () => {
  return (dispatch: Dispatch) => {
    dispatch(getStudentsByInterventionGroupRequest());
    return axios
      .get(API_STUDENTS_BY_INTERVENTION_GROUP)
      .then((res) => {
        const studentsByInterventionGroupIds: Array<any> = res.data.students_with_intervention_group;
        dispatch(getStudentsByInterventionGroupSuccess(studentsByInterventionGroupIds));
        return Promise.resolve(studentsByInterventionGroupIds);
      })
      .catch((err: AxiosError) => {
        dispatch(getStudentsByInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateSelectedUserData = (selectedUsers:any) => 
action(OnboardingActionTypes.UPDATE_SELECTED_USER_DATA, {selectedUsers});

export const getCoachesAction = createAsyncAction(
  "@@onboarding/GET_COACHES_REQUEST",
  "@@onboarding/GET_COACHES_SUCCESS",
  "@@onboarding/GET_COACHES_FAILURE"
)<void, CoachInfo[], string>();

export const getCoaches = () => {
  return (dispatch: Dispatch) => {
    dispatch(getCoachesAction.request());
    return axios
      .get(API_COACHES_ROSTER)
      .then((res) => {
        const coachInfos: Array<CoachInfo> = res.data;
        dispatch(getCoachesAction.success(coachInfos));
      })
      .catch((err: AxiosError) => {
        dispatch(getCoachesAction.failure(err.message));
      });
  };
};

export const updateTeacher = (id: number, updatedInfo: RenewedTeacherInfo) => {
  return (dispatch: Dispatch) => {
    dispatch(updateTeacherInfoRequest());
    return axios
      .put(API_TEACHERS_ROSTER_UPDATE_INFO(id), { ...updatedInfo })
      .then((res) => {
        const renewedTeacherInfo: RenewedTeacherInfo = res.data;
        dispatch(updateTeacherInfoSuccess(id, renewedTeacherInfo));
      })
      .catch((err: AxiosError) => {
        dispatch(updateTeacherInfoFailure(err.message));
      });
  };
};

export const getClassesAssignedToTeacher = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getClassesAssignedToTeacherRequest());
    return axios
      .get(API_TEACHERS_ROSTER_CLASSES(id))
      .then((res) => {
        const teachersClasses: Array<TeacherClass> = res.data;
        dispatch(getClassesAssignedToTeacherSuccess(teachersClasses));
        return teachersClasses;
      })
      .catch((err: AxiosError) => {
        dispatch(getClassesAssignedToTeacherFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getClassesLinkedToStudentRequest = () =>
  action(OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_START);
export const getClassesLinkedToStudentSuccess = (
  classesLinkedToStudent: Array<TeacherStudentClass>
) =>
  action(OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_SUCCESS, {
    classesLinkedToStudent,
  });
export const getClassesLinkedToStudentFailure = (message: string) =>
  action(OnboardingActionTypes.GET_CLASSES_LINKED_TO_STUDENT_FAILURE, {
    error: message,
  });

export const getClassesLinkedToStudent = (studentId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getClassesLinkedToStudentRequest());
    return axios
      .get(API_DISTRICT_STUDENT_CLASSES(studentId))
      .then((res) => {
        const classesLinkedToStudent: Array<TeacherStudentClass> = res.data;
        dispatch(getClassesLinkedToStudentSuccess(classesLinkedToStudent));
      })
      .catch((err: AxiosError) => {
        dispatch(getClassesLinkedToStudentFailure(err.message));
      });
  };
};

export const getAllClassesRequest = () =>
  action(OnboardingActionTypes.GET_ALL_CLASSES_START);
export const getAllClassesSuccess = (allClasses: Array<TeacherStudentClass>) =>
  action(OnboardingActionTypes.GET_ALL_CLASSES_SUCCESS, {
    allClasses,
  });
export const getAllClassesFailure = (message: string) =>
  action(OnboardingActionTypes.GET_ALL_CLASSES_FAILURE, {
    error: message,
  });

export const getAllClasses = (dataPeriodId:number = 0, grade?:string) => {
  return (dispatch: Dispatch) => {
    dispatch(getAllClassesRequest());
    return axios
      .get(API_DISTRICT_ALL_CLASSES(dataPeriodId, grade))
      .then((res) => {
        const classesLinkedToStudent: Array<TeacherStudentClass> = res.data;
        dispatch(getAllClassesSuccess(classesLinkedToStudent));
      })
      .catch((err: AxiosError) => {
        dispatch(getAllClassesFailure(err.message));
      });
  };
};

export const reassignClassToTeacherAction = createAsyncAction(
  "@@onboarding/REASSIGN_CLASS_TO_TEACHER_REQUEST",
  "@@onboarding/REASSIGN_CLASS_TO_TEACHER_SUCCESS",
  "@@onboarding/REASSIGN_CLASS_TO_TEACHER_FAILURE"
)<void, { classId: number; teacher: UserInfo }, string>();

export const reassignClassToTeacher = (classId: number, teacher: UserInfo) => {
  return (dispatch: Dispatch) => {
    dispatch(reassignClassToTeacherAction.request());
    return axios
      .patch(API_DISTRICT_ALL_CLASSES(classId), { teacher: teacher.id })
      .then((res) => {
        dispatch(reassignClassToTeacherAction.success({ classId, teacher }));
      })
      .catch((err: AxiosError) => {
        dispatch(reassignClassToTeacherAction.failure(err.message));
      });
  };
};

export const updateClassesLinkedToStudentRequest = () =>
  action(OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_START);
export const updateClassesLinkedToStudentSuccess = (
  studentClasses: StudentClass[],
  students: Student[]
) =>
  action(OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_SUCCESS, {
    studentClasses,
    students
  });
export const updateClassesLinkedToStudentFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_CLASSES_LINKED_TO_STUDENT_FAILURE, {
    error: message,
  });

export const updateClassesLinkedToStudent = (
  students: Array<Student>,
  classesIds: Array<number>,
  studentId: number = 0,
  dataPeriodId: number = 0,
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateClassesLinkedToStudentRequest());
    return axios
      .put(API_DISTRICT_STUDENT_CLASSES(studentId, dataPeriodId), { classes: classesIds, students })
      .then((res) => {
        const studentClasses: StudentClass[] = res.data.classes ?? [];
        const students: Student[] = res.data.students ?? [];
        dispatch(updateClassesLinkedToStudentSuccess(studentClasses, students));
      })
      .catch((err: AxiosError) => {
        dispatch(updateClassesLinkedToStudentFailure(err.message));
      });
  };
};

export const assignClassToTeacher = (
  teacherId: number,
  className: string,
  students: Array<Student>,
  grade?: string,
  dataPeridId?: number,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(assignClassToTeacherRequest());
    return axios
      .post(API_TEACHERS_ROSTER_CLASSES(teacherId,0, dataPeridId), {
        name: className,
        grade: grade,
        students,
      })
      .then((res) => {
        const teacherClass: TeacherClass = res.data;
        dispatch(assignClassToTeacherSuccess(teacherId, teacherClass));
        return teacherClass;
      })
      .catch((err: AxiosError) => {
        // if (err.response) {
        //   const errors: any = err.response.data;
        //   dispatch(
        //     assignClassToTeacherFailure(
        //       Object.entries(errors)
        //         .map(err => `${err[0]} ${err[1]}`)
        //         .join(", "),
        //     ),
        //   );
        // } else {
        dispatch(assignClassToTeacherFailure(err.message));
        // }
        return Promise.reject(err.message);
      });
  };
};

export const updateTeacherClass = (
  teacherId: number,
  updatedClass: TeacherClass
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateTeacherClassRequest());
    return axios
      .put(API_TEACHERS_ROSTER_CLASSES(teacherId, updatedClass.id), {
        name: updatedClass.name,
        students: updatedClass.students,
      })
      .then(() => {
        dispatch(updateTeacherClassSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(updateTeacherClassFailure(err.message));
      });
  };
};

export const getStudentsAssignedToTeacher = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getStudentsAssignedToTeacherRequest());
    return axios
      .get(API_TEACHERS_ROSTER_STUDENTS(id))
      .then((res) => {
        const students: Array<Student> = res.data;
        dispatch(getStudentsAssignedToTeacherSuccess(students));
      })
      .catch((err: AxiosError) => {
        dispatch(getStudentsAssignedToTeacherFailure(err.message));
      });
  };
};

export const assignStudentsToTeacher = (
  teacherId: number,
  students: Array<Student>,
  teacherInfo?: UserInfo, //todo remove redundant parameters
  isNewStudentAdd?: boolean
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(assignStudentsToTeacherRequest(teacherId));
    return axios
      .post(API_TEACHERS_ROSTER_STUDENTS(teacherId), {
        students,
      })
      .then((res) => {
        const students: Array<Student> = res.data.students;
        dispatch(
          assignStudentsToTeacherSuccess(teacherId, students, teacherInfo, isNewStudentAdd)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(assignStudentsToTeacherFailure(teacherId, err.message));
        return Promise.reject(err);
      });
  };
};

export const getStudents = (includeClasses?: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(getStudentsRequest());
    return axios
      .get(API_STUDENTS_ROSTER + (includeClasses ? "?include=classes" : ""))
      .then((res) => {
        const studentInfos: Array<StudentInfo> = res.data;
        dispatch(getStudentsSuccess(studentInfos));
      })
      .catch((err: AxiosError) => {
        dispatch(getStudentsFailure(err.message));
      });
  };
};

export const addStudents = (
  students: Array<Student>,
  addStudentsToEvidence?: boolean
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(addStudentsRequest());
    return axios
      .post(API_STUDENTS_ROSTER, { students })
      .then((res) => {
        const studentInfos: Array<Student> = res.data.students;
        dispatch(addStudentsSuccess(studentInfos));
        if (addStudentsToEvidence) {
          addStudentsToDataPeriod(studentInfos.map((st) => st.id!))(
            dispatch,
            getState
          );
        }
      })
      .catch((err: AxiosError) => {
        dispatch(addStudentsFailure(err.message));
      });
  };
};

export const updateTeacherStudentDetails = (student: Student, studentGrade?:Grade) => {
  return (dispatch: Dispatch) => {
    dispatch(updateStudentDetailsRequest());
    return axios
      .put(`${API_STUDENTS_ROSTER}/${student.id}`, {
        ...student,
      })
      .then((res) => {
        const updatedStudent: Student = res.data;
        dispatch(updateStudentDetailsSuccess(student.id!, updatedStudent, studentGrade!));
      })
      .catch((err: AxiosError) => {
        dispatch(updateStudentDetailsFailure(err.message));
      });
  };
};

export const getSchools = () => {
  return (dispatch: Dispatch) => {
    dispatch(getSchoolsRequest());
    return axios
      .get(API_DISTRICT_SCHOOLS())
      .then((res) => {
        const schools: Array<School> = res.data;
        dispatch(getSchoolsSuccess(schools));
      })
      .catch((err: AxiosError) => {
        dispatch(getSchoolsFailure(err.message));
      });
  };
};

export const addSchool = (school: AddSchoolRequest, districtId?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(addSchoolRequest());
    return axios
      .post(API_DISTRICT_SCHOOLS(), school)
      .then((res) => {
        dispatch(addSchoolSuccess(res.data));
      })
      .catch((err: AxiosError) => {
        dispatch(addSchoolFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateSchool = (school: School) => {
  return (dispatch: Dispatch) => {
    dispatch(updateSchoolRequest());
    return axios
      .put(API_DISTRICT_SCHOOLS(school.id), { school })
      .then((res) => {
        dispatch(updateSchoolSuccess(res.data));
      })
      .catch((err: AxiosError) => {
        dispatch(updateSchoolFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const deleteSchoolRequest = () =>
  action(OnboardingActionTypes.DELETE_SCHOOL_START);
export const deleteSchoolSuccess = (schoolId: number) =>
  action(OnboardingActionTypes.DELETE_SCHOOL_SUCCESS, { schoolId });
export const deleteSchoolFailure = () =>
  action(OnboardingActionTypes.DELETE_SCHOOL_FAILURE);

export const deleteSchool = (schoolId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteSchoolRequest());
    return axios
      .delete(API_DISTRICT_SCHOOLS(schoolId))
      .then((res) => {
        dispatch(deleteSchoolSuccess(schoolId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteSchoolFailure());
        return Promise.reject(err.message);
      });
  };
};

export const getParents = () => {
  return (dispatch: Dispatch) => {
    dispatch(getParentsRequest());
    return axios
      .get(API_PARENTS)
      .then((res) => {
        const parents: Array<UserInfo> = res.data;
        dispatch(getParentsSuccess(parents));
      })
      .catch((err: AxiosError) => {
        dispatch(getParentsFailure(err.message));
      });
  };
};

export const checkEmailExist = (requests: any[]) => {
  return (dispatch: Dispatch) => {
    return axios
      .post(API_CHECK_EMAIL_EXIST, { emails: requests })
      .then((res) => {
        const existingEmails: EmailCheckResponse = res.data;
        return Promise.resolve(existingEmails);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err.message);
      });
  };
};

export const createUserInDistrict = (requests: AddUserToDistrictRequest[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(addUserToDistrictRequest());
    const results = requests.map((req) => {
      const data = {
        first_name: req.first_name,
        last_name: req.last_name,
        email: req.email,
        profile: {
          current_assignment: {
            role: req.role,
          },
        },
        ...(req.resend_invite ? {resend_invite: req.resend_invite}: '')
      };
      return axios.post(API_DISTRICT_USERS, data).then((res) => {
        return res.data;
      });
    });

    return Promise.all(results)
      .then((addedUsers: Array<TeacherInfo>) => {
        const users: TeacherInfo[] = addedUsers;
        dispatch(addUserToDistrictSuccess(users));
      })
      .catch((error: AxiosError) => {
        dispatch(addUserToDistrictFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const sendInviteToTeachers = (request: SendInviteRequest[]) => {
  return (dispatch: Dispatch) => {
    dispatch(sendInviteToTeachersRequest());
    return axios
      .post(API_SEND_INVITE_TO_TEACHERS, { teachers: request })
      .then((res) => {
        const invitesResponse: InvitesResponse = res.data;
        dispatch(sendInviteToTeachersSuccess(invitesResponse));
        return Promise.resolve(invitesResponse)
      })
      .catch((err: AxiosError) => {
        dispatch(sendInviteToTeachersFailure(err.message));
        return Promise.reject(err.message)
      });
  };
};

export const mergeTeachers = (keep: number, merge: number) => {
  return (dispatch: Dispatch) => {
    dispatch(mergeTeachersRequest());
    return axios
      .post(API_MERGE_TEACHERS, { keep, merge })
      .then((res) => {
        const response: TeacherInfo = res.data;
        dispatch(mergeTeachersSuccess(response, merge));
      })
      .catch((err: AxiosError) => {
        dispatch(mergeTeachersFailure(err.message));
      });
  };
};

export const mergeSchools = (keep: number, merge: number) => {
  return (dispatch: Dispatch) => {
    dispatch(mergeSchoolsRequest());
    return axios
      .post(API_MERGE_SCHOOLS, { keep, merge })
      .then((res) => {
        const response: School = res.data;
        dispatch(mergeSchoolsSuccess(response, merge));
      })
      .catch((err: AxiosError) => {
        dispatch(mergeSchoolsFailure(err.message));
      });
  };
};

export const getReminders = () => {
  return (dispatch: Dispatch) => {
    dispatch(getRemindersRequest());
    return axios
      .get(API_REMINDER())
      .then((res) => {
        const response: Reminder[] = res.data;
        dispatch(getRemindersSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(getRemindersFailure(err.message));
      });
  };
};

export const updateReminder = (
  reminderId: number,
  reminder: ScheduleReminderRequest
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateReminderRequest());
    return axios
      .put(API_REMINDER(reminderId), reminder)
      .then((res) => {
        const response: Reminder = res.data;
        dispatch(updateReminderSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(updateReminderFailure(err.message));
      });
  };
};

export const deleteReminder = (reminderId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteReminderRequest());
    return axios
      .delete(API_REMINDER(reminderId))
      .then(() => {
        dispatch(deleteReminderSuccess(reminderId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteReminderFailure(err.message));
      });
  };
};

export const scheduleReminder = (request: ScheduleReminderRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(scheduleReminderRequest());
    return axios
      .post(API_REMINDER(), request)
      .then((res) => {
        const response: Reminder = res.data;
        dispatch(scheduleReminderSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(scheduleReminderFailure(err.message));
      });
  };
};

export const changeStudentDSEGrade = (
  dataPeriodId: number,
  studentId: number,
  grade: Grade
) => {
  return (dispatch: Dispatch) => {
    dispatch(changeStudentDSEGradeRequest());
    const data = JSON.stringify({ grade: grade });
    return axios
      .put(API_STUDENT_DSE_GRADE(dataPeriodId, studentId), data)
      .then(() => {
        dispatch(changeStudentDSEGradeSuccess(studentId, grade));
      })
      .catch((err: AxiosError) => {
        dispatch(changeStudentDSEGradeFailure(err.message));
      });
  };
};

export const getTeachersFromStudentRows = (gradesFilter: Grade[]) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { evidencePeriodData } = getState().onboarding;
    const teachers: BasicUserInfo[] = [];
    if (evidencePeriodData) {
      evidencePeriodData.student_rows.forEach((sr) => {
        if (
          gradesFilter.length === 0 ||
          evidencePeriodData.evidence_column_groups.some((ecg) =>
            ecg.evidence_columns.some((ec) =>
              ec.student_entries.some(
                (se) =>
                  se.student_id === sr.student.id &&
                  se.grade &&
                  gradesFilter.includes(se.grade)
              )
            )
          )
        )
          sr.student.teachers!.forEach((teacher) =>
            !teachers.some((t) => t.id === teacher.id)
              ? teachers.push(teacher)
              : null
          );
      });
    }
    
    return teachers.sort((a, b) => {
      if (a.first_name > b.first_name) return 1;
      if (a.first_name < b.first_name) return -1;
      if (a.last_name > b.last_name) return 1;
      if (a.last_name < b.last_name) return -1;
      return 0;
    });
  };
};

export const getClassFromStudentRowsTeachers = (gradesFilter: Grade[], teacherClassFilter: any[]) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { evidencePeriodData } = getState().onboarding;
    const classes: StudentClass[] = [];
    if (evidencePeriodData) {
      evidencePeriodData.student_rows.forEach((sr) => {
        if (
          gradesFilter.length === 0 ||
          evidencePeriodData.evidence_column_groups.some((ecg) =>
            ecg.evidence_columns.some((ec) =>
              ec.student_entries.some(
                (se) =>
                  se.student_id === sr.student.id &&
                  se.grade &&
                  gradesFilter.includes(se.grade)
              )
            )
          )
        )
          sr.student.teachers!.forEach((teacher) =>
            teacherClassFilter.length && teacherClassFilter.includes(teacher.id) ?    
              !classes.some((c) => teacher.classes?.find((tc) => c.id === tc.id)) 
                ? (teacher.classes != null ? classes.push(...teacher.classes!) : null)
                : null
            : null
          );
      });
    }
   
    return classes.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  };
};

export const addStudentSkills = (
  skill_identifiers: Array<string>,
  studentId: number,
  dataPeriodId?: number,
  grade?: Grade
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { onboarding } = getState();
    let targetDataPeriod: number | undefined;

    if (dataPeriodId) {
      targetDataPeriod = dataPeriodId;
    } else if (onboarding && onboarding.currentDataPeriod) {
      targetDataPeriod = onboarding.currentDataPeriod.id;
    }

    if (targetDataPeriod) {
      dispatch(addStudentSkillsRequest());
      const data = JSON.stringify({ identifiers: skill_identifiers });
      return axios
        .post(API_STUDENT_DIAGNOSTIC_INFO(+targetDataPeriod, studentId), data)
        .then((res) => {
          const diagnosticTags: Array<DiagnosticTag> = res.data.identifiers;
          dispatch(addStudentSkillsSuccess(diagnosticTags, studentId, grade));
        })
        .catch((err: AxiosError) => {
          dispatch(addStudentSkillsFailure(err.message));
        });
    } else {
      dispatch(addStudentSkillsFailure("Invalid data period"));
    }
  };
};

export const deleteDataStudentEntry = (dataStudentEntryId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteDataStudentEntryRequest());
    return axios
      .delete(API_DATA_STUDENT_ENTRY(dataStudentEntryId))
      .then(() => {
        console.log(dataStudentEntryId)
        dispatch(deleteDataStudentEntrySuccess(dataStudentEntryId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDataStudentEntryFailure(err.message));
      });
  };
};

export const detectAssessment = (csvHeaders: Array<string>) => {
  return (dispatch: Dispatch) => {
    dispatch(detectAssessmentRequest());
    return axios
      .post(API_DETECT_ASSESSMENT(), { csv_headers: csvHeaders.join(",") })
      .then((res) => {
        const assessment: AssessmentResponse = res.data;
        dispatch(detectAssessmentSuccess(assessment));
      })
      .catch((err: AxiosError) => {
        dispatch(detectAssessmentFailure(err.message));
      });
  };
};

export const detectMultiAssessment = (csvHeaders: any) => {
  return (dispatch: Dispatch) => {
    dispatch(detectAssessmentRequest());
    return axios
      .post(API_DETECT_ASSESSMENT(), { csv_headers: csvHeaders })
      .then((res) => {
          const assessment: AssessmentResponse = res.data;
          dispatch(detectAssessmentSuccess(assessment));
        })
     .catch((err: AxiosError) => {
        dispatch(detectAssessmentFailure(err.message));
      });
  };
};

export const uploadEvidenceData = (data: EvidenceData) => {
  return (dispatch: Dispatch) => {
    dispatch(uploadEvidenceDataRequest());
    return axios
      .post(API_EVIDENCE_DATA_UPLOAD(), data)
      .then((res) => {
        const assessment: any = res.data;
        dispatch(uploadEvidenceDataSuccess(assessment));
      })
      .catch((err: AxiosError) => {
        dispatch(uploadEvidenceDataFailure(err.message));
      });
  };
};

export const uploadAssessmentData = (
  assessmentId: number,
  rawCsvData: any,
  csvFileName: string,
  target_evidence_column_group_id?: number,
  dataPeriodId?: number,
  source?: string,
  grade?: Grade,
  timePeriod?: TimePeriod,
  year?: string,
  sheetRawData?:any,
  isDistrictAdmin?: boolean,
  upload_in_existing_shared_dp?: boolean,
  shared_data_period_selected?: number[],
  new_students_only?: string,
  currentDataPeriodIds?: number[]
) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(uploadAssessmentDataRequest());
    if (dataPeriodId || isDistrictAdmin) { 
      let sharedDataPeriodSelect = null;
      let uploadInExisting = null;
      if(shared_data_period_selected != undefined) {
        sharedDataPeriodSelect =  shared_data_period_selected
      }
      if(upload_in_existing_shared_dp != undefined) {
        uploadInExisting =  upload_in_existing_shared_dp
      }
      return axios
        .post(API_EVIDENCE_DATA_UPLOAD(assessmentId), {
          csv_file_name: csvFileName,
          data_period_id: dataPeriodId,
          raw_csv: sheetRawData.length ? "njtss files" : Papa.unparse(rawCsvData),
          target_evidence_column_group_id: target_evidence_column_group_id,
          source: source,
          grade,
          time_period: timePeriod,
          year,
          sheet_raw_data: sheetRawData,
          shared_data_period_selected: sharedDataPeriodSelect,
          upload_in_existing_shared_dp: uploadInExisting,
          new_students_only,
          current_data_period_ids: currentDataPeriodIds
        })
        .then((res) => {
          const assessment: any = res.data;
          dispatch(uploadAssessmentDataSuccess(assessment)); //todo
          return Promise.resolve(res.data.Success)
        })
        .catch((err: AxiosError) => {
          let errorMsg:string = err.message;
          if(Array.isArray(err.response?.data) && err.response?.data.length == 1) {
            errorMsg = err.response?.data[0];
          }
          dispatch(uploadAssessmentDataFailure(errorMsg));
          //return Promise.reject(err.message)
        });
    } else {
      dispatch(uploadAssessmentDataFailure("Invalid data period"));
      return Promise.reject("Invalid data period selected")
    }
  };
};

export const uploadUndefinedAssessmentData = (
  assessmentId: number,
  column_name_mappings: any,
  gradeColumn: string,
  timePeriod: string,
  assessmentStudentId: string,
  studentIdColumn: string,
  target_evidence_column_group_id?: number,
  year?: number
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(uploadUndefinedAssessmentDataRequest());
    const loadableFile = getState().onboarding.loadableFile;
    if (loadableFile) {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        let data = loadableFile.rawData;
        const columnMappings = column_name_mappings;

        const timeStamp = new Date().getTime();

        let idToUse = (studentIdColumn || "").trim();
        if (idToUse.length === 0) {
          idToUse = "AUTO_ADDED__GENERATED_STUDENT_ID";
          columnMappings[assessmentStudentId] = idToUse;
        }

        dispatch(uploadUndefinedAssessmentSetStudentId(idToUse));

        data = data.map((row: any, index: number) => {
          let studentId = (row[idToUse] || "").toString().trim();
          // if (studentId.length === 0) {
          //   studentId = `${timeStamp}_auto_${index}`;
          // }

          return {
            ...row,
            [idToUse]: studentId,
          };
        });

        dispatch(updateCsvFileRawData(data));

        return axios
          .post(API_EVIDENCE_DATA_UPLOAD(assessmentId), {
            data_period_id: currentDataPeriod.id,
            csv_file_name: loadableFile.originalFileName,
            raw_csv: Papa.unparse(data),
            column_name_mappings: column_name_mappings,
            target_evidence_column_group_id: target_evidence_column_group_id,
            grade_column: gradeColumn,
            time_period: timePeriod,
            year: year,
          })
          .then((res) => {
            dispatch(uploadUndefinedAssessmentDataSuccess()); //todo
          })
          .catch((err: AxiosError) => {
            dispatch(uploadUndefinedAssessmentDataFailure(err.message));
          });
      } else {
        dispatch(uploadUndefinedAssessmentDataFailure("Invalid data period"));
      }
    } else {
      dispatch(uploadUndefinedAssessmentDataFailure("Uploaded file not found"));
    }
  };
};

export const deleteEvidenceColumnsGroup = (evidenceColumnGroupId: number, dataPeriodId?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteEvidenceColumnsGroupRequest());
    return axios
      .delete(API_EVIDENCE_COLUMN_GROUPS(evidenceColumnGroupId))
      .then(() => {
        dispatch(deleteEvidenceColumnsGroupSuccess(evidenceColumnGroupId));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(deleteEvidenceColumnsGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

let isObject = function(a:any) {
  return (!!a) && (a.constructor === Object);
};

export const getEvidenceInDataPeriod = (id?: number) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (id) {
      dataPeriodId = id;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod && currentDataPeriod.name !== "Initial Data") {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    
    if (dataPeriodId) {
      dispatch(getEvidenceInDataPeriodRequest());
      return axios
        .get(API_GET_EVIDENCE_IN_DATE_PERIOD(dataPeriodId))
        .then((res) => {
          const evidencePeriodData: EvidencePeriodData = res.data;
          evidencePeriodData.evidence_column_groups.map(async (columnGroup) => {
            let columnGroupData = await columnGroup.evidence_columns.map((data: any) => {
           let colorCriteriaNew = [];
            if(data.measurement !== null) {
              let colorCriteria = data.measurement.custom_color_criteria;
              if((colorCriteria !== null) && (isObject(colorCriteria))) {
                for (const gradeKey in colorCriteria) {
                  for (const timePeriodKey in colorCriteria[gradeKey]) {
                    for (let index = 0; index < colorCriteria[gradeKey][timePeriodKey].length; index++) {
                      if(colorCriteria[gradeKey][timePeriodKey][index] !== null) {
                        let element = colorCriteria[gradeKey][timePeriodKey][index];
                        element['grade'] = gradeKey;
                        element['time_period'] = timePeriodKey;
                        colorCriteriaNew.push(element);
                      }
                    }
                  }
                }
                data.measurement.custom_color_criteria = colorCriteriaNew;
              }
              return data;

            }
            });
            return columnGroupData;
          });
          //console.log('evidencePeriodData', evidencePeriodData);
          let evidencePeriodDataObj: any;
          for (let i = 0; i < evidencePeriodData.evidence_column_groups.length; i++) {
            const ecg = evidencePeriodData.evidence_column_groups[i];
            let ecArray: any[] = [];
            for (let j = 0; j < ecg.evidence_columns.length; j++) {
              const ec = ecg.evidence_columns[j];
              if(ec.evidence_type === 0) {
                ecArray.push({
                  evidenceColumnId: ec.id, 
                  show: true
                })
              }

              if(ecArray.length) {
                evidencePeriodDataObj = {
                  ...evidencePeriodDataObj,
                  evidenceColumnGroupId: ecg.id,
                  evidenceColumns: ecArray
                } 
              }
              
            }
            
          }
          //console.log('evidencePeriodDataObj', evidencePeriodDataObj);
          if(evidencePeriodDataObj) { //RC-1228
            const displayedColumnsData = localStorage.getItem(
              LocalStorageKeys.DisplayedColumns
            );
            const storageColumns = displayedColumnsData
              ? JSON.parse(displayedColumnsData)
              : [];
            const existingStorageColumn = storageColumns.find(
              (displayedColumn: any) =>
                displayedColumn.evidenceColumnGroupId === evidencePeriodDataObj.evidenceColumnGroupId
            );
            
            if (existingStorageColumn) {
              if(existingStorageColumn.evidenceColumns.length !== evidencePeriodDataObj.evidenceColumns.length) {
                const displayedColumns = 
                  storageColumns.map((displayedColumn: any) => {
                    if(displayedColumn.evidenceColumnGroupId == evidencePeriodDataObj.evidenceColumnGroupId) {
                      displayedColumn = {
                        ...displayedColumn,
                        evidenceColumns: evidencePeriodDataObj.evidenceColumns
                      }
                    }
                    return displayedColumn;
                  });
                  localStorage.setItem(
                    LocalStorageKeys.DisplayedColumns, JSON.stringify(displayedColumns)
                  )
              }
            } else {
              storageColumns.push(evidencePeriodDataObj);
              localStorage.setItem(
                LocalStorageKeys.DisplayedColumns, JSON.stringify(storageColumns)
              )
            }
          }
          dispatch(getEvidenceInDataPeriodSuccess(evidencePeriodData));
          // dispatch(getEvidenceInDataPeriodSuccess({evidence_column_groups: [] , student_rows: []})); //for testing new coach)
        })
        .catch((err: AxiosError) => {
          dispatch(getEvidenceInDataPeriodFailure(err.message));
        });
    } else {
      dispatch(getEvidenceInDataPeriodFailure("Invalid data period"));
    }
  };
};

export const getStudentsDataRequest = () =>
  action(OnboardingActionTypes.GET_STUDENTS_DATA_START);
export const getStudentsDataSuccess = (
  studentsDataResponse: StudentsDataResponse
) =>
  action(OnboardingActionTypes.GET_STUDENTS_DATA_SUCCESS, {
    studentsDataResponse,
  });
export const getStudentsDataFailure = (message: string) =>
  action(OnboardingActionTypes.GET_STUDENTS_DATA_FAILURE, {
    error: message,
  });

export const getStudentsData = (id?: number) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (id) {
      dataPeriodId = id;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod && currentDataPeriod.name !== "Initial Data") {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(getStudentsDataRequest());
      return axios
        .get(API_STUDENTS_DATA(dataPeriodId))
        .then((res) => {
          const studentsDataResponse: StudentsDataResponse = res.data;
          dispatch(getStudentsDataSuccess(studentsDataResponse));
        })
        .catch((err: AxiosError) => {
          dispatch(getStudentsDataFailure(err.message));
          return Promise.reject(err.message);
        });
    } else {
      return Promise.reject("Invalid data period");
    }
  };
};

export const isAllStudentsHaveDiagnosticInfo = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { evidencePeriodData } = getState().onboarding;
    return (
      evidencePeriodData &&
      evidencePeriodData.evidence_column_groups.some((ecg) =>
        ecg.evidence_columns.some((ec) => {
          if (ec.evidence_type === EvidenceType.DiagnosticInfo) {
            return (
              ec.student_entries.length ===
              evidencePeriodData.student_rows.length &&
              !ec.student_entries.some(
                (se) => !se.diagnostic_tags || se.diagnostic_tags.length < 0
              )
            );
          } else {
            return false;
          }
        })
      )
    );
  };
};

export const getEvidenceAssessments = (type?: GoalTypes) => {
  return (dispatch: Dispatch) => {
    dispatch(getEvidenceAssessmentsRequest());
    return axios
      .get(API_EVIDENCE_ASSESSMENTS(type))
      .then((res) => {
        const evidenceAssessments: Array<EvidenceAssessment> = res.data;
        dispatch(getEvidenceAssessmentsSuccess(evidenceAssessments));
      })
      .catch((err: AxiosError) => {
        dispatch(getEvidenceAssessmentsFailure(err.message));
      });
  };
};

export const addStudentsToDataPeriod = (studentsIds: Array<number>) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(addStudentsToDataPeriodRequest());
    const currentDataPeriod = getState().onboarding.currentDataPeriod;
    if (currentDataPeriod) {
      return axios
        .post(API_STUDENTS_TO_DATA_PERIOD(currentDataPeriod.id), {
          students: studentsIds,
        })
        .then((res) => {
          const studentRows: Array<StudentRow> = res.data;
          dispatch(addStudentsToDataPeriodSuccess(studentRows));
        })
        .catch((err: AxiosError) => {
          dispatch(addStudentsToDataPeriodFailure(err.message));
        });
    } else {
      dispatch(addStudentsToDataPeriodFailure("Invalid data period"));
    }
  };
};

export const uploadStudentDemographicsActions = createAsyncAction(
  "@@onboarding/UPLOAD_STUDENT_DEMOGRAPHICS_REQUEST",
  "@@onboarding/UPLOAD_STUDENT_DEMOGRAPHICS_SUCCESS",
  "@@onboarding/UPLOAD_STUDENT_DEMOGRAPHICS_FAILURE"
)<void, Student[], string>();

export const uploadStudentDemographics = (file: File) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(uploadStudentDemographicsActions.request());
    const formData = new FormData();
    formData.append("file", file);
    const config: AxiosRequestConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios
      .post(API_STUDENTS_DEMOGRAPHIC_DATA, formData, config)
      .then((res) => {
        const updatedRecords: number = res.data.updated_records;
        dispatch(uploadStudentDemographicsActions.success([]));
        dispatch(getStudents()); // todo
        return Promise.resolve(updatedRecords);
      })
      .catch((err: AxiosError) => {
        dispatch(uploadStudentDemographicsActions.failure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const assignTeachersToStudentRequest = () =>
  action(OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_START);
export const assignTeachersToStudentSuccess = (student: Student) =>
  action(OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_SUCCESS, {
    student,
  });
export const assignTeachersToStudentFailure = (message: string) =>
  action(OnboardingActionTypes.ASSIGN_TEACHERS_TO_STUDENT_FAILURE, {
    error: message,
  });

export const assignTeachersToStudent = (
  studentId: number,
  teachersIds: Array<number>
) => {
  return (dispatch: Dispatch) => {
    dispatch(assignTeachersToStudentRequest());
    return axios
      .put(API_DISTRICT_STUDENT_TEACHERS(studentId), {
        teachers: teachersIds,
      })
      .then((res) => {
        const updatedStudent: Student = res.data;
        dispatch(assignTeachersToStudentSuccess(updatedStudent));
      })
      .catch((err: AxiosError) => {
        dispatch(assignTeachersToStudentFailure(err.message));
      });
  };
};

export const createAssessment = (assessment: Partial<EvidenceData>) => {
  return (dispatch: Dispatch) => {
    dispatch(createAssessmentRequest());
    return axios
      .post(API_ASSESSMENT, assessment)
      .then((res) => {
        dispatch(createAssessmentSuccess(res.data));
      })
      .catch((err: AxiosError) => {
        dispatch(createAssessmentFailure(err.message));
      });
  };
};

export const addAssessmentToDataPeriod = (assessment: ManuallyAssessment) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(addAssessmentToDataPeriodRequest());
    const currentDataPeriod = getState().onboarding.currentDataPeriod;
    if (currentDataPeriod) {
      return axios
        .post(API_ASSESSMENT_TO_DATA_PERIOD(currentDataPeriod.id), assessment)
        .then((res) => {
          const newEvidence: EvidencePeriodData = res.data;
          dispatch(addAssessmentToDataPeriodSuccess(newEvidence));
        })
        .catch((err: AxiosError) => {
          dispatch(addAssessmentToDataPeriodFailure(err.message));
        });
    } else {
      dispatch(addAssessmentToDataPeriodFailure("Invalid data period"));
    }
  };
};

export const setValueOfDSEtoDataPeriod = (
  evidenceColumnGroupId: number,
  dse: DataStudentEntry
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(setValueOfDSEtoDataPeriodRequest());
    const currentDataPeriod = getState().onboarding.currentDataPeriod;
    if (currentDataPeriod) {
      return axios
        .post(API_DSE_TO_DATA_PERIOD(currentDataPeriod.id), {
          ...dse,
        })
        .then((res) => {
          const dseAffectsResponse: DseAffectsResponse = res.data;

          dispatch(
            setValueOfDSEtoDataPeriodSuccess(
              evidenceColumnGroupId,
              dseAffectsResponse
            )
          );
        })
        .catch((err: AxiosError) => {
          // if (err.response) {
          //   const errors: Array<string> = err.response.data.non_field_errors;
          //   dispatch(setValueOfDSEtoDataPeriodFailure(errors.join(", ")));
          // } else {
          dispatch(setValueOfDSEtoDataPeriodFailure(err.message));
          // }
        });
    } else {
      dispatch(setValueOfDSEtoDataPeriodFailure("Invalid data period"));
    }
  };
};

export const updateValueOfDSEtoDataPeriod = (
  evidenceColumnGroupId: number,
  dseId: number,
  dse: DataStudentEntry
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(updateValueOfDSEtoDataPeriodRequest());
    const currentDataPeriod = getState().onboarding.currentDataPeriod;
    if (currentDataPeriod) {
      return axios
        .put(API_DSE_TO_DATA_PERIOD(currentDataPeriod.id, dseId), {
          ...dse,
        })
        .then((res) => {
          const dseAffectsResponse: DseAffectsResponse = res.data;
          dispatch(
            updateValueOfDSEtoDataPeriodSuccess(
              evidenceColumnGroupId,
              dseAffectsResponse
            )
          );
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            dispatch(
              updateValueOfDSEtoDataPeriodFailure(err.response.statusText)
            );
          } else {
            dispatch(updateValueOfDSEtoDataPeriodFailure(err.message));
          }
        });
    } else {
      dispatch(updateValueOfDSEtoDataPeriodFailure("Invalid data period"));
    }
  };
};

export const detectNonExistingStudentIds = (uniqueIds: Array<string>) => {
  return (dispatch: Dispatch) => {
    dispatch(detectNonExistingStudentIdsRequest());
    return axios
      .get(API_DETECT_NON_EXISTING_STUDENT_IDS(uniqueIds))
      .then((res) => {
        const nonExistingIds: Array<string> = res.data.non_existing_ids;
        dispatch(detectNonExistingStudentIdsSuccess(nonExistingIds));
      })
      .catch((err: AxiosError) => {
        dispatch(detectNonExistingStudentIdsFailure(err.message));
      });
  };
};

export const changeTeachersFilter = (teacherIdFilter?: number) =>
  action(OnboardingActionTypes.CHANGE_TEACHERS_FILTER, {
    teacherIdFilter,
  });

export const changeSelectedStudentEntry = (studentEntry?: StudentEntry) =>
  action(OnboardingActionTypes.CHANGE_SELECTED_STUDENT_ENTRY, {
    studentEntry,
  });

export const getDataPeriods = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getDataPeriodsRequest());
    return axios
      .get(API_DATA_PERIODS())
      .then((res) => {
        const dataPeriods: Array<DataPeriod> = res.data;
        dispatch(getDataPeriodsSuccess(dataPeriods));
        return Promise.resolve(dataPeriods);
      })
      .catch((err: AxiosError) => {
        dispatch(getDataPeriodsFailure(err.message));
      });
  };
};

export const addDataPeriodRequest = () =>
  action(OnboardingActionTypes.ADD_DATA_PERIOD_START);
export const addDataPeriodSuccess = (dataPeriod: DataPeriod) =>
  action(OnboardingActionTypes.ADD_DATA_PERIOD_SUCCESS, {
    dataPeriod,
  });
export const addDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const addDataPeriod = (dataPeriod: Partial<DataPeriod>) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addDataPeriodRequest());
    return axios
      .post(API_DATA_PERIODS(), dataPeriod)
      .then((res) => {
        const dataPeriod: DataPeriod = res.data;
        dispatch(addDataPeriodSuccess(dataPeriod));
      })
      .catch((err: AxiosError) => {
        dispatch(addDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateDataPeriodRequest = (
  dataPeriodId: number,
  dataPeriod: Partial<DataPeriod>
) =>
  action(OnboardingActionTypes.UPDATE_DATA_PERIOD_START, {
    dataPeriodId,
    dataPeriod,
  });

export const updateDataPeriodSuccess = (dataPeriod: DataPeriod) =>
  action(OnboardingActionTypes.UPDATE_DATA_PERIOD_SUCCESS, {
    dataPeriod,
  });
export const updateDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const updateDataPeriod = (
  dataPeriodId: number,
  dataPeriod: Partial<DataPeriod>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateDataPeriodRequest(dataPeriodId, dataPeriod));
    return axios
      .put(API_DATA_PERIODS(dataPeriodId), dataPeriod)
      .then((res) => {
        const dataPeriod: DataPeriod = res.data;
        dispatch(updateDataPeriodSuccess(dataPeriod));
      })
      .catch((err: AxiosError) => {
        dispatch(updateDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const patchDataPeriod = (
  dataPeriodId: number,
  dataPeriod: Partial<DataPeriod>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateDataPeriodRequest(dataPeriodId, dataPeriod));
    return axios
      .patch(API_DATA_PERIODS(dataPeriodId), dataPeriod)
      .then((res) => {
        const dataPeriod: DataPeriod = res.data;
        dispatch(updateDataPeriodSuccess(dataPeriod));
      })
      .catch((err: AxiosError) => {
        dispatch(updateDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const resetIsNewUploadedAssessment = () =>
  action(OnboardingActionTypes.RESET_IS_NEW_UPLOAD_ASSESSMENT);

export const deleteDataPeriodRequest = () =>
  action(OnboardingActionTypes.DELETE_DATA_PERIOD_START);
export const deleteDataPeriodSuccess = (dataPeriodId: number) =>
  action(OnboardingActionTypes.DELETE_DATA_PERIOD_SUCCESS, {
    dataPeriodId,
  });
export const deleteDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.DELETE_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const deleteDataPeriod = (dataPeriodId: number) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(deleteDataPeriodRequest());
    return axios
      .delete(API_DATA_PERIODS(dataPeriodId))
      .then((res) => {
        dispatch(deleteDataPeriodSuccess(dataPeriodId));
        // if(getState().auth.userInfo?.profile.current_role != 'district_owner') {
        //   dispatch(
        //     patchDataPeriod(getState().onboarding.dataPeriods[0].id, {
        //       is_selected: true,
        //       all_data_period_ids: getState().onboarding.dataPeriods.map((dp) => dp?.id) || []
        //     })
        //   );
        // }
        return Promise.resolve(dataPeriodId);
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getCustomColorCriteria = (measurementId: number) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getCustomColorCriteriaRequest());
    return axios
      .get(API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA(measurementId))
      .then((res) => {
        //const colorCriteria: Array<ColorCriteria> = res.data.color_criteria;
        let colorCriteria: any = res.data.color_criteria;
        let colorCriteriaNew = [];
        if((colorCriteria !== null) && (isObject(colorCriteria))) {
          for (const gradeKey in colorCriteria) {
            for (const timePeriodKey in colorCriteria[gradeKey]) {
              for (let index = 0; index < colorCriteria[gradeKey][timePeriodKey].length; index++) {
                if(colorCriteria[gradeKey][timePeriodKey][index] !== null) {
                  let element = colorCriteria[gradeKey][timePeriodKey][index];
                  element['grade'] = gradeKey;
                  element['time_period'] = timePeriodKey;
                  colorCriteriaNew.push(element);
                }
              }
            }
          }
          colorCriteria = colorCriteriaNew;
        }
        dispatch(getCustomColorCriteriaSuccess(colorCriteria));
      })
      .catch((err: AxiosError) => {
        const { response } = err;
        if (response && response.status === 404) {
          dispatch(getCustomColorCriteriaSuccess([]));
        } else {
          dispatch(getCustomColorCriteriaFailure(err.message));
        }
      });
  };
};

export const updateCustomColorCriteria = (
  measurementId: number,
  colorCriteria: Array<ColorCriteria>,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateCustomColorCriteriaRequest());
    return axios
      .put(API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA(measurementId), {
        color_criteria: colorCriteria,
      })
      .then((res) => {
        dispatch(getUserInfo());
        dispatch(updateCustomColorCriteriaSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(updateCustomColorCriteriaFailure(err.message));
      });
  };
};

export const setNewCustomColorCriteria = (
  measurementId: number,
  colorCriteria: Array<ColorCriteria>,
  user_id: number
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateCustomColorCriteriaRequest());
    console.log(measurementId,
      colorCriteria,
      user_id);
    return axios
      .post(API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA(measurementId), {
        color_criteria: colorCriteria,
        user_id
      })
      .then((res) => {
        dispatch(getUserInfo());
        dispatch(updateCustomColorCriteriaSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(updateCustomColorCriteriaFailure(err.message));
      });
  };
};

export const getUpdatedAreaOfNeed = (dataPeriodId: number) => {
  return (dispatch: Dispatch<any>,getState: () => ApplicationState) => {
    let queryStr:string = '';
    if(getState().cases.tabName == 'groups/behavior') {
      queryStr = '?is_behavior=1';
    } else {
      queryStr = '?is_behavior=0';
    }
    dispatch(getUpdatedAreaOfNeedRequest());
    return axios
      .get(API_GET_UPDATED_AREA_OF_NEED(dataPeriodId, queryStr))
      .then((res) => {
        const evidenceGroup: EvidenceColumnGroup = res.data;
        dispatch(getUpdatedAreaOfNeedSuccess(evidenceGroup));
      })
      .catch((err: AxiosError) => {
        dispatch(getUpdatedAreaOfNeedFailure(err.message));
      });
  };
}

export const getUpdatedAreaOfNeedRequest = () =>
  action(OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_START);
export const getUpdatedAreaOfNeedSuccess = (evidenceGroup:EvidenceColumnGroup) =>
  action(OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_SUCCESS, {
    evidenceGroup,
  });
export const getUpdatedAreaOfNeedFailure = (message: string) =>
  action(OnboardingActionTypes.GET_UPDATED_AREA_OF_NEED_FAILURE, {
    error: message,
  });

export const restoreDefaultBenchmarks = (measurementId: number) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(restoreDefaultBenchmarksRequest());
    return axios
      .delete(API_MEASUREMENTS_CUSTOM_COLOR_CRITERIA(measurementId))
      .then((res) => {
        dispatch(restoreDefaultBenchmarksSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(restoreDefaultBenchmarksFailure(err.message));
      });
  };
};

export const getStaticDataCategoryDomains = () => {
  return (dispatch: Dispatch) => {
    dispatch(getStaticDataCategoryDomainsRequest());
    return axios
      .get(API_STATIC_DATA_CATEGORY_DOMAINS)
      .then((res) => {
        const staticDataCategoryDomains: Array<CategoryBehavior> = res.data;
        dispatch(
          getStaticDataCategoryDomainsSuccess(staticDataCategoryDomains)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(getStaticDataCategoryDomainsFailure(err.message));
      });
  };
};

export const getCustomBehaviorsRequest = () =>
  action(OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_START);
export const getCustomBehaviorsSuccess = (
  customBehaviors: Array<CategoryBehavior>
) =>
  action(OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_SUCCESS, {
    customBehaviors,
  });
export const getCustomBehaviorsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_CUSTOM_BEHAVIORS_FAILURE, {
    error: message,
  });

export const getCustomBehaviors = () => {
  return (dispatch: Dispatch) => {
    dispatch(getCustomBehaviorsRequest());
    return axios
      .get(API_CUSTOM_BEHAVIORS())
      .then((res) => {
        const customBehaviors: Array<CategoryBehavior> = res.data;
        dispatch(getCustomBehaviorsSuccess(customBehaviors));
      })
      .catch((err: AxiosError) => {
        dispatch(getCustomBehaviorsFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addCustomBehaviorRequest = () =>
  action(OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_START);
export const addCustomBehaviorSuccess = (customBehavior: CategoryBehavior) =>
  action(OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_SUCCESS, {
    customBehavior,
  });
export const addCustomBehaviorFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_CUSTOM_BEHAVIOR_FAILURE, {
    error: message,
  });

export const addCustomBehavior = (customBehaviorName: string) => {
  return (dispatch: Dispatch) => {
    dispatch(addCustomBehaviorRequest());
    return axios
      .post(API_CUSTOM_BEHAVIORS(), { name: customBehaviorName })
      .then((res) => {
        const customBehavior: CategoryBehavior = res.data;
        dispatch(addCustomBehaviorSuccess(customBehavior));
        return Promise.resolve(customBehavior);
      })
      .catch((err: AxiosError) => {
        dispatch(addCustomBehaviorFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addCategoryBehavior = (
  categoryBehavior: CategoryBehaviorRequest
) => {
  return (dispatch: Dispatch) => {
    dispatch(addCategoryBehaviorStart());
    return axios
      .post(API_CATEGORY_BEHAVIOR, categoryBehavior)
      .then((res) => {
        dispatch(addCategoryBehaviorSuccess());
        return res.data;
      })
      .catch((err: AxiosError) => {
        dispatch(addCategoryBehaviorFailure(err.message));
        return undefined;
      });
  };
};

export const addTargetBehaviorToDataPeriod = (
  categoryBehavior: TargetBehavior,
  id?: number
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (id) {
      dataPeriodId = id;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(addTargetBehaviorToDataPeriodStart());
      return axios
        .post(
          API_TARGET_BEHAVIOR_TO_DATA_PERIOD(dataPeriodId),
          categoryBehavior
        )
        .then((res) => {
          const evidencePeriodDataChanges: EvidencePeriodData = res.data;
          dispatch(
            addTargetBehaviorToDataPeriodSuccess(evidencePeriodDataChanges)
          );
        })
        .catch((err: AxiosError) => {
          dispatch(addTargetBehaviorToDataPeriodFailure(err.message));
        });
    } else {
      dispatch(getEvidenceInDataPeriodFailure("Invalid data period"));
    }
  };
};

export const getDataSharingTableData = (coachId: number = 0) => {
  return (dispatch: Dispatch) => {
    dispatch(getDataSharingTableDataRequest());
    return axios
      .get(API_DATA_SHARING(0, coachId))
      .then((res) => {
        const dataSharingTableData: Array<DataSharingTableData> = res.data;
        dispatch(getDataSharingTableDataSuccess(dataSharingTableData));
      })
      .catch((err: AxiosError) => {
        dispatch(getDataSharingTableDataFailure(err.message));
      });
  };
};

export const shareCoachTeacherRelationData = (
  data: {
    grades: Array<Grade>;
    teachers: Array<number>;
    schools: Array<number>;
    email: string;
  }
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(shareCoachTeacherRelationDataRequest());
    return axios
      .post(API_DATA_SHARING(), data)
      .then((res) => {
        const coachTeacherRelationData: DataSharingTableData = res.data;
        dispatch(shareCoachTeacherRelationDataSuccess(coachTeacherRelationData));
      })
      .catch((err: AxiosError) => {
        dispatch(shareCoachTeacherRelationDataFailure(err.message));
      });
  };
};

export const updateDataSharingTableData = (
  id: number,
  data: {
    grades: Array<Grade>;
    teachers: Array<number>;
    schools: Array<number>;
    all_data: boolean;
  }
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateDataSharingTableDataRequest());
    return axios
      .patch(API_DATA_SHARING(id), data)
      .then((res) => {
        const dataSharingTableData: DataSharingTableData = res.data;
        dispatch(updateDataSharingTableDataSuccess(dataSharingTableData));
      })
      .catch((err: AxiosError) => {
        dispatch(updateDataSharingTableDataFailure(err.message));
      });
  };
};

export const deleteDataSharingTableData = (data: DataSharingTableData) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(deleteDataSharingTableDataRequest());
    return axios
      .delete(API_DATA_SHARING(data.id))
      .then(() => {
        dispatch(deleteDataSharingTableDataSuccess(data.id, data.user.id));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDataSharingTableDataFailure(err.message));
      });
  };
};

export const getInterviewsRequest = () =>
  action(OnboardingActionTypes.GET_INTERVIEWS_START);
export const getInterviewsSuccess = (interviews: Array<Interview>) =>
  action(OnboardingActionTypes.GET_INTERVIEWS_SUCCESS, { interviews });
export const getInterviewsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_INTERVIEWS_FAILURE, {
    error: message,
  });

export const getInterviews = (id?: number) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (id) {
      dataPeriodId = id;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(getInterviewsRequest());
      return axios
        .get(API_INTERVIEW(undefined, dataPeriodId))
        .then((res) => {
          const interviews: Array<Interview> = res.data;
          dispatch(getInterviewsSuccess(interviews));
        })
        .catch((err: AxiosError) => {
          dispatch(getInterviewsFailure(err.message));
        });
    } else {
      dispatch(getEvidenceInDataPeriodFailure("Invalid data period"));
    }
  };
};

export const createInterviewRequest = () =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_START);
export const createInterviewSuccess = (interview: Interview) =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_SUCCESS, { interview });
export const createInterviewFailure = (message: string) =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_FAILURE, {
    error: message,
  });

export const createInterview = (
  interview: Partial<InterviewRequest>,
  id?: number
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (id) {
      dataPeriodId = id;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(createInterviewRequest());
      return axios
        .post(API_INTERVIEW(), {
          ...interview,
          data_period: dataPeriodId,
        })
        .then((res) => {
          const interview: Interview = res.data;
          dispatch(createInterviewSuccess(interview));
        })
        .catch((err: AxiosError) => {
          dispatch(createInterviewFailure(err.message));
        });
    } else {
      dispatch(getEvidenceInDataPeriodFailure("Invalid data period"));
    }
  };
};

export const updateInterviewRequest = () =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_START);
export const updateInterviewSuccess = (interview: Interview) =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_SUCCESS, { interview });
export const updateInterviewFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_FAILURE, {
    error: message,
  });

export const updateInterview = (
  interview: Partial<InterviewRequest>,
  interviewId: number,
  customDataPeriod?: number
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (customDataPeriod) {
      dataPeriodId = customDataPeriod;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(updateInterviewRequest());
      return axios
        .put(API_INTERVIEW(interviewId), {
          ...interview,
          data_period: dataPeriodId,
        })
        .then((res) => {
          const interview: Interview = res.data;
          dispatch(updateInterviewSuccess(interview));
        })
        .catch((err: AxiosError) => {
          dispatch(updateInterviewFailure(err.message));
          return Promise.reject(err.message);
        });
    } else {
      dispatch(getEvidenceInDataPeriodFailure("Invalid data period"));
      return Promise.reject("Invalid data period");
    }
  };
};

export const getInterviewsStudentsRequest = () =>
  action(OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_START);
export const getInterviewsStudentsSuccess = (
  interviewsStudents: Array<InterviewStudent>
) =>
  action(OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_SUCCESS, {
    interviewsStudents,
  });
export const getInterviewsStudentsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_INTERVIEWS_STUDENTS_FAILURE, {
    error: message,
  });

export const getInterviewsStudents = () => {
  return (dispatch: Dispatch) => {
    dispatch(getInterviewsStudentsRequest());
    return axios
      .get(API_INTERVIEW_STUDENT())
      .then((res) => {
        const interviewsStudents: Array<InterviewStudent> = res.data;
        dispatch(getInterviewsStudentsSuccess(interviewsStudents));
      })
      .catch((err: AxiosError) => {
        dispatch(getInterviewsStudentsFailure(err.message));
      });
  };
};

export const createInterviewStudentRequest = () =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_START);
export const createInterviewStudentSuccess = (
  interviewStudent: InterviewStudent
) =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_SUCCESS, {
    interviewStudent,
  });
export const createInterviewStudentFailure = (message: string) =>
  action(OnboardingActionTypes.CREATE_INTERVIEW_STUDENT_FAILURE, {
    error: message,
  });

export const createInterviewStudent = (
  interviewStudentRequest: InterviewStudentRequest
) => {
  return (dispatch: Dispatch) => {
    dispatch(createInterviewStudentRequest());
    return axios
      .post(API_INTERVIEW_STUDENT(), interviewStudentRequest)
      .then((res) => {
        const interviewStudent: InterviewStudent = res.data;
        dispatch(createInterviewStudentSuccess(interviewStudent));
      })
      .catch((err: AxiosError) => {
        dispatch(createInterviewStudentFailure(err.message));
      });
  };
};

export const updateInterviewStudentRequest = () =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_START);
export const updateInterviewStudentSuccess = (
  interviewStudent: InterviewStudent
) =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_SUCCESS, {
    interviewStudent,
  });
export const updateInterviewStudentFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_INTERVIEW_STUDENT_FAILURE, {
    error: message,
  });

export const updateInterviewStudent = (
  interviewStudentRequest: InterviewStudentRequest,
  interviewStudentId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateInterviewStudentRequest());
    return axios
      .put(API_INTERVIEW_STUDENT(interviewStudentId), interviewStudentRequest)
      .then((res) => {
        const interviewStudent: InterviewStudent = res.data;
        dispatch(updateInterviewStudentSuccess(interviewStudent));
      })
      .catch((err: AxiosError) => {
        dispatch(updateInterviewStudentFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const sendManagerKeyRequest = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(sendManagerKeyRequestRequest());
    return axios
      .post(API_REQUEST_KEY())
      .then(() => {
        dispatch(sendManagerKeyRequestSuccess());
      })
      .catch((err: AxiosError) => {
        dispatch(sendManagerKeyRequestFailure(err.message));
      });
  };
};

export const uploadDocumentStart = () =>
  action(OnboardingActionTypes.UPLOAD_DOCUMENT_START);
export const uploadDocumentSuccess = (attachedResource: AttachedResource) =>
  action(OnboardingActionTypes.UPLOAD_DOCUMENT_SUCCESS, {
    attachedResource,
  });
export const uploadDocumentFailure = (message: string) =>
  action(OnboardingActionTypes.UPLOAD_DOCUMENT_FAILURE, {
    error: message,
  });

export const uploadDocument = (attachedResource: AttachedResource) => (
  dispatch: Dispatch
) => {
  const formData = new FormData();
  formData.append(
    "attached_to_type",
    attachedResource.attached_to_type.toString()
  );
  formData.append("resource_type", attachedResource.resource_type.toString());
  attachedResource.attachment &&
    formData.append("attachment", attachedResource.attachment);
  formData.append("title", attachedResource.title);
  formData.append("url", attachedResource.url || "");
  formData.append("mime_type", attachedResource.mime_type || "");
  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  dispatch(uploadDocumentStart());
  return axios
    .post(API_DOCUMENT_UPLOAD, formData, config)
    .then((res) => {
      const attachedResource: AttachedResource = res.data;
      dispatch(uploadDocumentSuccess(attachedResource));
      return attachedResource;
    })
    .catch((err: AxiosError) => {
      dispatch(uploadDocumentFailure(err.message));
      return Promise.reject(err.message);
    });
};

export const addStudentBehaviorsToDataPeriodStart = () =>
  action(OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_START);
export const addStudentBehaviorsToDataPeriodSuccess = (
  behaviors: Array<CategoryBehavior>,
  studentId: number
) =>
  action(OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_SUCCESS, {
    behaviors,
    studentId,
  });
export const addStudentBehaviorsToDataPeriodFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_STUDENT_BEHAVIORS_TO_DATA_PERIOD_FAILURE, {
    error: message,
  });

export const addStudentBehaviorsToDataPeriod = (
  behaviors: Array<CategoryBehavior>,
  studentId: number,
  selectedDataPeriodId?: number
) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (selectedDataPeriodId) {
      dataPeriodId = selectedDataPeriodId;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      if (currentDataPeriod) {
        dataPeriodId = currentDataPeriod.id;
      }
    }
    if (dataPeriodId) {
      dispatch(addStudentBehaviorsToDataPeriodStart());
      return axios
        .post(API_STUDENT_BEHAVIOR_TO_DATA_PERIOD(dataPeriodId, studentId), {
          behaviors: behaviors.map((b) => b.id),
        })
        .then((res) => {
          const behaviorsRes: Array<CategoryBehavior> = res.data.behaviors;

          if (
            !getState().onboarding.evidencePeriodData?.evidence_column_groups.find(
              (ecg) =>
                !ecg.assessment &&
                ecg.evidence_columns.some(
                  (ec) => ec.evidence_type === EvidenceType.ExistingBehavior
                )
            )
          ) {
            dispatch(getEvidenceInDataPeriod());
          }

          dispatch(
            addStudentBehaviorsToDataPeriodSuccess(behaviorsRes, studentId)
          );
          return Promise.resolve(behaviorsRes);
        })
        .catch((err: AxiosError) => {
          dispatch(addStudentBehaviorsToDataPeriodFailure(err.message));
          return Promise.reject(err.message);
        });
    } else {
      dispatch(addStudentBehaviorsToDataPeriodFailure("Invalid data period"));
      return Promise.reject("Invalid data period");
    }
  };
};

export const getDefaultProgressMonitoringStart = () =>
  action(OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_START);
export const getDefaultProgressMonitoringSuccess = (
  defaultProgressMonitoring: Measurement
) =>
  action(OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_SUCCESS, {
    defaultProgressMonitoring,
  });
export const getDefaultProgressMonitoringFailure = (message: string) =>
  action(OnboardingActionTypes.GET_DEFAULT_PROGRESS_MONITORING_FAILURE, {
    error: message,
  });

export const getDefaultProgressMonitoring = () => {
  return (dispatch: Dispatch) => {
    dispatch(getDefaultProgressMonitoringStart());
    return axios
      .get(API_STATIC_DEFAULT_PROGRESS_MONITORING)
      .then((res) => {
        const defaultProgressMonitoring: Measurement = res.data[0];
        dispatch(
          getDefaultProgressMonitoringSuccess(defaultProgressMonitoring)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(getDefaultProgressMonitoringFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addDistrictAssessmentStart = () =>
  action(OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_START);
export const addDistrictAssessmentSuccess = (
  evidenceAssessment: EvidenceAssessment
) =>
  action(OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_SUCCESS, {
    evidenceAssessment,
  });
export const addDistrictAssessmentFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_DISTRICT_ASSESSMENT_FAILURE, {
    error: message,
  });

export const addDistrictAssessment = (
  assessment: EvidenceAssessmentRequest
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(addDistrictAssessmentStart());
    return axios
      .post(
        API_DISTRICT_ASSESSMENT(
          getState().auth.userInfo?.profile.district?.id!
        ),
        assessment
      )
      .then((res) => {
        const assessment: EvidenceAssessment = res.data;
        dispatch(addDistrictAssessmentSuccess(assessment));
      })
      .catch((err: AxiosError) => {
        dispatch(addDistrictAssessmentFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateDistrictAssessmentStart = () =>
  action(OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_START);
export const updateDistrictAssessmentSuccess = (
  evidenceAssessment: EvidenceAssessment
) =>
  action(OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_SUCCESS, {
    evidenceAssessment,
  });
export const updateDistrictAssessmentFailure = (message: string) =>
  action(OnboardingActionTypes.UPDATE_DISTRICT_ASSESSMENT_FAILURE, {
    error: message,
  });

export const updateDistrictAssessment = (
  assessmentId: number,
  assessment: EvidenceAssessmentRequest
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(updateDistrictAssessmentStart());
    return axios
      .put(
        API_DISTRICT_ASSESSMENT(
          getState().auth.userInfo?.profile.district?.id!,
          assessmentId
        ),
        assessment
      )
      .then((res) => {
        const assessment: EvidenceAssessment = res.data;
        dispatch(updateDistrictAssessmentSuccess(assessment));
      })
      .catch((err: AxiosError) => {
        dispatch(updateDistrictAssessmentFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const patchDistrictAssessment = (
  assessmentId: number,
  assessment: Partial<EvidenceAssessmentRequest>
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(updateDistrictAssessmentStart());
    return axios
      .patch(
        API_DISTRICT_ASSESSMENT(
          getState().auth.userInfo?.profile.district?.id!,
          assessmentId
        ),
        assessment
      )
      .then((res) => {
        const assessment: EvidenceAssessment = res.data;
        dispatch(updateDistrictAssessmentSuccess(assessment));
      })
      .catch((err: AxiosError) => {
        dispatch(updateDistrictAssessmentFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getDistrictRelationshipsRequest = () =>
  action(OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_START);
export const getDistrictRelationshipsSuccess = (
  users: Array<StaffRelationship>
) => action(OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_SUCCESS, users);
export const getDistrictRelationshipsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_DISTRICT_RELATIONSHIPS_FAILURE, {
    error: message,
  });

export const getDistrictRelationships = (
  role: UserAssignmentRole.COACH | UserAssignmentRole.SUPERVISOR,
  userId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictRelationshipsRequest());
    return axios
      .get(API_DISTRICT_RELATIONSHIPS(role, userId))
      .then((res) => {
        const users: Array<StaffRelationship> = res.data;
        dispatch(getDistrictRelationshipsSuccess(users));
      })
      .catch((error: AxiosError) => {
        dispatch(getDistrictRelationshipsFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const getInterventionGroupLoggingRequest = () =>
  action(OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_START);
export const getInterventionGroupLoggingSuccess = (logs: Array<any>) =>
  action(OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_SUCCESS, logs);
export const getInterventionGroupLoggingFailure = (message: string) =>
  action(OnboardingActionTypes.GET_INTERVENTION_GROUP_LOGGING_FAILURE, {
    error: message,
  });

export const getInterventionGroupLogging = (groupId: number, studentId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getInterventionGroupLoggingRequest());
    return axios
      .get(API_INTERVENTION_GROUP_LOGGING(groupId, studentId))
      .then((res) => {
        const logs: Array<any> = res.data;
        dispatch(getInterventionGroupLoggingSuccess(logs));
      })
      .catch((error: AxiosError) => {
        dispatch(getInterventionGroupLoggingFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const addTeachersToCoachesRequest = () =>
  action(OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_START);
export const addTeachersToCoachesSuccess = () =>
  action(OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_SUCCESS);
export const addTeachersToCoachesFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_TEACHERS_TO_COACHES_FAILURE, {
    error: message,
  });

export const addTeachersToCoaches = (
  relationships: Array<CoachTeacherRelationshipRequest>
) => {
  return (dispatch: Dispatch) => {
    dispatch(addTeachersToCoachesRequest());

    const results = relationships.map((element) => {
      return axios
        .post(API_DISTRICT_RELATIONSHIPS(UserAssignmentRole.COACH), element)
        .then((res) => {
          return res.data;
        });
    });
    return Promise.all(results)
      .then((updatedUsers: Array<TeacherInfo>) => {
        //todo update current and previous list of educators
        dispatch(addTeachersToCoachesSuccess());
      })
      .catch((error: AxiosError) => {
        dispatch(addTeachersToCoachesFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const addEducatorsToSupervisorsRequest = () =>
  action(OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_START);
export const addEducatorsToSupervisorsSuccess = () =>
  action(OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_SUCCESS);
export const addEducatorsToSupervisorsFailure = (message: string) =>
  action(OnboardingActionTypes.ADD_EDUCATORS_TO_SUPERVISORS_FAILURE, {
    error: message,
  });

export const addEducatorsToSupervisors = (
  relationships: Array<SupervisorEducatorRelationshipRequest>
) => {
  return (dispatch: Dispatch) => {
    dispatch(addEducatorsToSupervisorsRequest());

    const results = relationships.map((element) => {
      return axios
        .post(
          API_DISTRICT_RELATIONSHIPS(UserAssignmentRole.SUPERVISOR),
          element
        )
        .then((res) => {
          return res.data;
        });
    });
    return Promise.all(results)
      .then((updatedUsers: Array<TeacherInfo>) => {
        //todo update current and previous list of educators
        dispatch(addEducatorsToSupervisorsSuccess());
      })
      .catch((error: AxiosError) => {
        dispatch(addEducatorsToSupervisorsFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const removeTeachersFromCoachesRequest = () =>
  action(OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_START);
export const removeTeachersFromCoachesSuccess = (userId:any) =>
  action(OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_SUCCESS,{ userId});
export const removeTeachersFromCoachesFailure = (message: string) =>
  action(OnboardingActionTypes.REMOVE_TEACHERS_FROM_COACHES_FAILURE, {
    error: message,
  });

export const removeTeachersFromCoaches = (
  relationship: CoachTeacherRelationshipRequest
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(removeTeachersFromCoachesRequest());
    return axios
      .delete(API_DISTRICT_RELATIONSHIPS(UserAssignmentRole.COACH), {
        data: relationship,
      })
      .then((res) => {
        //todo update current and previous list of educators
        dispatch(removeTeachersFromCoachesSuccess(relationship.teacher));
        dispatch(
          getDistrictRelationships(UserAssignmentRole.COACH, relationship.coach)
        );
      })
      .catch((error: AxiosError) => {
        dispatch(removeTeachersFromCoachesFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const removeEducatorsFromSupervisorsRequest = () =>
  action(OnboardingActionTypes.REMOVE_EDUCATORS_FROM_SUPERVISORS_START);
export const removeEducatorsFromSupervisorsSuccess = () =>
  action(OnboardingActionTypes.REMOVE_EDUCATORS_FROM_SUPERVISORS_SUCCESS);
export const removeEducatorsFromSupervisorsFailure = (message: string) =>
  action(OnboardingActionTypes.REMOVE_EDUCATORS_FROM_SUPERVISORS_FAILURE, {
    error: message,
  });

export const removeEducatorsFromSupervisors = (
  relationship: SupervisorEducatorRelationshipRequest
) => {
  return (dispatch: Dispatch) => {
    dispatch(removeEducatorsFromSupervisorsRequest());
    return axios
      .delete(API_DISTRICT_RELATIONSHIPS(UserAssignmentRole.SUPERVISOR), {
        data: relationship,
      })
      .then((res) => {
        //todo update current and previous list of educators
        dispatch(removeEducatorsFromSupervisorsSuccess());
      })
      .catch((error: AxiosError) => {
        dispatch(removeEducatorsFromSupervisorsFailure(error.message));
        return Promise.reject(error.message);
      });
  };
};

export const addExistingBehaviorEcgToDataPeriodStart = () =>
  action(OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_START);
export const addExistingBehaviorEcgToDataPeriodSuccess = (
  existingBehaviorEcg: EvidenceColumnGroup
) =>
  action(
    OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_SUCCESS,
    {
      existingBehaviorEcg,
    }
  );
export const addExistingBehaviorEcgToDataPeriodFailure = (message: string) =>
  action(
    OnboardingActionTypes.ADD_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD_FAILURE,
    {
      error: message,
    }
  );

export const addExistingBehaviorEcgToDataPeriod = (
  customDataPeriodId?: number,
  behaviorType?: string,
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (customDataPeriodId) {
      dataPeriodId = customDataPeriodId;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      dataPeriodId = currentDataPeriod?.id;
    }
    dispatch(addExistingBehaviorEcgToDataPeriodStart());

    if (dataPeriodId === undefined) {
      dispatch(
        addExistingBehaviorEcgToDataPeriodFailure("Invalid data period")
      );
      return;
    }
    let behaviorTypeQueryParam: string = '';
    if(behaviorType) {
      behaviorTypeQueryParam = `?type=${behaviorType}`
    }

    return axios
      .post(API_EXISTING_BEHAVIOR_ECG_TO_DATA_PERIOD(dataPeriodId, behaviorTypeQueryParam), {})
      .then((res) => {
        const existingBehaviorEcg: EvidenceColumnGroup = res.data;
        dispatch(
          addExistingBehaviorEcgToDataPeriodSuccess(existingBehaviorEcg)
        );
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(addExistingBehaviorEcgToDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addBehaviorScreenerEcgToDataPeriodStart = () =>
  action(OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_START);
export const addBehaviorScreenerEcgToDataPeriodSuccess = (
  behaviorScreenerEcg: EvidenceColumnGroup
) =>
  action(
    OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_SUCCESS,
    {
      behaviorScreenerEcg,
    }
  );
export const addBehaviorScreenerEcgToDataPeriodFailure = (message: string) =>
  action(
    OnboardingActionTypes.ADD_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD_FAILURE,
    {
      error: message,
    }
  );

export const addBehaviorScreenerEcgToDataPeriod = (
  customDataPeriodId?: number,
  behaviorType?: string,
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (customDataPeriodId) {
      dataPeriodId = customDataPeriodId;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      dataPeriodId = currentDataPeriod?.id;
    }
    dispatch(addBehaviorScreenerEcgToDataPeriodStart());

    if (dataPeriodId === undefined) {
      dispatch(
        addBehaviorScreenerEcgToDataPeriodFailure("Invalid data period")
      );
      return;
    }

    let behaviorTypeQueryParam: string = '';
    if(behaviorType) {
      behaviorTypeQueryParam = `?type=${behaviorType}`
    }

    return axios
      .post(API_BEHAVIOR_SCREENER_ECG_TO_DATA_PERIOD(dataPeriodId, behaviorTypeQueryParam), {})
      .then((res) => {
        const BehaviorScreenerEcg: EvidenceColumnGroup = res.data;
        dispatch(
          addBehaviorScreenerEcgToDataPeriodSuccess(BehaviorScreenerEcg)
        );
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(addBehaviorScreenerEcgToDataPeriodFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const createDiagnosticInfoColumn = createAsyncAction(
  "ADD_DIAGNOSTIC_INFO_COLUMN_REQUEST",
  "ADD_DIAGNOSTIC_INFO_COLUMN_SUCCESS",
  "ADD_DIAGNOSTIC_INFO_COLUMN_FAILURE"
)<void, EvidenceColumnGroup, string>();

export const createDiagnosticInfoColumnRequest = (
  customDataPeriodId?: number
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    let dataPeriodId;
    if (customDataPeriodId) {
      dataPeriodId = customDataPeriodId;
    } else {
      const currentDataPeriod = getState().onboarding.currentDataPeriod;
      dataPeriodId = currentDataPeriod?.id;
    }
    dispatch(createDiagnosticInfoColumn.request());

    if (dataPeriodId === undefined) {
      dispatch(createDiagnosticInfoColumn.failure("Invalid data period"));
      return;
    }

    return axios
      .post(API_READING_DIAGNOSTICS_ECG_TO_DATA_PERIOD(dataPeriodId), {})
      .then((res) => {
        const diagnosticInfoECG: EvidenceColumnGroup = res.data;
        dispatch(createDiagnosticInfoColumn.success(diagnosticInfoECG));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(createDiagnosticInfoColumn.failure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const openUpsertSchoolModal = (school?: School, districtId?: number) =>
  action(OnboardingActionTypes.OPEN_UPSERT_SCHOOL_MODAL, {
    school,
    districtId,
  });
export const hideUpsertSchoolModal = () =>
  action(OnboardingActionTypes.HIDE_UPSERT_SCHOOL_MODAL);

export const openManageDataPeriodsModal = () =>
  action(OnboardingActionTypes.OPEN_MANAGE_DATA_PERIODS_MODAL);
export const hideManageDataPeriodsModal = () =>
  action(OnboardingActionTypes.HIDE_MANAGE_DATA_PERIODS_MODAL);

export const getDistrictSummaryAction = createAsyncAction(
  "GET_DISTRICT_SUMMARY_REQUEST",
  "GET_DISTRICT_SUMMARY_SUCCESS",
  "GET_DISTRICT_SUMMARY_FAILURE"
)<{ districtId?: number }, DistrictSummary, Error>();

export const getDistrictSummary = ({
  districtId,
  schoolId,
  teachersIds,
  coachesIds,
  grades,
  race,
  concernArea,
  startDate,
  endDate,
}: {
  districtId?: number;
  schoolId?: number;
  teachersIds: number[];
  coachesIds: number[];
  grades: Grade[];
  race?: number;
  concernArea?: ConcernArea;
  startDate?: string;
  endDate?: string;
}) => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictSummaryAction.request({ districtId }));
    return axios
      .get(
        API_DISTRICT_SUMMARY({
          district: districtId,
          school: schoolId,
          teachers: teachersIds,
          coaches: coachesIds,
          race,
          grades,
          concern_area: concernArea,
          start_date: startDate,
          end_date: endDate,
        })
      )
      .then((res) => {
        const districtSummary: DistrictSummary = res.data;
        dispatch(getDistrictSummaryAction.success(districtSummary));
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictSummaryAction.failure(err));
      });
  };
};

export const getDistrictsAction = createAsyncAction(
  "GET_DISTRICTS_REQUEST",
  "GET_DISTRICTS_SUCCESS",
  "GET_DISTRICTS_FAILURE"
)<void, Array<District>, Error>();

export const getDistricts = () => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictsAction.request());
    return axios
      .get(API_DISTRICTS)
      .then((res) => {
        const districts: Array<District> = res.data;
        dispatch(getDistrictsAction.success(districts));
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictsAction.failure(err));
      });
  };
};

export const changeCustomBehaviorName = (name?: string) =>
  action(OnboardingActionTypes.CHANGE_CUSTOM_BEHAVIOR_NAME, {
    name,
  });

export const openInviteParentModal = createAction("OPEN_INVITE_PARENT_MODAL")();
export const hideInviteParentModal = createAction("HIDE_INVITE_PARENT_MODAL")();

export const getTutorialVideos = createAsyncAction(
  "GET_TUTORIAL_VIDEOS_REQUEST",
  "GET_TUTORIAL_VIDEOS_SUCCESS",
  "GET_TUTORIAL_VIDEOS_FAILURE"
)<void, TutorialVideo[], Error>();

export const getTutorialVideosRequest = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getTutorialVideos.request());
    try {
      const response = await axios.get(API_GET_TUTORIAL_VIDEOS);
      const tutorialVideos = response.data as TutorialVideo[];

      dispatch(getTutorialVideos.success(tutorialVideos));
    } catch (error: any) {
      dispatch(getTutorialVideos.failure(error));
    }
  };
};

export const getReleaseNotes = createAsyncAction(
  "GET_RELEASE_NOTES_REQUEST",
  "GET_RELEASE_NOTES_SUCCESS",
  "GET_RELEASE_NOTES_FAILURE"
)<void, TutorialVideo[], Error>();

export const getReleaseNotesRequest = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getReleaseNotes.request());
    try {
      const response = await axios.get(API_GET_RELEASE_NOTES);
      const releaseNotes = response.data as TutorialVideo[];

      dispatch(getReleaseNotes.success(releaseNotes));
    } catch (error: any) {
      dispatch(getReleaseNotes.failure(error));
    }
  };
};

export const setSelectedParent = createAction("SET_SELECTED_PARENT")<
  UserInfo | undefined
>();

export const inviteParent = createAsyncAction(
  "INVITE_PARENT_REQUEST",
  "INVITE_PARENT_SUCCESS",
  "INVITE_PARENT_FAILURE"
)<InviteParentRequest, any, Error>();

export const inviteParentRequest = (req: InviteParentRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(inviteParent.request(req));

    return axios
      .post(API_INVITE_PARENT, req)
      .then((res) => {
        const parentResponse = res.data;
        dispatch(inviteParent.success(parentResponse));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(inviteParent.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const addStudentToParent = createAsyncAction(
  "ADD_STUDENT_TO_PARENT_REQUEST",
  "ADD_STUDENT_TO_PARENT_SUCCESS",
  "ADD_STUDENT_TO_PARENT_FAILURE"
)<void, UserInfo, Error>();

export const addStudentToParentRequest = (
  children: number[],
  parent: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(addStudentToParent.request());

    return axios
      .post(API_ADD_STUDENT_TO_PARENT, {
        children,
        parent,
      })
      .then((res) => {
        const parent = res.data;
        dispatch(addStudentToParent.success(parent));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(addStudentToParent.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const sendAssessmentSuggestions = createAsyncAction(
  "ADD_ASSESSMENT_SUGGESTIONS_REQUEST",
  "ADD_ASSESSMENT_SUGGESTIONS_SUCCESS",
  "ADD_ASSESSMENT_SUGGESTIONS_FAILURE"
)<void, void, AxiosError>();

export const sendAssessmentSuggestionsRequest = (text: string) => {
  return (dispatch: Dispatch) => {
    dispatch(sendAssessmentSuggestions.request());

    return axios
      .post(API_ASSESSMENT_SUGGESTION, {
        text,
      })
      .then((res) => {
        dispatch(sendAssessmentSuggestions.success());
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(sendAssessmentSuggestions.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const openEvidenceConfirmDeletionModal = createAction(
  "OPEN_EVIDENCE_CONFIRM_DELETION_MODAL"
)<{evidenceColumnGroup: EvidenceColumnGroup, timeElipse?:boolean}>();
export const hideEvidenceConfirmDeletionModal = createAction(
  "HIDE_EVIDENCE_CONFIRM_DELETION_MODAL"
)();

export const changeIntGroupAssigmentCollapsedSettings = createAction(
  "CHANGE_INTERVENTION_GROUP_ASSIGMENT_COLLAPSED_SETTINGS"
)<{ id: number; collapsed?: boolean }>();

export const interventionGroupAccess = (intGrpAccessObj?: any) =>
  action(OnboardingActionTypes.INTERVENTION_GROUP_ACCESS, {
    intGrpAccessObj,
  });
  
export const changeIntGroupInnerCollapsedSettings = createAction(
  "CHANGE_INTERVENTION_GROUP_INNER_COLLAPSED_SETTINGS"
)<{ accordianSetting: any; loggedInUserId: number; interventionGroupId: number }>();

export const openClassWideNeedsModal = createAction(
  "@@onboarding/OPEN_CLASS_WIDE_NEEDS_MODAL"
)();
export const hideClassWideNeedsModal = createAction(
  "@@onboarding/HIDE_CLASS_WIDE_NEEDS_MODAL"
)();

export const openPreClassWideNeedsModal = createAction(
  "@@onboarding/OPEN_PRE_CLASS_WIDE_NEEDS_MODAL"
)();
export const hidePreClassWideNeedsModal = createAction(
  "@@onboarding/HIDE_PRE_CLASS_WIDE_NEEDS_MODAL"
)();

export const openEquityBenchmarksModal = createAction(
  "@@onboarding/OPEN_EQUITY_BENCHMARKS_MODAL"
)<number>();
export const hideEquityBenchmarksModal = createAction(
  "@@onboarding/HIDE_EQUITY_BENCHMARKS_MODAL"
)();

export const changeCollapsedAreas = createAction(
  "@@onboarding/CHANGE_COLLAPSED_AREA"
)<{ key: string; value: boolean }>();

export const changeGraphsDisplayedAreas = createAction(
  "@@onboarding/CHANGE_GRAPHS_DISPLAYED_AREAS"
)<boolean>();

export const getDistrictSummaryReportAction = createAsyncAction(
  "GET_DISTRICT_SUMMARY_REPORT_REQUEST",
  "GET_DISTRICT_SUMMARY_REPORT_SUCCESS",
  "GET_DISTRICT_SUMMARY_REPORT_FAILURE"
)<void, void, Error>();

export const getDistrictSummaryReport = ({
  districtId,
  schoolId,
  teachersIds,
  coachesIds,
  grades,
  race,
  concernArea,
  startDate,
  endDate,
  displayCharts,
  displayedSections,
}: {
  districtId?: number;
  schoolId?: number;
  teachersIds: number[];
  coachesIds: number[];
  grades: Grade[];
  race?: number;
  concernArea?: ConcernArea;
  startDate?: string;
  endDate?: string;
  displayCharts?: boolean;
  displayedSections: string[];
}) => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictSummaryReportAction.request());
    return axios
      .get(
        API_DISTRICT_SUMMARY(
          {
            district: districtId,
            school: schoolId,
            teachers: teachersIds,
            coaches: coachesIds,
            race,
            grades,
            concern_area: concernArea,
            start_date: startDate,
            end_date: endDate,
            display_charts: displayCharts,
            displayed_sections: displayedSections,
          },
          true
        ),
        {
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download =
          "District Summary " + moment().format("MM-DD-YYYY") + ".pdf";
        document.body.append(link);
        link.click();
        link.remove();

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);

        dispatch(getDistrictSummaryReportAction.success());
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictSummaryReportAction.failure(err));
      });
  };
};

export const getDistrictAccessDataSettingsAction = createAsyncAction(
  "GET_DISTRICT_ACCESS_DATA_SETTINGS_REQUEST",
  "GET_DISTRICT_ACCESS_DATA_SETTINGS_SUCCESS",
  "GET_DISTRICT_ACCESS_DATA_SETTINGS_FAILURE"
)<void, DistrictAccessDataSetting[], string>();

export const getDistrictAccessDataSettings = () => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictAccessDataSettingsAction.request());
    return axios
      .get(API_DISTRICT_ACCESS_DATA_SETTINGS())
      .then((res) => {
        let responseData = res.data;
        if(responseData) {
          responseData.map((district:DistrictAccessDataSetting) => district.status = true)
        }
        const accessDataSettings: DistrictAccessDataSetting[] = responseData;
        dispatch(
          getDistrictAccessDataSettingsAction.success(accessDataSettings)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictAccessDataSettingsAction.failure(err.message));
      });
  };
};

export const addDistrictAccessDataSettingAction = createAsyncAction(
  "ADD_DISTRICT_ACCESS_DATA_SETTING_REQUEST",
  "ADD_DISTRICT_ACCESS_DATA_SETTING_SUCCESS",
  "ADD_DISTRICT_ACCESS_DATA_SETTING_FAILURE"
)<void, DistrictAccessDataSetting, string>();

export const addDistrictAccessDataSetting = (
  requestData: Omit<DistrictAccessDataSetting<number, number, number>, "id">
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addDistrictAccessDataSettingAction.request());
    return axios
      .post(API_DISTRICT_ACCESS_DATA_SETTINGS(), requestData)
      .then((res) => {
        const accessDataSetting: DistrictAccessDataSetting = res.data;
        dispatch(addDistrictAccessDataSettingAction.success(accessDataSetting));
        toastr.success('Success!!', "District access data setting saved successfully.")
        dispatch(getDistrictAccessDataSettings());
      })
      .catch((err: AxiosError) => {
        
        dispatch(addDistrictAccessDataSettingAction.failure(err.message));
      });
  };
};

export const updateDistrictAccessDataSettingAction = createAsyncAction(
  "UPDATE_DISTRICT_ACCESS_DATA_SETTING_REQUEST",
  "UPDATE_DISTRICT_ACCESS_DATA_SETTING_SUCCESS",
  "UPDATE_DISTRICT_ACCESS_DATA_SETTING_FAILURE"
)<void, DistrictAccessDataSetting, string>();

export const updateDistrictAccessDataSetting = ({
  id,
  ...request
}: DistrictAccessDataSetting<number, number, number>) => {
  return (dispatch: Dispatch) => {
    dispatch(updateDistrictAccessDataSettingAction.request());
    return axios
      .put(API_DISTRICT_ACCESS_DATA_SETTINGS(id), request)
      .then((res) => {
        const accessDataSetting: DistrictAccessDataSetting = res.data;
        toastr.success('Success!!', "District access data setting updated successfully.")
        dispatch(
          updateDistrictAccessDataSettingAction.success(accessDataSetting)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(updateDistrictAccessDataSettingAction.failure(err.message));
      });
  };
};

export const deleteDistrictAccessDataSettingAction = createAsyncAction(
  "DELETE_DISTRICT_ACCESS_DATA_SETTING_REQUEST",
  "DELETE_DISTRICT_ACCESS_DATA_SETTING_SUCCESS",
  "DELETE_DISTRICT_ACCESS_DATA_SETTING_FAILURE"
)<void, number, string>();

export const deleteDistrictAccessDataSetting = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteDistrictAccessDataSettingAction.request());
    return axios
      .delete(API_DISTRICT_ACCESS_DATA_SETTINGS(id))
      .then((res) => {
        dispatch(deleteDistrictAccessDataSettingAction.success(id));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDistrictAccessDataSettingAction.failure(err.message));
      });
  };
};

export const openDistrictAccessDataSettingUpsertModal = createAction(
  "@@onboarding/OPEN_DISTRICT_ACCESS_DATA_SETTING_UPSERT_MODAL"
)<Partial<DistrictAccessDataSetting<number, UserInfo, number>> | undefined>();
export const hideDistrictAccessDataSettingUpsertModal = createAction(
  "@@onboarding/HIDE_DISTRICT_ACCESS_DATA_SETTING_UPSERT_MODAL"
)();

export const openDistrictDataPeriodUpsertModal = createAction(
  "@@onboarding/OPEN_DISTRICT_DATA_PERIOD_UPSERT_MODAL"
)<DataPeriod>();
export const hideDistrictDataPeriodUpsertModal = createAction(
  "@@onboarding/HIDE_DISTRICT_DATA_PERIOD_UPSERT_MODAL"
)();

export const changeUserStatusAction = createAsyncAction(
  "@@onboarding/CHANGE_USER_STATUS_REQUEST",
  "@@onboarding/CHANGE_USER_STATUS_SUCCESS",
  "@@onboarding/CHANGE_USER_STATUS_FAILURE"
)<void, Pick<UserInfo, "id" | "is_active">, string>();

export const changeUserStatus = (userId: number, isActive: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(changeUserStatusAction.request());
    return axios
      .post(API_USER_STATUS(userId), { is_active: isActive })
      .then((res) => {
        dispatch(
          changeUserStatusAction.success({
            id: userId,
            is_active: isActive,
          })
        );
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(changeUserStatusAction.failure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const changeSchoolsList = createAction(
  "@@onboarding/CHANGE_SCHOOLS_LIST"
)<School[]>();

export const setShowDisabledUsers = createAction(
  "@@onboarding/SET_SHOW_DISABLED_USERS"
)<boolean>();

export const setNoClassWideNeeds = createAction(
  "@@onboarding/SET_NO_CLASS_WIDE_NEEDS"
)<boolean>();

export const openStudentEditModal = createAction(
  "@@onboarding/OPEN_STUDENT_EDIT_MODAL"
)<Student>();
export const hideStudentEditModal = createAction(
  "@@onboarding/HIDE_STUDENT_EDIT_MODAL"
)();

export const openInterventionStatsModal = createAction(
  "@@onboarding/OPEN_INTERVENTION_STATS_MODAL"
)<UserInfo | undefined>();
export const hideInterventionStatsModal = createAction(
  "@@onboarding/HIDE_INTERVENTION_STATS_MODAL"
)();

export const openUploadStudentDemographicModal = createAction(
  "@@onboarding/OPEN_UPLOAD_STUDENT_DEMOGRAPHIC_MODAL"
)<void>();
export const hideUploadStudentDemographicModal = createAction(
  "@@onboarding/HIDE_UPLOAD_STUDENT_DEMOGRAPHIC_MODAL"
)();

export const isUrlBlocked = (isBlock: boolean) =>
  action(OnboardingActionTypes.IS_URL_BLOCKED_PAGE, {
    isBlock,
  });

export const showLoadingIndicator = () => 
  action(OnboardingActionTypes.SHOW_LOADING_INDICATOR);

export const hideLoadingIndicator = () => 
  action(OnboardingActionTypes.HIDE_LOADING_INDICATOR);
export const getDataPeriodSchoolsRequest = () =>
  action(OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_START);
export const getDataPeriodSchoolsSuccess = (dataPeriodSchools: Array<School>) =>
  action(OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_SUCCESS, { dataPeriodSchools });
export const getDataPeriodSchoolsFailure = (message: string) =>
  action(OnboardingActionTypes.GET_DATA_PERIOD_SCHOOLS_FAILURE, { error: message });

export const getDataPeriodSchools = (dataPeriodId?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getDataPeriodSchoolsRequest());
    return axios
      .get(API_DATA_PERIOD_SCHOOLS(dataPeriodId))
      .then((res) => {
        const dataPeriodSchools: Array<School> = res.data;
        dispatch(getDataPeriodSchoolsSuccess(dataPeriodSchools));
      })
      .catch((err: AxiosError) => {
        dispatch(getDataPeriodSchoolsFailure(err.message));
      });
  };
};

export const getRaceInfoRequest = () =>
  action(OnboardingActionTypes.GET_RACE_INFO_START);
export const getRaceInfoSuccess = (raceInfo: RaceInfo) =>
  action(OnboardingActionTypes.GET_RACE_INFO_SUCCESS, { raceInfo });
export const getRaceInfoFailure = (message: string) =>
  action(OnboardingActionTypes.GET_RACE_INFO_FAILURE, { error: message });

export const getRaceInfo = (dataPeriodId?: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getRaceInfoRequest());
    return axios
      .get(API_RACE_INFO(dataPeriodId))
      .then((res) => {
        const raceInfo: RaceInfo = res.data;
        dispatch(getRaceInfoSuccess(raceInfo));
      })
      .catch((err: AxiosError) => {
        dispatch(getRaceInfoFailure(err.message));
      });
  };
};
