import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFilter, faLongArrowAltDown, faLongArrowAltUp, faSort, faEraser, faSync } 
  from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { Grade } from "../../../../../store/groups/types";
import {
  BasicUserInfo,
  DataPeriod,
  EvidenceColumnGroup,
  EvidencePeriodData,
  GradeLevels,
  Race,
  School,
  StudentRow,
  TeacherClass,
  TeacherInfo,
  genderList,
} from "../../../../../store/onboarding/types";
import { getFullName } from "../../../../../utils/NamesUtils";
import { Dropdown, Modal, Overlay, OverlayTrigger, Popover, Spinner, Table, Tooltip } from "react-bootstrap";
import StudentsFilter from "./student-data-table/filters/StudentsFilter";
import TeachersFilter from "./student-data-table/filters/TeachersFilter";
import ClassesFilter from "./student-data-table/filters/ClassesFilter";
import SchoolsFilter from "./student-data-table/filters/SchoolsFilter";
import GradeFilter from "./student-data-table/filters/GradeFilter";
import CoachDataPeriodSelector from "../../../../pages/coach-dashboard/CoachDataPeriodSelector";
import DataPeriodsPanel from "../../../../pages/coach-dashboard/tabs/your-data-and-interviews/DataPeriodsPanel";
import { changeStudentsFilter, changeTeachersFilter, hidePreClassWideNeedsModal, openClassWideNeedsModal, openPreClassWideNeedsModal } from "../../../../../store/onboarding/actions";
import SortDataModal from "./SortDataModal";
import FontAwesomeIconDropDown from "./student-data-table/drop-down/FontAwesomeIconDropDown";
import { Portal } from "react-portal";
import CustomMenuItem from "./student-data-table/drop-down/CustomMenuItem";
import { getDisplayedLabel } from "./student-data-table/cells/AssessmentLabelTooltip";
import { evidenceColumnGroupDisplayTitle } from "./student-data-table/Helpers";
import { IS_READY_COACH } from "../../../../../constants";
import { changeClassIdFilter, changeDataGradesFilter, changeDataSchoolsFilter, changeELLFilter, changeFreeOrReducedLunchFilter, changeSLDFilter, changeSpecialEducationFilter, changeGenderFilter, changeRaceFilter, dataFilterApplied } from "../../../../../store/dataFilter/actions";
import { changeDataSort, columnSortApplied, resetColumnSort } from "../../../../../store/dataSort/actions";
import { DataSortState } from "../../../../../store/dataSort/types";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import DemographicsFilter from "./student-data-table/filters/DemographicsFilter";
import usePrevProps from "../../../../../utils/hooks/usePrevProps";


type StateProps = {
  schools: Array<School>;
  teachersClasses: Array<TeacherClass>;
  teacherRoster: Array<TeacherInfo>;
  teacherIdFilter?: number;
  schoolIdsFilter?: number[];
  gradeLevelFilter?: Grade[];
  classFilter?: number;
  studentsFilter?: string;
  showPreClassWideNeedsModal?: boolean;
  activeSort?: DataSortState[];
  raceFilter: string;
  raceOtherFilter: string;
  genderFilter: string;
  specialEducationFilter: boolean | undefined;
  ellFilter: boolean | undefined;
  sldFilter: boolean | undefined;
  freeOrReducedLunchFilter: boolean | undefined;
  currentDataPeriod?: DataPeriod;
};

const ASSESSMENT_DISPLAYED_CHARACTERS_NUM = 20;

type OwnProps = {
  isReadonly?: boolean;
  evidenceGroupData: any;
  numberDisplayedGroups: number;
  onDisplayedGroupsChange: (
    evidenceColumnGroupId: number,
    dataPeriodId: number
  ) => any;
  checkDisplayedGroups: (
    evidenceColumnGroupId: number,
    dataPeriodId: number
  ) => boolean;
};

type Props = StateProps & OwnProps;

const DataFiltersPanel: FunctionComponent<Props> = (props) => {

  let {
    evidenceGroupData,
    numberDisplayedGroups,
    checkDisplayedGroups,
    onDisplayedGroupsChange,
    showPreClassWideNeedsModal,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const partialGroupDisplay = useMemo(() => {
    return evidenceGroupData?.evidenceColumnGroup?.length > 1 &&
    evidenceGroupData?.evidenceColumnGroup?.length !== numberDisplayedGroups; 
  }, [numberDisplayedGroups]) 
 
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const activeSort = useSelector(
    (s: ApplicationState) => s.dataSort.activeSort
  );

  const prevProps = usePrevProps({
    currentDataPeriod: props.currentDataPeriod,
  });

  useEffect(() => {
    if(prevProps?.currentDataPeriod != props.currentDataPeriod) {
      resetFilterAction();
      dispatch(changeDataSort([]));
      dispatch(resetColumnSort(true));
    }
  },[props.currentDataPeriod])

  const multiSortIndication = useMemo(() => {
    const sortOptions = activeSort;
    const sortOrder: any = [];
    if (sortOptions.length) {
      for (let i in sortOptions) {
        sortOrder.push({ columnName: sortOptions[i].col.measurement.column_name, order: sortOptions[i].direction });
      }
    }
    return sortOrder
  },[activeSort])

  useEffect(() => {
    resetFilterAction();
    dispatch(changeDataSort([]));
    dispatch(resetColumnSort(true));
  },[])

  useEffect(() => {
    setLoading(false);
  },[showPreClassWideNeedsModal])

  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData>((s) => s.onboarding.evidencePeriodData!);

  const teachersByCurrentDataPeriod: BasicUserInfo[] = useMemo(() =>
    evidencePeriodData?.student_rows.flatMap((std) => std.student?.teachers || []) || []
  ,[evidencePeriodData])
  
  const generalFilter = useMemo(() => {
    const messageParts = [];
    if (props.studentsFilter?.length) {
      messageParts.push(`Student - ${props.studentsFilter}`)
    }

    if (props.teacherIdFilter !== undefined) {
      const teacher = teachersByCurrentDataPeriod.find(
        (t) => t.id === props.teacherIdFilter
      );
      if (teacher) {
        messageParts.push(`Educator - ${teacher.first_name} ${teacher.last_name}`)
      }
    }

    if (props.classFilter !== undefined) {
      const tClass = props.teachersClasses.find(
        (tc) => tc.id === props.classFilter
      );
      if (tClass) {
        messageParts.push(`Class - ${tClass.name}`)

      }
    }

    if (props.schoolIdsFilter?.length) {
      let schoolNames = props.schoolIdsFilter
      .map((id) => props.schools.find((school) => school.id === id)?.name);
      const more  = props.schoolIdsFilter.length > 1 
      ? ' and '+ (props.schoolIdsFilter.length - 1)+ '+'
      : ''
      messageParts.push(`School(s) - ${schoolNames.length ? schoolNames[0] + more : ''}`)
    }

    if (props.gradeLevelFilter?.length) {
      const more  = props.gradeLevelFilter.length > 1 
      ? ' and '+ (props.gradeLevelFilter.length - 1)+ '+'
      : ''
      messageParts.push(`Grade Level(s) - ${GradeLevels.find((gl) => gl.value == props.gradeLevelFilter![0])?.label + more}`)
    }

    return messageParts;
  }, [
    props.studentsFilter,
    props.teacherIdFilter,
    props.gradeLevelFilter,
    props.classFilter,
    props.schoolIdsFilter,
  ]);

  const demoGraphicsFilter = useMemo(() => {
    const messageParts = [];
    let raceData: string = "";
    if(props.raceFilter) {
      raceData = props.raceFilter;
    } else if(props.raceOtherFilter) {
      raceData = props.raceOtherFilter;
    } 
    if(raceData) {
      messageParts.push(`Race - ${raceData}`)
    }
    if(props.genderFilter) {
      messageParts.push(`Gender - ${genderList.find((gender) => gender.value == props.genderFilter)?.label}`) 
    }
    if(props.specialEducationFilter != undefined) {
      messageParts.push(`Special Education - ${props.specialEducationFilter ? 'Y' : 'N' }`)
    }
    if(props.freeOrReducedLunchFilter != undefined) {
      messageParts.push(`Free Or Reduced Lunch - ${props.freeOrReducedLunchFilter ? 'Y' : 'N' }`)
    }
    if(props.ellFilter != undefined) {
      messageParts.push(`ELL - ${props.ellFilter ? 'Y' : 'N' }`)
    }
    if(props.sldFilter != undefined) {
      messageParts.push(`SLD - ${props.sldFilter ? 'Y' : 'N' }`)
    }

    return messageParts;
  }, [
    props.raceFilter,
    props.raceOtherFilter,
    props.genderFilter,
    props.specialEducationFilter,
    props.ellFilter,
    props.sldFilter,
    props.freeOrReducedLunchFilter,
    
  ]);

  const [showFiltersOverlay, setShowFiltersOverlay] = useState<boolean>(false);

  const filtersBtnRef = useRef(null);

  const handleFiltersClick = () => {
    setShowFiltersOverlay((prevState) => !prevState);
  };

  const onHideFiltersOverlay = () => {
    setShowFiltersOverlay(false);
  };

  const noClassWideNeeds = useSelector<ApplicationState, boolean | undefined>(
    (s) => s.onboarding.noClassWideNeeds
  );

  const rowsFilter = (studentRow: StudentRow) => {
    let result: boolean = true;

    if (IS_READY_COACH && props.teacherIdFilter) {
      result =
        result &&
        !!studentRow.student.teachers?.some(
          (teacher) => teacher.id === props.teacherIdFilter
        );
    }

    if (props.studentsFilter) {
      result =
        result &&
        getFullName(studentRow.student)
          .toLowerCase()
          .includes(props.studentsFilter.toLowerCase());
    }

    if (props.schoolIdsFilter && props.schoolIdsFilter.length > 0) {
      result =
        result &&
        studentRow.student
          .teachers!.flatMap((teacher) => teacher.schools || [])
          .some((schoolId) => schoolId && props.schoolIdsFilter?.includes(schoolId));
    }

    // if (teacherIdFilter && teacherIdFilter.length > 0) {
    //   result =
    //     result &&
    //     studentRow.student.teachers!.some(
    //       (teacher) => teacher.id && teacherIdFilter.includes(teacher.id)
    //     );
    // }
    if (props.classFilter) {
      result =
        result &&
        studentRow.student.teachers!.some((st) => st.classes?.find((cls) => cls.id == props.classFilter));
    }
    if (evidenceGroupData && props.gradeLevelFilter && props.gradeLevelFilter.length > 0) {
      result =
        result &&
        evidenceGroupData.evidenceColumnGroup.some((ecg: EvidenceColumnGroup) =>
          ecg.evidence_columns.some((ec) =>
            ec.student_entries.some(
              (se) =>
                se.student_id === studentRow.student.id &&
                se.grade &&
                props.gradeLevelFilter?.includes(se.grade)
            )
          )
        );
    }
    return result;
  };

  const handleHide = () => {
    setLoading(false);
    dispatch(hidePreClassWideNeedsModal());
  };

  const handleResetSort = () => {
    dispatch(
      showConfirmDialog({
        title: "Confirm",
        text:
          "Are you sure want to clear all sorting?",
        onConfirm: () => {
          dispatch(changeDataSort([]));
          dispatch(resetColumnSort(true));
          dispatch(columnSortApplied(false));
        },
        confirmButtonText: "Continue",
      })
    );
  }

  const resetFilterAction = () => {
    dispatch(changeStudentsFilter(''));
    dispatch(changeDataSchoolsFilter());
    dispatch(changeTeachersFilter());
    dispatch(changeDataGradesFilter());
    dispatch(changeClassIdFilter());
    dispatch(changeGenderFilter(''));
    dispatch(changeRaceFilter(''));
    dispatch(changeSpecialEducationFilter(undefined));
    dispatch(changeSLDFilter(undefined));
    dispatch(changeELLFilter(undefined));
    dispatch(changeFreeOrReducedLunchFilter(undefined));
    dispatch(dataFilterApplied(false));
  }

  const handleResetFilters = () => {
    dispatch(
      showConfirmDialog({
        title: "Confirm",
        text:
          "Are you sure want to clear all filter(s)?",
        onConfirm: () => {
          resetFilterAction();
        },
        confirmButtonText: "Continue",
      })
    );
  }

  return (
    <div id={"evidence-table-filter-row"} className="filterRow">
      <div className={`${!evidenceGroupData?.evidenceColumnGroup.length ? 
      "emptyDataPeriod" : 'filterRowDataPeriod'}`}>
        <div>Viewing data for:</div>
        <div>
          <CoachDataPeriodSelector small />
          <DataPeriodsPanel />
        </div>
      </div>

      
      {evidenceGroupData?.evidenceColumnGroup.length > 0 ?
        <>
          <div ref={filtersBtnRef} >
            <div className="row flex-column justify-content-center">
              <div className="col-auto pointer header-content">
                {/* assessement table dropdown code */}
                
                  <OverlayTrigger
                    overlay={<Tooltip id="editIcon">Click to show/hide tables</Tooltip>}
                  >
                      <Dropdown drop="down">
                        <Dropdown.Toggle
                          as={FontAwesomeIconDropDown(
                            <div className="d-flex align-items-center">
                            <div className="assessmentLabelDetails">
                              <div className="assessmentLabelName">
                                <div>
                                  <span className="mr-1">
                                    <FontAwesomeIcon icon={faCog} />
                                  </span>
                                  {getDisplayedLabel(
                                    `Showing ${partialGroupDisplay ? 'Partial': 'All'} Data`,
                                    ASSESSMENT_DISPLAYED_CHARACTERS_NUM
                                  )}
                                </div>
                                {partialGroupDisplay ? (
                                  <div className="partialColumnsDisplay">
                                    (Displaying {numberDisplayedGroups} of{" "}
                                    {evidenceGroupData.evidenceColumnGroup.length} tables)
                                  </div>
                                ) : ''}
                              </div>
          
                            </div>
                            
                          </div>

                                
                          , false)}
                          id={`dropdown`}
                        />

                          <Portal>
                            <Dropdown.Menu>
                              {(evidenceGroupData != undefined) && evidenceGroupData?.evidenceColumnGroup?.map(
                                (evidenceGroup: any, index:number) => (
                                  <Dropdown.Item key={index} as={CustomMenuItem}>
                                    <div
                                      onClick={onDisplayedGroupsChange(
                                        evidenceGroupData.dataPeriodId,
                                        evidenceGroup.id,
                                      )}
                                    >
                                      <input
                                        readOnly
                                        type="checkbox"
                                        checked={checkDisplayedGroups(
                                          evidenceGroupData.dataPeriodId,
                                          evidenceGroup.id
                                        )}
                                      />
                                      &nbsp;
                                      {getDisplayedLabel(
                                      evidenceColumnGroupDisplayTitle(evidenceGroup),
                                      ASSESSMENT_DISPLAYED_CHARACTERS_NUM
                                    )}
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Portal>
                      </Dropdown>
                  </OverlayTrigger>
              
                {/* assessement table dropdown code */}
              </div>
              <div className="col-auto d-flex">
                <div className="col-auto pointer">
                  {`Multi-Sort `} 
                {multiSortIndication.length ?
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Popover id="showMultiSort">
                          <Popover.Title>Sort By Column with Order</Popover.Title>
                          <Popover.Content>
                            <Table className="table-bordered table-sm">
                              <tbody>
                                {multiSortIndication.map((multi: any) => 
                                <tr>
                                  <td className="testLabel" style={{background:"#00c6f7"}}>
                                    <h5 style={{color:"#00265b", fontWeight: 600}}>
                                      <FontAwesomeIcon
                                        icon={
                                          multi.order == 'Descending' ? faLongArrowAltUp : faLongArrowAltDown
                                        }
                                        style={{color:"#fff"}}
                                        className="mr-1"
                                      /> {" "} {multi.columnName}
                                    </h5>
                
                                  </td>
                                </tr>)}
                              </tbody>
                            </Table>
                          </Popover.Content>
                        </Popover>
                    }
                  >
                    <span className="marigoldBtnSm" onClick={() => setShow(sw => !sw)}><FontAwesomeIcon icon={faSort} /></span>
                  </OverlayTrigger>
                :
                <span onClick={() => setShow(sw => !sw)}><FontAwesomeIcon icon={faSort} /></span>
                }
                {props.activeSort?.length ? 
                  <span className="redBtnSmFilter ml-1 " title="Clear All Sorts" onClick={handleResetSort}>
                    <FontAwesomeIcon 
                      icon={faSync}  
                      color="red"
                      size="1x"
                      className="pointer"
                    />
                  </span>
                : ''}
                </div>
                <div className="col-auto pointer" style={{maxWidth: '280px'}}>
                  {" Filter "} {" "}
                  {generalFilter.length || demoGraphicsFilter.length ?
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Popover id="showFilters" className="showFilterClass">
                          <Popover.Title>Applied Filter(s)</Popover.Title>
                          <Popover.Content>
                           <div className="d-flex">
                            {generalFilter.length ? 
                              <Table className="table-bordered table-sm">
                                <tbody>
                                  {generalFilter.map((generalFilterData: any) => 
                                    <tr>
                                      <td style={{background:"#00c6f7"}}>
                                        <h5 style={{color:"#00265b", fontWeight: 600}}>{generalFilterData}</h5>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            : ''}
                            {demoGraphicsFilter.length
                            ?
                              <Table className="table-bordered table-sm">
                                <tbody>
                                  {demoGraphicsFilter.map((demoGraphicsFilterData: any) => 
                                    <tr>
                                      <td style={{background:"#00c6f7"}}>
                                        <h5 style={{color:"#00265b", fontWeight: 600}}>{demoGraphicsFilterData}</h5>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            : ""}
                            </div>
                          </Popover.Content>
                        </Popover>
                    }
                  >
                    <span className="marigoldBtnSmFilter ml-1" onClick={handleFiltersClick}><FontAwesomeIcon icon={faFilter}/></span>
                  </OverlayTrigger>
                :
                <span><FontAwesomeIcon icon={faFilter} onClick={handleFiltersClick}/></span>
                }
                {generalFilter.length || demoGraphicsFilter.length ? 
                  <span className="redBtnSmFilter ml-1" title="Clear Filter(s)" onClick={handleResetFilters}>
                    <FontAwesomeIcon 
                      icon={faSync}  
                      color="red"
                      size="1x"
                      
                      className="pointer"
                    />
                  </span>
                : ''}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3">
            <button
              disabled={!!noClassWideNeeds || !evidenceGroupData?.evidenceColumnGroup.length}
              className={!!noClassWideNeeds || !evidenceGroupData?.evidenceColumnGroup.length ? "whiteBtnSm" : "marigoldBtnSm"}
              onClick={() => evidenceGroupData?.studentRows.filter(rowsFilter).length > 1000 ?
                dispatch(openPreClassWideNeedsModal()): dispatch(openClassWideNeedsModal()) }
            >
              {!!noClassWideNeeds || !evidenceGroupData?.evidenceColumnGroup.length ? "No Class-Wide Needs" : "Show Class-Wide Needs"}
            </button>
          </div>
        </>
      : " "}

      <Overlay
        placement="bottom"
        target={filtersBtnRef?.current as any}
        show={showFiltersOverlay}
        rootClose
        rootCloseEvent={"click"}
        onHide={onHideFiltersOverlay}
      >
        <Popover id="student-data-table-filters" className="popOverClass">
          <Popover.Content>
            <div className="row">
              <div className="col-6">
                <div className="studentDataTableFilters">
                  <StudentsFilter />
                  <TeachersFilter />
                  <ClassesFilter />
                  <SchoolsFilter />
                  <GradeFilter />
                </div>
              </div>
              <div className="col-6">
                <div className="studentDataTableFilters">
                  <DemographicsFilter fromDataFilter/>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
      {' '}
      { show && <SortDataModal show={show} setShow={setShow}/>}
      {showPreClassWideNeedsModal ? 
        <Modal
        show={showPreClassWideNeedsModal}
        onHide={handleHide}
        animation={false}
        backdrop="static"
        keyboard={false}
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header closeButton className="purpleModalHeader">
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <ul>
              <li>{`Class-wide needs are generated based on filters applied.`}</li>
              <li>{`Class-wide needs are limited to 3,000 students.`}</li>
              {/* <li>{`PDFs are limited to 72 pages.`}</li> */}
            </ul>
            <p className="mb-0">{`Given the size of the class-wide need report, please be aware that it may take some time to generate.`}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="btnActions">
                <button className="whiteBtnSm" onClick={() => handleHide()}>
                    Cancel
                </button>
                <button className="blueBtnSm" onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    dispatch(openClassWideNeedsModal());
                  }, 10);
                }}>
                  {"Ok! Go Ahead"}
                  {loading && <Spinner className="ml-1" animation="border" size="sm" />}
                </button>
            </div>
        </Modal.Footer>
      </Modal>
      :''
      }    

    </div>
  );
};

const mapStateToProps = ({
  onboarding,
  dataFilter,
  dataSort
}: ApplicationState): StateProps => {
  return {
    schools: onboarding.schools,
    teachersClasses: onboarding.teachersClasses,
    teacherRoster: onboarding.teachersRoster,
    teacherIdFilter: onboarding.teacherIdFilter,
    schoolIdsFilter: dataFilter.dataSchoolsFilter,
    gradeLevelFilter: dataFilter.dataGradesFilter,
    classFilter: dataFilter.classIdFilter,
    studentsFilter: onboarding.studentsFilter,
    showPreClassWideNeedsModal: onboarding.modalsState.showPreClassWideNeedsModal,
    activeSort: dataSort?.activeSort,
    raceFilter: dataFilter.raceFilter,
    raceOtherFilter: dataFilter.raceOtherFilter,
    genderFilter: dataFilter.genderFilter,
    specialEducationFilter: dataFilter.specialEducationFilter,
    ellFilter: dataFilter.ellFilter,
    sldFilter: dataFilter.sldFilter,
    freeOrReducedLunchFilter: dataFilter.freeOrReducedLunchFilter,
    currentDataPeriod: onboarding.currentDataPeriod,
  };
};

export default connect(mapStateToProps)(DataFiltersPanel);
