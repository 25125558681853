import React, { Component } from "react";
// @ts-ignore
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, createStore, Dispatch } from "redux";
import {
  changeStudentDSEGrade,
  deleteEvidenceColumnsGroup,
  getDataPeriods,
  getEvidenceInDataPeriod,
  getInterviews,
  getStudents,
  getSchools,
  getTeachers,
  hideViewBenchmarksModal,
  openLoadKeyModal,
  openViewBenchmarksModal,
  setValueOfDSEtoDataPeriod,
  updateValueOfDSEtoDataPeriod,
  getAllClasses,
  getClassesLinkedToStudent,
  deleteDataStudentEntry,
  getRaceInfo,
} from "../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import "fixed-data-table-2/dist/fixed-data-table.css";
import {
  DataPeriod,
  DataStudentEntry,
  EvidenceColumn,
  EvidenceColumnGroup,
  EvidencePeriodData,
  EvidenceType,
  Measurement,
  MeasurementType,
  PossibleValue,
  RaceInfo,
  raceList,
  School,
  Student,
  StudentEntry,
  StudentRow,
  TeacherClass,
} from "../../../../../store/onboarding/types";
import { DataSortState } from "../../../../../store/dataSort/types";

import ViewBenchmarksModal from "./color-criteria-modal/ViewBenchmarksModal";
import { ValueType } from "react-select/src/types";
import { toastr } from "react-redux-toastr";
import { Cell, Column, ColumnGroup, Table } from "fixed-data-table-2";
import AddStudentsHeaderCell from "./student-data-table/cells/AddStudentsHeaderCell";
import AssessmentHeaderCell from "./student-data-table/cells/AssessmentHeaderCell";
import MeasurementHeaderCell from "./student-data-table/cells/MeasurementHeaderCell";
import DataStudentEntryCell from "./student-data-table/cells/DataStudentEntryCell";
import StudentHeaderCell from "./student-data-table/cells/StudentHeaderCell";
import CheckStudentsHeaderCell from "./student-data-table/cells/CheckStudentsHeaderCell";
import LoadingIndicator from "../../LoadingIndicator";
import {
  addStudentsToNewGroup,
  clickGotIt,
} from "../../../../../store/onboarding/cases/actions";
import { IS_READY_COACH } from "../../../../../constants";
import SkillsFocusModal from "../../../reading-interventions/intervention-tools/groups-tab/modals/SkillsFocusModal";
import { InterventionGroup } from "../../../../../store/onboarding/cases/types";
import { hideSkillFocusesModal } from "../../../../../store/groups/actions";
import StudentCell from "./student-data-table/cells/StudentCell";
import { Grade } from "../../../../../store/groups/types";
import DiagnosticSkillHeaderCell from "./student-data-table/cells/DiagnosticSkillHeaderCell";
import DiagnosticSkillDataEntryCell from "./student-data-table/cells/DiagnosticSkillDataEntryCell";
import { getUnknownStudentsLength } from "../../../../../utils/StudentMapUtils";
import EmptyDataContent from "../../../reading-interventions/intervention-tools/data-tab/EmptyDataContent";
import UploadStudentsRosterModal from "../../../../../containers/onboarding/second-step/students/UploadStudentsRosterModal";
import EmptyStudentsRows from "./EmptyStudentsRows";

import { RouteComponentProps, withRouter } from "react-router";
import TeacherInviteIndividuallyModal from "../TeacherInviteIndividuallyModal";
import { getFullName } from "../../../../../utils/NamesUtils";
import DataLoadingCell from "./student-data-table/cells/DataLoadingCell";
import RemoveEvidenceConfirmModal from "./student-data-table/RemoveEvidenceConfirmModal";
import { LocalStorageKeys } from "../../../../../utils/LocalStorageUtils";
import { getStudentGradeFromEcg } from "./student-data-table/Helpers";
import CheckTeacherClassHeaderCell from "./student-data-table/cells/CheckTeacherClassHeaderCell";
import StudentGroupIconOverlay from "./student-data-table/StudentGroupIconOverlay";
import { ListGroup, Overlay, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import StudentClassesTab from "./student-data-table/edit-student-dropdown/StudentClassesTab";
import { UserAssignmentRole, UserInfo } from "../../../../../store/auth/types";
import BehaviorScreenerModal from "../../../reading-interventions/intervention-tools/groups-tab/modals/BehaviorScreenerModal";
import { showConfirmDialog } from "../../../../../store/confirm-dialog/actions";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import _ from "lodash";
import { columnSortApplied } from "../../../../../store/dataSort/actions";
import { dataFilterApplied } from "../../../../../store/dataFilter/actions";

type PropsFromState = {
  errors: {
    getEvidenceData?: string;
    addAssessmentToDataPeriod?: string;
    addStudentsToDataPeriod?: string;
    setValueOfDSEtoDataPeriod?: string;
    updateValueOfDSEtoDataPeriod?: string;
    uploadAssessmentData?: string;
    uploadUndefinedAssessmentData?: string;
    uploadEvidenceData?: string;
    deleteEvidenceColumnsGroup?: string;
    changeStudentDSEGrade?: string;
    addTargetBehaviorToDataPeriod?: string;
    addStudentBehaviorsToDataPeriod?: string;
  };
  isLoading: {
    getEvidenceData: boolean;
    getDataPeriods: boolean;
    addAssessmentToDataPeriod: boolean;
    addStudentsToDataPeriod: boolean;
    updateStudentDetails: boolean;
    setValueOfDSEtoDataPeriod: boolean;
    updateValueOfDSEtoDataPeriod: boolean;
    uploadAssessmentData: boolean;
    uploadUndefinedAssessmentData: boolean;
    uploadEvidenceData: boolean;
    deleteEvidenceColumnsGroup: boolean;
    changeStudentDSEGrade: boolean;
    addStudentSkills: boolean;
    deleteDataStudentEntry: boolean;
    addTargetBehaviorToDataPeriod: boolean;
    addStudentBehaviorsToDataPeriod: boolean;
    addExistingBehaviorEcgToDataPeriod: boolean;
    addBehaviorScreenerEcgToDataPeriod: boolean;
    assignTeachersToStudent: boolean;
    updateClassesLinkedToStudent: boolean;
    createDiagnosticInfoColumn?: boolean;
  };
  evidencePeriodData?: EvidencePeriodData;
  currentDataPeriod?: DataPeriod;
  teacherIdFilter?: number;
  schoolIdsFilter?: number[];
  teacherIdsFilter?: number[];
  selectedClass?: TeacherClass;
  selectedStudent?: Student;
  newGroupStudents: Array<Student>;
  gradeLevelFilter?: Grade[];
  classFilter?: number;
  studentsFilter?: string;
  isEnterSpecificSkillActive: boolean;
  interventionGroups: Array<InterventionGroup>;
  hasDiagnosticInfo: boolean;
  raceFilter: string;
  raceOtherFilter: string;
  genderFilter: string;
  specialEducationFilter: boolean | undefined;
  ellFilter: boolean | undefined;
  sldFilter: boolean | undefined;
  freeOrReducedLunchFilter: boolean | undefined;
  schools: School[];
  showTopBar?: boolean;
  isCompressedViewOfDataTable?: boolean;
  showGroupingStudentInfo?: boolean;
  dataSort?: DataSortState;
  teacherClassStudent?:any;
  userInfo?: UserInfo,
  raceInfo?: RaceInfo,
  isDataFilterApplied: boolean;
};

type DispatchProps = {
  getDataPeriods: () => any;
  getTeachers: () => any;
  getSchools: () => any;
  getEvidenceInDataPeriod: (dataPeriodId?: number) => any;
  deleteEvidenceColumnsGroup: (evidenceColumnGroupId: number) => any;
  openViewBenchmarksModal: () => any;
  hideViewBenchmarksModal: () => any;
  hideSkillFocusesModal: () => any;
  setValueOfDSEtoDataPeriod: (
    evidenceColumnGroupId: number,
    dse: DataStudentEntry
  ) => any;
  updateValueOfDSEtoDataPeriod: (
    evidenceColumnGroupId: number,
    dseId: number,
    dse: DataStudentEntry
  ) => any;
  addStudentsToNewGroup: (students: Array<Student>) => any;
  changeStudentDSEGrade: (
    dataPeriodId: number,
    studentId: number,
    grade: Grade
  ) => any;
  clickGotIt: () => any;
  openLoadKeyModal: () => any;
  getInterviews: typeof getInterviews;

  getAllClasses: (dataPeriodId:number, grade?:string) => any;
  getClassesLinkedToStudent: (studentId: number) => any;
  showConfirmDialog: (confirmObj:any) => any;
  deleteStudentEntry: (studentEntryId: number) => any;
  getRaceInfo: (currentDataPeriodId: number) =>  any;
  columnSortApplied: (isColumnSortApplied: boolean) => any;
  dataFilterApplied: (isDataFilterApplied: boolean) => any;
};

type DisplayedColumn = {
  evidenceColumnGroupId: number;
  evidenceColumns: Array<{
    evidenceColumnId: number;
    show: boolean;
  }>;
};

export type StudentRowEditing = {
  studentId: number;
  evidenceColumnGroups: Array<EvidenceColumnGroupEditing>;
};

type EvidenceColumnGroupEditing = {
  evidenceColumnGroupId: number;
  evidenceColumnEditingStatuses: Array<EvidenceColumnEditingStatus>;
};

type EvidenceColumnEditingStatus = {
  evidenceColumnId: number;
  isEdited: boolean;
  value?: number;
};

type OwnProps = {
  isPlanning?: boolean;
  isReadonly?: boolean;
  interventionGroup?: InterventionGroup;
  minimal?: boolean;
  isDataInterview?: boolean;
  fromSetGoalModal?: boolean;
  filterDisplayedGroups?: (
      dataPeriodId: number,
      evidenceColumnGroupId: number,
    ) => any;
  };

type MatchParams = {
  tabName: string;
};

type Props = OwnProps &
  RouteComponentProps<MatchParams> &
  PropsFromState &
  DispatchProps;

export type Sorting = {
  column?: string;
  measurement?: {
    evidenceColumnGroupId: number;
    evidenceColumnId: number;
  };
  direction: "desc" | "asc";
};

type State = {
  headerHeight: number;
  dataRowHeight: number;
  groupHeaderHeight: number;
  selectedEvidence?: {
    assessmentName: string;
    evidenceColumnGroupId: number;
    evidenceColumn: EvidenceColumn;
  };
  selectedStudents: Array<Student>;
  evidencePeriodData?: EvidencePeriodData;
  displayedColumns: Array<DisplayedColumn>;
  studentEditingRows: Array<StudentRowEditing>;
  sorting: Sorting;
  tableWidth?: number;
  tableHeight?: number;
  scrollToRow?: number;
  showDropDown: boolean;
  isDistrictOwner?: boolean;
};

export enum ColumnNames {
  STUDENT = "student",
  TEACHER = "teacher",
  GRADE = "grade",
  CLASS = "class",
}

export const gradeLevels = [
  "K",
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
  "G6",
  "G7",
  "G8",
  "G9",
  "G10",
  "G11",
  "G12",
];

class EvidenceTable extends Component<Props, State> {

  teacherClassModalRef: React.RefObject<HTMLInputElement>; 

  constructor(props: Props) {
    super(props);
    this.teacherClassModalRef = React.createRef();
  }


  defHeight = {
    groupHeaderHeight: 60,
    dataRowHeight: 65,
    headerHeight: 40,
  };

  defMinHeight = {
    dataRowHeight: 45,
    groupHeaderHeight: 60,
    headerHeight: 35,
  };

  state: Readonly<State> = {
    ...this.defHeight,
    scrollToRow: undefined,
    sorting: {
      column: undefined,
      measurement: undefined,
      direction: "asc",
    },
    studentEditingRows: [],
    displayedColumns: JSON.parse(
      localStorage.getItem(LocalStorageKeys.DisplayedColumns) || "[]"
    ),
    selectedStudents: [],
    showDropDown: false,
    isDistrictOwner: false,
  };

  elementRef?: HTMLDivElement;
  shadowImageRef?: HTMLDivElement;
  lowestGrade?: number;

  refCallback = (element: HTMLDivElement) => {
    if (element) {
      this.elementRef = element;
      this.calculateTableSize();
    }
  };

  getTableContainerWidth = () => {
    if (IS_READY_COACH && !this.props.interventionGroup) {
      const padding = this.props.showTopBar ? 0 : 20;
      return (
        (document.getElementById("user-dashboard")?.clientWidth ?? 0) -
        (document.getElementById("user-dashboard-sidebar")?.clientWidth ?? 0) -
        padding
      );
    }

    //todo maybe remove; need to check RI version

    return (
      (this.elementRef?.parentElement?.clientWidth ??
        this.elementRef?.parentElement?.parentElement?.parentElement
          ?.clientWidth) ||
      0
    );
  };

  getTableContainerHeight = () => {
    const viewPortHeight = document.documentElement.clientHeight;
    let topArea = 0;
    if (IS_READY_COACH) {
      const elements = document.getElementsByClassName("onboardingContainer");
      if (elements.length) {
        topArea =
          document
            .getElementsByClassName("dataTableContainer")?.[0]
            ?.getBoundingClientRect().top ??
          elements[0].getBoundingClientRect().bottom;
      }
    } else {
      const elements = document.getElementsByClassName(
        "groupMethodSelectionContainer"
      );
      if (elements.length) {
        topArea = elements[0].getBoundingClientRect().bottom;
      }
    }

    let margins = 20;
    let filtersHeight = 0;
    if (IS_READY_COACH) {
      filtersHeight =
        document
          .getElementById("evidence-table-filter-row")
          ?.getBoundingClientRect().height ?? 0;
    } else {
      margins = 20 * 2;
      filtersHeight = 100;
    }

    return viewPortHeight - topArea - margins - filtersHeight;
  };

  calculateMinimumGrade = (students: Array<StudentRow>) => {
    this.lowestGrade = undefined;
    students?.forEach(s => {
      if (this.props.evidencePeriodData) {
        let grade = getStudentGradeFromEcg(
          this.props.evidencePeriodData.evidence_column_groups,
          s.student.id!
        );
        if (grade) {
          let gradeIndex = gradeLevels.indexOf(grade)
          if (gradeIndex != -1 && (this.lowestGrade == undefined || this.lowestGrade > gradeIndex)) this.lowestGrade = gradeIndex
        }

      }
    })
  }

  calculateTableSize = () => {
    if (this.elementRef) {
      this.setState((prevState) => ({
        ...prevState,
        tableWidth: this.getTableContainerWidth(),
        tableHeight: Math.min(
          this.getTableContainerHeight(),
          (this.props.minimal ? 110 : 120) +
          prevState.dataRowHeight *
          (this.state.evidencePeriodData
            ? this.state.evidencePeriodData?.student_rows?.length
            : 0)
        ),
        ...(IS_READY_COACH &&
          (window.innerWidth <= 1200 ||
            this.props.isCompressedViewOfDataTable ||
            this.props.minimal)
          ? this.defMinHeight
          : this.defHeight),
      }));
    }
  };

  checkShowLoadKeyModal = () => {
    if (this.state.evidencePeriodData) {
      const length = getUnknownStudentsLength(this.state.evidencePeriodData);
      const flag = localStorage.getItem("showLoadKeyModal");
      if (length !== 0 && !flag) {
        this.props.openLoadKeyModal();
        localStorage.setItem("showLoadKeyModal", "1");
      }
    }
  };

  callbackFunction = () => {
    this.checkShowLoadKeyModal();
    this.calculateTableSize();
  };

  componentDidMount() {
    if (this.props.evidencePeriodData) {
      this.updateStudentData(true, false);
    }

    if(!this.props.schools.length) {
      this.props.getSchools();
    }

    if(!this.props.raceInfo?.races.length) {
      if(this.props.currentDataPeriod){
        this.props.getRaceInfo(this.props.currentDataPeriod.id)
      }
    }

    if (
      IS_READY_COACH &&
      (window.screen.width <= 1200 ||
        this.props.isCompressedViewOfDataTable ||
        this.props.minimal)
    ) {
      this.setState({
        ...this.defMinHeight,
      });
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        displayedColumns: JSON.parse(
          localStorage.getItem(LocalStorageKeys.DisplayedColumns) || "[]"
        ),
      }), () => this.updateStudentData(false, false, false));

    window.addEventListener("resize", this.calculateTableSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      IS_READY_COACH &&
      prevProps.isCompressedViewOfDataTable !==
      this.props.isCompressedViewOfDataTable
    ) {
      this.setState({
        ...(this.props.isCompressedViewOfDataTable
          ? this.defMinHeight
          : this.defHeight),
      });
    }

    if ( 
      (prevProps.isLoading.setValueOfDSEtoDataPeriod &&
        !this.props.isLoading.setValueOfDSEtoDataPeriod) ||
      (prevProps.isLoading.updateValueOfDSEtoDataPeriod &&
        !this.props.isLoading.updateValueOfDSEtoDataPeriod)
    ) {
      if (
        (!this.props.errors.setValueOfDSEtoDataPeriod &&
        !this.props.errors.updateValueOfDSEtoDataPeriod)
      ) {
        
        this.updateStudentData(true, false, false);
      } else {
        toastr.error(
          "Error",
          this.props.errors.setValueOfDSEtoDataPeriod ||
          this.props.errors.updateValueOfDSEtoDataPeriod ||
          "Error"
        );
      }
    }


    if (this.props.match.params.tabName !== prevProps.match.params.tabName) {
      this.calculateTableSize();
    }
    if (
      (prevProps.isLoading.addAssessmentToDataPeriod &&
        !this.props.isLoading.addAssessmentToDataPeriod) ||
      (prevProps.isLoading.uploadEvidenceData &&
        !this.props.isLoading.uploadEvidenceData) ||
      (prevProps.isLoading.uploadUndefinedAssessmentData &&
        !this.props.isLoading.uploadUndefinedAssessmentData)
    ) {
      this.props.getDataPeriods();
      this.props.getTeachers();
      
    }

    if((prevProps.isLoading.uploadAssessmentData &&
      !this.props.isLoading.uploadAssessmentData)) {
        if((this.props.userInfo?.profile.current_assignment?.role 
          !== UserAssignmentRole.DISTRICT_OWNER)) { // RC-1247 stop the calls
            
            this.props.getDataPeriods();
          }
        this.props.getTeachers();
      }

    if (prevState.scrollToRow) {
      this.setState({ scrollToRow: undefined });
    }
    if (
      prevProps.isLoading.getEvidenceData &&
      !this.props.isLoading.getEvidenceData
    ) {
      this.updateStudentData(true, false);
    }

    if (
      prevProps.isLoading.addTargetBehaviorToDataPeriod &&
      !this.props.isLoading.addTargetBehaviorToDataPeriod
    ) {
      if (!this.props.errors.addTargetBehaviorToDataPeriod) {
        
        this.updateStudentData(true, false);
      } else {
        toastr.error("Error", this.props.errors.addTargetBehaviorToDataPeriod);
      }
    }
    // RC-1366 comment this code
    // if (
    //   this.props.evidencePeriodData &&
    //   prevProps.teacherIdFilter !== this.props.teacherIdFilter
    // ) {
    //   const evidencePeriodData = this.props.teacherIdFilter
    //     ? {
    //       ...this.props.evidencePeriodData,
    //       student_rows: this.props.evidencePeriodData.student_rows.filter(
    //         this.teachersFilter
    //       ),
    //     }
    //     : this.props.evidencePeriodData;
    //   this.setState(
    //     {
    //       ...prevState,
    //       evidencePeriodData: evidencePeriodData,
    //     },
    //     () => {
    //       this.calculateTableSize();
    //     }
    //   );
    // }
if(this.props.evidencePeriodData &&
  (prevProps.evidencePeriodData !== this.props.evidencePeriodData)
){
    if(
      prevProps.teacherIdFilter !== this.props.teacherIdFilter
      || prevProps.schoolIdsFilter !== this.props.schoolIdsFilter 
      || prevProps.classFilter !== this.props.classFilter 
      || prevProps.gradeLevelFilter !== this.props.gradeLevelFilter 
      || prevProps.studentsFilter !== this.props.studentsFilter 
      || prevProps.currentDataPeriod !== this.props.currentDataPeriod 
      || prevProps.raceFilter !== this.props.raceFilter 
      || prevProps.raceOtherFilter !== this.props.raceOtherFilter 
      || prevProps.genderFilter !== this.props.genderFilter 
      || prevProps.specialEducationFilter !== this.props.specialEducationFilter 
      || prevProps.ellFilter !== this.props.ellFilter 
      || prevProps.sldFilter !== this.props.sldFilter 
      || prevProps.freeOrReducedLunchFilter !== this.props.freeOrReducedLunchFilter
      ) {
    
    const {
    teacherIdsFilter,
    schoolIdsFilter,
    classFilter,
    gradeLevelFilter,
    studentsFilter,
    raceFilter,
      raceOtherFilter,
      genderFilter,
      specialEducationFilter,
      ellFilter,
      sldFilter,
      freeOrReducedLunchFilter,
    dataSort
    } = this.props;
    const evidencePeriodData =
    (teacherIdsFilter && teacherIdsFilter.length > 0) 
      || (schoolIdsFilter && schoolIdsFilter.length > 0) 
      || classFilter 
      || studentsFilter 
      || gradeLevelFilter 
      || raceFilter
      || raceOtherFilter
      || genderFilter
      || specialEducationFilter
      || ellFilter
      || sldFilter
      || freeOrReducedLunchFilter
      ? {
        ...this.props.evidencePeriodData,
        student_rows: this.demographicsFilter(this.props.evidencePeriodData?.student_rows)
        .filter(
          this.rowsFilter
        ),
      }
      : this.props.evidencePeriodData;
      let sortedResult: StudentRow[] = this.handleMultiSortExc(this.props.dataSort, evidencePeriodData)!;
      this.setState((prevState) => ({
        ...prevState,
        evidencePeriodData: {
          ...prevState.evidencePeriodData!,
          student_rows: sortedResult,
        }
      }),() => {
        this.calculateTableSize;
        this.props.dataFilterApplied(true);
      });
    }
  }

    if (
      prevProps.isLoading.addStudentsToDataPeriod &&
      !this.props.isLoading.addStudentsToDataPeriod
    ) {
      if (!this.props.errors.addStudentsToDataPeriod) {
        
        this.updateStudentData(false, false);
      } else {
        toastr.error("Error", this.props.errors.addStudentsToDataPeriod);
      }
    }
    if (
      prevProps.dataSort?.activeSort.length !==
      this.props.dataSort?.activeSort.length
    ) {
      if(this.props.dataSort?.isResetColumnSort) {
        this.updateStudentData(false, false);
      }
    }
    { '' }
    if (
      prevProps.isLoading.addAssessmentToDataPeriod &&
      !this.props.isLoading.addAssessmentToDataPeriod
    ) {
      if (!this.props.errors.addAssessmentToDataPeriod) {
        this.setState({ scrollToRow: 0 });
        
        this.updateStudentData(true, false, false);
      } else {
        toastr.error("Error", this.props.errors.addAssessmentToDataPeriod);
      }
    }

    if (
      this.props.currentDataPeriod &&
      prevProps.isLoading.deleteEvidenceColumnsGroup &&
      !this.props.isLoading.deleteEvidenceColumnsGroup
    ) {
      this.props.getEvidenceInDataPeriod(this.props.currentDataPeriod.id);
    }

    if (
      prevProps.isLoading.changeStudentDSEGrade &&
      !this.props.isLoading.changeStudentDSEGrade
    ) {
      this.updateStudentData(true, false, false);
    }

    if (
      prevProps.isLoading.createDiagnosticInfoColumn &&
      !this.props.isLoading.createDiagnosticInfoColumn
    ) {
      this.updateStudentData(true, false, false);
    }

    if (
      this.props.currentDataPeriod &&
      prevProps.isLoading.addStudentSkills &&
      !this.props.isLoading.addStudentSkills
    ) {
      if (this.props.isEnterSpecificSkillActive) {
        this.props.getEvidenceInDataPeriod(this.props.currentDataPeriod.id);
        this.props.clickGotIt();
      } else {
        this.updateStudentData(true, false, false);
      }
    }

    if (
      prevProps.isLoading.deleteDataStudentEntry &&
      !this.props.isLoading.deleteDataStudentEntry
    ) {
      this.updateStudentData(true, false, false);
    }

    if (
      prevProps.isLoading.addStudentBehaviorsToDataPeriod &&
      !this.props.isLoading.addStudentBehaviorsToDataPeriod
    ) {
      this.updateStudentData(true, true, false);
    }

    if (
      prevProps.isLoading.addExistingBehaviorEcgToDataPeriod &&
      !this.props.isLoading.addExistingBehaviorEcgToDataPeriod
    ) {
      this.updateStudentData(true, false, false);
    }

    if (
      prevProps.isLoading.addBehaviorScreenerEcgToDataPeriod &&
      !this.props.isLoading.addBehaviorScreenerEcgToDataPeriod
    ) {
      this.updateStudentData(true, false, false);
    }

    if (
     ( prevProps.isLoading.updateStudentDetails &&
      !this.props.isLoading.updateStudentDetails)
    ) {
      console.log('update student')
      this.updateStudentData(true, false, false);
    }

    if(prevProps.isLoading.assignTeachersToStudent && 
      !this.props.isLoading.assignTeachersToStudent) {
        this.updateStudentData(true, false, false);
      }

    if (
      // update the column of students when the list of teachers (classes) of a student is changed
      ((prevProps.isLoading.assignTeachersToStudent &&
        !this.props.isLoading.assignTeachersToStudent) ||
        (prevProps.isLoading.updateClassesLinkedToStudent &&
          !this.props.isLoading.updateClassesLinkedToStudent)) &&
      this.props.selectedStudent
    ) {
      this.setState((state) => ({
        ...state,
        evidencePeriodData: {
          ...state.evidencePeriodData!,
          student_rows: state.evidencePeriodData!.student_rows.map((sr) =>
            sr.student.id === this.props.selectedStudent?.id
              ? { ...sr, student: this.props.selectedStudent! }
              : sr
          ),
        },
      }));
    }

    if (
      prevProps.showTopBar !== this.props.showTopBar ||
      prevProps.showGroupingStudentInfo !== this.props.showGroupingStudentInfo
    ) {
      this.calculateTableSize();
    }
    if (this.props.evidencePeriodData && (JSON.stringify(prevProps.dataSort) !== JSON.stringify(this.props.dataSort)
    )) {
      if(!this.props.dataSort?.isColumnSortApplied && this.props.isDataFilterApplied) {
        this.setState((prevState) => ({
          ...prevState,
          evidencePeriodData: {
            ...prevState.evidencePeriodData!,
            student_rows: this.demographicsFilter(this.props.evidencePeriodData?.student_rows)
            .filter(
              this.rowsFilter
            ),
          }
        })
        );
      }
      if(this.props.dataSort?.isColumnSortApplied) {
        let sortedResult: StudentRow[] = this.handleMultiSortExc(this.props.dataSort, this.state.evidencePeriodData!)!;
        this.setState((prevState) => ({
          ...prevState,
          evidencePeriodData: {
            ...prevState.evidencePeriodData!,
            student_rows: sortedResult,
          }
        }));
      }
      
    }

    if (
      prevProps.isLoading.updateClassesLinkedToStudent &&
      !this.props.isLoading.updateClassesLinkedToStudent
    ) {
      this.updateStudentData(true, false, false);
    }

    if (this.state.evidencePeriodData !== prevState.evidencePeriodData) {
      this.calculateTableSize();
    }
  }

  demographicsFilter = (studentRows: StudentRow[] = []) => {
    const {
      specialEducationFilter,
      ellFilter,
      sldFilter,
      freeOrReducedLunchFilter,
    } = this.props;

    if(specialEducationFilter != undefined) {
      studentRows =  studentRows
      .filter((studentRow) =>  studentRow.student.special_education == specialEducationFilter)
    }
    if(ellFilter != undefined) {
      studentRows =  studentRows
      .filter((studentRow) =>  studentRow.student.ell == ellFilter)
    }
    if(sldFilter != undefined) {
      studentRows =  studentRows
      .filter((studentRow) =>  studentRow.student.sld == sldFilter)
    } 
    if(freeOrReducedLunchFilter != undefined) {
      studentRows =  studentRows
      .filter((studentRow) =>  studentRow.student.free_or_reduced_lunch == freeOrReducedLunchFilter)
    } 
   // console.log(studentRows.length)
    return studentRows;
  }  

  rowsFilter = (studentRow: StudentRow) => {
    const {
      interventionGroup,
      schoolIdsFilter,
      teacherIdsFilter,
      classFilter,
      gradeLevelFilter,
      selectedClass,
      evidencePeriodData,
      studentsFilter,
      teacherIdFilter,
      raceFilter,
      raceOtherFilter,
      genderFilter
    } = this.props;
    let result: boolean = true;

    if (IS_READY_COACH && interventionGroup) {
      return true;
    }

    if (IS_READY_COACH && teacherIdFilter) {
      result =
        result &&
        !!studentRow.student.teachers?.some(
          (teacher) => teacher.id === teacherIdFilter
        );
    }

    if (studentsFilter) {
      result =
        result &&
        getFullName(studentRow.student)
          .toLowerCase()
          .includes(studentsFilter.toLowerCase());
    }

    if (schoolIdsFilter && schoolIdsFilter.length > 0) {
      result =
        result &&
        studentRow.student
          .teachers!.flatMap((teacher) => teacher.schools || [])
          .some((schoolId) => schoolId && schoolIdsFilter.includes(schoolId));
    }

    if (teacherIdsFilter && teacherIdsFilter.length > 0) {
      result =
        result &&
        studentRow.student.teachers!.some(
          (teacher) => teacher.id && teacherIdsFilter.includes(teacher.id)
        );
    }
    if (classFilter && selectedClass) {
      result =
        result &&
        studentRow.student.teachers!.some((st) => st.classes?.find((cls) => cls.id == classFilter));
    }
    if (evidencePeriodData && gradeLevelFilter && gradeLevelFilter.length > 0) {
      let resultsFromStudentEntries = evidencePeriodData.evidence_column_groups.some((ecg) =>
        ecg.evidence_columns.some((ec) =>
          ec.student_entries.some(
            (se) =>
              se?.student_id === studentRow?.student.id &&
              se?.grade &&
              gradeLevelFilter.includes(se.grade)
          )
        )
      );
      let resultsFromStudentRows = evidencePeriodData.student_rows.some(
        (sr) =>
          sr?.student.id === studentRow?.student.id &&
          sr?.grade &&
          gradeLevelFilter.includes(sr.grade)
      );
      result = result && (resultsFromStudentEntries || resultsFromStudentRows)
    }
    if(raceFilter) {
      result =
        result &&
        studentRow?.student?.race == this.props.raceInfo?.races
        .map((race, index) =>({
          value: index,
          label: race
        })).find((race) => race.label == raceFilter)?.value;
    }
    if(raceOtherFilter) {
      result =
        result &&
        studentRow?.student?.race_other_value == raceOtherFilter;
    }
    if(genderFilter) {
      result =
        result &&
        studentRow.student.gender == genderFilter;
    }
    return result;
  };

  teachersFilter = (studentRow: StudentRow) => {
    const { teacherIdFilter } = this.props;
    if (!teacherIdFilter) return true;
    return studentRow.student.teachers!.some(
      (teacher) => teacher.id === teacherIdFilter
    );
  };

  interventionGroupFilter = (studentRow: StudentRow) => {
    const { interventionGroup } = this.props;
    if (!interventionGroup) return true;
    return interventionGroup.students.some(
      (student) => student.id === studentRow.student.id
    );
  };

  getDisplayedColumnsFromStorage = (evidencePeriodData: EvidencePeriodData) => {
      const displayedColumnsData = localStorage.getItem(
        LocalStorageKeys.DisplayedColumns
      );
      const storageColumns = displayedColumnsData
        ? JSON.parse(displayedColumnsData)
        : [];
      const result = [...evidencePeriodData.evidence_column_groups.map(
        (evidenceColumnGroup) => {

          const existingDisplayedColumn = this.state.displayedColumns.find(
            (displayedColumn: any) =>
              displayedColumn.evidenceColumnGroupId === evidenceColumnGroup.id
          );
          if (existingDisplayedColumn) {
            return existingDisplayedColumn;
          } else {
            const existingStorageColumn = storageColumns.find(
              (displayedColumn: any) =>
                displayedColumn.evidenceColumnGroupId === evidenceColumnGroup.id
            );
            if (existingStorageColumn) {
              return existingStorageColumn;
            } 
            else {
              const displayedColumn = {
                evidenceColumnGroupId: evidenceColumnGroup.id,
                evidenceColumns: [...evidenceColumnGroup.evidence_columns.map(
                  (evidenceColumn) => ({
                    evidenceColumnId: evidenceColumn.id,
                    show: true,
                  })
                )],
              };
              storageColumns.push(displayedColumn);
              return displayedColumn;
            }
          }
        }
      )];
      localStorage.setItem(
        LocalStorageKeys.DisplayedColumns,
        JSON.stringify(storageColumns)
      );
      return result;
  };

  updateStudentData = (
    needDataFiltering: boolean = true,
    needEditing: boolean = true,
    needInterviewUpdating: boolean = true
  ) => {
    if (needInterviewUpdating) {
      this.props.getInterviews(); //todo maybe move to another place
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        isDistrictOwner: IS_READY_COACH && 
                this.props.userInfo?.profile.current_assignment?.role 
                === UserAssignmentRole.DISTRICT_OWNER,
        scrollToRow: needDataFiltering ? prevState.scrollToRow : 1,
        sorting: needDataFiltering
          ? prevState.sorting
          : { measurement: undefined, column: undefined, direction: "desc" },
        evidencePeriodData: {
          ...this.props.evidencePeriodData!,
          student_rows: needDataFiltering
            ? this.getSortedStudentRows()
            : this.props.evidencePeriodData!.student_rows,
        },
        displayedColumns: this.getDisplayedColumnsFromStorage(this.props.evidencePeriodData!),
        studentEditingRows: this.props.evidencePeriodData!.student_rows.map(
          (studentRow) => {
            return {
              studentId: studentRow.student.id!,
              evidenceColumnGroups: this.props.evidencePeriodData!.evidence_column_groups.map(
                (evidenceColumnGroup) => {
                  if (prevState.studentEditingRows.length > 0) {
                    console.log(prevState.studentEditingRows, studentRow.student.id);
                    const studentEditingRow = prevState.studentEditingRows.find(
                      (x) => x.studentId === studentRow.student.id!
                    );
                    const existingEvidenceColumnGroup:
                      | EvidenceColumnGroupEditing
                      | undefined = studentEditingRow
                        ? studentEditingRow.evidenceColumnGroups.find(
                          (x) =>
                            x.evidenceColumnGroupId === evidenceColumnGroup.id
                        )
                        : undefined;
                    if (existingEvidenceColumnGroup) {
                      return {
                        evidenceColumnGroupId: evidenceColumnGroup.id,
                        evidenceColumnEditingStatuses: evidenceColumnGroup.evidence_columns.map(
                          (evidenceColumn) => {
                            const value = this.getEvidenceValue(
                              evidenceColumn,
                              studentRow
                            );
                            const evidenceColumnEditingStatus = existingEvidenceColumnGroup.evidenceColumnEditingStatuses.find(
                              (editingStatus) =>
                                editingStatus?.evidenceColumnId ===
                                evidenceColumn.id
                            )!;
                            if (
                              value !== undefined &&
                              evidenceColumnEditingStatus.value === value
                            ) {
                              return {
                                evidenceColumnId: evidenceColumn.id,
                                isEdited: false,
                                value: undefined,
                              };
                            }
                            return evidenceColumnEditingStatus;
                          }
                        ),
                      };
                    }
                  }
                  return {
                    evidenceColumnGroupId: evidenceColumnGroup.id,
                    evidenceColumnEditingStatuses: evidenceColumnGroup.evidence_columns.map(
                      (evidenceColumn) => {
                        return {
                          evidenceColumnId: evidenceColumn.id,
                          isEdited: needEditing,
                        };
                      }
                    ),
                  };
                }
              ),
            };
          }
        ),
      }),
      () => {
        if(this.props.fromSetGoalModal && this.props.interventionGroup) {
          this.setState((prevState) => ({
            ...prevState,
            evidencePeriodData: {
              ...prevState.evidencePeriodData!,
             student_rows: 
                prevState.evidencePeriodData?.student_rows?.filter((sr) => this.props.interventionGroup?.students.some((std) => std.id == sr.student.id))!
            },
          }));
        }
        return IS_READY_COACH ? this.calculateTableSize : this.callbackFunction
      }
    );

    this.calculateMinimumGrade(this.props.evidencePeriodData!.
      student_rows);
  };

  getEvidenceValue = (
    evidenceColumn: EvidenceColumn,
    studentRow: StudentRow
  ) => {
    let value: number | undefined;
    const studentEntry = evidenceColumn.student_entries.find(
      (studentEntry) => studentEntry.student_id === studentRow.student.id
    );
    if (studentEntry) {
      if (evidenceColumn.evidence_type === EvidenceType.Data) {
        if (evidenceColumn.measurement.type === MeasurementType.CATEGORY) {
          const possibleValue = evidenceColumn.measurement.possible_values!.find(
            (possibleValue) => possibleValue.order === +studentEntry.value!
          );
          return possibleValue ? possibleValue.order : +studentEntry.value!;
        } else {
          value = +studentEntry.value!;
        }
      }
    }
    return value;
  };

  filterDisplayedColumns = (evidenceColumnGroupId: number) => (
    evidenceColumn: EvidenceColumn
  ) => {
    const existingDisplayedColumn = this.state.displayedColumns.find(
      (displayedColumn: any) =>
        displayedColumn.evidenceColumnGroupId === evidenceColumnGroupId
    );
    if(existingDisplayedColumn) {
      const col = this.state.displayedColumns
        .find(
          (displayedColumn) =>
            displayedColumn.evidenceColumnGroupId === evidenceColumnGroupId
        )!
        .evidenceColumns.find((x) => x.evidenceColumnId === evidenceColumn.id);
      if (col) {
        return col.show;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  isChecked = (evidenceColumnGroupId: number, evidenceColumnId: number) => {
    if(!this.state.displayedColumns.length) {
      this.setState(
        (prevState) => ({
          ...prevState,
          displayedColumns: JSON.parse(
            localStorage.getItem(LocalStorageKeys.DisplayedColumns) || "[]"
          ),
        }));
    }
    const group = this.state.displayedColumns.find(
      (x) => x.evidenceColumnGroupId === evidenceColumnGroupId
    );
    if (group) {
      const col = group.evidenceColumns.find(
        (x) => x.evidenceColumnId === evidenceColumnId
      );
      if (col) {
        return col.show;
      }
    }
    return false;
  };

  getSortedStudentRows = () => {
    if (this.props.evidencePeriodData) {
      const filteredStudentsRows = this.demographicsFilter(this.props
        .evidencePeriodData?.student_rows)?.filter(this.rowsFilter)
        .filter(this.interventionGroupFilter);
      const { sorting } = this.state;
      if (sorting.measurement) {
        const evidenceColumnGroup:
          | EvidenceColumnGroup
          | undefined = this.props.evidencePeriodData!.evidence_column_groups.find(
            (x) => x.id === sorting.measurement!.evidenceColumnGroupId
          );
        if (evidenceColumnGroup) {
          const evidenceColumn:
            | EvidenceColumn
            | undefined = evidenceColumnGroup.evidence_columns.find(
              (x) => x.id === sorting.measurement!.evidenceColumnId
            );
          if (evidenceColumn) {
            return filteredStudentsRows.sort(
              this.sortStudentRowsByDse(evidenceColumn, sorting.direction, evidenceColumnGroup.meta_group_type)
            );
          }
        }
      } else if (sorting.column) {
        return filteredStudentsRows.sort(
          this.sortStudentRowsByName(
            sorting.column as ColumnNames,
            sorting.direction
          )
        );
      }
      return filteredStudentsRows;
    }
    return [];
  };

  handleSort = (columnName: ColumnNames) => () => {
    const direction = this.state.sorting.direction === "asc" ? "desc" : "asc";
    const sortedData = this.state.evidencePeriodData!.student_rows.sort(
      this.sortStudentRowsByName(columnName, direction)
    );
    this.setState((prevState) => ({
      ...prevState,
      evidencePeriodData: {
        ...prevState.evidencePeriodData!,
        student_rows: sortedData,
      },
      sorting: {
        direction: direction,
        measurement: undefined,
        column: columnName,
      },
    }));
  };

  handleDseSort = (
    evidence_column: EvidenceColumn,
    evidenceColumnGroup: EvidenceColumnGroup
  ) => () => {
    const direction = this.state.sorting.direction === "asc" ? "desc" : "asc";
    const sortedData = this.state.evidencePeriodData!.student_rows.sort(
      this.sortStudentRowsByDse(evidence_column, direction, evidenceColumnGroup.meta_group_type)
    );
    this.setState((prevState) => ({
      ...prevState,
      evidencePeriodData: {
        ...prevState.evidencePeriodData!,
        student_rows: sortedData,
      },
      sorting: {
        direction: direction,
        column: undefined,
        measurement: {
          evidenceColumnGroupId: evidenceColumnGroup.id,
          evidenceColumnId: evidence_column.id,
        },
      },
    }));
  };

  getSortFunction = (sortOrder: any) => {
    const sortOptionLength = sortOrder.length;
    if (sortOptionLength == 1) {
      return (a: any, b: any) =>
        ((sortOrder[0].order == "Ascending" ? a[sortOrder[0].key] - b[sortOrder[0].key] : b[sortOrder[0].key] - a[sortOrder[0].key]));
    } else if (sortOptionLength == 2) {
      return (a: any, b: any) =>
        ((sortOrder[0].order == "Ascending" ? a[sortOrder[0].key] - b[sortOrder[0].key] : b[sortOrder[0].key] - a[sortOrder[0].key])
        || (sortOrder[1].order == "Ascending" ? a[sortOrder[1].key] - b[sortOrder[1].key] : b[sortOrder[1].key] - a[sortOrder[1].key]));
    } else if (sortOptionLength == 3) {
      return (a: any, b: any) =>
        ((sortOrder[0].order == "Ascending" ? a[sortOrder[0].key] - b[sortOrder[0].key] : b[sortOrder[0].key] - a[sortOrder[0].key])
        || (sortOrder[1].order == "Ascending" ? a[sortOrder[1].key] - b[sortOrder[1].key] : b[sortOrder[1].key] - a[sortOrder[1].key])
        || (sortOrder[2].order == "Ascending" ? a[sortOrder[2].key] - b[sortOrder[2].key] : b[sortOrder[2].key] - a[sortOrder[2].key]))
    } else if (sortOptionLength == 4) {
      return (a: any, b: any) =>
        ((sortOrder[0].order == "Ascending" ? a[sortOrder[0].key] - b[sortOrder[0].key] : b[sortOrder[0].key] - a[sortOrder[0].key])
        || (sortOrder[1].order == "Ascending" ? a[sortOrder[1].key] - b[sortOrder[1].key] : b[sortOrder[1].key] - a[sortOrder[1].key])
        || (sortOrder[2].order == "Ascending" ? a[sortOrder[2].key] - b[sortOrder[2].key] : b[sortOrder[2].key] - a[sortOrder[2].key])
        || (sortOrder[3].order == "Ascending" ? a[sortOrder[3].key] - b[sortOrder[3].key] : b[sortOrder[3].key] - a[sortOrder[3].key])
      )
    }
  }

  handleMultiSortExc = (
    dataSort: any,
    evidencePeriodData: EvidencePeriodData
  )  => {
    const sortOptions = dataSort?.activeSort;
    
    if (sortOptions.length) {
      const combined: any = sortOptions[0].col.student_entries.map((std: any) => { return { student_id: std.student_id } });
      const sortOrder: any = [];
      for (let i in sortOptions) {
        for (let j in sortOptions[i].col.student_entries) {
          const index = combined.findIndex(
            (obj: any) => obj.student_id.toString() === sortOptions[i].col.student_entries[j].student_id.toString()
            )
          if(combined[index])
            combined[index][sortOptions[i].col.measurement.column_name] = parseInt(sortOptions[i].col.student_entries[j].value);
        }
        sortOrder.push({ key: sortOptions[i].col.measurement.column_name, order: sortOptions[i].direction });
        const direction = sortOptions[i].direction !== "Ascending" ? "desc" : "asc";
        console.log(direction)
      }
          
      let columnSort = combined.sort(this.getSortFunction(sortOrder));
      
      const sortStudentIds = columnSort.map((et: any) => et.student_id);
      
      let sortedResult = evidencePeriodData?.student_rows.sort((a, b) => sortStudentIds.indexOf(a.student.id) - sortStudentIds.indexOf(b.student.id));
      return sortedResult;
    }
    return evidencePeriodData?.student_rows;
  };

  getStudentGrade = (studentId?: number): Grade | undefined => {
    const { evidencePeriodData } = this.state;
    if (evidencePeriodData && studentId !== undefined) {
      for (const ecg of evidencePeriodData.evidence_column_groups) {
        for (const ec of ecg.evidence_columns) {
          for (const se of ec.student_entries) {
            if (se.student_id === studentId) {
              return se.grade;
            }
          }
        }
      }
    }
    return undefined;
  };

  sortStudentRowsByName = (
    columnName: ColumnNames,
    direction: "asc" | "desc"
  ) => ({ student: a }: StudentRow, { student: b }: StudentRow) => {
    if (columnName === ColumnNames.GRADE) {
      const gradeA = this.getStudentGrade(a.id);
      const gradeB = this.getStudentGrade(b.id);

      if (!gradeA) return 1;
      if (!gradeB) return -1;

      if (
        gradeLevels.indexOf(gradeA as any) < gradeLevels.indexOf(gradeB as any)
      ) {
        return direction === "asc" ? -1 : 1;
      } else if (
        gradeLevels.indexOf(gradeA as any) > gradeLevels.indexOf(gradeB as any)
      ) {
        return direction === "asc" ? 1 : -1;
      }

      return 0;
    }

    let nameA, nameB;
    if (columnName === ColumnNames.STUDENT) {
      nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
      nameB = `${b.first_name} ${b.last_name}`.toUpperCase();
    } else if(columnName == ColumnNames.CLASS) {
      nameA = a
      .teachers!.map(
        (teacher) => (teacher.classes != null) && teacher.classes!.map((classInfo) => `${classInfo.name}`)
      )
      .join(", ")
      .toUpperCase();
    nameB = b
      .teachers!.map(
        (teacher) => (teacher.classes != null) && teacher.classes!.map((classInfo) => `${classInfo.name}`)
      )
      .join(", ")
      .toUpperCase();
    } else {
      nameA = a
        .teachers!.map(
          (teacher) => `${teacher.first_name} ${teacher.last_name}`
        )
        .join(", ")
        .toUpperCase();
      nameB = b
        .teachers!.map(
          (teacher) => `${teacher.first_name} ${teacher.last_name}`
        )
        .join(", ")
        .toUpperCase();
    }
    if (nameA < nameB) {
      return direction === "asc" ? -1 : 1;
    }
    if (nameA > nameB) {
      return direction === "asc" ? 1 : -1;
    }
    return 0;
  };

  sortStudentRowsByDse = (
    evidenceColumn: EvidenceColumn,
    direction: "asc" | "desc",
    evidenceGroupType: string
  ) => (a: StudentRow, b: StudentRow) => {
    const studentEntryA:
      | StudentEntry
      | undefined = evidenceColumn.student_entries.find(
        (r) => r.student_id === a.student.id
      );
    const studentEntryB:
      | StudentEntry
      | undefined = evidenceColumn.student_entries.find(
        (r) => r.student_id === b.student.id
      );

    if (!studentEntryB && !studentEntryA)
      return this.sortStudentRowsByName(ColumnNames.STUDENT, "asc")(a, b);
    if (!studentEntryB) return -1;
    if (!studentEntryA) return 1;

    if (evidenceColumn.evidence_type === EvidenceType.Data) {
      const valueA = +studentEntryA.value!;
      const valueB = +studentEntryB.value!;
      if (valueA < valueB) {
        return direction === "asc" ? -1 : 1;
      }

      if (valueA > valueB) {
        return direction === "asc" ? 1 : -1;
      }
    }

    if (evidenceColumn.evidence_type === EvidenceType.NeedAreas) {
      let valueA, valueB = null;
      if(evidenceGroupType == 'meta_group_needs') { // Reading area of need
        valueA = (studentEntryA.needs_area_tags != null) && studentEntryA.needs_area_tags.length
          ? studentEntryA.needs_area_tags.map((nat) => nat.display_name).join(" ").replace(" ", "")
          : null;
        
        valueB = (studentEntryB.needs_area_tags != null) && studentEntryB.needs_area_tags.length
          ? studentEntryB.needs_area_tags.map((nat) => nat.display_name).join(" ").replace(" ", "")
          : null;

      } else { // behavior area of need
        valueA = (studentEntryA.needs_area_tags != null) && studentEntryA.needs_area_tags.length
        ? studentEntryA.needs_area_tags.sort((a,b) => {
            if(a.display_name > b.display_name) {
              return direction === "asc" ? -1 : 1;
            }
            return 0;
        }).length
        : null;
      
      valueB = (studentEntryB.needs_area_tags != null) && studentEntryB.needs_area_tags.length
        ? studentEntryB.needs_area_tags.sort((a,b) => {
            if(a.display_name < b.display_name) {
              return direction === "asc" ? 1 : -1;
            }
            return 0;
        }).length
        : null;
      }

      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA < valueB) {
        return direction === "asc" ? 1 : -1;
      }
      if (valueA > valueB) {
        return direction === "asc" ? -1 : 1;
      }
      
    }

    if (evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo) {
      const valueA = (studentEntryA.diagnostic_tags != null) && studentEntryA.diagnostic_tags.length
        ? studentEntryA.diagnostic_tags[0].display_name
        : null;
      const valueB = (studentEntryB.diagnostic_tags != null) && studentEntryB.diagnostic_tags.length
        ? studentEntryB.diagnostic_tags[0].display_name
        : null;
      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA < valueB) {
        return direction === "asc" ? 1 : -1;
      }
      if (valueA > valueB) {
        return direction === "asc" ? -1 : 1;
      }
    }

    return 0;
  };

  sortStudentRowsByDseMulti = (
    evidenceColumn: EvidenceColumn,
    direction: "asc" | "desc",
  ) => (a: StudentRow, b: StudentRow) => {
    const studentEntryA:
      | StudentEntry
      | undefined = evidenceColumn.student_entries.find(
        (r) => r.student_id === a.student.id
      );
    const studentEntryB:
      | StudentEntry
      | undefined = evidenceColumn.student_entries.find(
        (r) => r.student_id === b.student.id
      );

    if (!studentEntryB && !studentEntryA)
      return this.sortStudentRowsByName(ColumnNames.STUDENT, "asc")(a, b);
    if (!studentEntryB) return -1;
    if (!studentEntryA) return 1;

    if (evidenceColumn.evidence_type === EvidenceType.Data) {
      const valueA = +studentEntryA.value!;
      const valueB = +studentEntryB.value!;
      if (valueA < valueB) {
        return direction === "asc" ? -1 : 1;
      }

      if (valueA > valueB) {
        return direction === "asc" ? 1 : -1;
      }
    }

    if (evidenceColumn.evidence_type === EvidenceType.NeedAreas) {
      const valueA = (studentEntryA.needs_area_tags != null) && studentEntryA.needs_area_tags.length
        ? studentEntryA.needs_area_tags[0].display_name
        : null;
      const valueB = (studentEntryB.needs_area_tags != null) && studentEntryB.needs_area_tags.length
        ? studentEntryB.needs_area_tags[0].display_name
        : null;
      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA < valueB) {
        return direction === "asc" ? 1 : -1;
      }
      if (valueA > valueB) {
        return direction === "asc" ? -1 : 1;
      }
    }

    if (evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo) {
      const valueA = (studentEntryA.diagnostic_tags != null) && studentEntryA.diagnostic_tags.length
        ? studentEntryA.diagnostic_tags[0].display_name
        : null;
      const valueB = (studentEntryB.diagnostic_tags != null) && studentEntryB.diagnostic_tags.length
        ? studentEntryB.diagnostic_tags[0].display_name
        : null;
      if (!valueA) return 1;
      if (!valueB) return -1;
      if (valueA < valueB) {
        return direction === "asc" ? 1 : -1;
      }
      if (valueA > valueB) {
        return direction === "asc" ? -1 : 1;
      }
    }
    return 0;
  };


  handleViewBenchmarks = (
    assessmentName: string,
    selectedEvidenceColumnGroupId: number,
    selectedEvidenceColumn: EvidenceColumn
  ) => () => {
    this.setState({
      selectedEvidence: {
        assessmentName: assessmentName,
        evidenceColumnGroupId: selectedEvidenceColumnGroupId,
        evidenceColumn: selectedEvidenceColumn,
      },
    });
    this.props.openViewBenchmarksModal();
  };

  updateMeasurementCustomColorCriteria = (editedMeasurement: Measurement) => {
    if (
      editedMeasurement.custom_color_criteria !==
      this.state.selectedEvidence!.evidenceColumn.measurement
        .custom_color_criteria
    ) {
      this.setState((prevState) => {
        return {
          ...prevState,
          evidencePeriodData: {
            ...prevState.evidencePeriodData!,
            evidence_column_groups: prevState.evidencePeriodData!.evidence_column_groups.map(
              (evidenceColumnGroup) => {
                if (
                  evidenceColumnGroup.id ===
                  this.state.selectedEvidence!.evidenceColumnGroupId
                ) {
                  return {
                    ...evidenceColumnGroup,
                    evidence_columns: evidenceColumnGroup.evidence_columns.map(
                      (evidenceColumn) => {
                        if (
                          evidenceColumn.id ===
                          this.state.selectedEvidence!.evidenceColumn.id
                        ) {
                          return {
                            ...evidenceColumn,
                            measurement: {
                              ...evidenceColumn.measurement,
                              custom_color_criteria:
                                editedMeasurement.custom_color_criteria,
                            },
                          };
                        }
                        return evidenceColumn;
                      }
                    ),
                  };
                }
                return evidenceColumnGroup;
              }
            ),
          },

        };
      });
    }
  };

  handleViewBenchmarksModalHide = (editedMeasurement?: Measurement) => {
    if (editedMeasurement) {
      this.updateMeasurementCustomColorCriteria(editedMeasurement);
    }
    this.props.hideViewBenchmarksModal();
  };

  handleDisplayedColumnsChange = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number
  ) => () => {
    const displayedColumns = this.state.displayedColumns.map(
      (displayedColumn) => {
        if (displayedColumn.evidenceColumnGroupId === evidenceColumnGroupId) {
          const displayedColumnsNum = displayedColumn.evidenceColumns.filter(
            (x) => x.show
          ).length;
          const result: DisplayedColumn = {
            ...displayedColumn,
            evidenceColumns: displayedColumn.evidenceColumns.map(
              (evidenceColumn) => {
                if (evidenceColumn.evidenceColumnId === evidenceColumnId) {
                  return {
                    ...evidenceColumn,
                    show: !evidenceColumn.show
                      ? !evidenceColumn.show
                      : displayedColumnsNum > 1
                        ? !evidenceColumn.show
                        : evidenceColumn.show,
                  };
                }
                return evidenceColumn;
              }
            ),
          };

          const found =
            result.evidenceColumns.filter(
              (ec) => ec.evidenceColumnId === evidenceColumnId
            ).length > 0;

          if (!found) {
            result.evidenceColumns.push({
              evidenceColumnId: evidenceColumnId,
              show: true,
            });
          }

          return result;
        }
        return displayedColumn;
      }
    );
    this.setState({
      displayedColumns: displayedColumns,
    });
    const storageData = JSON.parse(
      localStorage.getItem(LocalStorageKeys.DisplayedColumns) || "[]"
    ) as Array<DisplayedColumn>;
    const mergedData = storageData
      .filter(
        (data) =>
          !displayedColumns.some(
            (dc) => dc.evidenceColumnGroupId === data.evidenceColumnGroupId
          )
      )
      .concat(displayedColumns);
    localStorage.setItem(
      LocalStorageKeys.DisplayedColumns,
      JSON.stringify(mergedData)
    );
  };

  findDataStudentEntry = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => {
    let dataStudentEntry: StudentEntry | undefined;

    const curEvidenceColumnGroup:
      | EvidenceColumnGroup
      | undefined = this.state.evidencePeriodData!.evidence_column_groups.find(
        (evidenceColumnGroup) => evidenceColumnGroup.id === evidenceColumnGroupId
      );
    if (curEvidenceColumnGroup) {
      const curEvidenceColumn:
        | EvidenceColumn
        | undefined = curEvidenceColumnGroup.evidence_columns.find(
          (evidenceColumn) => evidenceColumn.id === evidenceColumnId
        );
      if (curEvidenceColumn) {
        const studentEntry:
          | StudentEntry
          | undefined = curEvidenceColumn.student_entries.find(
            (studentEntry) => studentEntry.student_id === studentId
          );
        if (studentEntry) {
          if (studentEntry) {
            dataStudentEntry = studentEntry;
          }
        }
      }
    }
    return dataStudentEntry;
  };

  handleStudentDataChange = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    propertyValueName: string,
    value: number
  ) => {
    console.log('handleStudentDataChage', value)

    const dataStudentEntry = this.findDataStudentEntry(
      evidenceColumnGroupId,
      evidenceColumnId,
      studentId
    );
    if (dataStudentEntry) {
      if (value === +dataStudentEntry.value!) {
        this.changeCellEditingStatus(
          evidenceColumnGroupId,
          evidenceColumnId,
          studentId,
          undefined,
          true
        );
        return; //value hasn't changed - just close
      }
      const stdId = dataStudentEntry.entry_id;
      if (stdId) {
        //updated STE
        this.props.updateValueOfDSEtoDataPeriod(evidenceColumnGroupId, stdId, {
          [propertyValueName]: value,
        });
        return;
      }
    }
    //new STE
    this.props.setValueOfDSEtoDataPeriod(evidenceColumnGroupId, {
      student_id: studentId,
      evidence_column_id: evidenceColumnId,
      [propertyValueName]: value,
    });
  };

  handleChangesApply = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    value?: number
  ) => () => {
    if (value !== undefined) {
      this.handleStudentDataChange(
        evidenceColumnGroupId,
        evidenceColumnId,
        studentId,
        "value",
        +value
      );
    }
  };

  handleKeyDown = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const { value } = event.target as HTMLInputElement;
      if (value !== "") {
        this.handleStudentDataChange(
          evidenceColumnGroupId,
          evidenceColumnId,
          studentId,
          "value",
          +value
        );
      }
    }
  };

  handleSelectedValueChange = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => (value: ValueType<PossibleValue, false>) => {
    console.log('handleSelectedValueChange', value)

    if (value) {
      this.changeCellEditingStatus(
        evidenceColumnGroupId,
        evidenceColumnId,
        studentId,
        (value as PossibleValue).order
      );
      this.handleStudentDataChange(
        evidenceColumnGroupId,
        evidenceColumnId,
        studentId,
        "selected_value_order",
        (value as PossibleValue).order
      );
    }
  };

  handleGradeValueChange = (studentId: number, grade: Grade) => {
    if (this.props.currentDataPeriod) {
      this.props.changeStudentDSEGrade(
        this.props.currentDataPeriod.id,
        studentId,
        grade
      );
    }
  };

  handleCellEditFormChange = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    value?: string
  ) => () => {
    const evidenceColumnGroup: EvidenceColumnGroup | undefined =
      this.props.evidencePeriodData?.evidence_column_groups
        .find((ecg: EvidenceColumnGroup) => ecg.id == evidenceColumnGroupId); 
        console.log('handleCellEditFormChange', value)
    
    if (!this.props.isReadonly && (evidenceColumnGroup?.assessment.name !== 'SRSS')) {
      this.changeCellEditingStatus(
        evidenceColumnGroupId,
        evidenceColumnId,
        studentId,
        value,
        true
      );
    }
  };

  changeCellEditingStatus = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number,
    value?: string | number,
    isCloseAction?: boolean
  ) => {
    this.setState((prevState) => ({
      studentEditingRows: prevState.studentEditingRows.map((studentRow) => {
        if (studentRow.studentId === studentId) {
          return {
            ...studentRow,
            evidenceColumnGroups: studentRow.evidenceColumnGroups.map(
              (evidenceColumnGroup) => {
                if (
                  evidenceColumnGroup.evidenceColumnGroupId ===
                  evidenceColumnGroupId
                ) {
                  return {
                    ...evidenceColumnGroup,
                    evidenceColumnEditingStatuses: evidenceColumnGroup.evidenceColumnEditingStatuses.map(
                      (evidenceColumnEditingStatus) => {
                        if (
                          evidenceColumnEditingStatus.evidenceColumnId ===
                          evidenceColumnId
                        ) {
                          return {
                            ...evidenceColumnEditingStatus,
                            isEdited: isCloseAction
                              ? !evidenceColumnEditingStatus.isEdited
                              : evidenceColumnEditingStatus.isEdited,
                            value: value ? +value : undefined,
                          };
                        }
                        return evidenceColumnEditingStatus;
                      }
                    ),
                  };
                }
                return evidenceColumnGroup;
              }
            ),
          };
        }
        return studentRow;
      }),
    }));
  };

  handleStudentRowEditingChange = (
    evidenceColumnGroupId: number,
    evidenceColumnId: number,
    studentId: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log('handleStudentRowEdit', value)
    this.changeCellEditingStatus(
      evidenceColumnGroupId,
      evidenceColumnId,
      studentId,
      value
    );
  };

  checkSorting = (evidenceColumnGroupId: number, evidenceColumnId: number) => {
    const { sorting } = this.state;
    if (!sorting.measurement) return undefined;
    if (
      sorting.measurement.evidenceColumnGroupId === evidenceColumnGroupId &&
      sorting.measurement.evidenceColumnId === evidenceColumnId
    ) {
      return sorting.direction;
    }
    return undefined;
  };

  handleStudentCheck = (student: Student) => (
    event: React.ChangeEvent<any>
  ) => {
    const { checked } = event.target;
    if (checked) {
      this.setState((prevState) => ({
        ...prevState,
        selectedStudents: [...prevState.selectedStudents, student],
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        selectedStudents: prevState.selectedStudents.filter(
          (selectedStudent) => selectedStudent.id !== student.id
        ),
      }));
    }
  };

  handleSelectedStudentsAdd = () => {
    this.props.addStudentsToNewGroup(this.state.selectedStudents);
    this.setState({ selectedStudents: [] });
  };

  handleSelectedStudentClassAdd = () => {
    const {
      currentDataPeriod
    } = this.props;
    this.setState({showDropDown: !this.state.showDropDown}, () => {
      if(this.state.showDropDown) {
        let grade = this.state.selectedStudents[0].grade;
        this.props.getAllClasses(currentDataPeriod!.id, grade);
      }
    })
  }

  resetState = () => {
    this.setState({ selectedStudents: [] });
  }

  getColumnWidth = (
    evidenceColumnGroup: EvidenceColumnGroup,
    evidenceColumn: EvidenceColumn
  ) => {
    const coeff =
      window.innerWidth <= 1200 ||
        this.props.isCompressedViewOfDataTable ||
        this.props.minimal
        ? 0.85
        : 1;

    if (evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo)
      return coeff * 570;
    if (evidenceColumn.evidence_type === EvidenceType.BehaviorScreener)
      return coeff * 150;
    if (evidenceColumn.evidence_type === EvidenceType.NeedAreas) return 300;
    if (evidenceColumn.evidence_type === EvidenceType.ExistingBehavior)
      return coeff * 300;
    return evidenceColumnGroup.evidence_columns.filter(
      this.filterDisplayedColumns(evidenceColumnGroup.id)
    ).length === 1
      ? coeff * 240
      : coeff * 110;
  };

  getStudentColumnGroupHeader = () => {
    
    if (this.props.isPlanning) {
      return (
        <>
          <DataLoadingCell />
          <AddStudentsHeaderCell />
          <div className="parentCell">
            {!this.state.isDistrictOwner ? <CheckStudentsHeaderCell
              selectedStudents={this.state.selectedStudents}
              onClick={this.handleSelectedStudentsAdd}
            /> : ''}
            <div 
              ref={this.teacherClassModalRef}
              onClick={() => this.handleSelectedStudentClassAdd}
            >
              <CheckTeacherClassHeaderCell
                selectedStudents={this.state.selectedStudents}
                onClick={() => this.handleSelectedStudentClassAdd()}
              />

            </div>
            {this.state.showDropDown && (
              <Overlay
               rootClose={true}
               onHide={() => this.setState({showDropDown: false})}
               show={this.state.showDropDown}
               target={this.teacherClassModalRef.current}
               placement={"bottom"}
             >
               <Popover
                 id="popover-basic"
                 style={{ width: "100%", zIndex: 9999999 }}
               >
                 <Popover.Content>
                  <StudentClassesTab
                      students={this.state.selectedStudents}
                      onBackClick={this.handleSelectedStudentClassAdd}
                      onReset={this.resetState}
                  />
                  </Popover.Content>
               </Popover>
              </Overlay>
            )}
            
          </div>
        </>
      );
    } else {
      if (this.props.interventionGroup || this.props.isReadonly) {
        return <div />;
      } else {
        return <AddStudentsHeaderCell />;
      }
    }
  };

  getStudentCheckColumn = () => {
    return this.props.isPlanning ? (
      <Column
        allowCellsRecycling
        fixed={true}
        width={30}
        cell={(props) => {
          const student = this.state.evidencePeriodData!.student_rows[
            props.rowIndex
          ].student;

          student.grade = this.state.evidencePeriodData!.student_rows[
            props.rowIndex
          ].grade;

          if(student.grade ==  null) {
            this.state.evidencePeriodData!.evidence_column_groups
            .map((evidence_column_groups) => {
              return evidence_column_groups.evidence_columns.map((evidenceColumn) => {
                return evidenceColumn.student_entries.map((studentEntry) => {
                  student.grade = studentEntry.grade;
                  return student;
                })
              })
            })
          }
          return (
            <Cell>
              {(!this.props.newGroupStudents.some(
                (s) => s.id === student.id
              )) && (
                  <input
                    checked={this.state.selectedStudents.some(
                      (selectedStudent) => selectedStudent.id === student.id
                    )}
                    onChange={this.handleStudentCheck(student)}
                    type="checkbox"
                    key={student.id}
                  />
                )}
            </Cell>
          );
        }}
      />
    ) : (
      <Column width={0} />
    );
  };

  getStudentGroupIconColumn = () => {
    
    return this.props.isPlanning ? (
      <Column
      allowCellsRecycling
      fixed={true}
      width={10}
      cell={(props) => {
          const student = this.state.evidencePeriodData!.student_rows[props.rowIndex].student;
           return (
            <Cell className="blueStrip">
              <StudentGroupIconOverlay
              interventionGroups={this.props.interventionGroups}
              student={student}
            />
            </Cell>
          );
        }}
      />
    ) : (
      <Column width={0} />
    );
  };

  handleStudentDragStart = (student: Student) => (
    event: React.DragEvent<Cell>
  ) => {
    if(this.state.isDistrictOwner) {
      event.preventDefault();
    }
    event.dataTransfer.setData("student", JSON.stringify(student));
    const divElement = this.getShadowImageDiv();
    event.dataTransfer.setDragImage(divElement, 175, 25);
    document.body.appendChild(divElement);
  };

  handleStudentDragEnd = (student: Student) => {
    document.body.removeChild(this.shadowImageRef!);
    this.setState((pState) => ({
      ...pState,
      selectedStudents: pState.selectedStudents.filter(
        (ss) => ss.id !== student.id
      ),
    }));
  };

  checkStudentCellDraggable = (student: Student) => {
    return (
      this.props.isPlanning &&
      !this.props.newGroupStudents.some((s) => s.id === student.id)
    );
  };

  getNumberDisplayingColumns = (evidenceColumnGroupId: number): number => {
    const { displayedColumns } = this.state;
    //
    let displayColumn =  displayedColumns
    .find((dc) => dc.evidenceColumnGroupId === evidenceColumnGroupId);
    if(displayColumn != undefined) {
      return displayedColumns
        .find((dc) => dc.evidenceColumnGroupId === evidenceColumnGroupId)!
        .evidenceColumns.filter((ec) => ec.show).length;
    } else {
      return 0;
    }
  };


  render() {
    //const sortingArr = ['Composite','Maze-Adjusted' , 'ORF-Accuracy', 'ORF-WordsCorrect', 'ORF-Errors','WRF',  'NWF-WRC', 'NWF-CLS', 'PSF', 'LNF'];
    
    const {
      evidencePeriodData,
      dataRowHeight,
      groupHeaderHeight,
      headerHeight,
    } = this.state;
    
    const {
      currentDataPeriod,
      hasDiagnosticInfo,
      isLoading,
      isReadonly,
    } = this.props;

    if (IS_READY_COACH) {
      if (isLoading.getEvidenceData && !evidencePeriodData) {
        return <LoadingIndicator />;
      }
    } else {
      if (
        isLoading.getEvidenceData ||
        isLoading.getDataPeriods ||
        isLoading.addAssessmentToDataPeriod ||
        isLoading.uploadEvidenceData ||
        isLoading.uploadAssessmentData ||
        isLoading.uploadUndefinedAssessmentData
      ) {
        return <LoadingIndicator />;
      } else {
        if (
          !evidencePeriodData ||
          !evidencePeriodData.evidence_column_groups.length
        ) {
          return <EmptyDataContent />;
        }
      }
    }

    return (
      <div ref={this.refCallback}>
        {evidencePeriodData && (
          <Table
            scrollToRow={this.state.scrollToRow}
            className={`dataTable ${this.props.isCompressedViewOfDataTable || this.props.minimal
              ? "compressed-view"
              : ""
              }`}
            rowHeight={dataRowHeight}
            groupHeaderHeight={groupHeaderHeight}
            headerHeight={headerHeight}
            rowsCount={evidencePeriodData.student_rows?.length}
            width={this.state.tableWidth || 0}
            maxHeight={this.props.isDataInterview ? 600 : (this.state.tableHeight || 1000)}
            //height={screen.height}
            
            {...this.props}
          >
            <ColumnGroup
              fixed={true}
              header={this.getStudentColumnGroupHeader()}
            >
              {this.getStudentGroupIconColumn()}
              {this.getStudentCheckColumn()}
              <Column
                allowCellsRecycling
                fixed={true}
                header={
                  <StudentHeaderCell
                    onSort={this.handleSort}
                    sorting={this.state.sorting}
                  />
                }
                cell={(props) => {
                  const student =
                    evidencePeriodData.student_rows[props.rowIndex].student;
                  const teacherNames = evidencePeriodData.student_rows[
                    props.rowIndex
                  ].student.teachers!.map(
                    (teacher) => `${teacher.first_name} ${teacher.last_name}`
                  );
                  return (
                    <StudentCell
                      key={student.unique_id}
                      isReadonly={this.props.isReadonly}
                      student={student}
                      rowGrade={
                        evidencePeriodData.student_rows[props.rowIndex].grade
                      }
                      draggable={!this.state.isDistrictOwner ? this.checkStudentCellDraggable(student) : false}
                      studentTeacherNames={teacherNames}
                      interventionGroups={this.props.interventionGroups}
                      evidencePeriodData={evidencePeriodData}
                      handleGradeValueChange={this.handleGradeValueChange}
                      onDragStart={this.handleStudentDragStart(student)}
                      onDragEnd={() => this.handleStudentDragEnd(student)}
                      userInfo={this.props.userInfo}
                      showConfirmDialog = {this.props.showConfirmDialog}
                      deleteStudentEntry = {this.props.deleteStudentEntry}
                    />
                  );
                }}
                width={220}
              />
            </ColumnGroup>
            {evidencePeriodData.evidence_column_groups
            .filter((data) => {
              if(typeof(this.props.filterDisplayedGroups) == 'function') {
                if(evidencePeriodData.student_rows?.length) {
                  return this.props.filterDisplayedGroups!(evidencePeriodData.student_rows[0].data_period, data.id)
                } else {
                  return true;
                }
              } else {
                return true;
              }
            })
            .map(
              (evidenceColumnGroup: EvidenceColumnGroup) => (
                <ColumnGroup
                  key={evidenceColumnGroup.id}
                  header={
                    <AssessmentHeaderCell
                      isReadonly={this.props.isReadonly}
                      numberDisplayedColumns={this.getNumberDisplayingColumns(
                        evidenceColumnGroup.id
                      )}
                      evidenceColumnGroup={evidenceColumnGroup}
                      onDisplayedColumnChange={
                        this.handleDisplayedColumnsChange
                      }
                      checkDisplayedColumn={this.isChecked}
                      deleteEvidenceColumnsGroup={
                        this.props.deleteEvidenceColumnsGroup
                      }
                    />
                  }
                >
                  {evidenceColumnGroup.evidence_columns
                    .filter(this.filterDisplayedColumns(evidenceColumnGroup.id))
                    // .sort((a: EvidenceColumn, b: EvidenceColumn) => {
                    //     return sortingArr.indexOf(a.measurement?.column_name!) - sortingArr.indexOf(b.measurement?.column_name!)
                    // })
                    .sort((a: EvidenceColumn, b: EvidenceColumn) => (a.evidence_type < b.evidence_type) ? -1 : 1)
                    .sort((a: EvidenceColumn, b: EvidenceColumn) => (a.id < b.id) ? -1 : 1)
                    .map((evidenceColumn) => (
                      <Column
                        allowCellsRecycling
                        key={`${evidenceColumnGroup.id}-${evidenceColumn.id}`}
                        header={
                          <MeasurementHeaderCell
                            sortingDirection={this.checkSorting(
                              evidenceColumnGroup.id,
                              evidenceColumn.id
                            )}
                            evidenceColumn={evidenceColumn}
                            evidenceColumnGroup={evidenceColumnGroup}
                            onBenchmarksView={this.handleViewBenchmarks}
                            onEvidenceSort={this.handleDseSort}

                          />
                        }
                        cell={(props) => (
                          <DataStudentEntryCell
                            isReadonly={this.props.isReadonly}
                            evidenceColumn={evidenceColumn}
                            dataPeriod={currentDataPeriod}
                            evidenceColumnGroupId={evidenceColumnGroup.id}
                            student={
                              evidencePeriodData.student_rows[props.rowIndex]
                                .student
                            }
                            onCellEditFormChange={this.handleCellEditFormChange}
                            onChangesApply={this.handleChangesApply}
                            onKeyDown={this.handleKeyDown}
                            onSelectedValueChange={
                              this.handleSelectedValueChange
                            }
                            onStudentRowEditingChange={
                              this.handleStudentRowEditingChange
                            }
                            studentEditingRows={this.state.studentEditingRows}
                            evidenceColumnGroup={evidenceColumnGroup}
                          />
                        )}
                        width={this.getColumnWidth(
                          evidenceColumnGroup,
                          evidenceColumn
                        )}
                      />
                    ))}
                </ColumnGroup>
              )
            )}
            {!IS_READY_COACH &&
              !hasDiagnosticInfo &&
              this.props.isEnterSpecificSkillActive ? (
              <ColumnGroup
                header={
                  <Cell className="assessmentLabel">
                    <h4 className="assessmentLabelName">
                      CONTENT AREAS OF NEED
                    </h4>
                  </Cell>
                }
              >
                <Column
                  header={<DiagnosticSkillHeaderCell />}
                  cell={(props) => (
                    <DiagnosticSkillDataEntryCell
                      studentId={
                        evidencePeriodData.student_rows[props.rowIndex].student
                          .id!
                      }
                      showHint={props.rowIndex === 0}
                      dataPeriod={currentDataPeriod}
                    />
                  )}
                  width={320}
                />
              </ColumnGroup>
            ) : null}
          </Table>
        )}
        {!isReadonly &&
          evidencePeriodData &&
          !evidencePeriodData.student_rows?.length && <EmptyStudentsRows />}
        {this.state.selectedEvidence && (
          <ViewBenchmarksModal
            selectedEvidence={this.state.selectedEvidence}
            lowestGradeInData={gradeLevels[(this.lowestGrade && this.lowestGrade != -1) ? this.lowestGrade : 0]}
            onHide={this.handleViewBenchmarksModalHide}
          />
        )}
        {/* <UploadStudentsRosterModal />  comment as per rc-421*/}
        <BehaviorScreenerModal />
        <SkillsFocusModal />
        <TeacherInviteIndividuallyModal />
        <RemoveEvidenceConfirmModal />
      </div>
    );
  }

  getShadowImageDiv = () => {
    if (this.shadowImageRef) return this.shadowImageRef;
    const divElement = document.createElement("div");
    const text = document.createTextNode("Add 1 student");
    divElement.className = "ghostImage";
    divElement.appendChild(text);
    this.shadowImageRef = divElement;
    return this.shadowImageRef;
  };
}

const mapStateToProps = ({
  onboarding,
  cases,
  dataFilter,
  help,
  dataSort,
  auth
}: ApplicationState): PropsFromState => {
  let evidencePeriodData: EvidencePeriodData | undefined =
    _.cloneDeep(onboarding.evidencePeriodData!);
  const diagnosticInfoECG =
    !!evidencePeriodData && 
    evidencePeriodData?.evidence_column_groups?.find(
      (ecg) =>
        !ecg.assessment &&
        ecg.evidence_columns.some(
          (ec) => ec.evidence_type === EvidenceType.DiagnosticInfo
        )
    );

  if (diagnosticInfoECG && evidencePeriodData) {
    evidencePeriodData = {
      ...evidencePeriodData,
      evidence_column_groups: evidencePeriodData.evidence_column_groups.sort(
        (a, b) => (b.id === diagnosticInfoECG.id ? -1 : 0)
      ),
    };
  }
  if(onboarding.teacherClassStudent && Object.keys(onboarding.teacherClassStudent).length){
    for (let i = 0; i < evidencePeriodData!.student_rows?.length; i++) {
      onboarding.teacherClassStudent!.students.length && 
        onboarding.teacherClassStudent!.students.map((tcs:any, index:number) => {
        if(evidencePeriodData!.student_rows[i].student.id == tcs.id) {
          evidencePeriodData!.student_rows[i].student = {
            ...evidencePeriodData!.student_rows[i].student,
            teachers: tcs.teachers
          }
        }
      })
    }
    onboarding.teacherClassStudent = {};
  }


  return {
    showTopBar: help.showTopBar,
    isCompressedViewOfDataTable: help.isCompressedViewOfDataTable,
    showGroupingStudentInfo: cases.showGroupingStudentInfo,
    errors: {
      getEvidenceData: onboarding.errors.getEvidenceInDatePeriod,
      addAssessmentToDataPeriod: onboarding.errors.addAssessmentToDataPeriod,
      addStudentsToDataPeriod: onboarding.errors.addStudentsToDataPeriod,
      setValueOfDSEtoDataPeriod: onboarding.errors.setValueOfDSEtoDataPeriod,
      updateValueOfDSEtoDataPeriod:
        onboarding.errors.updateValueOfDSEtoDataPeriod,
      uploadAssessmentData: onboarding.errors.uploadAssessmentData,
      uploadUndefinedAssessmentData:
        onboarding.errors.uploadUndefinedAssessmentData,
      uploadEvidenceData: onboarding.errors.uploadEvidenceData,
      deleteEvidenceColumnsGroup: onboarding.errors.deleteEvidenceColumnsGroup,
      changeStudentDSEGrade: onboarding.errors.changeStudentDSEGrade,
      addStudentBehaviorsToDataPeriod:
        onboarding.errors.addStudentBehaviorsToDataPeriod,
      addTargetBehaviorToDataPeriod:
        onboarding.errors.addTargetBehaviorToDataPeriod,
    },
    isLoading: {
      getEvidenceData: onboarding.isLoading.getEvidenceInDatePeriod,
      getDataPeriods: onboarding.isLoading.getDataPeriods,
      addAssessmentToDataPeriod: onboarding.isLoading.addAssessmentToDataPeriod,
      addStudentsToDataPeriod: onboarding.isLoading.addStudentsToDataPeriod,
      updateStudentDetails: onboarding.isLoading.updateStudentDetails,
      setValueOfDSEtoDataPeriod: onboarding.isLoading.setValueOfDSEtoDataPeriod,
      addStudentBehaviorsToDataPeriod:
        onboarding.isLoading.addStudentBehaviorsToDataPeriod,
      updateValueOfDSEtoDataPeriod:
        onboarding.isLoading.updateValueOfDSEtoDataPeriod,
      uploadAssessmentData: onboarding.isLoading.uploadAssessmentData,
      uploadUndefinedAssessmentData:
        onboarding.isLoading.uploadUndefinedAssessmentData,
      uploadEvidenceData: onboarding.isLoading.uploadEvidenceData,
      deleteEvidenceColumnsGroup:
        onboarding.isLoading.deleteEvidenceColumnsGroup,
      changeStudentDSEGrade: onboarding.isLoading.changeStudentDSEGrade,
      addStudentSkills: onboarding.isLoading.addStudentSkills,
      deleteDataStudentEntry: onboarding.isLoading.deleteDataStudentEntry,
      addTargetBehaviorToDataPeriod:
        onboarding.isLoading.addTargetBehaviorToDataPeriod,
      addExistingBehaviorEcgToDataPeriod:
        onboarding.isLoading.addExistingBehaviorEcgToDataPeriod,
      addBehaviorScreenerEcgToDataPeriod:
        onboarding.isLoading.addBehaviorScreenerEcgToDataPeriod,
      assignTeachersToStudent: onboarding.isLoading.assignTeachersToStudent,
      updateClassesLinkedToStudent:
        onboarding.isLoading.updateClassesLinkedToStudent,
      createDiagnosticInfoColumn:
        onboarding.isLoading.createDiagnosticInfoColumn,
    },
    evidencePeriodData: evidencePeriodData,
    currentDataPeriod: onboarding.currentDataPeriod,
    teacherIdFilter: onboarding.teacherIdFilter,
    schoolIdsFilter: dataFilter.dataSchoolsFilter,
    teacherIdsFilter: dataFilter.dataTeachersFilter,
    selectedClass: onboarding.selectedClass,
    selectedStudent: onboarding.selectedStudent,
    newGroupStudents: cases.newGroupStudents,
    gradeLevelFilter: dataFilter.dataGradesFilter,
    classFilter: dataFilter.classIdFilter,
    studentsFilter: onboarding.studentsFilter,
    isEnterSpecificSkillActive: cases.isEnterSpecificSkillActive,
    hasDiagnosticInfo: !!diagnosticInfoECG,
    interventionGroups: cases.interventionGroups,
    dataSort: dataSort,
    userInfo: auth.userInfo,
    raceFilter: dataFilter.raceFilter,
    raceOtherFilter: dataFilter.raceOtherFilter,
    genderFilter: dataFilter.genderFilter,
    specialEducationFilter: dataFilter.specialEducationFilter,
    ellFilter: dataFilter.ellFilter,
    sldFilter: dataFilter.sldFilter,
    freeOrReducedLunchFilter: dataFilter.freeOrReducedLunchFilter,
    schools: onboarding.schools,
    raceInfo: onboarding.raceInfo,
    isDataFilterApplied: dataFilter.isDataFilterApplied
    // activeSort: dataSort?.activeSort,
    // allColoumnFields: dataSort?.allColoumnFields,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      getSchools: getSchools,
      getDataPeriods: getDataPeriods,
      getEvidenceInDataPeriod: getEvidenceInDataPeriod,
      deleteEvidenceColumnsGroup: deleteEvidenceColumnsGroup,
      openViewBenchmarksModal: openViewBenchmarksModal,
      hideViewBenchmarksModal: hideViewBenchmarksModal,
      hideSkillFocusesModal: hideSkillFocusesModal,
      getStudents: getStudents,
      setValueOfDSEtoDataPeriod: setValueOfDSEtoDataPeriod,
      updateValueOfDSEtoDataPeriod: updateValueOfDSEtoDataPeriod,
      addStudentsToNewGroup: addStudentsToNewGroup,
      changeStudentDSEGrade: changeStudentDSEGrade,
      clickGotIt: clickGotIt,
      openLoadKeyModal: openLoadKeyModal,
      getInterviews: getInterviews,
      getAllClasses: getAllClasses,
      getClassesLinkedToStudent: getClassesLinkedToStudent,
      showConfirmDialog: showConfirmDialog,
      deleteStudentEntry: deleteDataStudentEntry,
      getRaceInfo: getRaceInfo,
      columnSortApplied: columnSortApplied,
      dataFilterApplied: dataFilterApplied
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EvidenceTable));