import React, { Component } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from "react-bootstrap";
import { ApplicationState } from "../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { loginAttempts, loginUser, resetLoginAttempts } from "../../store/auth/actions";
import { LoginAttempt, UserCredential } from "../../store/auth/types";
import { APP_NAME, IS_READY_COACH } from "../../constants";
import { Link } from "react-router-dom";

type PropsFromState = {
  isAuthenticated: boolean;
  hasMultiLoginAttempt: boolean;
  loginAttempt?: LoginAttempt
  isLoading: boolean;
  error?: string;
};

type Props = PropsFromState & {
  loginUser: (user: UserCredential) => any;
  resetLoginAttempts: () => any;
};

type State = {
  user: UserCredential;
  hasCredentialsChanges: boolean;
  // loginTimer: number;
  // minutes: number;
  // seconds: number;
};


class LoginPage extends Component<Props, State> {
  private intervalId: NodeJS.Timeout | null = null;
  
  state: Readonly<State> = {
    user: {
      username: "",
      password: "",
    },
    hasCredentialsChanges: false,
    // loginTimer: 0,
    // minutes: 0, // Initial minutes
    // seconds: 0, // Initial seconds
  };

  // componentDidMount(): void {
  //   let minutes = localStorage.getItem('minutes');
  //   let seconds = localStorage.getItem('seconds');
  //   let hasMultiLoginAttempt = JSON.parse(localStorage.getItem('hasMultiLoginAttempt')!);
  //   if(minutes && seconds) {
  //     this.setState({
  //       minutes: +minutes!,
  //       seconds: +seconds!
  //     }, () => {
  //       hasMultiLoginAttempt ? this.startTimer() : ''
  //     });
  //   }
  // }
  
  // componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<State>): void => {
  //   let minute = localStorage.getItem('minutes');
  //   let second = localStorage.getItem('seconds');
  //   let hasMultiLoginAttempt = JSON.parse(localStorage.getItem('hasMultiLoginAttempt')!);

  //   if(prevProps.loginAttempt !== this.props.loginAttempt) { //(this.state.minutes !== +minute!)
  //     let minutes = localStorage.getItem('minutes');
  //     let seconds = localStorage.getItem('seconds');
  //     this.setState({
  //       minutes: minutes ? +minutes : (this.props.loginAttempt?.login_time_interval!),
  //       seconds: seconds ? +seconds : 0
  //     }, () => {
  //       hasMultiLoginAttempt ? this.startTimer() : ''
  //     });
  //   }
  // }

  // // componentWillUnmount() {
  // //   this.clearTimer();
  // // }

  // startTimer = () => {
  //   this.intervalId = setInterval(this.updateTimer, 1000);
  // };

  // clearTimer = () => {
  //   if (this.intervalId) {
  //     clearInterval(this.intervalId);
  //   }
  // };
  
  // updateTimer = () => {
  //   const { minutes, seconds } = this.state;
  //   let hasMultiLoginAttempt = JSON.parse(localStorage.getItem('hasMultiLoginAttempt')!);

  //   if (minutes === 0 && seconds === 0) {
  //     // Timer has reached zero
  //     this.clearTimer();
  //     localStorage.removeItem('hasMultiLoginAttempt');
  //     localStorage.removeItem('minutes');
  //     localStorage.removeItem('seconds');
  //     this.props.resetLoginAttempts()
  //     return;
  //   }

  //   if (seconds === 0) {
  //     this.setState((prevState) => ({
  //       minutes: prevState.minutes - 1,
  //       seconds: 59,
  //     }), () => hasMultiLoginAttempt ? localStorage.setItem('minutes', ''+this.state.minutes): '');
  //   } else {
  //     this.setState((prevState) => ({
  //       seconds: prevState.seconds - 1,
  //     }), () => hasMultiLoginAttempt ? localStorage.setItem('seconds', ''+this.state.seconds): '');
  //   }
  // };

  // formatTime = (time: number) => (time < 10 ? `0${time}` : `${time}`);

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      hasCredentialsChanges: true,
    }));
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    const { user } = this.state;
    this.props.loginUser(user);
    this.setState({ hasCredentialsChanges: false });
  };

  render() {
    const { isLoading, hasMultiLoginAttempt, loginAttempt } = this.props;
    const { hasCredentialsChanges, user } = this.state;
    // let minute = localStorage.getItem('minutes') ??  minutes;
    // let second = localStorage.getItem('seconds') ?? seconds;
    return (
      <div>
        <Container className="loginContainer">
          <Row className="align-items-center">
            <Col sm>
              <h1 className="hMax">
                Sign up for <strong>{APP_NAME}</strong>
              </h1>
              <br />
              <p className="text-white text-center">
                You can sign up your district for <strong>{APP_NAME}</strong>
              </p>
              <p className="text-white text-center">
                Once you have an account you'll be able to invite your staff and
                begin coaching.
              </p>
            </Col>
            <Col sm>
              <div className="welcomeInputContainer">
                <div>
                  <h2 className="m-0">
                    <strong>Welcome back!</strong>
                  </h2>
                  <h5>Login with your email and password</h5>
                </div>
                <Alert
                  data-cy="alert-error"
                  variant={"danger"}
                  show={!!this.props.error && !hasCredentialsChanges}
                >
                  Incorrect email or password.
                </Alert>
                <Alert
                  data-cy="alert-error"
                  variant={"danger"}
                  show={hasMultiLoginAttempt}
                >
                  {/* {this.formatTime(+minute)}:{this.formatTime(+second)} minutes */}
                  Maximum login attempts reached. Please do not refresh and try again after some time.
                </Alert>
                <Form id="login-form" onSubmit={this.handleSubmit}>
                  <FormGroup controlId="email">
                    <FormLabel>
                      <h4>Your Email</h4>
                    </FormLabel>
                    <FormControl
                      autoFocus
                      name={"username"}
                      type="email"
                      value={user.username}
                      onChange={this.handleChange}
                      className="fullWidthInput"
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="password" className="mb-30">
                    <FormLabel>
                      <h4>Your Password</h4>
                    </FormLabel>
                    <FormControl
                      name={"password"}
                      value={user.password}
                      onChange={this.handleChange}
                      type="password"
                      required
                    />
                  </FormGroup>
                  <div className="text-right">
                    <Link
                      data-cy="forgot-password"
                      to={"/password-reset"}
                      className="clickableLabel mr-3"
                    >
                      I forgot my password
                    </Link>
                    <button
                      className="blueBtnMd"
                      type="submit"
                      data-cy="submit"
                      disabled={hasMultiLoginAttempt}
                    >
                      Login{" "}
                      {isLoading && <Spinner animation="border" size="sm" />}
                    </button>
                    {/* <a href={"/app/registration"} className="clickableLabel">
                      {IS_READY_COACH ? "Start a Free Trial" : "Registration"}
                    </a> */}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: ApplicationState): PropsFromState => {
  let hasMultiLoginAttempt = JSON.parse(localStorage.getItem('hasMultiLoginAttempt')!)
  return {
    isAuthenticated: auth.isAuthenticated,
    isLoading: auth.isLoading.loginUser,
    error: auth.errors.loginUser,
    hasMultiLoginAttempt: hasMultiLoginAttempt ?? auth.hasMultiLoginAttempt,
    loginAttempt: auth.loginAttempt
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loginUser: loginUser,
      resetLoginAttempts: resetLoginAttempts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
