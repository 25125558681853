import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useMemo } from "react";
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { evidenceColumnDisplayTitle } from "./Helpers";
import { EvidenceColumn, EvidenceType } from "../../../../../../store/onboarding/types";

type OwnProps = {
  sortingDirection?: "asc" | "desc" | undefined;
  evidenceColumn: EvidenceColumn;
};

type Props = OwnProps;

const MeasurementLabelTooltip: React.FunctionComponent<Props> = ({
  sortingDirection,
  evidenceColumn,
}) => {
  const columnName = evidenceColumn.measurement
    ? evidenceColumn.measurement.column_name
    : undefined;

  const name = useMemo(() => evidenceColumnDisplayTitle(evidenceColumn), [
    evidenceColumn,
  ]);

  const displayedName = columnName ? columnName : name;
  const isBehaviorScreener = useMemo(() => {
    return evidenceColumn.evidence_type == EvidenceType.BehaviorScreener
  },[evidenceColumn])

  return (
    !isBehaviorScreener ? 
      <OverlayTrigger
        overlay={
          <Tooltip id="fullName" className="customInfoTooltip">
            {evidenceColumn.evidence_type === EvidenceType.DiagnosticInfo || evidenceColumn.evidence_type === EvidenceType.NeedAreas ? "" : name}
            {evidenceColumn.evidence_type === EvidenceType.Data ? <br />:""}
            <small>{evidenceColumn.evidence_type === EvidenceType.Data
                      ? `Click to View Benchmarks or Sort` :  `Click to Sort`}</small>
          </Tooltip>
        }
      >
        <span>
          {sortingDirection !== undefined ? (
            <FontAwesomeIcon
              icon={
                sortingDirection === "asc" ? faLongArrowAltUp : faLongArrowAltDown
              }
              className="mr-1"
            />
          ) : ''}
          {displayedName}
        </span>
      </OverlayTrigger>
    :
      <span title={displayedName}>
        {sortingDirection && (
          <FontAwesomeIcon
            icon={
              sortingDirection === "asc" ? faLongArrowAltUp : faLongArrowAltDown
            }
            className="mr-1"
          />
        )}
        {displayedName}
      </span>
  );
};

export default MeasurementLabelTooltip;
