import {
  AssessmentData,
  EvidenceAssessment,
  Measurement,
  Student,
  StudentInfo,
} from "../types";
import { action, createAction, createAsyncAction } from "typesafe-actions";
import {
  AddInterventionSchoolRequest,
  Attendance,
  CasesActionTypes,
  ConcernType,
  ConcernTypeValue,
  Fba,
  FbaABC,
  FbaModalTabs,
  FbaObservation,
  GasMeasurement,
  GroupRecommendation,
  Intervention,
  InterventionGroup,
  InterventionGroupAssignment,
  InterventionGroupPatchRequest,
  InterventionGroupRequest,
  InterventionRequest,
  LessonNotes,
  StudentGoal,
  StudentGoalForGroup,
  StudentGoalOptions,
} from "./types";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import {
  API_ATTENDANCE,
  API_FBA,
  API_FBA_ABC,
  API_FBA_OBSERVATION,
  API_GAS_MEASUREMENT,
  API_GROUP_RECOMMENDATIONS,
  API_INTERVENTION_GROUP_ADD_INTERVENTIONS,
  API_INTERVENTION_GROUP_DETAILS,
  API_INTERVENTION_GROUP_DISCONTINUE_INTERVENTION,
  API_INTERVENTION_GROUP_PRINT,
  API_INTERVENTION_GROUP_SET_GOAL,
  API_INTERVENTION_GROUP_SET_GOAL_FOR_SINGLE_STUDENT,
  API_INTERVENTION_GROUPS,
  API_INTERVENTION_GROUPS_BY_PARENT,
  API_INTERVENTIONS,
  API_INTERVENTIONS_SCHOOL_RESOURCES,
  API_LESSON_NOTES,
  API_STATIC_DATA_ASSESSMENT,
  API_STATIC_DATA_MEASUREMENT,
  IS_READY_COACH,
  API_LOG_INTERVENTION,
  API_PROGRESS_TOWARDS_GOAL_DELETE,
  API_INTERVENTION_GROUPS_PROGRESS_OVERVIEW,
} from "../../../constants";
import { ApplicationState } from "../../index";
import { Specific } from "../../groups/types";
import { Meeting, ProgressOverview } from "../meeting-module/types";
import { getBadgesRequest } from "../../badges/actions";
import { changeIntGroupAssigmentCollapsedSettings } from "../actions";
import { UserAssignmentRole } from "../../auth/types";
import moment from "moment";

export const addStudentsToNewGroup = (students: Array<Student>) =>
  action(CasesActionTypes.ADD_STUDENTS_TO_NEW_GROUP, {
    students,
  });

export const removeStudentFromNewGroup = (studentId: number) =>
  action(CasesActionTypes.REMOVE_STUDENT_FROM_NEW_GROUP, {
    studentId,
  });

export const removeAllStudentsFromNewGroup = () =>
  action(CasesActionTypes.REMOVE_ALL_STUDENT_FROM_NEW_GROUP);

export const resetSelectedPDFInterventionPlanFilter = () =>
  action(CasesActionTypes.RESET_SELECTED_PDF_INTERVENTION_PLAN_FILTERS);

export const changeSelectedInterventionGroup = (
  interventionGroupInfo?: InterventionGroup
) =>
  action(CasesActionTypes.CHANGE_SELECTED_INTERVENTION_GROUP, {
    interventionGroupInfo,
  });

export const changeSelectedStudentGoal = (studentGoal?: StudentGoal) =>
  action(CasesActionTypes.CHANGE_SELECTED_STUDENT_GOAL, {
    studentGoal,
  });

export const addInterventionImmediately = createAction(
  "@cases:fbas:ADD_INTERVENTION_IMMEDIATELY"
)<InterventionGroup>();

export const onEnterSpecificSkill = () =>
  action(CasesActionTypes.ON_ENTER_SPECIFIC_SKILL);

export const clickGotIt = () => action(CasesActionTypes.CLICK_GOT_IT);

export const onCreateGroupManually = () =>
  action(CasesActionTypes.CREATE_GROUP_MANUALLY);
export const cancelCreateGroupManually = () =>
  action(CasesActionTypes.CANCEL_CREATE_GROUP_MANUALLY);

export const openGroupingStudentsModal = () =>
  action(CasesActionTypes.OPEN_GROUPING_STUDENTS_MODAL);
export const hideGroupingStudentsModal = () =>
  action(CasesActionTypes.HIDE_GROUPING_STUDENTS_MODAL);

//todo remove
export const openGroupDetailsModal = () =>
  action(CasesActionTypes.OPEN_GROUP_DETAILS_MODAL);
export const hideGroupDetailsModal = () =>
  action(CasesActionTypes.HIDE_GROUP_DETAILS_MODAL);

export const changeSetGoalsModal = ( // show old behavior screen
  show: boolean,
  interventionGroup?: InterventionGroup,
  studentGoal?: StudentGoal
) =>
  action(CasesActionTypes.CHANGE_SET_GOALS_MODAL, {
    show,
    interventionGroup,
    studentGoal,
  });

export const backToFba = createAction("@cases:fbas:BACK_TO_FBA")<void>();

export const openSetGoalChooseConcernModal = (
  interventionGroup?: InterventionGroup,
  studentGoal?: StudentGoal,
  studentGoalOptions?: StudentGoalOptions,
  setMissedGoal = false
) => {
  return action(CasesActionTypes.OPEN_SET_GOAL_CHOOSE_CONCERN_MODAL, {
    interventionGroup,
    studentGoal,
    studentGoalOptions,
    setMissedGoal
  });
};
export const hideSetGoalChooseConcernModal = () =>
  action(CasesActionTypes.HIDE_SET_GOAL_CHOOSE_CONCERN_MODAL);

export const openFindInterventionModal = () =>
  action(CasesActionTypes.OPEN_FIND_INTERVENTION_MODAL);
export const hideFindInterventionModal = () =>
  action(CasesActionTypes.HIDE_FIND_INTERVENTION_MODAL);

export const openChangePasswordModal = () =>
  action(CasesActionTypes.OPEN_CHANGE_PASSWORD_MODAL);
export const hideChangePasswordModal = () =>
  action(CasesActionTypes.HIDE_CHANGE_PASSWORD_MODAL);

export const openAddInterventionModal = (isSaveInState?: boolean) =>
  action(CasesActionTypes.OPEN_ADD_INTERVENTION_MODAL, { isSaveInState });
export const hideAddInterventionModal = () =>
  action(CasesActionTypes.HIDE_ADD_INTERVENTION_MODAL);

export const openInterventionLibraryModal = (
  isSaveInState?: boolean,
  selectedIntervention?: Intervention,
  callbacks?: {
    onBack: Function;
  }
) =>
  action(CasesActionTypes.OPEN_INTERVENTION_LIBRARY_MODAL, {
    isSaveInState,
    selectedIntervention,
    callbacks,
  });
export const hideInterventionLibraryModal = () =>
  action(CasesActionTypes.HIDE_INTERVENTION_LIBRARY_MODAL);

export const openImplementStepModal = () =>
  action(CasesActionTypes.OPEN_IMPLEMENT_STEP_MODAL);
export const hideImplementStepModal = () =>
  action(CasesActionTypes.HIDE_IMPLEMENT_STEP_MODAL);

export const openEditGroupModal = () =>
  action(CasesActionTypes.OPEN_EDIT_GROUP_MODAL);
export const hideEditGroupModal = () =>
  action(CasesActionTypes.HIDE_EDIT_GROUP_MODAL);

export const hideAllModal = () => action(CasesActionTypes.HIDE_ALL_MODAL);

export const openLearnMoreModal = () =>
  action(CasesActionTypes.OPEN_LEARN_MORE_MODAL);
export const hideLearnMoreModal = () =>
  action(CasesActionTypes.HIDE_LEARN_MORE_MODAL);

export const openUpsertAssessmentModal = (assessment?: EvidenceAssessment) =>
  action(CasesActionTypes.OPEN_UPSERT_ASSESSMENT_MODAL, { assessment });
export const hideUpsertAssessmentModal = () =>
  action(CasesActionTypes.HIDE_UPSERT_ASSESSMENT_MODAL);

export const openGroupInterventionsModal = () =>
  action(CasesActionTypes.OPEN_GROUP_INTERVENTIONS_MODAL);
export const hideGroupInterventionsModal = () =>
  action(CasesActionTypes.HIDE_GROUP_INTERVENTIONS_MODAL);

export const changeAssessmentFilter = (assessmentFilter: string) =>
  action(CasesActionTypes.CHANGE_ASSESSMENT_FILTER, {
    assessmentFilter,
  });

export const hideWelcomePanel = () =>
  action(CasesActionTypes.HIDE_WELCOME_PANEL);

export const hideGroupingStudentInfoPanel = () => {
  return action(CasesActionTypes.HIDE_GROUPING_STUDENT_INFO_PANEL);
};

export const hideThreeStepProgress = () => {
  return action(CasesActionTypes.HIDE_THREE_STEP_PROGRESS);
};
export const setShowStartWorkingIntro = (value?: boolean) => {
  return action(CasesActionTypes.SET_SHOW_START_WORKING_INTRO, value);
};
export const setShowNoGroupsIntro = (value?: boolean) => {
  return action(CasesActionTypes.SET_SHOW_NO_GROUPS_INTRO, value);
};
export const setShowNoUnsavedGroupsIntro = (value?: boolean) => {
  return action(CasesActionTypes.SET_SHOW_NO_UNSAVED_GROUPS_INTRO, value);
};
export const setShowNoAbcDataCollectionIntro = (value?: boolean) => {
  return action(CasesActionTypes.SET_SHOW_NO_ABC_DATA_COLLECTION_INTRO, value);
};

export const activeTabUrl = (tabName: string) => {
  return action(CasesActionTypes.ACTIVE_TAB_URL, tabName);
};

export const getInterventionGroupsRequest = () =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_START);
export const getInterventionGroupsSuccess = (
  interventionGroups: Array<InterventionGroup>,
  archived?: boolean,
  isTabMatched?: boolean
) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_SUCCESS, {
    interventionGroups,
    archived,
    isTabMatched
  });
export const getInterventionGroupsFailure = (message: string) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_FAILURE, { error: message });

export const changeInterventionGroupLatestMeeting = (meeting: Meeting) =>
  action(CasesActionTypes.CHANGE_INTERVENTION_GROUP_LATEST_MEETING, {
    meeting,
  });

export const getNotFinishedGroupsRequest = () =>
  action(CasesActionTypes.GET_NOT_FINISHED_GROUPS_START);
export const getNotFinishedGroupsSuccess = (
  interventionGroups: Array<InterventionGroup>
) =>
  action(CasesActionTypes.GET_NOT_FINISHED_GROUPS_SUCCESS, {
    interventionGroups,
  });
export const getNotFinishedGroupsFailure = (message: string) =>
  action(CasesActionTypes.GET_NOT_FINISHED_GROUPS_FAILURE, { error: message });

export const getAssessmentStaticDataRequest = () =>
  action(CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_START);
export const getAssessmentStaticDataSuccess = (
  assessments: Array<AssessmentData>
) =>
  action(CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_SUCCESS, {
    assessments,
  });
export const getAssessmentStaticDataFailure = (message: string) =>
  action(CasesActionTypes.GET_ASSESSMENT_STATIC_DATA_FAILURE, {
    error: message,
  });

export const getMeasurementStaticDataRequest = () =>
  action(CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_START);
export const getMeasurementStaticDataSuccess = (
  measurements: Array<Measurement>
) =>
  action(CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_SUCCESS, {
    measurements,
  });
export const getMeasurementStaticDataFailure = (message: string) =>
  action(CasesActionTypes.GET_MEASUREMENT_STATIC_DATA_FAILURE, {
    error: message,
  });

export const createStudentGoalForStudentRequest = () =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_START);
export const createStudentGoalForStudentSuccess = (
  interventionGroup: InterventionGroup,
  isMultiAPICallsForSetGoal: boolean
) =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_SUCCESS, {
    interventionGroup,
    isMultiAPICallsForSetGoal
  });
export const createStudentGoalForStudentFailure = (message: string) =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_STUDENT_FAILURE, {
    error: message,
  });

export const deleteStudentGoalRequest = () =>
  action(CasesActionTypes.DELETE_STUDENT_GOAL_START);
export const deleteStudentGoalSuccess = (studentGoal: StudentGoal) =>
  action(CasesActionTypes.DELETE_STUDENT_GOAL_SUCCESS, {
    studentGoal,
  });
export const deleteStudentGoalFailure = (message: string) =>
  action(CasesActionTypes.DELETE_STUDENT_GOAL_FAILURE, {
    error: message,
  });

export const createStudentGoalForInterventionGroupRequest = () =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_START);
export const createStudentGoalForInterventionGroupSuccess = (
  interventionGroup: InterventionGroup
) =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_SUCCESS, {
    interventionGroup,
  });
export const createStudentGoalForInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.CREATE_STUDENT_GOAL_FOR_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const createGasMeasurementRequest = () =>
  action(CasesActionTypes.CREATE_GAS_MEASUREMENT_START);
export const createGasMeasurementSuccess = (gasMeasurement: GasMeasurement) =>
  action(CasesActionTypes.CREATE_GAS_MEASUREMENT_SUCCESS, { gasMeasurement });
export const createGasMeasurementFailure = (message: string) =>
  action(CasesActionTypes.CREATE_GAS_MEASUREMENT_FAILURE, {
    error: message,
  });

export const getInterventionGroupDetailsRequest = () =>
  action(CasesActionTypes.GET_INTERVENTION_GROUP_DETAILS_START);
export const getInterventionGroupDetailsSuccess = (data: any) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUP_DETAILS_SUCCESS, {
    data,
  });
export const getInterventionGroupDetailsFailure = (message: string) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUP_DETAILS_FAILURE, {
    error: message,
  });

export const getInterventionsRequest = () =>
  action(CasesActionTypes.GET_INTERVENTIONS_START);
export const getInterventionsSuccess = (interventions: any) =>
  action(CasesActionTypes.GET_INTERVENTIONS_SUCCESS, {
    interventions,
  });
export const getInterventionsFailure = (message: string) =>
  action(CasesActionTypes.GET_INTERVENTIONS_FAILURE, {
    error: message,
  });

export const createInterventionRequest = () =>
  action(CasesActionTypes.CREATE_INTERVENTION_START);
export const createInterventionSuccess = (intervention: Intervention) =>
  action(CasesActionTypes.CREATE_INTERVENTION_SUCCESS, {
    intervention,
  });
export const createInterventionFailure = (message: string) =>
  action(CasesActionTypes.CREATE_INTERVENTION_FAILURE, {
    error: message,
  });

export const createIntervention = (intervention: InterventionRequest) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createInterventionRequest());
    return axios
      .post(API_INTERVENTIONS(), intervention)
      .then((res) => {
        const intervention: Intervention = res.data;
        dispatch(createInterventionSuccess(intervention));
        dispatch(getBadgesRequest());
        return Promise.resolve(intervention);
      })
      .catch((err: AxiosError) => {
        dispatch(createInterventionFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const updateInterventionAction = createAsyncAction(
  "@cases/UPDATE_INTERVENTION_REQUEST",
  "@cases/UPDATE_INTERVENTION_SUCCESS",
  "@cases/UPDATE_INTERVENTION_FAILURE"
)<void, Intervention, string | undefined>();

export const updateIntervention = ({
  id,
  ...intervention
}: InterventionRequest) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateInterventionAction.request());
    return axios
      .put(API_INTERVENTIONS(undefined, id), intervention)
      .then((res) => {
        const intervention: Intervention = res.data;
        dispatch(updateInterventionAction.success(intervention));
        return Promise.resolve(intervention);
      })
      .catch((err: AxiosError) => {
        dispatch(updateInterventionAction.failure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addInterventionsToGroupRequest = () =>
  action(CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_START);
export const addInterventionsToGroupSuccess = (
  interventionGroup: Partial<InterventionGroup>,
  interventionGroupId: number
) =>
  action(CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_SUCCESS, {
    interventionGroup,
    interventionGroupId
  });
export const addInterventionsToGroupFailure = (message: string) =>
  action(CasesActionTypes.ADD_INTERVENTIONS_TO_GROUP_FAILURE, {
    error: message,
  });

export const addInterventionSchoolResourcesRequest = () =>
  action(CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_START);
export const addInterventionSchoolResourcesSuccess = (
  intervention: Intervention
) =>
  action(CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_SUCCESS, {
    intervention,
  });
export const addInterventionSchoolResourcesFailure = (message: string) =>
  action(CasesActionTypes.ADD_INTERVENTIONS_SCHOOL_RESOURCES_FAILURE, {
    error: message,
  });

export const updateInterventionGroupStart = () =>
  action(CasesActionTypes.UPDATE_INTERVENTION_GROUP_START);
export const updateInterventionGroupSuccess = (
  interventionGroup: Partial<InterventionGroup>
) =>
  action(CasesActionTypes.UPDATE_INTERVENTION_GROUP_SUCCESS, {
    interventionGroup,
  });
export const updateInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.UPDATE_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const logProgressTowardsGoalDeleteStart = () =>
  action(CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_START);
export const logProgressTowardsGoalDeleteSuccess = (
  interventionGroup: Partial<InterventionGroup>
) =>
  action(CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_SUCCESS, {
    interventionGroup,
  });
export const logProgressTowardsGoalDeleteFailure = (message: string) =>
  action(CasesActionTypes.PROGRESS_TOWARDS_GOAL_DELETE_FAILURE, {
    error: message,
  });

export const patchInterventionGroupStart = () =>
  action(CasesActionTypes.PATCH_INTERVENTION_GROUP_START);
export const patchInterventionGroupSuccess = (
  interventionGroup: Partial<InterventionGroup>
) =>
  action(CasesActionTypes.PATCH_INTERVENTION_GROUP_SUCCESS, {
    interventionGroup
  });
export const patchInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.PATCH_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const finishInterventionGroupStart = () =>
  action(CasesActionTypes.FINISH_INTERVENTION_GROUP_START);
export const finishInterventionGroupSuccess = (
  interventionGroup: InterventionGroup
) =>
  action(CasesActionTypes.FINISH_INTERVENTION_GROUP_SUCCESS, {
    interventionGroup,
  });
export const finishInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.FINISH_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const changeRecommendedGroup = (
  oldGroup: GroupRecommendation,
  changedGroup: GroupRecommendation
) =>
  action(CasesActionTypes.CHANGE_RECOMMENDED_GROUP, { oldGroup, changedGroup });

export const addBlankRecommendedGroup = (group: GroupRecommendation) =>
  action(CasesActionTypes.ADD_BLANK_RECOMMENDED_GROUP, group);

export const discardRecommendedGroup = (group: GroupRecommendation) =>
  action(CasesActionTypes.DISCARD_RECOMMENDED_GROUP, { group });

export const callFromStudentDetail = (isStudentDetail: boolean) => 
  action(CasesActionTypes.CALL_FROM_STUDENT_DETAIL,{isStudentDetail});

export const callFromGroupDetail = (isGroupDetail: boolean) => 
  action(CasesActionTypes.CALL_FROM_GROUP_DETAIL,{isGroupDetail});

export const getStudentFromEductor = (student: StudentInfo) => 
  action(CasesActionTypes.GET_STUDENT_FROM_EDUCATOR, { student });

export const deleteInterventionGroupStart = () =>
  action(CasesActionTypes.DELETE_INTERVENTION_GROUP_START);
export const deleteInterventionGroupSuccess = (interventionGroupId: number) =>
  action(CasesActionTypes.DELETE_INTERVENTION_GROUP_SUCCESS, {
    interventionGroupId,
  });
export const deleteInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.DELETE_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const unsaveInterventionGroupStart = () =>
  action(CasesActionTypes.UNSAVE_INTERVENTION_GROUP_START);
export const unsaveInterventionGroupSuccess = (
  interventionGroup: InterventionGroup,
  groupRecommendation: GroupRecommendation
) =>
  action(CasesActionTypes.UNSAVE_INTERVENTION_GROUP_SUCCESS, {
    interventionGroup,
    groupRecommendation,
  });
export const unsaveInterventionGroupFailure = (message: string) =>
  action(CasesActionTypes.UNSAVE_INTERVENTION_GROUP_FAILURE, {
    error: message,
  });

export const getIntProgressOverviewRequest = ( 
  archived?: boolean, 
  activeTab?: string,
  studentId?: number
) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_START, {
    archived, 
    activeTab,
    studentId
  });
export const getIntProgressOverviewSuccess = (
  interventionGroups: Array<InterventionGroup>,
  archived?: boolean,
  activeTab?: string,
  studentId?: number
) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_SUCCESS, {
    interventionGroups,
    archived,
    activeTab,
    studentId
  });

export const getIntProgressOverviewFailure = (
  message: string,
  archived?: boolean, 
  activeTab?: string,
  studentId?: number
  ) =>
  action(CasesActionTypes.GET_INTERVENTION_GROUPS_PROGRESS_OVERVIEW_FAILURE, { 
    error: message,
    archived, 
    activeTab,
    studentId
  });

export const getIntProgressOverview = (
    globleQueryStr: string, 
    archived?: boolean, 
    activeTab?: string,
    studentId?: number
  ) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(getIntProgressOverviewRequest( 
      archived, 
      activeTab,
      studentId));
    return axios
      .get(API_INTERVENTION_GROUPS_PROGRESS_OVERVIEW() + globleQueryStr)
      .then((res) => {
        const progressOverview: any = res.data;
        const interventionGroups: Array<InterventionGroup> = 
                        archived 
                          ? getState().cases.archivedInterventionGroups 
                          : getState().cases.interventionGroups;
        if(interventionGroups.length) {
          for (let i = 0; i < interventionGroups.length; i++) {
            let group: InterventionGroup = interventionGroups[i];
             interventionGroups[i] = {
              ...group,
              progress_overview: progressOverview[group.id]
            }
          }
        }
        dispatch(getIntProgressOverviewSuccess(interventionGroups, archived, activeTab, studentId));
      })
      .catch((err: AxiosError) => {
        dispatch(getIntProgressOverviewFailure(err.message, archived, activeTab, studentId));
      });
  };
} 

//let counterVar = 0;
export const getInterventionGroups = (archived?: boolean, activeTab?:string, studentId?: number) => {
  return async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    dispatch(getInterventionGroupsRequest());
    //counterVar++;
    if(activeTab == undefined) {
      activeTab = getState().cases.tabName;
    }
    const queryStr = activeTab && activeTab == 'groups/behavior'? '?is_behavior=1': '?is_behavior=0';
    let globleQueryStr = (IS_READY_COACH
      ?
       (archived
        ? "?archived=true" + (queryStr ? queryStr.replace('?', '&') : '')
        : ("" + queryStr ? queryStr: '')) + (studentId ? '&student_id=' + studentId : '')

      : "?finished=true" + (queryStr ? queryStr.replace('?', '&') : '') 
      + (studentId ? '&student_id=' + studentId : ''))
    if(!!studentId && globleQueryStr.includes('is_behavior=1&')) {
      globleQueryStr = globleQueryStr.replace('is_behavior=1&','')
    }
    if(!!studentId && globleQueryStr.includes('is_behavior=0&')) {
      globleQueryStr = globleQueryStr.replace('is_behavior=0&','')
    }
    return axios
      .get(API_INTERVENTION_GROUPS() + globleQueryStr)
      .then((res) => {
        const interventionGroups: Array<InterventionGroup> = res.data;
        let isTabMatched: boolean = false;
        // console.log('getState().cases.tabName', getState().cases.tabName)
        // console.log('activeTab', activeTab)
        // console.log('===============')
        if(getState().cases.tabName == activeTab) {
          isTabMatched = true;
        }
        getIntProgressOverview(globleQueryStr,  archived, activeTab,studentId)(
          dispatch,
          getState
        );
        dispatch(getInterventionGroupsSuccess(interventionGroups, archived, isTabMatched));
        // let readingIntervention:Array<InterventionGroup> = [];
        // let behaviorIntervention:Array<InterventionGroup> = [];
        // if(counterVar) {
        //   dispatch(getInterventionGroupsRequest());
        //   --counterVar;
        //   console.log('req',counterVar);
        //   if(activeTab == 'groups/behavior'){
        //     behaviorIntervention = interventionGroups;
        //   } 
        //   if(activeTab == 'groups/reading') {
        //     readingIntervention = interventionGroups;
        //   }
        // } 
        // if(counterVar == 0) {
        //   console.log('complete',counterVar)
        //   if(getState().cases.tabName == 'groups/behavior'){
        //     dispatch(getInterventionGroupsSuccess(behaviorIntervention, archived, isTabMatched));
        //   } 
        //   if(getState().cases.tabName == 'groups/reading') {
        //     dispatch(getInterventionGroupsSuccess(readingIntervention, archived, isTabMatched));

        //   }
        //   counterVar = 0;
        // }
      })
      .catch((err: AxiosError) => {
        dispatch(getInterventionGroupsFailure(err.message));
      });
  };
};

export const displayGroupChange = (displayGroupName: string) => 
action(CasesActionTypes.DISPLAY_GROUP_NAME, { displayGroupName });

export const getInterventionGroupsByParent = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getInterventionGroupsRequest());
    return axios
      .get(API_INTERVENTION_GROUPS_BY_PARENT)
      .then((res) => {
        const interventionGroups: Array<InterventionGroup> = res.data;
        dispatch(getInterventionGroupsSuccess(interventionGroups));
      })
      .catch((err: AxiosError) => {
        dispatch(getInterventionGroupsFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getNotFinishedGroups = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(getNotFinishedGroupsRequest());
    let queryStr = getState().cases.tabName == 'groups/behavior' ? '?is_behavior=1': '?is_behavior=0';
    return axios
      .get(API_INTERVENTION_GROUPS() + "?finished=false" + (queryStr ? queryStr.replace('?', '&') : ''))
      .then((res) => {
        const interventionGroups: Array<InterventionGroup> = res.data;
        dispatch(getNotFinishedGroupsSuccess(interventionGroups));
      })
      .catch((err: AxiosError) => {
        dispatch(getNotFinishedGroupsFailure(err.message));
      });
  };
};

export const createInterventionGroupsRequest = (
  silentGroupCreation?: boolean
) =>
  action(
    CasesActionTypes.CREATE_INTERVENTION_GROUPS_START,
    silentGroupCreation
  );
export const createInterventionGroupsSuccess = (
  interventionGroup: InterventionGroup,
  fromRecommendation?: boolean
) =>
  action(CasesActionTypes.CREATE_INTERVENTION_GROUPS_SUCCESS, {
    interventionGroup,
    fromRecommendation,
  });
export const createInterventionGroupsFailure = (message: string) =>
  action(CasesActionTypes.CREATE_INTERVENTION_GROUPS_FAILURE, {
    error: message,
  });

export const getRecommendationsRequest = () =>
  action(CasesActionTypes.GET_RECOMMENDATIONS_START);
export const getRecommendationsSuccess = (
  recommendedGroups: Array<GroupRecommendation>, isTabMatched: boolean
) =>
  action(CasesActionTypes.GET_RECOMMENDATIONS_SUCCESS, { recommendedGroups, isTabMatched });
export const getRecommendationsFailure = (message: string) =>
  action(CasesActionTypes.GET_RECOMMENDATIONS_FAILURE, { error: message });

export const createInterventionGroup = (
  interventionGroupRequest: InterventionGroupRequest,
  fromRecommendation?: boolean,
  silentGroupCreation?: boolean
) => {
  return (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
    const { userInfo } = getState().auth;
    if (!userInfo) {
      dispatch(createInterventionGroupsFailure("No user information found"));
      return Promise.reject("No user information found");
    }
    dispatch(createInterventionGroupsRequest(silentGroupCreation));
    const role = userInfo.profile.current_assignment?.role;
    const isTeacher = role === UserAssignmentRole.TEACHER;
    const coach_assignment = isTeacher
      ? interventionGroupRequest.coach_assignment
      : userInfo.profile.current_assignment?.id;
    const teacher_assignment = isTeacher
      ? userInfo.profile.current_assignment?.id
      : interventionGroupRequest.teacher_assignment;
    if (!(coach_assignment || isTeacher) || !teacher_assignment) {
      dispatch(
        createInterventionGroupsFailure("The coach/teacher has no purpose")
      );
      return Promise.reject("The coach/teacher has no purpose");
    }

    let associated_assignments = interventionGroupRequest.associated_assignments;
    if(associated_assignments?.length) {
      if(associated_assignments.some((associateAssign:any) => Object.prototype.hasOwnProperty.call(associateAssign, 'id'))) {
        associated_assignments = [...associated_assignments.map((associateAssignment:any) => associateAssignment.id)]
      }
    }

    const data: InterventionGroupRequest = {
      coach_assignment: coach_assignment,
      teacher_assignment: teacher_assignment,
      students: interventionGroupRequest.students,
      name: interventionGroupRequest.name,
      goal_type: interventionGroupRequest.goal_type,
      mtss_tier: interventionGroupRequest.mtss_tier,
      recommendation_group_key: interventionGroupRequest.recommendation_group_key,
      additional_educator_plan: interventionGroupRequest.additional_educator_plan,
      associated_assignments: associated_assignments,
      focuses: interventionGroupRequest.focuses,
      student_focuses: interventionGroupRequest.student_focuses,
      finished: interventionGroupRequest.finished,
      ...(interventionGroupRequest.group_copied_from 
          ? { group_copied_from: interventionGroupRequest.group_copied_from } : '')
    };
    return axios
      .post(API_INTERVENTION_GROUPS(), data)
      .then((res) => {
        const interventionGroup: InterventionGroup =
          interventionGroupRequest?.id && interventionGroupRequest.id < 0
            ? ({ ...res.data, oldId: interventionGroupRequest.id } as any) // it is necessary to know the previous position of the card for "pop into place"
            : res.data;
        dispatch(
          createInterventionGroupsSuccess(interventionGroup, fromRecommendation)
        );
        dispatch(getBadgesRequest());
        return Promise.resolve(interventionGroup);
      })
      .catch((err: AxiosError) => {
        dispatch(createInterventionGroupsFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const getRecommendations = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { currentDataPeriod } = getState().onboarding;
    const { tabName } = getState().cases;
    if (currentDataPeriod) {
      dispatch(getRecommendationsRequest());
      const {
        maxSizeTierTwo,
        maxSizeTierThree,
        groupByGrade,
        groupByClass,
        recommendTeachersFilter,
        recommendClassFilter,
        recommendGradesFilter,
        recommendSchoolsFilter,
        raceFilter,
        raceOtherFilter,
        genderFilter,
        specialEducationFilter,
        ellFilter,
        sldFilter,
        freeOrReducedLunchFilter,
        showDiagnosedStudentFilter
      } = getState().dataFilter;

      const { studentsFilter } = getState().onboarding;

      let queryStr = '';
      if(tabName == 'groups/behavior'){
        queryStr = 'is_behavior=1&'+(groupByGrade ? `group_by_grade=${groupByGrade ? 1 : 0}&` : "") +
        (groupByClass ? `group_by_class=${groupByClass ? 1 : 0}&` : "");
      } else {
        queryStr = 'is_behavior=0&'+(
          (maxSizeTierTwo! >= 0) ? `max_moderate=${maxSizeTierTwo}&` : "")
           + ((maxSizeTierThree ! >= 0) ? `max_intensive=${maxSizeTierThree}&` : "")
      }
      const params =
      (recommendTeachersFilter && recommendTeachersFilter.length > 0
        ? `teacher=${recommendTeachersFilter.join(",")}&`
        : "") +
      (recommendClassFilter && recommendClassFilter.length > 0
          ? `class=${recommendClassFilter.join(",")}&`
          : "") +
      (recommendGradesFilter && recommendGradesFilter.length > 0
            ? `grade=${recommendGradesFilter.join(",")}&`
            : "") +
      (recommendSchoolsFilter && recommendSchoolsFilter.length > 0
              ? `school=${recommendSchoolsFilter.join(",")}&`
              : "")
      + (studentsFilter ? `student=${studentsFilter}&` : "")
      //+ (raceFilter ? `race=${raceFilter}&` : "")
      //+ (raceOtherFilter ? `race_other=${raceOtherFilter}&` : "")
      //+ (genderFilter ? `gender=${genderFilter}&` : "")
      + (specialEducationFilter ? `special_education=${specialEducationFilter}&` : "")
      + (ellFilter ? `ell=${ellFilter}&` : "")
      + (sldFilter ? `sld=${sldFilter}&` : "")
      //+ (freeOrReducedLunchFilter ? `free_or_reduced_lunch=${freeOrReducedLunchFilter}&` : "") 
      + (showDiagnosedStudentFilter ? `diagnostics_only=${showDiagnosedStudentFilter}&` : "") 
      + queryStr;  
      const getRequest =
        API_GROUP_RECOMMENDATIONS(currentDataPeriod.id) +
        (params ? "?" + params : "");
      return axios
        .get(getRequest)
        .then((res) => {
          const recommendedGroups: Array<GroupRecommendation> = res.data.groups;
          const activeAPITab:any = res.data.is_behavior == 1 ? 'groups/behavior': 'groups/reading';
          let isTabMatched: boolean = false;
        // console.log('getState().cases.tabName', getState().cases.tabName)
        // console.log('activeAPITab', activeAPITab)
        // console.log('===============')
        if(getState().cases.tabName == activeAPITab) {
          isTabMatched = true;
        }
          dispatch(
            getRecommendationsSuccess(
              recommendedGroups.map((gr, index) => ({
                id: (recommendedGroups.length - index) * -1,
                ...gr,
              })), isTabMatched
            )
          );
        })
        .catch((err: AxiosError) => {
          dispatch(getRecommendationsFailure(err.message));
        });
    }
  };
};

export const getAssessmentStaticData = () => {
  return (dispatch: Dispatch) => {
    dispatch(getAssessmentStaticDataRequest());
    return axios
      .get(API_STATIC_DATA_ASSESSMENT)
      .then((res) => {
        const assessments: Array<AssessmentData> = res.data;
        dispatch(getAssessmentStaticDataSuccess(assessments));
      })
      .catch((err: AxiosError) => {
        dispatch(getAssessmentStaticDataFailure(err.message));
      });
  };
};

export const getMeasurementStaticData = () => {
  return (dispatch: Dispatch) => {
    dispatch(getMeasurementStaticDataRequest());
    return axios
      .get(API_STATIC_DATA_MEASUREMENT)
      .then((res) => {
        const measurements: Array<Measurement> = res.data;
        dispatch(getMeasurementStaticDataSuccess(measurements));
      })
      .catch((err: AxiosError) => {
        dispatch(getMeasurementStaticDataFailure(err.message));
      });
  };
};

export const createStudentGoalForStudent = (
  interventionGroupId: number,
  studentId: number,
  goal: StudentGoalForGroup,
  isMultiAPICallsForSetGoal = false
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createStudentGoalForStudentRequest());
    return axios
      .post(
        API_INTERVENTION_GROUP_SET_GOAL_FOR_SINGLE_STUDENT(
          interventionGroupId,
          studentId
        ),
        goal
      )
      .then((res) => {
        const data: {
          intervention_group: InterventionGroup;
          student_goals: Array<StudentGoal>;
        } = res.data;
        dispatch(createStudentGoalForStudentSuccess(data.intervention_group, isMultiAPICallsForSetGoal));

        return Promise.resolve(data);
      })
      .catch((err: AxiosError) => {
        dispatch(createStudentGoalForStudentFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const createStudentGoalForInterventionGroup = (
  interventionGroupId: number,
  goal: StudentGoalForGroup
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createStudentGoalForInterventionGroupRequest());
    return axios
      .post(API_INTERVENTION_GROUP_SET_GOAL(interventionGroupId), goal)
      .then((res) => {
        const data: {
          intervention_group: InterventionGroup;
          student_goals: Array<StudentGoal>;
        } = res.data;
        dispatch(
          createStudentGoalForInterventionGroupSuccess(data.intervention_group)
        );
        dispatch(getBadgesRequest());
        return Promise.resolve(data.intervention_group);
      })
      .catch((err: AxiosError) => {
        dispatch(createStudentGoalForInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const createGasMeasurement = (gasMeasurement: GasMeasurement) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createGasMeasurementRequest());
    return axios
      .post(API_GAS_MEASUREMENT, { ...gasMeasurement })
      .then((res) => {
        const gasMeasurement: GasMeasurement = res.data;
        dispatch(createGasMeasurementSuccess(gasMeasurement));
      })
      .catch((err: AxiosError) => {
        dispatch(createGasMeasurementFailure(err.message));
      });
  };
};

export const getInterventionGroupDetails = (interventionGroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getInterventionGroupDetailsRequest());
    return axios
      .get(API_INTERVENTION_GROUP_DETAILS(interventionGroupId))
      .then((res) => {
        const measurements: InterventionGroup = res.data;
        dispatch(getInterventionGroupDetailsSuccess(measurements));
      })
      .catch((err: AxiosError) => {
        dispatch(getInterventionGroupDetailsFailure(err.message));
      });
  };
};

export const getInterventions = (concernType?: ConcernType) => {
  return (dispatch: Dispatch) => {
    dispatch(getInterventionsRequest());
    return axios
      .get(
        API_INTERVENTIONS(
          concernType !== undefined ? ConcernTypeValue[concernType] : undefined
        )
      )
      .then((res) => {
        const interventions: any = res.data;
        dispatch(getInterventionsSuccess(interventions));
      })
      .catch((err: AxiosError) => {
        dispatch(getInterventionsFailure(err.message));
      });
  };
};

export const addInterventionsToGroup = (
  interventionGroupId: number,
  interventionIds: Array<number>
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(addInterventionsToGroupRequest());
    return axios
      .post(API_INTERVENTION_GROUP_ADD_INTERVENTIONS(interventionGroupId), {
        interventions: interventionIds,
      })
      .then((res) => {
        const interventionGroup: Partial<InterventionGroup> = res.data;
        dispatch(
          addInterventionsToGroupSuccess(interventionGroup, interventionGroupId)
        );
        dispatch(getBadgesRequest());
        return Promise.resolve(interventionGroup);
      })
      .catch((err: AxiosError) => {
        dispatch(addInterventionsToGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const addInterventionSchoolResources = (
  request: AddInterventionSchoolRequest
) => {
  return (dispatch: Dispatch) => {
    dispatch(addInterventionSchoolResourcesRequest());
    return axios
      .post(API_INTERVENTIONS_SCHOOL_RESOURCES(), request)
      .then((res) => {
        const intervention: Intervention = res.data;
        dispatch(addInterventionSchoolResourcesSuccess(intervention));
      })
      .catch((err: AxiosError) => {
        dispatch(addInterventionSchoolResourcesFailure(err.message));
      });
  };
};

export const updateInterventionGroup = (groupInfo: InterventionGroup) => {
  return (dispatch: Dispatch) => {
    dispatch(updateInterventionGroupStart());
    return axios
      .put(API_INTERVENTION_GROUPS(groupInfo.id), {
        ...groupInfo,
        associated_assignments: groupInfo.associated_assignments
          ? groupInfo.associated_assignments.map((a) => a.id)
          : [],
        coach_assignment: groupInfo.coach_assignment
          ? groupInfo.coach_assignment.id
          : undefined,
        teacher_assignment: groupInfo.teacher_assignment
          ? groupInfo.teacher_assignment.id
          : undefined,
        students: groupInfo.students ? groupInfo.students.map((s) => s.id) : [],
        focuses: groupInfo.focuses
          ? groupInfo.focuses.map((s) => s.identifier)
          : [],
        interventions: groupInfo.interventions
          ? groupInfo.interventions.map((s) => s.id)
          : [],
      })
      .then((res) => {
        const interventionGroup: Partial<InterventionGroup> = res.data;
        dispatch(updateInterventionGroupSuccess(interventionGroup));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(updateInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const openDeleteLogModal = () =>
  action(CasesActionTypes.OPEN_DELETE_INTERVENTION_LOG_MODAL);

export const closeDeleteLogModal = () =>
  action(CasesActionTypes.CLOSE_DELETE_INTERVENTION_LOG_MODAL);

export const deleteInterventionLog = (groupId: number, id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(updateInterventionGroupStart());
    return axios
      .delete(API_LOG_INTERVENTION(groupId, id))
      .then((res) => {
        const interventionGroup: any = res.data;
        const data: any = { ...interventionGroup?.intervention_group };
        dispatch(updateInterventionGroupSuccess({ ...data }));
        dispatch(closeDeleteLogModal());
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(updateInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const deleteLoggedProgress = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(logProgressTowardsGoalDeleteStart());
    return axios
      .delete(API_PROGRESS_TOWARDS_GOAL_DELETE(id))
      .then((res) => {
        const interventionGroup: any = res.data;
        const data: any = { ...interventionGroup?.intervention_group };
        dispatch(logProgressTowardsGoalDeleteSuccess({ ...data }));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(logProgressTowardsGoalDeleteFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const patchInterventionGroup = (
  groupId: number,
  request: InterventionGroupPatchRequest,
  isDeleteIntervation?: boolean
) => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { interventionGroups } = getState().cases;
    dispatch(patchInterventionGroupStart());
    return axios
      .patch(API_INTERVENTION_GROUPS(groupId), request)
      .then((res) => {
        let interventionGroup: Partial<InterventionGroup> = res.data;
        if(isDeleteIntervation) {
          if(interventionGroups.length) {
            let progressOverview = interventionGroups
                            .find((interventionGrp) => interventionGroup.id == interventionGrp.id)?.progress_overview;
            interventionGroup = {
              ...interventionGroup,
              progress_overview: progressOverview
            }
          }
          interventionGroup = {
            ...interventionGroup,
            progress_overview: {
              ...interventionGroup.progress_overview!,
              implementation_checks: [...interventionGroup.progress_overview!.implementation_checks
                .filter((implChecks) => request.intervention_plan?.plan_items?.some(
                            (planItem) => planItem.intervention_id == implChecks.intervention))]
            }
          }
          
        }
        //console.log(interventionGroup)
        dispatch(patchInterventionGroupSuccess(interventionGroup));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(patchInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const moveStudentFromGroupToGroup = (
  prevGroupId: number,
  prevGroupRequest: InterventionGroupPatchRequest,
  nextGroupId: number,
  nextGroupRequest: InterventionGroupPatchRequest
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(patchInterventionGroupStart());

    return (prevGroupId > 0
      ? axios.patch(API_INTERVENTION_GROUPS(prevGroupId), prevGroupRequest)
      : Promise.resolve<any>(null)
    )
      .then((prevRes: any) => {
        const prevGroup: Partial<InterventionGroup> | undefined = prevRes?.data;
        return axios
          .patch(API_INTERVENTION_GROUPS(nextGroupId), nextGroupRequest)
          .then( (nextRes) => {
            const nextGroup: Partial<InterventionGroup> = nextRes.data;
             dispatch(patchInterventionGroupSuccess(nextGroup));
            if (prevGroup) {
               dispatch(patchInterventionGroupSuccess(prevGroup));
                let showStudentWarnArray = 
                  prevGroup?.finished ? 
                    prevGroup.students!.filter(
                      (student:any) => prevGroup.student_goals!.find(
                        (sg:any) => (student.id == sg.student.id) && !sg.archived))
                  : prevGroup?.students;
                  
                if((!showStudentWarnArray!.length) && !prevGroup?.finished) {
                  dispatch(deleteInterventionGroup(prevGroup?.id!))
                }
            }
            return Promise.resolve();
            // .then(() => {
            //   let showStudentWarnArray = 
            //   prevGroup?.finished ? 
            //     prevGroup.students!.filter(
            //       (student:any) => prevGroup.student_goals!.find(
            //         (sg:any) => (student.id == sg.student.id) && !sg.archived))
            //   : prevGroup?.students;
            //   if((showStudentWarnArray!.length <= 1) && !prevGroup?.finished) {
            //     deleteInterventionGroup(prevGroup?.id!)
            //   }
            // });
          })
          .catch((err: AxiosError) => {
            dispatch(patchInterventionGroupFailure(err.message));
            return Promise.reject(err.message);
          });
      })
      .catch((err: AxiosError) => {
        dispatch(patchInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const unsaveInterventionGroup = (
  interventionGroup: InterventionGroup,
  groupRecommendation: GroupRecommendation
) => {
  return (dispatch: Dispatch) => {
    dispatch(unsaveInterventionGroupStart());
    return axios
      .delete(API_INTERVENTION_GROUPS(interventionGroup.id))
      .then(() => {
        dispatch(
          unsaveInterventionGroupSuccess(interventionGroup, groupRecommendation)
        );
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        dispatch(unsaveInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const finishInterventionGroup = (groupId: number) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(finishInterventionGroupStart());
    return axios
      .post(API_INTERVENTION_GROUPS(groupId) + "/finish")
      .then((res) => {
        const interventionGroup: InterventionGroup = res.data;
        dispatch(finishInterventionGroupSuccess(interventionGroup));
        dispatch(getBadgesRequest());
        dispatch(
          changeIntGroupAssigmentCollapsedSettings({
            id: interventionGroup.teacher_assignment?.id!,
            collapsed: true,
          })
        );
        return Promise.resolve(interventionGroup);
      })
      .catch((err: AxiosError) => {
        dispatch(finishInterventionGroupFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const deleteInterventionGroup = (interventionGroupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteInterventionGroupStart());
    return axios
      .delete(API_INTERVENTION_GROUPS(interventionGroupId))
      .then(() => {
        dispatch(deleteInterventionGroupSuccess(interventionGroupId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteInterventionGroupFailure(err.message));
      });
  };
};

export const getTeachersFromInterventionGroups = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { interventionGroups } = getState().cases;
    const teachers: InterventionGroupAssignment[] = [];
    interventionGroups.forEach((ig) =>
      ig.teacher_assignment &&
      !teachers.some((t) => t.id === ig.teacher_assignment!.id)
        ? teachers.push(ig.teacher_assignment!)
        : null
    );
    return teachers.sort((a, b) => {
      if (a.user.first_name > b.user.first_name) return 1;
      if (a.user.first_name < b.user.first_name) return -1;
      if (a.user.last_name > b.user.last_name) return 1;
      if (a.user.last_name < b.user.last_name) return -1;
      return 0;
    });
  };
};

export const getFocusesFromInterventionGroups = () => {
  return (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { interventionGroups } = getState().cases;
    const focuses: Specific[] = [];
    interventionGroups.forEach(
      (group) =>
        group.focuses &&
        group.focuses.forEach((focus) =>
          !focuses.some((foc) => foc.identifier === focus.identifier)
            ? focuses.push(focus)
            : null
        )
    );
    return focuses.sort((a, b) => {
      if (a.display_name > b.display_name) return 1;
      if (a.display_name < b.display_name) return -1;
      return 0;
    });
  };
};

export const setFbaModal = (show: boolean, tab: FbaModalTabs | null) =>
  action(CasesActionTypes.SET_FBA_MODAL, { show, tab });

export const createNewFbaStart = () =>
  action(CasesActionTypes.CREATE_FBA_START);
export const createNewFbaSuccess = (fba: Fba) =>
  action(CasesActionTypes.CREATE_FBA_SUCCESS, { fba });
export const createNewFbaFailure = (message: string) =>
  action(CasesActionTypes.CREATE_FBA_FAILURE, {
    error: message,
  });

export const createNewFba = (studentId: number, teacherId: number) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createNewFbaStart());
    return axios
      .post(API_FBA(), {
        teacher: teacherId,
        student: studentId,
      })
      .then((res) => {
        const fba: Fba = res.data;
        dispatch(createNewFbaSuccess(fba));
        dispatch(getBadgesRequest());
        dispatch(highlightFba(fba.id));
        setTimeout(() => dispatch(highlightFba(undefined)), 1000);
      })
      .catch((err: AxiosError) => {
        dispatch(createNewFbaFailure(err.message));
      });
  };
};

export const updateFbaStart = () => action(CasesActionTypes.UPDATE_FBA_START);
export const updateFbaSuccess = (fba: Fba) =>
  action(CasesActionTypes.UPDATE_FBA_SUCCESS, { fba });
export const updateFbaFailure = (message: string) =>
  action(CasesActionTypes.UPDATE_FBA_FAILURE, {
    error: message,
  });

export const updateFba = (fba: Fba) => {
  return (dispatch: Dispatch) => {
    dispatch(updateFbaStart());
    return axios
      .put(API_FBA(fba.id), {
        ...fba,
        teacher: fba.teacher.id,
        student: fba.student.id,
        observer: fba.observer.id,
        intervention_group: fba.intervention_group?.id,
      })
      .then((res) => {
        const fba: Fba = res.data;
        dispatch(updateFbaSuccess(fba));
        return Promise.resolve(fba);
      })
      .catch((err: AxiosError) => {
        dispatch(updateFbaFailure(err.message));
        return Promise.reject(fba);
      });
  };
};

export const getFbasStart = () => action(CasesActionTypes.GET_FBAS_START);
export const getFbasFbaSuccess = (fbas: Array<Fba>) =>
  action(CasesActionTypes.GET_FBAS_SUCCESS, { fbas });
export const getFbasFailure = (message: string) =>
  action(CasesActionTypes.GET_FBAS_FAILURE, {
    error: message,
  });

export const getFbas = () => {
  return (dispatch: Dispatch) => {
    dispatch(getFbasStart());
    return axios
      .get(API_FBA())
      .then((res) => {
        const fbas: Array<Fba> = res.data;
        dispatch(getFbasFbaSuccess(fbas));
      })
      .catch((err: AxiosError) => {
        dispatch(getFbasFailure(err.message));
      });
  };
};

export const highlightFba = createAction("@cases:fbas:HIGHLIGHT_FBA")<
  number | undefined
>();

export const deleteFbaStart = () => action(CasesActionTypes.DELETE_FBA_START);
export const deleteFbaSuccess = (fbaId: number) =>
  action(CasesActionTypes.DELETE_FBA_SUCCESS, { fbaId });
export const deleteFbaFailure = (message: string) =>
  action(CasesActionTypes.DELETE_FBA_FAILURE, {
    error: message,
  });

export const deleteFba = (fbaId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteFbaStart());
    return axios
      .delete(API_FBA(fbaId))
      .then(() => {
        dispatch(deleteFbaSuccess(fbaId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteFbaFailure(err.message));
      });
  };
};

export const createFbaABCStart = () =>
  action(CasesActionTypes.CREATE_FBA_ABC_START);
export const createFbaABCSuccess = (
  fbaAbc: FbaABC,
  observationId: number,
  isSingleAbc?: boolean
) =>
  action(CasesActionTypes.CREATE_FBA_ABC_SUCCESS, {
    fbaAbc,
    observationId,
    isSingleAbc,
  });
export const createFbaABCFailure = (message: string) =>
  action(CasesActionTypes.CREATE_FBA_ABC_FAILURE, {
    error: message,
  });

export const createFbaABC = ({
  antecedents,
  behaviors,
  consequence,
  customAntecedents,
  customBehavior,
  customConsequence,
  behaviorDescriptions,
  observation,
  isSingleAbc,
}: {
  antecedents: number[];
  behaviors: number[];
  consequence: number;
  customAntecedents: (string | undefined)[];
  customBehavior: string | undefined;
  customConsequence: string | undefined;
  behaviorDescriptions:
    | {
        [behaviorId: number]: string | undefined;
      }
    | undefined;
  observation: number;
  isSingleAbc?: boolean;
}) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createFbaABCStart());
    return axios
      .post(API_FBA_ABC, {
        antecedents: antecedents,
        behaviors: behaviors,
        consequence: consequence,
        other_sequence: customConsequence,
        other_antecedents: customAntecedents,
        other_behavior: customBehavior,
        behavior_descriptions: behaviorDescriptions,
        observation,
      })
      .then((res) => {
        const fbaAbc: FbaABC = res.data;
        dispatch(createFbaABCSuccess(fbaAbc, observation, isSingleAbc));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createFbaABCFailure(err.message));
      });
  };
};

export const getFbaObservationAction = createAsyncAction(
  "@cases:fba/GET_FBA_OBSERVATION_REQUEST",
  "@cases:fba/GET_FBA_OBSERVATION_SUCCESS",
  "@cases:fba/GET_FBA_OBSERVATION_FAILURE"
)<void, FbaObservation, string>();

export const getFbaObservation = (observationId: number) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(getFbaObservationAction.request());
    return axios
      .get(API_FBA_OBSERVATION(observationId))
      .then((res) => {
        const fbaObservation: FbaObservation = res.data;
        dispatch(getFbaObservationAction.success(fbaObservation));
        dispatch(getBadgesRequest());
        return Promise.resolve(fbaObservation);
      })
      .catch((err: AxiosError) => {
        dispatch(getFbaObservationAction.failure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const createFbaObservationStart = () =>
  action(CasesActionTypes.CREATE_FBA_OBSERVATION_START);
export const createFbaObservationSuccess = (fbaObservation: FbaObservation) =>
  action(CasesActionTypes.CREATE_FBA_OBSERVATION_SUCCESS, fbaObservation);
export const createFbaObservationFailure = (message: string) =>
  action(CasesActionTypes.CREATE_FBA_OBSERVATION_FAILURE, {
    error: message,
  });

export const createFbaObservation = (fbaId: number, order: 0 | 1 = 0) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createFbaObservationStart());
    return axios
      .post(API_FBA_OBSERVATION(), {
        order: order,
        fba: fbaId,
      })
      .then((res) => {
        const fbaObservation: FbaObservation = res.data;
        dispatch(createFbaObservationSuccess(fbaObservation));
        dispatch(getBadgesRequest());
        return Promise.resolve(fbaObservation);
      })
      .catch((err: AxiosError) => {
        dispatch(createFbaObservationFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const changeSelectedObservation = (fbaObs?: FbaObservation) =>
  action(CasesActionTypes.CHANGE_SELECTED_OBSERVATION, { fbaObs });

export const changeSelectedFba = (fba?: Fba) =>
  action(CasesActionTypes.CHANGE_SELECTED_FBA, { fba });

export const completeFbaObservationStart = () =>
  action(CasesActionTypes.COMPLETE_FBA_OBSERVATION_START);
export const completeFbaObservationSuccess = (fbaObservation: FbaObservation) =>
  action(CasesActionTypes.COMPLETE_FBA_OBSERVATION_SUCCESS, fbaObservation);
export const completeFbaObservationFailure = (message: string) =>
  action(CasesActionTypes.COMPLETE_FBA_OBSERVATION_FAILURE, {
    error: message,
  });

export const completeFbaObservation = (
  fbaId: number,
  observationId: number,
  completed_at: string
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(completeFbaObservationStart());
    return axios
      .put(API_FBA_OBSERVATION(observationId), {
        order: 1,
        fba: fbaId,
        completed_at: completed_at,
      })
      .then((res) => {
        const fbaObservation: FbaObservation = res.data;
        dispatch(completeFbaObservationSuccess(fbaObservation));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(completeFbaObservationFailure(err.message));
      });
  };
};

export const openInterpretFbaModal = () =>
  action(CasesActionTypes.OPEN_INTERPRET_FBA_MODAL);
export const hideInterpretFbaModal = () =>
  action(CasesActionTypes.HIDE_INTERPRET_FBA_MODAL);

export const openCreateNewInterventionModal = (
  selectedIntervention?: Intervention,
  interventionGroupId?: number,
  options?: {
    onBack?: Function;
    editing?: boolean;
  }
) =>
  action(CasesActionTypes.OPEN_CREATE_NEW_INTERVENTION_MODAL, {
    selectedIntervention,
    interventionGroupId,
    options,
  });
export const hideCreateNewInterventionModal = () =>
  action(CasesActionTypes.HIDE_CREATE_NEW_INTERVENTION_MODAL);

export const updateProgressOverview = (interventionGroup: InterventionGroup) =>
  action(CasesActionTypes.UPDATE_PROGRESS_OVERVIEW, {
    interventionGroup,
  });

export const changeSelectedIntervention = (
  selectedIntervention?: Intervention
) =>
  action(CasesActionTypes.CHANGE_SELECTED_INTERVENTION, {
    selectedIntervention,
  });

export const discontinueIntGroupIntRequest = () =>
  action(CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_START);
export const discontinueIntGroupIntSuccess = (
  interventionGroupId: number,
  interventionGroup?: InterventionGroup
) =>
  action(CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_SUCCESS, {
    interventionGroupId,
    interventionGroup,
  });
export const discontinueIntGroupIntFailure = (message: string) =>
  action(CasesActionTypes.DISCONTINUE_INTERVENTION_GROUP_INTERVENTION_FAILURE, {
    error: message,
  });

export const discontinueIntGroupInt = (
  interventionGroupId: number,
  // interventionId: number,
  students: Array<number>,
  reason: string | null,
  allStudentSelected?: boolean | null,
  studentGoalId?: number,
) => {
  return (dispatch: Dispatch) => {
    dispatch(discontinueIntGroupIntRequest());
    return axios
      .post(
        API_INTERVENTION_GROUP_DISCONTINUE_INTERVENTION(interventionGroupId),
        {
          students: students,
          reason: reason,
          all_students_selected: allStudentSelected,
          goal_id: studentGoalId
        }
      )
      .then((res) => {
        const interventionGroup: InterventionGroup | undefined =
          res.data?.intervention_group;
        dispatch(
          discontinueIntGroupIntSuccess(interventionGroupId, interventionGroup)
        );
      })
      .catch((err: AxiosError) => {
        dispatch(discontinueIntGroupIntFailure(err.message));
        return Promise.reject(err.message);
      });
  };
};

export const sendSharedWithData = (shareDataWith?: boolean) =>
action(CasesActionTypes.SEND_SHARE_DATA_WITH,{shareDataWith});

export const sendNotSharedWithData = (notShareDataWith?: boolean) =>
action(CasesActionTypes.SEND_NOT_SHARE_DATA_WITH,{notShareDataWith});

export const getLessonNotesAction = createAsyncAction(
  "@cases/GET_LESSON_NOTES_REQUEST",
  "@cases/GET_LESSON_NOTES_SUCCESS",
  "@cases/GET_LESSON_NOTES_FAILURE"
)<void, LessonNotes[], string>();

export const getLessonNotes = (groupId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getLessonNotesAction.request());
    return axios
      .get(API_LESSON_NOTES(groupId))
      .then((res) => {
        const lessonNotes: LessonNotes[] = res.data;
        dispatch(getLessonNotesAction.success(lessonNotes));
      })
      .catch((err: AxiosError) => {
        dispatch(getLessonNotesAction.failure(err.message));
      });
  };
};

export const createLessonNotesAction = createAsyncAction(
  "@cases/CREATE_LESSON_NOTES_REQUEST",
  "@cases/CREATE_LESSON_NOTES_SUCCESS",
  "@cases/CREATE_LESSON_NOTES_FAILURE"
)<void, LessonNotes, string>();

export const createLessonNotes = (
  groupId: number,
  body: Pick<LessonNotes, "date" | "notes" | "interventions" | "is_general">
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createLessonNotesAction.request());
    return axios
      .post(API_LESSON_NOTES(groupId), body)
      .then((res) => {
        const lessonNotes: LessonNotes = res.data;
        dispatch(createLessonNotesAction.success(lessonNotes));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createLessonNotesAction.failure(err.message));
      });
  };
};

export const updateLessonNotesAction = createAsyncAction(
  "@cases/UPDATE_LESSON_NOTES_REQUEST",
  "@cases/UPDATE_LESSON_NOTES_SUCCESS",
  "@cases/UPDATE_LESSON_NOTES_FAILURE"
)<void, LessonNotes, string>();

export const updateLessonNotes = (
  groupId: number,
  {
    id,
    ...body
  }: Pick<LessonNotes, "id" | "date" | "notes" | "interventions" | "is_general">
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateLessonNotesAction.request());
    return axios
      .put(API_LESSON_NOTES(groupId, id), body)
      .then((res) => {
        const lessonNotes: LessonNotes = res.data;
        dispatch(updateLessonNotesAction.success(lessonNotes));
      })
      .catch((err: AxiosError) => {
        dispatch(updateLessonNotesAction.failure(err.message));
      });
  };
};

export const deleteLessonNotesAction = createAsyncAction(
  "@cases/DELETE_LESSON_NOTES_REQUEST",
  "@cases/DELETE_LESSON_NOTES_SUCCESS",
  "@cases/DELETE_LESSON_NOTES_FAILURE"
)<void, any, string>();

export const deleteLessonNotes = (groupId: number, lessonNotesId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteLessonNotesAction.request());
    return axios
      .delete(API_LESSON_NOTES(groupId, lessonNotesId))
      .then((res) => {
        const lessonNotes: LessonNotes = res.data;
        dispatch(deleteLessonNotesAction.success({lessonNotesId, lessonNotes}));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteLessonNotesAction.failure(err.message));
      });
  };
};

export const showFbaObservationModal = createAction(
  "@cases:fba/SHOW_FBA_OBSERVATION_MODAL"
)<{ fba: Fba; isSingleAbc?: boolean; observation?: FbaObservation }>();
export const hideFbaObservationModal = createAction(
  "@cases:fba/HIDE_FBA_OBSERVATION_MODAL"
)<void>();

export const showAnalyzeFbaResultsModal = createAction(
  "@cases:fba/SHOW_ANALYZE_FBA_RESULTS_MODAL"
)<Fba>();
export const hideAnalyzeFbaResultsModal = createAction(
  "@cases:fba/HIDE_ANALYZE_FBA_RESULTS_MODAL"
)<void>();

export const getAttendancesAction = createAsyncAction(
  "@cases/GET_ATTENDANCES_REQUEST",
  "@cases/GET_ATTENDANCES_SUCCESS",
  "@cases/GET_ATTENDANCES_FAILURE"
)<void, Attendance[], string>();

export const getAttendances = (groupId: number, studentId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getAttendancesAction.request());
    return axios
      .get(API_ATTENDANCE(groupId, 0, studentId))
      .then((res) => {
        const attendances: Attendance[] = res.data;
        dispatch(getAttendancesAction.success(attendances));
      })
      .catch((err: AxiosError) => {
        dispatch(getAttendancesAction.failure(err.message));
      });
  };
};

export const createAttendanceAction = createAsyncAction(
  "@cases/CREATE_ATTENDANCE_REQUEST",
  "@cases/CREATE_ATTENDANCE_SUCCESS",
  "@cases/CREATE_ATTENDANCE_FAILURE"
)<void, Attendance, string>();

export const createAttendance = (
  groupId: number,
  body: Pick<
    Attendance,
    "date" | "interventions" | "absent_students" | "present_students"
  >
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(createAttendanceAction.request());
    return axios
      .post(API_ATTENDANCE(groupId), body)
      .then((res) => {
        const attendance: Attendance = res.data;
        dispatch(createAttendanceAction.success(attendance));
        dispatch(getBadgesRequest());
      })
      .catch((err: AxiosError) => {
        dispatch(createAttendanceAction.failure(err.message));
      });
  };
};

export const updateAttendanceAction = createAsyncAction(
  "@cases/UPDATE_ATTENDANCE_REQUEST",
  "@cases/UPDATE_ATTENDANCE_SUCCESS",
  "@cases/UPDATE_ATTENDANCE_FAILURE"
)<void, Attendance, string>();

export const updateAttendance = (
  groupId: number,
  {
    id,
    ...body
  }: Pick<
    Attendance,
    "id" | "date" | "interventions" | "absent_students" | "present_students"
  >
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateAttendanceAction.request());
    return axios
      .put(API_ATTENDANCE(groupId, id), body)
      .then((res) => {
        const attendance: Attendance = res.data;
        dispatch(updateAttendanceAction.success(attendance));
      })
      .catch((err: AxiosError) => {
        dispatch(updateAttendanceAction.failure(err.message));
      });
  };
};

export const deleteAttendanceAction = createAsyncAction(
  "@cases/DELETE_ATTENDANCE_REQUEST",
  "@cases/DELETE_ATTENDANCE_SUCCESS",
  "@cases/DELETE_ATTENDANCE_FAILURE"
)<void, number, string>();

export const deleteAttendance = (groupId: number, attendancesId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteAttendanceAction.request());
    return axios
      .delete(API_ATTENDANCE(groupId, attendancesId))
      .then(() => {
        dispatch(deleteAttendanceAction.success(attendancesId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteAttendanceAction.failure(err.message));
      });
  };
};

export const selectGroupCardFocusTab = createAction(
  "@cases/SELECT_GROUP_CARD_FOCUS_TAB"
)<"matrix" | "common">();

export const changeInterventionPlanFilters = createAction(
  "@cases/CHANGE_INTERVENTION_PLAN_FILTERS"
)<any>();

export const showInterventionPlanModal = createAction(
  "@cases/SHOW_INTERVENTION_PLAN_MODAL"
)<InterventionGroup | void>();
export const hideInterventionPlanModal = createAction(
  "@cases/HIDE_INTERVENTION_PLAN_MODAL"
)<void>();

export const clickedFromAlert = createAction(
  "@cases/CLICKED_FROM_ALERT"
)<{isClickedFromAlert: boolean}>();

export const showRecommendations = createAction(
  "@cases:groups:SHOW_RECOMMENDATIONS"
)<boolean>();

export const showDiagnosedStudents = createAction(
  "@cases:groups:SHOW_DIAGNOSED_STUDENTS"
)<boolean>();

export const changeActiveGroupsView = createAction(
  "@cases:groups:CHANGE_ACTIVE_GROUPS_VIEW"
)<"list" | "grid">();

export const printInterventionGroupReportAction = createAsyncAction(
  "@cases/PRINT_INTERVENTION_GROUP_REPORT_REQUEST",
  "@cases/PRINT_INTERVENTION_GROUP_REPORT_SUCCESS",
  "@cases/PRINT_INTERVENTION_GROUP_REPORT_FAILURE"
)<void, void, string>();

export const printInterventionGroupReport = (
  intGroupId: number,
  queryObj: any
) => {
  return (dispatch: Dispatch) => {
    dispatch(printInterventionGroupReportAction.request());
    const url = API_INTERVENTION_GROUP_PRINT(intGroupId, queryObj);
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download =
          "Intervention Group " + moment().format("MM-DD-YYYY") + ".pdf";
        document.body.append(link);
        link.click();
        link.remove();

        setTimeout(() => URL.revokeObjectURL(link.href), 7000);

        dispatch(printInterventionGroupReportAction.success());
      })
      .catch((err: AxiosError) => {
        dispatch(printInterventionGroupReportAction.failure(err.message));
      });
  };
};

export const resetGoalChooseConcernModel = (setGoalChooseConcernModel: boolean) =>
  action(CasesActionTypes.RESET_GOAL_CHOOSE_CONCERN_MODAL, {setGoalChooseConcernModel});
  
export const sendLogProgressDataToGraph = (loggedProgressData: any) =>
  action(CasesActionTypes.SEND_LOG_PROGRESS_DATA_TO_GRAPH, {
    loggedProgressData,
  });

export const listingOrderSwitch = (listOrderSwitch: any) => 
  action(CasesActionTypes.LIST_ORDER_SWITCH, {
    listOrderSwitch,
  });

export const openGroupRecommendationFromData = createAction(
  "@cases/OPEN_GROUP_RECOMMENDATION_FROM_DATA"
)();
export const hideGroupRecommendationFromData = createAction(
  "@cases/HIDE_GROUP_RECOMMENDATION_FROM_DATA"
)();
