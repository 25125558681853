import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Form, Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import GroupInfoModalTitle from "../common/GroupInfoModalTitle";
import InterventionsBar from "../common/InterventionsBar";
import { ApplicationState } from "../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  getProgressOverviewData,
  hideImplementationCheckModal,
  logIntervention,
} from "../../../../../../store/onboarding/meeting-module/actions";
import { connect, useSelector } from "react-redux";
import {
  ChecklistItem,
  Intervention,
  InterventionGroup,
  InterventionPlan,
  InterventionPlanItem,
  InterventionPlanStates,
} from "../../../../../../store/onboarding/cases/types";
import ModalCloseButton from "../../../../../common/onboarding/third-step/group-students/common/ModalCloseButton";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import CustomDateBoxInput from "../common/CustomDateBoxInput";
import InterventionFidelityCollapse from "../InterventionFidelityCollapse";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import jsPDF from "jspdf";
// @ts-ignore
import html2pdf from "html2pdf.js";
import moment from "moment";
import usePDFGenerate from "../../../../../common/pdf-generate/usePDFGenerate";
import { checkedInterventionPlan } from "../../../../../utils";

type StateProps = {
  showModal: boolean;
  showResourcesModal: boolean;
  interventionGroup?: InterventionGroup;
  isLoading: {
    getProgressOverviewData: boolean;
    logIntervention: boolean;
  };
  errors: {
    getProgressOverviewData?: string;
    logIntervention?: string;
  };
};
type DispatchProps = {
  hideImplementationCheckModal: () => any;
  getProgressOverviewData: (interventionGroupId: number) => any;
  logIntervention: (
    interventionGroupId: number,
    interventionId: number,
    observedDate: Date,
    selectedChecklistItemIds: number[],
    selectedNotPlannedItemIds: number[],
    notPlannedReason?: string,
    notes?: string
  ) => any;
};

type OwnProps = {};

type Props = OwnProps & StateProps & DispatchProps;

// const DEFAULT_DISPLAYED_ITEMS_NUM = 5;

const ImplementationCheckModal: FunctionComponent<Props> = ({
  showModal,
  showResourcesModal,
  interventionGroup,
  isLoading,
  errors,
  getProgressOverviewData,
  logIntervention,
  hideImplementationCheckModal,
}) => {

  const contentRef = useRef<HTMLDivElement | null>(null);
  const { reportGenerate } = usePDFGenerate();
  // const [displayedItemsNum, setDisplayedItemsNum] = useState(
  //   DEFAULT_DISPLAYED_ITEMS_NUM
  // );
  const [observedDate, setObservedDate] = useState<Date | undefined>(undefined);
  const [selectedChecklistItems, setSelectedChecklistItems] = useState<
    Set<number>
  >(new Set());
  const [selectedNotPlannedItems, setSelectedNotPlannedItems] = useState<
    Set<number>
  >(new Set());

  const datePickerRef = useRef<ReactDatePicker>(null);

  const [intervention, setIntervention] = useState<Intervention | undefined>(
    undefined
  );

  const [isImplStep, setImplStep] = useState(false);
  const [isImplCheckDisable, setImplCheckDisable] = useState(false);
  const [notPlannedReason, setNotPlannedReason] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setNotes('')
    setNotPlannedReason('')
  },[showModal])

  useEffect(() => {
    if(!Array.from(selectedNotPlannedItems).length) {
      setNotPlannedReason('');
    }
  },[selectedNotPlannedItems])

  const callbacks = useSelector(
    (s: ApplicationState) =>
      s.meetingModuleReducer.modalsState.implementationCheckModalCallbacks
  );
  const uniqueByKey = (array: InterventionPlanItem[], key: any = 'id') => {
    return Array.from(new Map(array.map((item: any) => [item[key], item])).values());
  };
  
  const mergePlanItems = (interventions: Intervention[], interventionPlan: InterventionPlan) => {
    const interventionPlanItems = checkedInterventionPlan(interventions, interventionPlan);
    return {
      ...interventionPlan,
      plan_items: uniqueByKey([...interventionPlan.plan_items!, ...interventionPlanItems.plan_items!], 'intervention_id')
    };
  };
  
  const modifiedInterventionGroup = useMemo(() => {
    if (!interventionGroup) return undefined;
  
    const hasArchivedInterventions = interventionGroup.archived_interventions.some(archivedInt =>
      interventionGroup.interventions.some(int => int.id === archivedInt.id)
    );
  
    if (hasArchivedInterventions) {
      return {
        ...interventionGroup,
        intervention_plan: mergePlanItems(interventionGroup.interventions!, interventionGroup.intervention_plan!)
      };
    }
  
    return interventionGroup;
  }, [interventionGroup]);

  if (!interventionGroup) return null;


  const onImpCheckAlert = (implCheck: boolean) => {
    setImplCheckDisable(implCheck)
  }

  const onModalHide = () => {
    hideImplementationCheckModal();
    onImpCheckAlert(false);
    callbacks?.onClose && callbacks.onClose();
  };

  const onModalShow = () => {
    getProgressOverviewData(interventionGroup.id!);
    //auto-select Intervention if a group has only one
    const intervention =
      interventionGroup?.interventions?.length === 1
        ? interventionGroup.interventions[0]
        : undefined;
    setIntervention(intervention);
    setImplStep(!!intervention);
    setObservedDate(undefined);
    // setDisplayedItemsNum(DEFAULT_DISPLAYED_ITEMS_NUM);
    setSelectedChecklistItems(new Set());
    setSelectedNotPlannedItems(new Set());
  };

  const beginLogRequest = () => {
    if (!observedDate) {
      datePickerRef.current?.setOpen(true);
      return;
    }
    if(Array.from(selectedNotPlannedItems).length && (notPlannedReason == '')) {
      toastr.error("Alert!!","Must provide a reason for marking steps Not Planned.");
      return;
    }

    if (interventionGroup.id && intervention) {
      logIntervention(
        interventionGroup.id,
        intervention.id,
        observedDate,
        Array.from(selectedChecklistItems),
        Array.from(selectedNotPlannedItems),
        notPlannedReason,
        notes
      ).then(
        () => onModalHide(),
        (err: string) => toastr.error("Failed to log the intervention", err)
      );
    }
  };

  const handleChecked = (item: ChecklistItem, checked: boolean) => {
    if (checked) {
      const items = new Set(selectedChecklistItems);
      items.add(item.id!);
      setSelectedChecklistItems(items);
      if(selectedNotPlannedItems.has(item.id!)) {
        const items = new Set(selectedNotPlannedItems);
        items.delete(item.id!);
        setSelectedNotPlannedItems(items);
      }
    } else if (selectedChecklistItems.has(item.id!)) {
      const items = new Set(selectedChecklistItems);
      items.delete(item.id!);
      setSelectedChecklistItems(items);
    }
  };
  
  const handleNotPlannedChecked = (item: ChecklistItem, checked: boolean) => {
    if (checked) {
      const items = new Set(selectedNotPlannedItems);
      items.add(item.id!);
      setSelectedNotPlannedItems(items);
      if(selectedChecklistItems.has(item.id!)) {
        const items = new Set(selectedChecklistItems);
        items.delete(item.id!);
        setSelectedChecklistItems(items);
      }
    } else if (selectedNotPlannedItems.has(item.id!)) {
      const items = new Set(selectedNotPlannedItems);
      items.delete(item.id!);
      setSelectedNotPlannedItems(items);
    }
  };

  const filterInterventions = (interventions: Array<Intervention>) => {
    if (modifiedInterventionGroup && interventions) {
      const deactivatedIds = modifiedInterventionGroup?.intervention_plan?.plan_items?.filter(et => et.state === InterventionPlanStates.DISCONTINUED).map(et => et.intervention_id.toString());
      const newInterventionList = interventions.filter(etr => !deactivatedIds?.includes(etr.id.toString()))
      return newInterventionList.filter((intervention) => {
        if(modifiedInterventionGroup.intervention_plan?.plan_items?.length) {
          return modifiedInterventionGroup.intervention_plan?.plan_items.some((interventionPlan) => (interventionPlan.intervention_id == intervention.id) && (interventionPlan.state == InterventionPlanStates.ACTIVE))
        } else {
          return intervention;
        }
      });
    } else 
    return [];
  }

  

  const handleReportGenerate = () => {
    const fileName:string = `${intervention?.name.replace(" ", "_")}${observedDate ? moment(observedDate).format('MM-DD-YYYY') : ''}: Implementation Check.pdf`;
    return reportGenerate(contentRef, fileName);
  };

  return (
    <Modal
      show={showModal}
      onHide={onModalHide}
      onShow={onModalShow}
      animation={false}
      size="lg"
      backdropClassName={`customDarkModalBackdrop in ${
        showResourcesModal ? "d-none" : ""
      }`}
      dialogClassName={showResourcesModal ? "d-none" : ""}
    >
      <Modal.Header className="purpleModalHeader centerModalHeader">
        <GroupInfoModalTitle />
        <Modal.Title>Implementation Check</Modal.Title>
        <ModalCloseButton onClose={onModalHide} />
      </Modal.Header>
      <Modal.Body>
        {intervention && isImplStep ? (
          <>
            <h3 className="mb-3">
              <span className="font-weight-bold">Group: </span>
              {interventionGroup.name}
            </h3>
            <InterventionsBar>
              <h2 className="font-weight-bold">{intervention.name}</h2>
            </InterventionsBar>

            <InterventionFidelityCollapse onImpCheckDisabled={onImpCheckAlert}/>

            <h3 className="font-weight-bold purpleText">Date implemented:</h3>
            <div className="d-flex justify-content-between my-2">
              <DatePicker
                ref={datePickerRef}
                customInput={<CustomDateBoxInput />}
                selected={observedDate}
                onChange={(date: Date) => setObservedDate(date)}
                maxDate={new Date()}
                disabled={isImplCheckDisable}
              />
              {!!intervention?.checklist_items.length && (
                <button
                  className="whiteBtnSm"
                  onClick={handleReportGenerate}
                >
                  Generate Checklist PDF
                </button>
              )}
            </div> 
            <Alert
            variant={"info"}
            >
              <p className="font-weight-bold text-left mb-1">Indicators: </p>
              <div className="d-flex mb-2">
                <Form.Check
                  label
                  custom
                  className="observedItemCheckbox"
                  type="checkbox"
                  checked={true}
                /> P : Step was implemented as planned.
              </div>
              <div className="d-flex">
                <Form.Check
                  label
                  custom
                  className="notPlannedItemCheckbox"
                  type="checkbox"
                  checked={true}
                /> NP : A decision was made not to include the step in the intervention (i.e., not planned).
              </div>
            </Alert>
            {!!intervention?.checklist_items.length && (
              <div className="observedItemsContainer" ref={contentRef}>
                {intervention.checklist_items
                  .sort((a, b) => a.order - b.order)
                  // .slice(0, displayedItemsNum)
                  .map((item, index) => (
                    <>
                      {item.header && (
                        <>
                        <div
                          className={"checklistItemHeader"}
                          key={`header_${item.id}`}
                        >
                          <span>{item.header}</span>
                        </div>
                        <div className="d-flex mb-1 mx-3 stepHeader" style={{paddingLeft:'15px'}}>
                          <div className="col-auto font-weight-bold pl-0">P</div>
                          <div className="col-auto font-weight-bold pl-1">NP</div>
                        </div> 
                        </>
                      )}

                      <div
                        className={`observedInterventionItemContainer ${item.header ? 'narrowHeaderPadding': ''}`}
                        key={item.id}
                      >
                        <OverlayTrigger
                          overlay={
                            <Popover id='Planned'>
                              <Popover.Content>Planned</Popover.Content>
                            </Popover>
                          }
                        >    
                          <Form.Check
                            id={(item.id || index).toString()}
                            label
                            custom
                            className="observedItemCheckbox"
                            type="checkbox"
                            checked={selectedChecklistItems.has(item.id!)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChecked(item, e.target.checked)
                            }
                            disabled={isImplCheckDisable}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Popover id='NotPlanned'>
                              <Popover.Content>Not Planned</Popover.Content>
                            </Popover>
                          }
                        >
                          <Form.Check
                            id={(item.id || index).toString()}
                            label
                            custom
                            className="notPlannedItemCheckbox"
                            type="checkbox"
                            checked={selectedNotPlannedItems.has(item.id!)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleNotPlannedChecked(item, e.target.checked)
                            }
                            disabled={isImplCheckDisable}
                          />
                        </OverlayTrigger>
                        <div>
                          <p className="mb-0">
                            <strong>Step {index + 1}:</strong> {item.title}
                          </p>
                          {item.details && (
                            <p className="mb-0">{item.details}</p>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                {/*{displayedItemsNum < intervention.checklist_items.length && (*/}
                {/*  <div*/}
                {/*    className="observedMoreSteps"*/}
                {/*    onClick={() =>*/}
                {/*      setDisplayedItemsNum(intervention.checklist_items.length)*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <h3 className="font-weight-bold">*/}
                {/*      {intervention.checklist_items.length - displayedItemsNum}{" "}*/}
                {/*      more steps*/}
                {/*    </h3>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            )}
            {Array.from(selectedNotPlannedItems).length ? 
              <Form.Group className="my-2">
                <Form.Label>Not Planned Reason (Required)</Form.Label>
                <Form.Control
                  as={"textarea"}
                  value={notPlannedReason}
                  onChange={(e) => setNotPlannedReason((e.target as HTMLInputElement).value)}
                  type="text"
                  placeholder="Type here..."
                  maxLength={800}
                  />
                  <small>Character limit: 800</small>
              </Form.Group>  
            : ''}
            <Form.Group className="my-2">
            <Form.Label>Notes (Optional)</Form.Label>
            <Form.Control
              as={"textarea"}
              // required={showAntecedentForm ? false : true}
              value={notes}
              onChange={(e) => setNotes((e.target as HTMLInputElement).value)}
              type="text"
              placeholder="Type here..."
              maxLength={800}
              />
              <small>Character limit: 800</small>
          </Form.Group>    

            <div className="modalActions">
              <div />
              <button
                disabled={isLoading.logIntervention}
                onClick={beginLogRequest}
                className="blueBtnSm"
              >
                Log{" "}
                {isLoading.logIntervention && (
                  <Spinner animation="border" size="sm" />
                )}
              </button>
            </div>
          </>
        ) : (
          <div>
            <h3 className="font-weight-bold mb-3 text-center">
              Which intervention do you want to log?
            </h3>
            <div className="m-auto w-50">
              <Select
                isClearable
                placeholder="Select Intervention..."
                name="intervention"
                value={intervention}
                options={filterInterventions(interventionGroup.interventions)}
                getOptionLabel={(intervention: Intervention) =>
                  intervention.name
                }
                getOptionValue={(intervention: Intervention) =>
                  intervention.id.toString()
                }
                onChange={(intervention) =>
                  setIntervention(intervention as any)
                }
              />
            </div>
            <div className="spaceBetween mt-3">
              <div />
              <button
                className="blueBtnSm"
                disabled={!intervention}
                onClick={() => setImplStep(true)}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({
  meetingModuleReducer,
  cases,
}: ApplicationState): StateProps => {
  return {
    interventionGroup: cases.selectedInterventionGroup,
    showModal: meetingModuleReducer.modalsState.implementationCheckModal,
    showResourcesModal:
      meetingModuleReducer.modalsState.interventionsBriefModal,
    isLoading: {
      getProgressOverviewData:
        meetingModuleReducer.isLoading.getProgressOverviewData,
      logIntervention: meetingModuleReducer.isLoading.logIntervention,
      
    },
    errors: {
      getProgressOverviewData:
        meetingModuleReducer.errors.getProgressOverviewData,
      logIntervention: meetingModuleReducer.errors.logIntervention,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return bindActionCreators(
    {
      hideImplementationCheckModal: hideImplementationCheckModal,
      getProgressOverviewData: getProgressOverviewData,
      logIntervention: logIntervention,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImplementationCheckModal);
