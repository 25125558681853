import { ActionType } from "typesafe-actions";
import { Reducer } from "redux";
import * as authActions from "./actions";
import { AuthActionTypes, AuthState } from "./types";
import { LocalStorageKeys } from "../../utils/LocalStorageUtils";

export type AuthAction = ActionType<typeof authActions>;

const storedUserInfoJSON = localStorage.getItem(LocalStorageKeys.CurrentUser);

const initialState: AuthState = {
  userInfo: storedUserInfoJSON ? JSON.parse(storedUserInfoJSON) : undefined,
  isAuthenticated: !!storedUserInfoJSON,
  hasMultiLoginAttempt: false,
  isLoading: {
    loginUser: false,
    changePassword: false,
    isTokenValid: false,
    updateProfile: false,
    patchProfile: false,
    registerUser: false,
    loadRegistrationDistricts: false,
    updateUserOnboardingStep: false,
    getInviteInfo: false,
    loadUserInfo: false,
    acceptInvite: false,
    uploadUserProfileImage: false,
  },
  modalsState: {
    editProfileModal: false,
  },
  errors: {},
};

const reducer: Reducer<AuthState> = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "RESET_APP" as any:
      return {
        ...initialState,
        userInfo: undefined,
        isAuthenticated: false,
      };

    case AuthActionTypes.LOGIN:
      return {
        ...state,
        isLoading: { ...state.isLoading, loginUser: true },
        errors: { ...state.errors, loginUser: undefined },
        isAuthenticated: false,
        hasMultiLoginAttempt: false,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, loginUser: false },
        isAuthenticated: true,
        hasMultiLoginAttempt: false,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, loginUser: false },
        isAuthenticated: false,
        errors: { ...state.errors, loginUser: action.payload.error },
      };
    case AuthActionTypes.LOGIN_ATTEMPTS:
      return {
        ...state,
        isLoading: { ...state.isLoading, loginUser: false },
        isAuthenticated: false,
        hasMultiLoginAttempt: true,
        loginAttempt: action.payload
      };
    case AuthActionTypes.RESET_LOGIN_ATTEMPTS:
      return {
        ...state,
        isLoading: { ...state.isLoading, loginUser: false },
        isAuthenticated: false,
        hasMultiLoginAttempt: false,
        loginAttempt: undefined
      };
    case AuthActionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: true },
      };
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: false },
        errors: { ...state.errors, changePassword: undefined },
      };
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, changePassword: false },
        errors: { ...state.errors, changePassword: action.payload.error },
      };

    case AuthActionTypes.TOKEN_VALID_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, isTokenValid: true },
      };
    case AuthActionTypes.TOKEN_VALID_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, isTokenValid: false },
        errors: { ...state.errors, isTokenValid: undefined },
      };
    case AuthActionTypes.TOKEN_VALID_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, isTokenValid: false },
        errors: { ...state.errors, isTokenValid: action.payload.error },
      };

    case AuthActionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: true },
        errors: { ...state.errors, resetPassword: undefined },
      };
    case AuthActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: false },
        resetPasswordResponse: action.payload.responseData
      };
    case AuthActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, resetPassword: false },
        errors: { ...state.errors, resetPassword: action.payload.error },
      };

    case AuthActionTypes.OPEN_EDIT_PROFILE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editProfileModal: true,
        },
      };
    case AuthActionTypes.HIDE_EDIT_PROFILE_MODAL:
      return {
        ...state,
        modalsState: {
          ...state.modalsState,
          editProfileModal: false,
        },
      };

    case AuthActionTypes.UPDATE_PROFILE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateProfile: true },
      };
    case AuthActionTypes.UPDATE_PROFILE_SUCCESS: {
      localStorage.setItem(
        LocalStorageKeys.CurrentUser,
        JSON.stringify(action.payload.userInfo)
      );
      return {
        ...state,
        userInfo: action.payload.userInfo,
        isLoading: { ...state.isLoading, updateProfile: false },
        errors: { ...state.errors, updateProfile: undefined },
        modalsState: {
          ...state.modalsState,
          editProfileModal: false,
        },
      };
    }
    case AuthActionTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateProfile: false },
        errors: { ...state.errors, updateProfile: action.payload.error },
      };

    case AuthActionTypes.LOGOUT: {
      localStorage.clear();
      sessionStorage.clear();
      return { ...initialState, isAuthenticated: false, userInfo: undefined };
    }

    case AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUserOnboardingStep: true },
      };
    case AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUserOnboardingStep: false },
        userInfo: {
          ...state.userInfo!,
          profile: {
            ...state.userInfo!.profile,
            onboarding_state: action.payload.onboardingState,
          },
        },
        errors: {
          ...state.errors,
          updateUserOnboardingStep: undefined,
        },
      };
    case AuthActionTypes.UPDATE_USER_ONBOARDING_STEP_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateUserOnboardingStep: false },
        errors: {
          ...state.errors,
          updateUserOnboardingStep: action.payload.error,
        },
      };

    case AuthActionTypes.GET_INVITE_INFO_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInviteInfo: true },
      };
    case AuthActionTypes.GET_INVITE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInviteInfo: false },
        errors: { ...state.errors, getInviteInfo: undefined },
        inviteInfo: action.payload.inviteInfo,
      };
    case AuthActionTypes.GET_INVITE_INFO_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, getInviteInfo: false },
        errors: { ...state.errors, getInviteInfo: action.payload.error },
      };

    case AuthActionTypes.LOAD_USER_INFO_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, loadUserInfo: true },
      };
    case AuthActionTypes.LOAD_USER_INFO_SUCCESS: {
      // const a = {
      //   ...action.payload.userInfo,
      //   profile: {
      //     ...action.payload.userInfo.profile,
      //     current_assignment: {
      //       ...action.payload.userInfo.profile.current_assignment!,
      //       role: UserAssignmentRole.DISTRICT_OWNER
      //     }
      //   }
      // };

      localStorage.setItem(
        LocalStorageKeys.CurrentUser,
        JSON.stringify(action.payload.userInfo)
      );
      // localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(a));

      return {
        ...state,
        isLoading: { ...state.isLoading, loadUserInfo: false },
        errors: { ...state.errors, loadUserInfo: undefined },
        userInfo: action.payload.userInfo,
        // userInfo: a
      };
    }
    case AuthActionTypes.LOAD_USER_INFO_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, loadUserInfo: false },
        errors: { ...state.errors, loadUserInfo: action.payload.error },
      };

    case AuthActionTypes.REGISTER_USER_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, registerUser: true },
      };
    case AuthActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, registerUser: false },
        errors: { ...state.errors, registerUser: undefined },
      };
    case AuthActionTypes.REGISTER_USER_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, registerUser: false },
        errors: { ...state.errors, registerUser: action.payload.error },
      };

    case AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, loadRegistrationDistricts: true },
      };
    case AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, loadRegistrationDistricts: false },
        errors: { ...state.errors, loadRegistrationDistricts: undefined },
        registrationDistricts: action.payload.districts,
      };
    case AuthActionTypes.LOAD_REGISTRATION_DISTRICTS_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, loadRegistrationDistricts: false },
        errors: {
          ...state.errors,
          loadRegistrationDistricts: action.payload.error,
        },
      };

    case AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadUserProfileImage: true },
      };
    case AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadUserProfileImage: false },
        userInfo: {
          ...state.userInfo!,
          profile: {
            ...state.userInfo!.profile,
            profile_image_url: action.payload.image,
          },
        },
        errors: {
          ...state.errors,
          uploadUserProfileImage: undefined,
        },
      };
    case AuthActionTypes.UPLOAD_USER_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, uploadUserProfileImage: false },
        errors: {
          ...state.errors,
          uploadUserProfileImage: action.payload.error,
        },
      };

    case AuthActionTypes.ACCEPT_INVITE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, acceptInvite: true },
      };
    case AuthActionTypes.ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: { ...state.isLoading, acceptInvite: false },
        errors: { ...state.errors, acceptInvite: undefined },
      };
    case AuthActionTypes.ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, acceptInvite: false },
        errors: { ...state.errors, acceptInvite: action.payload.error },
      };

    case AuthActionTypes.PATCH_PROFILE_START:
      return {
        ...state,
        isLoading: { ...state.isLoading, patchProfile: true },
      };
    case AuthActionTypes.PATCH_PROFILE_SUCCESS:
      localStorage.setItem(
        LocalStorageKeys.CurrentUser,
        JSON.stringify(action.payload.userInfo)
      );

      return {
        ...state,
        isLoading: { ...state.isLoading, patchProfile: false },
        errors: { ...state.errors, patchProfile: undefined },
        userInfo: action.payload.userInfo,
      };
    case AuthActionTypes.PATCH_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: { ...state.isLoading, patchProfile: false },
        errors: { ...state.errors, patchProfile: action.payload.error },
      };

    default:
      return state;
  }
};

export { reducer as authReducer };
