import { School, StudentInfo } from "../onboarding/types";
import { Grade } from "../groups/types";
import { InterventionGroupAssignment } from "../onboarding/cases/types";
import { License } from "../superuser/types";
import { IS_READY_COACH } from "../../constants";

export type AuthState = {
  userInfo?: UserInfo;
  inviteInfo?: InviteInfo;
  registrationDistricts?: Array<RegistrationDistrict>;
  isAuthenticated: boolean;
  hasMultiLoginAttempt: boolean;
  loginAttempt?: LoginAttempt;
  modalsState: {
    editProfileModal: boolean;
  };
  isLoading: {
    loginUser: boolean;
    changePassword: boolean;
    isTokenValid: boolean;
    resetPassword?: boolean;
    updateProfile: boolean;
    patchProfile: boolean;
    updateUserOnboardingStep: boolean;
    getInviteInfo: boolean;
    loadUserInfo: boolean;
    registerUser: boolean;
    loadRegistrationDistricts: boolean;
    acceptInvite: boolean;
    uploadUserProfileImage: boolean;
  };
  errors: {
    loginUser?: string;
    changePassword?: string;
    isTokenValid?: string;
    resetPassword?: string;
    updateProfile?: string;
    patchProfile?: string;
    registerUser?: string;
    updateUserOnboardingStep?: string;
    getInviteInfo?: string;
    loadUserInfo?: string;
    loadRegistrationDistricts?: string;
    acceptInvite?: string;
    uploadUserProfileImage?: string;
  };
  resetPasswordResponse?:any;
};

export enum AuthActionTypes {
  LOGIN = "@@auth/LOGIN",
  LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@auth/LOGIN_FAILURE",
  LOGIN_ATTEMPTS = "@@auth/LOGIN_ATTEMPTS",
  RESET_LOGIN_ATTEMPTS = "@@auth/RESET_LOGIN_ATTEMPTS",

  LOAD_USER_INFO_START = "@@auth/LOAD_USER_INFO_START",
  LOAD_USER_INFO_SUCCESS = "@@auth/LOAD_USER_INFO_SUCCESS",
  LOAD_USER_INFO_FAILURE = "@@auth/LOAD_USER_INFO_FAILURE",

  REGISTER_USER_START = "@@auth/REGISTER_USER_START",
  REGISTER_USER_SUCCESS = "@@auth/REGISTER_USER_START_SUCCESS",
  REGISTER_USER_FAILURE = "@@auth/REGISTER_USER_START_FAILURE",

  LOAD_REGISTRATION_DISTRICTS_START = "@@auth/LOAD_REGISTRATION_DISTRICTS_START",
  LOAD_REGISTRATION_DISTRICTS_SUCCESS = "@@auth/LOAD_REGISTRATION_DISTRICTS_SUCCESS",
  LOAD_REGISTRATION_DISTRICTS_FAILURE = "@@auth/LOAD_REGISTRATION_DISTRICTS_FAILURE",

  LOGOUT = "@@auth/LOGOUT",

  UPLOAD_USER_PROFILE_IMAGE_START = "@@auth/UPLOAD_USER_PROFILE_IMAGE_START",
  UPLOAD_USER_PROFILE_IMAGE_SUCCESS = "@@auth/UPLOAD_USER_PROFILE_IMAGE_SUCCESS",
  UPLOAD_USER_PROFILE_IMAGE_FAILURE = "@@auth/UPLOAD_USER_PROFILE_IMAGE_FAILURE",

  UPDATE_USER_ONBOARDING_STEP_START = "@@auth/UPDATE_USER_ONBOARDING_STEP_START",
  UPDATE_USER_ONBOARDING_STEP_SUCCESS = "@@auth/UPDATE_USER_ONBOARDING_STEP_SUCCESS",
  UPDATE_USER_ONBOARDING_STEP_FAILURE = "@@auth/UPDATE_USER_ONBOARDING_STEP_FAILURE",

  CHANGE_PASSWORD_START = "@@auth/CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS = "@@auth/CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE = "@@auth/CHANGE_PASSWORD_FAILURE",

  RESET_PASSWORD_START = "@@auth/RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS = "@@auth/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "@@auth/RESET_PASSWORD_FAILURE",

  OPEN_EDIT_PROFILE_MODAL = "@@onboarding/cases/OPEN_EDIT_PROFILE_MODAL",
  HIDE_EDIT_PROFILE_MODAL = "@@onboarding/cases/HIDE_EDIT_PROFILE_MODAL",

  UPDATE_PROFILE_START = "@@auth/UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS = "@@auth/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE = "@@auth/UPDATE_PROFILE_FAILURE",

  PATCH_PROFILE_START = "@@auth/PATCH_PROFILE_START",
  PATCH_PROFILE_SUCCESS = "@@auth/PATCH_PROFILE_SUCCESS",
  PATCH_PROFILE_FAILURE = "@@auth/PATCH_PROFILE_FAILURE",

  GET_INVITE_INFO_START = "@@auth/GET_INVITE_INFO_START",
  GET_INVITE_INFO_SUCCESS = "@@auth/GET_INVITE_INFO_SUCCESS",
  GET_INVITE_INFO_FAILURE = "@@auth/GET_INVITE_INFO_FAILURE",

  ACCEPT_INVITE_START = "@@auth/ACCEPT_INVITE_START",
  ACCEPT_INVITE_SUCCESS = "@@auth/ACCEPT_INVITE_SUCCESS",
  ACCEPT_INVITE_FAILURE = "@@auth/ACCEPT_INVITE_FAILURE",

  TOKEN_VALID_START = "@@auth/TOKEN_VALID_START",
  TOKEN_VALID_SUCCESS = "@@auth/TOKEN_VALID_SUCCESS",
  TOKEN_VALID_FAILURE = "@@auth/TOKEN_VALID_FAILURE",
}

export enum OnboardingStates {
  COACH_ONBOARDING_STATE_INTRO = "coach_intro",
  COACH_ONBOARDING_STATE_TEACHERS = "coach_teachers",
  COACH_ONBOARDING_STATE_EVIDENCE = "coach_evidence",
  COACH_ONBOARDING_STATE_GROUPS = "coach_groups",
  COACH_ONBOARDING_STATE_NONE = "none",
  COACH_ONBOARDING_COMPLETE = "complete",
}

export const OnboardingStatesValues = {
  [OnboardingStates.COACH_ONBOARDING_STATE_NONE]: 0,
  [OnboardingStates.COACH_ONBOARDING_STATE_INTRO]: 0,
  [OnboardingStates.COACH_ONBOARDING_STATE_TEACHERS]: 1,
  [OnboardingStates.COACH_ONBOARDING_STATE_EVIDENCE]: 2,
  [OnboardingStates.COACH_ONBOARDING_STATE_GROUPS]: 3,
  [OnboardingStates.COACH_ONBOARDING_COMPLETE]: 4,
};

export type District = {
  id: number;
  name: string;
  owner: UserInfo;
  state: string;
  schools: School[];
  individual_view_permission: boolean;
};

export type UserProfile = {
  id: number;
  is_active: boolean;
  account_disabled: boolean;
  is_educator: boolean;
  district: District | null;
  current_assignment?: {
    id: number;
    school_year: number;
    role: UserAssignmentRole;
    schools: Array<School>;
  } | null;
  current_role: UserAssignmentRole;
  custom_role_title?: string;
  profile_image_url?: string;
  onboarding_state: OnboardingStates;
  agreed_to_data_sharing: boolean;
  xp: {
    rank_display_name: string;
    rank_points: number;
  };
};

export enum UserAssignmentRole {
  STATE_LEADERSHIP = "state_leader",
  DISTRICT_OWNER = "district_owner",
  DISTRICT_LEADERSHIP = "district_leadership",
  SUPERVISOR = "supervisor",
  DATA_MANAGER = "data_manager",
  PRINCIPAL = "principal",
  ADMINISTRATOR = "administrator",
  COACH = "coach",
  TEACHER = "teacher",
  NJTSS_USER = "njtss_user",
  NJTSS_IMPORTED_USER = "njtss_imported",
  PARENT = "parent",
  SUPERUSER = "superuser",
}

export const UserAssignmentRoleDisplayedNames = {
  [UserAssignmentRole.STATE_LEADERSHIP]: "State Leader",
  [UserAssignmentRole.DISTRICT_OWNER]: "District Admin",
  [UserAssignmentRole.DISTRICT_LEADERSHIP]: "District Leadership",
  [UserAssignmentRole.SUPERVISOR]: "Supervisor",
  [UserAssignmentRole.DATA_MANAGER]: "Data Manager",
  [UserAssignmentRole.PRINCIPAL]: "Principal",
  [UserAssignmentRole.ADMINISTRATOR]: "Administrator",
  [UserAssignmentRole.COACH]: "Coach",
  [UserAssignmentRole.TEACHER]: "Teacher",
  [UserAssignmentRole.NJTSS_USER]: "NJTSS User",
  [UserAssignmentRole.NJTSS_IMPORTED_USER]: IS_READY_COACH
    ? "Teacher (Imported from File)"
    : "NJTSS Imported User",
  [UserAssignmentRole.PARENT]: "Parent",
  [UserAssignmentRole.SUPERUSER]: "Superuser",
};

export type UserAssignmentProfile = {
  id: number;
  is_active: boolean;
  is_educator: boolean;
  district: number;
  current_assignment: number;
  current_role: string;
  profile_image_url: string;
  profile_image: string;
  onboarding_state: OnboardingStates;
  created: string;
  updated: string;
  user: number;
};

export type UserCredential = {
  username: string;
  password: string;
};

export type LoginAttempt = {
  success: boolean;
  login_time_interval: number;
  message?: string;
};

export type UserRegistrationCredential = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  district?: number;
  school?: number;
};

export type NewDistrictRegistrationCredential = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  district_name: string;
  state?: string;
};

export type RegistrationDistrict = {
  id: number;
  name: string;
  schools: Array<School>;
  state: string;
};

export type UserInfo = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile: UserProfile;
  available_grades?: Grade[];
  account_disabled?: boolean;
  allowed_edit_benchmarks?:boolean;
  allowed_grades: Grade[];
  allowed_no_grade: boolean;
  allowed_no_teacher: boolean;
  allowed_schools: School[];
  share_all_data: boolean;
  is_active?: boolean;
  is_staff?: boolean;
  is_superuser?: boolean;
  children?: StudentInfo[];
  current_license?: License;
  login_history_stats: LoginHistory;
  allowed_assigned_teachers: [],
  allowed_assigned_teachers_ids: [],
  settings_present: boolean;
  block_access_to_all_data: boolean;
};

export type LoginHistory = {
  user_id: number;
  number_of_logins?: number;
  avg_duration_of_logins: number;
  datafile_upload_count: number;
  last_login: Date;

}

export type UserAssignmentInfo = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile: UserAssignmentProfile;
};

export type StaffRelationship = {
  id: number;
  is_active: boolean;
  relationship_type: number;
  parent: InterventionGroupAssignment; // This type name should be refactored to PositionAssignment
  child: InterventionGroupAssignment;
};

export type UserToken = {
  access: string;
  refresh: string;
};

export type InviteToken = {
  code: string;
  email: string;
};

export type InviteInfo = {
  id: number;
  receiver: UserInfo;
  sent_by: UserInfo;
};

export type FilledInvite = InviteToken & {
  first_name: string;
  last_name: string;
  password: string;
};

export type uploadedAssessment = {
  all_data_periods?: Array<any>
  csv_file_name: string;
  data_period_id: number;
  raw_csv: string;
  source: string;
}
