import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { useEffect, useMemo } from "react";
import _ from "lodash";
import { getFullName } from "../../../../../../utils/NamesUtils";
import {
  BasicUserInfo,
  EvidenceColumnGroup,
  EvidencePeriodData,
  EvidenceType,
  MeasurementType,
  Student,
  StudentRow,
} from "../../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../../constants";

const BREAKPOINT = 0.5;

type ClassWideNeed = {
  letter_naming: SkillAreaStats;
  phonemic_awareness: SkillAreaStats;
  phonics: SkillAreaStats;
  fluency: SkillAreaStats;
  comprehension: SkillAreaStats;
};

type SkillAreaStats = {
  studentsIds: number[];
  skills: SkillStat[];
};

type SkillStat = {
  studentIds: number[];
  skill_focus: string;
  display_name: string;
};
export type ClassWideNeedsStats = {
  classWideNeed: Record<string, SkillAreaStats>;
  grade?: string;
  classInfo?: string;
  teacher: BasicUserInfo;
  students: Student[];
};

const SkillAreaGroup: { [key: string]: string } = {
  letter_naming: "letter_naming",

  diag_area_phonemic_awareness: "phonemic_awareness",
  diag_area_early_phonemic_awareness: "phonemic_awareness",
  phonemic_awareness: "phonemic_awareness",
  early_phonemic_awareness: "phonemic_awareness",

  diag_area_phonics: "phonics",
  phonics: "phonics",

  orf_wrc: "fluency",
  diag_area_fluency: "fluency",
  accuracy: "phonics",
  orf_accuracy: "phonics",
};

const useClassWideNeeds = () => {
  let evidencePeriodDataInfo = useSelector(
    (s: ApplicationState) => {
      if(s.onboarding.evidencePeriodData?.student_rows.length! > 3000) {
        let evidencePeriodDataInfo = s.onboarding.evidencePeriodData;
        let topStudentRows = evidencePeriodDataInfo?.student_rows.slice(0, 3000);
          evidencePeriodDataInfo = {
            ...evidencePeriodDataInfo!,
            student_rows: topStudentRows!
          }

        for (let i = 0; i < evidencePeriodDataInfo?.evidence_column_groups.length!; i++) {
          const ecg = evidencePeriodDataInfo?.evidence_column_groups[i];
          for (let j = 0; j < ecg?.evidence_columns.length!; j++) {
            let ec = ecg?.evidence_columns[j];
            ec.student_entries = ec?.student_entries.filter((ec) => topStudentRows?.some((tsr) => tsr.student.id == ec.student_id));
            ecg.evidence_columns[j] = ec;
          }
        }
        return evidencePeriodDataInfo;
      } else {
        return s.onboarding.evidencePeriodData
      }
    }
  );

  const currentDataPeriod = useSelector(
    (s: ApplicationState) => s.onboarding.currentDataPeriod
  );

  const timePeriod = useMemo(() => {
    return currentDataPeriod?.time_period;
  }, [currentDataPeriod?.time_period]);
  
  const {teacherIdFilter,
    schoolIdsFilter,
    gradeLevelFilter,
    classFilter,
    studentsFilter} = useSelector(
    (s: ApplicationState) => ({
        teacherIdFilter: s.onboarding.teacherIdFilter,
        schoolIdsFilter: s.dataFilter.dataSchoolsFilter,
        gradeLevelFilter: s.dataFilter.dataGradesFilter,
        classFilter: s.dataFilter.classIdFilter,
        studentsFilter: s.onboarding.studentsFilter,
    })
  );
  let evidencePeriodData: EvidencePeriodData | undefined = _.cloneDeep(evidencePeriodDataInfo);
  
  const rowsFilter = (studentRow: StudentRow) => {
    let result: boolean = true;

    if (IS_READY_COACH && teacherIdFilter) {
      result =
        result &&
        !!studentRow.student.teachers?.some(
          (teacher) => teacher.id === teacherIdFilter
        );
    }

    if (studentsFilter) {
      result =
        result &&
        getFullName(studentRow.student)
          .toLowerCase()
          .includes(studentsFilter.toLowerCase());
    }

    if (schoolIdsFilter && schoolIdsFilter.length > 0) {
      result =
        result &&
        studentRow.student
          .teachers!.flatMap((teacher) => teacher.schools || [])
          .some((schoolId) => schoolId && schoolIdsFilter.includes(schoolId));
    }

    // if (teacherIdFilter && teacherIdFilter.length > 0) {
    //   result =
    //     result &&
    //     studentRow.student.teachers!.some(
    //       (teacher) => teacher.id && teacherIdFilter.includes(teacher.id)
    //     );
    // }
    if (classFilter) {
      result =
        result &&
        studentRow.student.teachers!.some((st) => st.classes?.find((cls) => cls.id == classFilter));
    }
    if (evidencePeriodData && gradeLevelFilter && gradeLevelFilter.length > 0) {
      result =
        result &&
        evidencePeriodData.evidence_column_groups.some((ecg: EvidenceColumnGroup) =>
          ecg.evidence_columns.some((ec) =>
            ec.student_entries.some(
              (se) =>
                se.student_id === studentRow.student.id &&
                se.grade &&
                gradeLevelFilter.includes(se.grade)
            )
          )
        );
    }
    return result;
  };


  let filteredEvidencePeriodData = useMemo(()=> {
    if(evidencePeriodData &&
    //&& originalEvidencePeriodData?.evidence_column_groups.length > 0
    (teacherIdFilter ||
      schoolIdsFilter ||
      classFilter ||
      gradeLevelFilter ||
      studentsFilter)
    ) {
      if(
       teacherIdFilter ||
        (schoolIdsFilter && schoolIdsFilter.length > 0) ||
        (classFilter != undefined) ||
        studentsFilter !== "" ||
        gradeLevelFilter?.length
       ) {
          let evidenceData = {
            ...evidencePeriodData,
              student_rows: evidencePeriodData.student_rows.filter((studentRow: StudentRow) =>
                rowsFilter(studentRow)
              ),
          }
          return evidenceData
        } else {
          return evidencePeriodDataInfo
        }
      }
    }, [studentsFilter,
      teacherIdFilter,
      gradeLevelFilter,
      classFilter,
      schoolIdsFilter]);

    evidencePeriodData = useMemo(() => {
      for (let i = 0; i < filteredEvidencePeriodData!.evidence_column_groups.length; i++) {
        const ecg = filteredEvidencePeriodData?.evidence_column_groups[i];
        for (let j = 0; j < ecg!.evidence_columns.length; j++) {
          const ec = ecg!.evidence_columns[j];
          for (let k = 0; k < ec.student_entries.length; k++) {
            filteredEvidencePeriodData!.student_rows.map((studentRow) =>{
              if(studentRow.student.id === ec.student_entries[k].student_id) {
                studentRow.student.teachers?.filter((teacher)=> {
                  if(teacher.classes == null) {
                    return teacher.classes = [];
                  }
                })
                .map((teacher:any)=> (teacher.classes.length) ? teacher.classes : teacher.classes.push({id:0, name: 'Unassigned'}))
                studentRow.grade = ec.student_entries[k].grade;
                return studentRow;
              }
            })
          }
        }
      }

      return filteredEvidencePeriodData;

    },[filteredEvidencePeriodData])

  // evidencePeriodData?.evidence_column_groups.map((evidenceColumnGroup)=>
  //   evidenceColumnGroup.evidence_columns.map((evidenceColumn)=>
  //     evidenceColumn.student_entries.map((studentEntries) => 
  //       evidencePeriodData!.student_rows.map((studentRow) =>{
  //         if(studentRow.student.id === studentEntries.student_id) {
  //           studentRow.student.teachers?.filter((teacher)=> {
  //             if(teacher.classes == null) {
  //               return teacher.classes = [];
  //             }
  //           })
  //           .map((teacher:any)=> (teacher.classes.length) ? teacher.classes : teacher.classes.push({id:0, name: 'Unassigned'}))
  //           studentRow.grade = studentEntries.grade;
  //           return studentRow;
  //         }
  //       })
  //     )
  //   )
  // )

  let evidenceGroups = useMemo(() => {
    if (evidencePeriodData?.student_rows) {
      //console.log('evidencePeriodData?.student_rows', evidencePeriodData?.student_rows)
      let studentGroupRow =  _.chain(evidencePeriodData.student_rows)
        .flatMap((studentRow) =>
          (studentRow.student.teachers || []).flatMap((teacher) => (teacher.classes || []).map((classInfo) => ({
            teacher: teacher,
            grade: studentRow.grade,
            student: studentRow.student,
            classInfo: classInfo.name == 'Unassigned' ? `Unassigned-${studentRow.grade}-${teacher.last_name}`: classInfo.name
          })))
        )
        //.groupBy((group) => group.teacher.id)
        
        .groupBy((group) => group.classInfo)
        .values()
        .map((studentRows) =>  
          studentRows.map((studentRow) => 
          ({
            teacher: studentRow.teacher,
            grade: studentRow.grade,
            students: studentRows.flatMap((sr) => sr.student),
            classInfo: studentRow.classInfo
          })

        ))
        .sortBy((group) => group.map((gr)=> gr.classInfo))
        .value();
          
        return studentGroupRow.flat(1);
    }
    return [];
  }, [evidencePeriodData]);

  let groups: any = useMemo(() => {
    let groupArray:any = [];
    for (let i = 0; i < evidenceGroups.length; i++) {
      const group = evidenceGroups[i];
      if(!groupArray.some((data:any)=> (data.grade == group.grade) && (data.teacher.id == group.teacher.id) && (data.teacher.classes != null) &&(data.teacher.classes[0].name == group.classInfo))) {

        const groupSt = group.students.filter((student)=> student.teachers?.find((st)=> (st.id == group.teacher.id)))
        
        const groupObj = {
          grade: group.grade,
          students: Array.from( new Set(groupSt)),
          teacher: group.teacher,
          classInfo: group.classInfo
        }
        
         groupArray.push(groupObj);
      }
    }
    //console.log('groupArray', groupArray)
    return groupArray;

  // groups.map((group)=> {
  //   if(!groupArray.some((data:any)=> (data.grade == group.grade) && (data.teacher.id == group.teacher.id) && (data.teacher.classes != null) &&(data.teacher.classes[0].name == group.classInfo))) {

  //     const groupSt = group.students.filter((student)=> student.teachers?.find((st)=> (st.id == group.teacher.id)))
      
  //     const groupObj = {
  //       grade: group.grade,
  //       students: Array.from( new Set(groupSt)),
  //       teacher: group.teacher,
  //       classInfo: group.classInfo
  //     }
      
  //     return groupArray.push(groupObj);
  //   }
  // })

  // groups = [...groupArray];
},[evidenceGroups])
  //console.log('groups', groups)

  //console.log('groups', groups)

  const allStudentsIdsWithBelowOrWellBelowByArea = useMemo(() => {
    if (evidencePeriodData?.evidence_column_groups) {
      return _.chain(evidencePeriodData.evidence_column_groups)
        .filter((ecg) => ecg.is_primary_screening === true)
        .flatMap((ecg) => ecg.evidence_columns)
        .filter(
          (ecg) =>
            ecg.evidence_type === EvidenceType.Data &&
            ecg.measurement.type !== MeasurementType.CATEGORY &&
            ecg.measurement.skill_areas != null &&
            ecg.measurement.skill_areas!.length > 0
        )
        .reduce<{ studentId: number; areas: (string | undefined)[] }[]>(
          (prevEcg, ecg) => {
            const studentsIdsWithBelowOrWellBelow:any = ecg.student_entries.reduce<
              {
                studentId: number;
                areas: (string | undefined)[];
              }[]
            >((prevEc, se) => {
              const colorCriteriaList =
                timePeriod &&
                se.grade &&
                ecg.measurement.grade_specific_color_criteria &&
                ecg.measurement.grade_specific_color_criteria[se.grade]?.[
                timePeriod
                ];

              if (colorCriteriaList && se.value !== undefined) {
                const value: number = +se.value;
                const colorCriteria = colorCriteriaList.find(
                  (cc) => {
                    if (cc) {
                      return value >= +cc.range_min! && value <= +cc.range_max!
                    }
                  }
                  );
                  
                  if (
                    colorCriteria &&
                    (colorCriteria.benchmark_status === "well_below" ||
                    colorCriteria.benchmark_status === "below")
                    ) {
                      
                  return [
                    ...prevEc,
                    {
                      studentId: se.student_id,
                      areas:
                        ecg.measurement.skill_areas?.map(
                          (sa) => SkillAreaGroup[sa]
                        ) ?? [],
                    },
                  ];
                }
              }

              return prevEc;
            }, []);
            //console.log('studentsIdsWithBelowOrWellBelow', studentsIdsWithBelowOrWellBelow)
            return [...prevEcg, ...studentsIdsWithBelowOrWellBelow];
          },
          []
        )
        .flatMap((g) =>
          g.areas.map((a) => ({ area: a, studentId: g.studentId }))
        )
        .groupBy((g) => g.area)
        .values()
        .reduce<ClassWideNeed>(
          (pV, cV) => {
            const area: keyof ClassWideNeed = cV[0].area as any;
            return {
              ...pV,
              [area]: {
                ...pV[area],
                studentsIds: _.chain(cV)
                  .map((a) => a.studentId)
                  .uniq()
                  .value(),
              },
            };
          },
          {
            letter_naming: {
              skills: [],
              studentsIds: [],
            },
            phonemic_awareness: {
              skills: [],
              studentsIds: [],
            },
            phonics: {
              skills: [],
              studentsIds: [],
            },
            fluency: {
              skills: [],
              studentsIds: [],
            },
            comprehension: {
              skills: [],
              studentsIds: [],
            },
          }
        )
        .value();
    }
    return {
      letter_naming: {
        skills: [],
        studentsIds: [],
      },
      phonemic_awareness: {
        skills: [],
        studentsIds: [],
      },
      phonics: {
        skills: [],
        studentsIds: [],
      },
      fluency: {
        skills: [],
        studentsIds: [],
      },
      comprehension: {
        skills: [],
        studentsIds: [],
      }
    };
  }, [evidencePeriodData?.evidence_column_groups]);

  //console.log('allStudentsIdsWithBelowOrWellBelowByArea', allStudentsIdsWithBelowOrWellBelowByArea)

  const evidenceDiagnosticInfoColumn = useMemo(() => {
    if (evidencePeriodData?.evidence_column_groups) {
      return _.chain(evidencePeriodData.evidence_column_groups)
        .flatMap((ecg) => ecg.evidence_columns)
        .find((ec) => ec.evidence_type === EvidenceType.DiagnosticInfo)
        .value();
    }
    return undefined;
  }, [evidencePeriodData?.evidence_column_groups]);

  //console.log('evidenceDiagnosticInfoColumn', evidenceDiagnosticInfoColumn)

  const classWideNeed = useMemo(() => {
    if (evidenceDiagnosticInfoColumn) {
      return _.chain(evidenceDiagnosticInfoColumn.student_entries)
        .reduce<ClassWideNeed>((prev, se) => {
          if (!se.diagnostic_tags?.length) {
            return prev;
          }

          return se.diagnostic_tags.reduce((prevC, dt) => {
            const groupName: keyof ClassWideNeed =
              dt.area && (SkillAreaGroup[dt.area] as any);

            if (groupName) {
              return {
                ...prevC,
                [groupName]: prevC[groupName].skills.some(
                  (g) => g.skill_focus === dt.skill_focus
                )
                  ? {
                    ...prevC[groupName],
                    skills: prevC[groupName].skills.map((g) =>
                      g.skill_focus === dt.skill_focus
                        ? {
                          ...g,
                          studentIds: _.union(g.studentIds, [
                            se.student_id,
                          ]),
                        }
                        : g
                    ),
                  }
                  : {
                    ...prevC[groupName],
                    skills: [
                      ...prevC[groupName].skills,
                      {
                        skill_focus: dt.skill_focus,
                        display_name: dt.display_name,
                        studentIds: [se.student_id],
                      },
                    ],
                  },
              };
            }
            return prevC;
          }, prev);
        }, allStudentsIdsWithBelowOrWellBelowByArea)
        .mapValues((a) => ({
          ...a,
          studentsIds: _.union(
            a.studentsIds,
            a.skills?.flatMap((s) => s.studentIds)
          ),
        }))
        .value();
    }
    return allStudentsIdsWithBelowOrWellBelowByArea;
  }, [allStudentsIdsWithBelowOrWellBelowByArea, evidenceDiagnosticInfoColumn]);

  //console.log('classWideNeed', classWideNeed)

  const academicStats = useMemo(() => {
    //console.log('A groups', groups)
    return groups.map((group: any) => {
        return {
          ...group,
          classWideNeed: _.chain(classWideNeed)
            .mapValues((skillAreaStats) => {
              return {
                studentsIds: skillAreaStats.studentsIds.filter((id) =>
                  group.students.some((s: any) => s.id === id)
                ),
                skills: skillAreaStats.skills
                  ?.map((ss) => ({
                    ...ss,
                    studentIds: ss.studentIds.filter((id) =>
                      group.students.some((s: any) => s.id === id)
                    ),
                  }))
                  .filter((s) => s.studentIds.length),
              };
            })
            .entries()
            .filter(
              ([key, skillAreaStat]) =>
                skillAreaStat.studentsIds.length >=
                group.students.length * BREAKPOINT
            )
            .fromPairs()
            .value(),
        };
      })
      .filter((group: any) =>
        Object.entries(group.classWideNeed).some(
          ([key, list]) =>
            (list as any).studentsIds.length >= group.students.length * BREAKPOINT
        )
      );
  }, [groups, classWideNeed]);

  //console.log('academicStats', academicStats)

  return {
    academicStats,
  };
};

export default useClassWideNeeds;