import React from "react";
import { Alert, Col, Dropdown, FormCheck, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  addInterventionsToGroup,
  changeSelectedInterventionGroup,
  getInterventions,
  hideInterventionLibraryModal,
  patchInterventionGroup,
} from "../../../../../store/onboarding/cases/actions";
import { connect, useSelector } from "react-redux";
import {
  ConcernArea,
  ConcernType,
  ConcernTypeDisplayedNames,
  ExtendedInterventionTarget,
  GoalTypes,
  GoalTypesDisplayedNames,
  Intervention,
  InterventionGroup,
  InterventionGroupPatchRequest,
  InterventionTarget,
  InterventionTargetDisplayedNames,
} from "../../../../../store/onboarding/cases/types";
import { ValueType } from "react-select/src/types";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import ModifyInterventionPopup from "./ModifyInterventionPopup";
import ModifyingInterventionBody from "./ModifyingInterventionBody";
import InterventionGroupTooltip from "./common/InterventionGroupTooltip";
import { IS_READY_COACH } from "../../../../../constants";
import GroupCountIcon from "../group-students/common/GroupCountIcon";
import { Identifier, SkillFocusArea } from "../../../../../store/groups/types";
import { getSkillFocuses } from "../../../../../store/groups/actions";
import { UserInfo } from "../../../../../store/auth/types";
import LoadingIndicator from "../../LoadingIndicator";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import { getFullNameForStudent } from "../../../../../utils/StudentMapUtils";
import { toastr } from "react-redux-toastr";
import ModalCloseButton from "../group-students/common/ModalCloseButton";
import InterventionItem from "../../../../pages/coach-dashboard/tabs/explore-and-learn/intervention-library/InterventionItem";
import { addInterventionToPlan } from "../../../../utils";
import InterventionEditButton from "./InterventionEditButton";
import { RouteComponentProps, withRouter } from "react-router";
import jsPDF from "jspdf";
// @ts-ignore
import html2pdf from "html2pdf.js";
import InterventionShared from "../../../../pages/coach-dashboard/tabs/explore-and-learn/intervention-library/new-intervention-modal/intervention-shared/InterventionShared";

type PropsFromState = {
  callbacks?: {
    onBack: Function;
  };
  userInfo: UserInfo | undefined;
  interventionGroup?: InterventionGroup;
  selectedIntervention?: Intervention;
  interventions: Array<Intervention>;
  skillFocusInfo: Array<SkillFocusArea>;
  identifiersInfo: { [identifier: string]: Identifier };
  showModal: boolean;
  interventionCalledFromFBA: boolean;
  isSaveInState: boolean;
  createdIntervention?: Intervention;
  isLoading: {
    addInterventionsToGroup: boolean;
    getInterventions: boolean;
  };
  errors: {
    addInterventionsToGroup?: string;
  };
};

type DispatchProps = {
  getInterventions: (concernType?: ConcernType) => any;
  hideInterventionLibraryModal: () => any;
  getSkillFocuses: () => any;
  addInterventionsToGroup: (
    interventionGroupId: number,
    interventionIds: Array<number>
  ) => any;
  patchInterventionGroup: (
    groupId: number,
    request: InterventionGroupPatchRequest
  ) => any;
  changeSelectedInterventionGroup: (group: InterventionGroup) => any;
};

type MatchParams = {
  tabName: string;
};

type Props = PropsFromState & DispatchProps & RouteComponentProps<MatchParams>;

type State = {
  recommendedOnly: boolean;
  interventions: Array<Intervention>;
  targetPersonFilter?: ReactSelectOption<InterventionTarget>;
  concernTypeFilter?: ReactSelectOption<ConcernType>;
  areaFilter?: ReactSelectOption<string>;
  areaOfNeedFilter?: ReactSelectOption<string>;
  createdByFilter?: ReactSelectOption<number>;
  selectedIntervention?: Intervention;
  step: number; //todo
  showModifyInterventionPopup: boolean;
  searchStringFilter?: string;
  contentRef: React.RefObject<HTMLDivElement | null>;
  shareByDistrict: boolean;
};

const RECOMMENDATIONS_LIMIT = 1500000;


class InterventionLibraryModal extends React.Component<Props, State> {
  defState: Readonly<State> = {
    recommendedOnly: true,
    step: 0,
    interventions: [],
    createdByFilter: { label: "All", value: 0 },
    targetPersonFilter: undefined,
    selectedIntervention: undefined,
    showModifyInterventionPopup: false,
    searchStringFilter: undefined,
    contentRef: React.createRef(),
    shareByDistrict: false
  };
  state: Readonly<State> = this.defState;

  componentDidMount(): void {
    this.props.getSkillFocuses();
    this.props.getInterventions();
  }
  
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      this.props.createdIntervention &&
      this.props.interventionGroup &&
      prevProps.isLoading.addInterventionsToGroup &&
      !this.props.isLoading.addInterventionsToGroup
    ) {
      if (this.props.errors.addInterventionsToGroup) {
        toastr.error("Error", this.props.errors.addInterventionsToGroup);
      } else {
        const { interventionGroup, createdIntervention } = this.props;
        
        const changedPlan = addInterventionToPlan(
          interventionGroup.intervention_plan,
          createdIntervention,
          interventionGroup.interventions
        );
        this.props.patchInterventionGroup(interventionGroup.id, {
          intervention_plan: changedPlan,
        });
      }
    }

    if (
      prevProps.isLoading.addInterventionsToGroup &&
      !this.props.isLoading.addInterventionsToGroup
    ) {
      if (this.props.errors.addInterventionsToGroup) {
        toastr.error("Error", this.props.errors.addInterventionsToGroup);
      } else {
        this.props.hideInterventionLibraryModal();
        this.props.callbacks?.onBack();
      }
    }
    
    if (prevProps.interventions !== this.props.interventions) {
      if(!this.props.interventionGroup?.recommendation_group_key) { //RC-1414 show all for manual grouping
         this.setState({
          recommendedOnly: false
        })
      }
      if (this.state.recommendedOnly) {
        this.setState({
          interventions: this.getRecommendedOnlyInterventions(),
        });
      } else {
        this.setState({
          interventions: this.props.interventions,
        });
      }
    }
  }

  getRecommendedOnlyInterventions = () => {
    const { identifiersInfo, interventionGroup, interventions } = this.props;
    let fInterventions = interventions;

    if (interventionGroup) {
      const focuses = interventionGroup.focuses;
      let filteredInterventions: Array<Intervention> = [];
      let goalType = interventionGroup.goal_type ??
      (interventionGroup.recommendation_group_key !== null ||
      !!interventionGroup.focuses?.length
        ? GoalTypes.ACADEMIC
        : null);

      if((this.props.match.params.tabName == 'groups/behavior') && (interventionGroup.goal_type == GoalTypes.BEHAVIOR)){
        goalType = interventionGroup.goal_type ??
        (interventionGroup.recommendation_group_key !== null ||
        !!interventionGroup.focuses?.length
          ? GoalTypes.BEHAVIOR
          : null);
      } 
      if (focuses) {
        const idsAdded = new Set();
        // Recommendation group key
        fInterventions.forEach((intervention) => {
          if (idsAdded.has(intervention.id)) {
            return;
          }

          let toTest: string[] = [];
          if (intervention.focuses) {
            toTest = toTest.concat(intervention.focuses);
          }

          if (intervention.skill_areas) {
            toTest = toTest.concat(intervention.skill_areas);
          }

          let addedFromRecs = false;
          if (interventionGroup.recommendation_group_key) {
            if (
              toTest &&
              toTest.includes(interventionGroup.recommendation_group_key)
              ) {
                filteredInterventions.push(intervention);
                idsAdded.add(intervention.id);
                addedFromRecs = true;
              } 
              // if (
              //   intervention.name.toLowerCase().startsWith("research based") ||
              //   intervention.name.toLowerCase().startsWith("research-based")
              // ) {
              //   if (
              //     focuses.some((focus) => {
              //       const identifier: Identifier | undefined =
              //         identifiersInfo[focus.identifier];
              //       return intervention?.skill_areas?.includes(identifier.area_identifier);
              //     })
              //   ) {
              //     filteredInterventions.push(intervention);
              //     idsAdded.add(intervention.id);
              //     addedFromRecs = true;
              //   }
              // }
              // if (
              //   focuses.some((focus) => {
              //       const identifier: Identifier | undefined = identifiersInfo[focus.identifier];
              //       return intervention?.skill_areas?.includes(identifier.area_identifier)
              //     })
              // ) {
              //     if(intervention.name.toLowerCase().startsWith('research based')
              //       || intervention.name.toLowerCase().startsWith('research-based')) {
              //         filteredInterventions.push(intervention);
              //         idsAdded.add(intervention.id);
              //         addedFromRecs = true;
              //     }
              // }
            if (interventionGroup.recommendation_group_key === "ABC" &&
              (toTest.includes("A") || toTest.includes("B") ||
                toTest.includes("C"))) {
              filteredInterventions.push(intervention);
              idsAdded.add(intervention.id);
              addedFromRecs = true;
            }
          }
        });

        let furtherInterventions: any[] = [];
        
        // first condition specific id
        const idsAddedForInterventions = new Set();
        
        fInterventions.forEach((intervention) => {
          if (idsAddedForInterventions.has(intervention.id)) {
            return;
          }

          let toTest: string[] = [];
          if (intervention.focuses) {
            toTest = toTest.concat(intervention.focuses);
          }

          if (intervention.skill_areas) {
            toTest = toTest.concat(intervention.skill_areas);
          }
          focuses.forEach((focus) => {
            const identifier: Identifier | undefined =
              identifiersInfo[focus.identifier];
            const readRecMappedValue: string =
              READ_REC_ID_MAP[focus.identifier];

            if ((readRecMappedValue === 'diag_area_comprehension') && 
              toTest.includes('diag_area_vocab')){
              // Since we don't have any rr for vocab we need to extract related interventions using comprehension
              furtherInterventions.push(intervention);
              idsAdded.add(intervention.id);
            }
            if (identifier.area_identifier == "diag_area_vocab" &&
              toTest.includes("diag_area_comprehension")){                
               // to stop recommendation of comprehension when diag area is vocabulary
            } else if (toTest && identifier && (identifier.type == 'specific') && toTest.includes(identifier.identifier_info[0])) {
              furtherInterventions.push(intervention);
              idsAdded.add(intervention.id);
            }
          });
        });

        // second condition skill focus of specific
        if (!furtherInterventions.length){
          const idsAdded = new Set();
          fInterventions.forEach((intervention) => {
            if (idsAdded.has(intervention.id)) {
              return;
            }

            let toTest: string[] = [];
            if (intervention.focuses) {
              toTest = toTest.concat(intervention.focuses);
            }

            if (intervention.skill_areas) {
              toTest = toTest.concat(intervention.skill_areas);
            }
            focuses.forEach((focus) => {
              const identifier: Identifier | undefined =
                identifiersInfo[focus.identifier];

                if(toTest.includes(identifier?.skill_focus_identifier!)){
                  filteredInterventions.push(intervention);
                  idsAdded.add(intervention.id);
                }
            });
          });
        }

        // third condition skill focus
        if (!furtherInterventions.length){
          const idsAdded = new Set();
          fInterventions.forEach((intervention) => {
            if (idsAdded.has(intervention.id)) {
              return;
            }
  
            let toTest: string[] = [];
            if (intervention.focuses) {
              toTest = toTest.concat(intervention.focuses);
            }
  
            if (intervention.skill_areas) {
              toTest = toTest.concat(intervention.skill_areas);
            }
            focuses.forEach((focus) => {
              const identifier: Identifier | undefined =
                identifiersInfo[focus.identifier];
  
                if((identifier.type == 'skill_focus') && toTest.includes(identifier.identifier_info[0])) { // ID
                    filteredInterventions.push(intervention);
                    idsAdded.add(intervention.id);
                }
            });
          });
        }

        // fourth condition area of need
        if (!furtherInterventions.length){
          const idsAdded = new Set();
          fInterventions.forEach((intervention) => {
            if (idsAdded.has(intervention.id)) {
              return;
            }
  
            let toTest: string[] = [];
            if (intervention.focuses) {
              toTest = toTest.concat(intervention.focuses);
            }
  
            if (intervention.skill_areas) {
              toTest = toTest.concat(intervention.skill_areas);
            }
            focuses.forEach((focus) => {
              const readRecMappedValue: string =
                READ_REC_ID_MAP[focus.identifier];
              const behaveRecMappedValue: string =
                BEHAVE_REC_ID_MAP[focus.identifier];
  
                if(toTest.includes(readRecMappedValue || behaveRecMappedValue)) {
                  filteredInterventions.push(intervention);
                  idsAdded.add(intervention.id); 
                }
            });
          });
        }

        let mergedInterventions = filteredInterventions.concat(furtherInterventions);

        filteredInterventions =  this.uniqueByKey(mergedInterventions);

        if(!filteredInterventions.length) {
          const idsAddedGroups = new Set();
          fInterventions.forEach((intervention) => {
            if (idsAddedGroups.has(intervention.id)) {
              return;
            }
    
            let toTest: string[] = [];
            if (intervention.focuses) {
              toTest = toTest.concat(intervention.focuses);
            }
    
            if (intervention.skill_areas) {
              toTest = toTest.concat(intervention.skill_areas);
            }
    
            focuses.forEach((focus) => {
            const identifier: Identifier | undefined =
              identifiersInfo[focus.identifier];
  
              if (toTest && identifier) {
                if(toTest.includes(identifier.identifier_info[3])) { // Sub Group
                  filteredInterventions.push(intervention);
                  idsAddedGroups.add(intervention.id);
                } else if(toTest.includes(identifier.identifier_info[2])) { // Main Group
                  filteredInterventions.push(intervention);
                  idsAddedGroups.add(intervention.id);
                } 
              }
            });
          });
        }
      }
        
      fInterventions = filteredInterventions;
      if (fInterventions.length === 0) {
        if(focuses?.length) {
          focuses!.forEach((focus) => {
            const identifier: Identifier | undefined = 
              (focus.identifier != undefined) 
              ? identifiersInfo[focus.identifier] 
              : identifiersInfo[focus as any];
            let fluencyComperihance = interventions.filter((intervention:Intervention)=> 
                    (intervention.skill_areas != null) 
                    && (intervention.skill_areas.length > 0) 
                    && (identifier != undefined)
                    && intervention.skill_areas.find((skillArea)=> skillArea == identifier.area_identifier));
                     
                    fInterventions = fluencyComperihance;
          });

          
          
        } else {
          fInterventions = interventions;
        }
      }

      if(focuses?.length) {
        interventions.forEach((intervention) => {
          if (
            intervention.name.toLowerCase().startsWith("research based") ||
            intervention.name.toLowerCase().startsWith("research-based")
          ) {
            if (
              focuses.some((focus) => {
                const identifier: Identifier | undefined =
                  identifiersInfo[focus.identifier];
                return intervention?.skill_areas?.includes(identifier.area_identifier);
              })
            ) {
              fInterventions.push(intervention);
            }
          }

        })
      }

      if (goalType) {
       
        fInterventions = fInterventions.filter((intervention) => {
          if (goalType == GoalTypes.ACADEMIC) {
            return (
              intervention.concern_area === ConcernArea.ACADEMIC ||
              intervention.concern_area === ConcernArea.BOTH
            );
          } else if (goalType == GoalTypes.BEHAVIOR) {
            return (
              intervention.concern_area === ConcernArea.BEHAVIOR ||
              intervention.concern_area === ConcernArea.BOTH
            );
          } else {
            return true;
          }
        });
      }
    }

    return fInterventions.slice(0, RECOMMENDATIONS_LIMIT);
  };

  handleModalClose = () => {
    this.setState({ showModifyInterventionPopup: false });
    this.props.hideInterventionLibraryModal();
    this.props.callbacks?.onBack();
  };

  handleBackClick = () => {
    this.props.hideInterventionLibraryModal();
    this.props.callbacks?.onBack();
  };

  handleModalShow = () => {
    if (this.props.selectedIntervention) {
      this.setState({
        ...this.defState,
        step: 1,
        selectedIntervention: this.props.selectedIntervention,
        interventions: this.getRecommendedOnlyInterventions(),
      });
    } else {
      this.setState({
        ...this.defState,
        interventions: this.getRecommendedOnlyInterventions(),
      });
    }

    if(this.props.interventionGroup?.finished) {
      this.handleRecommendedOnlyCheck(false);
    }
  };

  getInterventionTargetValues = (): Array<
    ReactSelectOption<InterventionTarget>
  > => {
    return Object.keys(InterventionTarget)
      .filter((value) => !isNaN(+value))
      .map((value: string): any => ({
        label: InterventionTargetDisplayedNames[+value as InterventionTarget],
        value: value,
      }));
  };

  getConcernTypeValues = (): Array<ReactSelectOption<ConcernType>> => {
    return Object.keys(ConcernType)
      .filter((value) => !isNaN(+value))
      .map((value: string): any => ({
        label: ConcernTypeDisplayedNames[+value as ConcernType],
        value: value,
      }));
  };

  getAreaValues = (): Array<ReactSelectOption<string>> => {
    const interventionTags = new Set();
    this.props.interventions.forEach((intervention) => {
      const focuses = intervention.focuses || [];
      const skillAreas = intervention.skill_areas || [];

      focuses
        .concat(skillAreas)
        .forEach((ident) => interventionTags.add(ident));
    });

    const possible = this.props.skillFocusInfo.map((sfInfo) => ({
      label: sfInfo.display_name,
      value: sfInfo.identifier,
    }));

    return possible.filter((p) => interventionTags.has(p.value));
  };

  getAreaOfNeedValues = (): Array<ReactSelectOption<string>> => {
    const areas = this.props.skillFocusInfo;
    return areas.flatMap(
      (a) =>({
        label: a.display_name,
        value: a.identifier,
      })
    ) ?? [];
  };

  getCreatedByValues = (): Array<ReactSelectOption<number>> => {
    return [
      {
        label: "All",
        value: 0,
      },
      {
        label: "Just me",
        value: this.props.userInfo?.id ?? -1,
      },
    ];
  };

  targetPersonFilter = (
    targetPersonFilter: ReactSelectOption<InterventionTarget> | undefined = this
      .state.targetPersonFilter
  ) => (intervention: Intervention) => {
    if (!targetPersonFilter) return true;
      if(InterventionTarget.INDIVIDUALS === +targetPersonFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.INDIVIDUALS_AND_GROUPS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_INDIVIDUALS ||
          intervention.target === +InterventionTarget.INDIVIDUALS || 
          intervention.target === InterventionTarget.ALL
        );
      } else if(InterventionTarget.SMALL_GROUP === +targetPersonFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.INDIVIDUALS_AND_GROUPS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_GROUPS ||
          intervention.target === +InterventionTarget.SMALL_GROUP || 
          intervention.target === InterventionTarget.ALL
        );
      }  else if(InterventionTarget.WHOLE_CLASS === +targetPersonFilter.value){
        return (
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_INDIVIDUALS ||
          intervention.target === +ExtendedInterventionTarget.WHOLE_CLASS_AND_GROUPS ||
          intervention.target === +InterventionTarget.WHOLE_CLASS || 
          intervention.target === InterventionTarget.ALL
        );
      }  else if(InterventionTarget.ALL === +targetPersonFilter.value){
        return (
          intervention.target === InterventionTarget.ALL
        );
      }
  };

  concernTypeFilter = (
    concernTypeFilter: ReactSelectOption<ConcernType> | undefined = this.state
      .concernTypeFilter
  ) => (intervention: Intervention) => {
    if (!concernTypeFilter) return true;
    return intervention.concern_type === +concernTypeFilter.value;
  };

  areaFilter = (
    areaFilter: ReactSelectOption<string> | undefined = this.state.areaFilter
  ) => (intervention: Intervention) => {
    if (!areaFilter) return true;
    if (intervention.skill_areas) {
      return intervention.skill_areas.includes(areaFilter.value.toString());
    } else {
      return false;
    }
  };

  areaOfNeedFilter = (
    areaOfNeedFilter: ReactSelectOption<string> | undefined = this.state.areaOfNeedFilter
  ) => (intervention: Intervention) => {
    if (!areaOfNeedFilter) return true;
    if (intervention.skill_areas) {
      if (areaOfNeedFilter.value.toString() === 'diag_area_early_phonemic_awareness') {
        return intervention.skill_areas?.includes('diag_area_early_phonemic_awarene') 
        || intervention.skill_areas?.includes('diag_area_early_phonemic_awareness');
      } else {
        return intervention.skill_areas.includes(areaOfNeedFilter.value.toString());
      }
    } else {
      return false;
    }
  };

  createdByFilter = (
    createdByFilter: ReactSelectOption<number> | undefined = this.state
      .createdByFilter
  ) => (intervention: Intervention) => {
    if (!createdByFilter) return true;
    if (createdByFilter.value === 0) return true;
    return intervention.created_by_user?.id === +createdByFilter.value;
  };

  searchStringFilter = (searchStringFilter = this.state.searchStringFilter) => (
    intervention: Intervention
  ) => {
    if (!searchStringFilter) return true;
    return intervention.name
      .toLowerCase()
      .includes(searchStringFilter.toLowerCase());
  };

  handleTargetPersonFilterChange = (
    value: ValueType<ReactSelectOption<InterventionTarget>, false>
  ) => {
    if (value) {
      const targetPersonFilter = value as ReactSelectOption<InterventionTarget>;
      this.setState({
        targetPersonFilter: targetPersonFilter,
        interventions: this.getInterventions()
          .filter(this.targetPersonFilter(targetPersonFilter))
          .filter(this.concernTypeFilter())
          .filter(this.areaFilter())
          .filter(this.areaOfNeedFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      }, () => console.log(this.state.interventions));
    } else {
      this.setState({
        targetPersonFilter: undefined,
        interventions: this.getInterventions()
          .filter(this.concernTypeFilter())
          .filter(this.areaFilter())
          .filter(this.areaOfNeedFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    }
  };

  handleConcernTypeFilterChange = (
    value: ValueType<ReactSelectOption<ConcernType>, false>
  ) => {
    if (value) {
      const concernTypeFilter = value as ReactSelectOption<ConcernType>;
      this.setState({
        concernTypeFilter: concernTypeFilter,
        interventions: this.getInterventions()
          .filter(this.concernTypeFilter(concernTypeFilter))
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    } else {
      this.setState({
        concernTypeFilter: undefined,
        interventions: this.getInterventions()
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    }
  };

  handleAreaFilterChange = (
    value: ValueType<ReactSelectOption<string>, false>
  ) => {
    if (value) {
      const areaFilter = value as ReactSelectOption<string>;
      this.setState({
        areaFilter: areaFilter,
        interventions: this.getInterventions()
          .filter(this.areaFilter(areaFilter))
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    } else {
      this.setState({
        areaFilter: undefined,
        interventions: this.getInterventions()
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    }
  };

  handleAreaOfNeedFilterChange = (
    value: ValueType<ReactSelectOption<string>, false>
  ) => {
    
    if (value) {
      const areaOfNeedFilter = value as ReactSelectOption<string>;
      this.setState({
        areaOfNeedFilter: areaOfNeedFilter,
        interventions: this.getInterventions()
          .filter(this.areaOfNeedFilter(areaOfNeedFilter))
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    } else {
      this.setState({
        areaOfNeedFilter: undefined,
        interventions: this.getInterventions()
          .filter(this.targetPersonFilter())
          .filter(this.createdByFilter())
          .filter(this.searchStringFilter()),
      });
    }
  };

  handleCreatedByFilterChange = (
    value: ValueType<ReactSelectOption<number>, false>
  ) => {
    const createdByFilter = value as ReactSelectOption<number>;
    this.setState({
      createdByFilter: createdByFilter,
      interventions: this.getInterventions()
        .filter(this.createdByFilter(createdByFilter))
        .filter(this.targetPersonFilter())
        .filter(this.concernTypeFilter())
        .filter(this.areaFilter())
        .filter(this.areaOfNeedFilter())
        .filter(this.searchStringFilter()),
    });
  };

  handleSearchStringFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchStringFilter = event.target.value;
    this.setState({
      searchStringFilter: searchStringFilter,
      interventions: this.getInterventions()
        .filter(this.searchStringFilter(searchStringFilter))
        .filter(this.createdByFilter())
        .filter(this.targetPersonFilter())
        .filter(this.concernTypeFilter())
        .filter(this.areaFilter())
        .filter(this.areaOfNeedFilter()),
    });
  };

  handleInterventionSelect = (intervention: Intervention) => {
    this.setState({ selectedIntervention: intervention, step: 1 });
  };

  handleBackToCatalogClick = () => {
    this.setState({ 
      selectedIntervention: undefined,
       step: 0,
       shareByDistrict: false
      });
  };

  handleBackToInterventionInfoClick = () => {
    this.setState({ step: 1 });
  };

  handleBackToSharedInfoClick = () => {
    this.setState({ step: 2 });
  };

  handleUseThisInterventionClick = () => {
    IS_READY_COACH
      ? this.setState({ showModifyInterventionPopup: true })
      : this.handleAddInterventionsToGroup();
  };

  handleModifyInterventionPopupClose = () => {
    this.setState({ showModifyInterventionPopup: false });
  };

  handleAddInterventionsToGroup = () => {
    const { interventionGroup, isSaveInState } = this.props;
    const { selectedIntervention } = this.state;
    if (interventionGroup && selectedIntervention) {
      if (isSaveInState) {
        if (
          !(interventionGroup.interventions || []).some(
            (int) => int.id === selectedIntervention.id
          )
        ) {
          this.props.changeSelectedInterventionGroup({
            ...interventionGroup,
            interventions: (interventionGroup.interventions || []).concat(
              selectedIntervention
            ),
          });
        }
        this.props.hideInterventionLibraryModal();
      } else {
        const ids = (interventionGroup.interventions || []).map(
          (intervention) => intervention.id
        );
        this.props.addInterventionsToGroup(
          interventionGroup.id!,
          ids.concat([selectedIntervention.id])
        );
      }
    }
  };

  handleInterventionModify = () => {
    this.setState({ showModifyInterventionPopup: false, shareByDistrict:false, step: 2 });
  };

  getInterventions = () => {
    if (this.state.recommendedOnly)
      return this.getRecommendedOnlyInterventions();
    else return this.props.interventions;
  };

  handleRecommendedOnlyCheck = (checked: boolean) => {
    if (checked) {
      this.setState((prevState) => ({
        ...prevState,
        recommendedOnly: true,
        interventions: this.getRecommendedOnlyInterventions()
          .filter(this.createdByFilter())
          .filter(this.targetPersonFilter())
          .filter(this.concernTypeFilter())
          .filter(this.areaFilter())
          .filter(this.areaOfNeedFilter())
          .filter(this.searchStringFilter()),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        recommendedOnly: false,
        interventions: this.props.interventions
          .filter(this.createdByFilter())
          .filter(this.targetPersonFilter())
          .filter(this.concernTypeFilter())
          .filter(this.areaFilter())
          .filter(this.areaOfNeedFilter())
          .filter(this.searchStringFilter()),
      }));
    }
  };

  
  getDownloadButtonForIntervention = (intervention: Intervention) => {
    //const attachments = intervention.attachments;
    let attachmentURL = '';
    if(window.location.host.includes('localhost')) {
      attachmentURL = 'http://localhost:8000'
    } else if (window.location.host === 'staging.readycoach.net'){
      attachmentURL = 'https://stage-api.readycoach.net'
    } 
  const attachments = [...intervention.attachments, ...intervention.checklist_items.flatMap((ci) => ci.attachments)];
  if (attachments.length === 1) {
      return (
        <a
        href={attachmentURL + attachments[0].attachment_url!}
        target="_blank"
        rel="noopener noreferrer"
        className="interventionPdfButton"
      >
        <FontAwesomeIcon icon={faDownload} />
        PDF
      </a>
      );
    } else if (attachments.length >= 2) {
      return (
        <Dropdown className="m-1 recommendationsSelectInterventions">
          <Dropdown.Toggle id="dropdown-basic">
            <button className="blueBtnSm">PDF</button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {attachments.map((attachment) => (
              <Dropdown.Item key={attachment.id} as="button">
                <a
                href={attachmentURL + (attachment.attachment_url! || attachment.url!)}
                target="_blank"
                rel="noopener noreferrer"
                //onClick={downloadFile(attachment.attachment_url! || attachment.url!)}
                key={attachment.url}
              >
                  {attachment.title}
                </a>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };
  
  uniqueByKey = (array:Intervention[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  setShareByDistrict = (checked: any) => {
    this.setState({
      shareByDistrict: checked
    });
  }

  handleReportGenerate = () => {
    if (this.defState.contentRef.current) {
      const doc = new jsPDF("p", "pt", "letter", false);

      const opt = {
        margin: [8, 8, 8, 8],
        filename: `${this.state.selectedIntervention!.name}: Checklist Steps.pdf`,
        enableLinks: false,
        pagebreak: {
          avoid: ["div", "tr"],
          mode: ["css", "legacy"],
        },
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: { doc },
      };

      html2pdf()
        .from(this.defState.contentRef.current)
        .set(opt)
        .toPdf()
        .get("pdf")
        .save();
    }
  };


  getModalBodyByStep = () => {
    switch (this.state.step) {
      case 0: {
        const {
          interventions,
          targetPersonFilter,
          concernTypeFilter,
          areaFilter,
          areaOfNeedFilter,
          createdByFilter,
          searchStringFilter,
        } = this.state;
       
        return (
          <>
            {IS_READY_COACH ? (
              <>
                <h2
                  className="purpleText text-center"
                  style={{ margin: "0px 15% 20px" }}
                >
                  Based on your answers, here are interventions that may help
                  you achieve your goal.
                </h2>
                <div className="d-flex justify-content-center">
                  <h4 className="mx-2">
                    <strong>Area of Concern:</strong>{" "}
                    {this.props.interventionGroup?.goal_type ? (
                      GoalTypesDisplayedNames[
                        this.props.interventionGroup.goal_type
                      ]
                    ) : (
                      <i>none</i>
                    )}
                  </h4>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex">
                  <GroupCountIcon
                    svgSize={80}
                    studentsNum={
                      this.props.interventionGroup?.students.length ?? 0
                    }
                  />
                  <div style={{ width: "60%" }}>
                    <div className="font-weight-bold ml-1">Students</div>
                    <div className="d-flex flex-wrap">
                      {this.props.interventionGroup?.students.map((student) => (
                        <div
                          className="specificButton"
                          style={{ margin: "3px" }}
                          key={student.id}
                        >
                          {getFullNameForStudent(student)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ width: "30%" }}>
                    <div className="font-weight-bold ml-1">Focus</div>
                    <div className="d-flex flex-wrap">
                      {this.props.interventionGroup?.focuses &&
                        this.props.interventionGroup.focuses.map((focus) => (
                          <div
                            className="specificButton"
                            style={{ margin: "3px" }}
                            key={focus.identifier}
                          >
                            {focus.display_name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <h2 className="font-weight-bold mt-2">
                  Based on this group&apos;s needs, consider these
                  interventions:
                </h2>
              </>
            )}
            <Row className="mt-4 mb-4">
              <Col sm={3} style={{ borderRight: "1px #cfd8dc solid" }}>
                <div className=" css-2b097c-container">
                  <input
                    type="text"
                    name="searchStringFilter"
                    defaultValue={searchStringFilter}
                    onChange={this.handleSearchStringFilterChange}
                    placeholder="Search an Intervention..."
                    className="stdInput"
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                </div>

                <div className="libraryLabel text-uppercase">Filters</div>
                {IS_READY_COACH ? (
                  <>
                    {this.props.interventionGroup?.recommendation_group_key !== null ?
                    <FormCheck
                      className="font-weight-bold my-2"
                      custom
                      checked={this.state.recommendedOnly}
                      onChange={(event) => this.handleRecommendedOnlyCheck(event.target.checked)}
                      type="checkbox"
                      id="recommendationOnly"
                      label={`Recommended only`}
                    /> : ''}
                    <h5 className="mb-0 mt-2 font-weight-bold">
                      Target Person(s)
                    </h5>
                    <Select
                      isDisabled={
                        !!this.props.interventionGroup?.recommendation_group_key
                      }
                      name="targetPersonFilter"
                      isClearable
                      value={targetPersonFilter}
                      options={this.getInterventionTargetValues()}
                      onChange={this.handleTargetPersonFilterChange}
                    />
                    <h5 className="mb-0 mt-2 font-weight-bold">
                      Type of Concern
                    </h5>
                    <Select
                      isDisabled={
                        !!this.props.interventionGroup?.recommendation_group_key
                      }
                      name="concernTypeFilter"
                      isClearable
                      value={concernTypeFilter}
                      options={this.getConcernTypeValues()}
                      onChange={this.handleConcernTypeFilterChange}
                    />
                    <h5 className="mb-0 mt-2 font-weight-bold">Area of Need</h5>
                    <Select
                      // isDisabled={
                      //   !!this.props.interventionGroup?.recommendation_group_key
                      // }
                      isClearable
                      name="areaOfNeedFilter"
                      value={areaOfNeedFilter}
                      options={this.getAreaOfNeedValues()}
                      onChange={this.handleAreaOfNeedFilterChange}
                    />
                    <h5 className="mb-0 mt-2 font-weight-bold">Created by</h5>
                    <Select
                      isDisabled={
                        !!this.props.interventionGroup?.recommendation_group_key
                      }
                      name="createdByFilter"
                      value={createdByFilter}
                      options={this.getCreatedByValues()}
                      onChange={this.handleCreatedByFilterChange}
                    />
                  </>
                ) : (
                  <>
                    <h5 className="mb-0 mt-2 font-weight-bold">Area</h5>
                    <Select
                      name="areaFilter"
                      isClearable
                      value={areaFilter}
                      options={this.getAreaValues()}
                      onChange={this.handleAreaFilterChange}
                    />
                  </>
                )}
                <br />
              </Col>
              <Col sm={9}>
                  {/* <Alert variant="warning">
                    The interventions shown are limited to our recommendations. 
                    Uncheck "Recommended Only" to see all available interventions.
                    If this group was created through manual grouping, 
                    the intervention library is not filtered by recommendations.
                  </Alert> */}
                  {this.props.interventionGroup?.recommendation_group_key == null ? 
                    <Alert variant="warning">
                      This group was created through manual grouping, 
                      so the intervention library is not filtered by recommendations, 
                      and all interventions in the library are listed below. 
                    </Alert>
                   : ''}
                <div className="libraryLabel mb-3">
                  SELECT THE INTERVENTION YOU WANT TO USE
                </div>
                <div data-cy="intervention-list" className="interventionList">
                  {!this.props.interventions.length ? (
                    <h2>Interventions Not found</h2>
                  ) : (
                    !interventions.length && (
                      <h2>
                        No Interventions found. Try changing the Selection
                        Criteria.
                      </h2>
                    )
                  )}
                  {(interventions.length > 0) && this.uniqueByKey(interventions).map((intervention) => (
                    <InterventionItem
                      key={`intervention_${intervention.id}`}
                      intervention={intervention}
                      onSelect={this.handleInterventionSelect}
                    />
                  ))}
                </div>
              </Col>
            </Row>

            <div className="btnActions">
              <div className="blueBtnSm" onClick={this.handleBackClick}>
                Back
              </div>
              <div />
            </div>
          </>
        );
      }
      case 1: {
        return (
          <div data-cy="intervention-info">
            {IS_READY_COACH ? (
              <>
                <div className="d-flex justify-content-start mb-4">
                  {
                  !this.props.selectedIntervention ?
                  // ? 
                  //   (
                  //     <InterventionEditButton
                  //       intervention={this.props.selectedIntervention}
                  //       interventionGroupId={this.props.interventionGroup?.id}
                  //     />
                  //   ) : 
                    (
                      <button
                        data-cy="use-this-intervention-btn"
                        className="blueBtnLg ml-auto text-nowrap"
                        onClick={this.handleUseThisInterventionClick}
                      >
                        Use this intervention
                      </button>
                    ) : ''
                  }
                </div>
                <hr />
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="blueBtnMd"
                    onClick={this.handleUseThisInterventionClick}
                  >
                    Use this intervention
                  </button>
                </div>
                <hr />
              </>
            )}
            <Row className="mx-0" style={{ textAlign: "left" }}>
              <Col className="p-0">
                {this.state.selectedIntervention!.description && (
                  <>
                    <h3>Description</h3>
                    <p>{this.state.selectedIntervention!.description}</p>
                  </>
                )}
                {this.state.selectedIntervention!.purpose && (
                  <>
                    <h3>Purpose</h3>
                    <p>{this.state.selectedIntervention!.purpose}</p>
                  </>
                )}
                {this.state.selectedIntervention!.considerations && (
                  <>
                    <h3>Considerations</h3>
                    <p>{this.state.selectedIntervention!.considerations}</p>
                  </>
                )}
                {this.state.selectedIntervention!.preparation && (
                  <>
                    <h3>Preparation</h3>
                    <p>{this.state.selectedIntervention!.preparation}</p>
                  </>
                )}
                {this.state.selectedIntervention!.prerequisites && (
                  <>
                    <h3>Prerequisites</h3>
                    <ul>
                      {this.state
                        .selectedIntervention!.prerequisites.trim()
                        .split("\n")
                        .map((pr) => (
                          <li>{pr}</li>
                        ))}
                    </ul>
                  </>
                )}
                {this.state.selectedIntervention!.setup_steps?.length > 0 && (
                  <>
                    <h3>Setup Steps</h3>
                    <ul style={{ marginTop: 8 }}>
                      {this.state.selectedIntervention!.setup_steps?.map(
                        (step) => (
                          <li>
                            <strong>{step.title}</strong>
                            {step.details &&
                              step.details.trim() !== "-" &&
                              step.details.trim().length > 1 &&
                              `: ${step.details}`}
                          </li>
                        )
                      )}
                    </ul>
                  </>
                )}
              </Col>
            </Row>

            {!!this.state.selectedIntervention?.checklist_items?.length && (
              <div className="mt-3">
                <div className="d-flex justify-content-between mb-2">
                  <h3 style={{ marginBottom: 10 }}>Checklist Items</h3>
                  <button
                    className="float-right whiteBtnSm"
                    onClick={this.handleReportGenerate}
                  >
                    Generate Checklist PDF
                  </button>
                </div>
                <div ref={this.defState.contentRef as React.RefObject<HTMLDivElement>}>
                  {this.state.selectedIntervention.checklist_items
                    .sort((a, b) => a.order - b.order)
                    .map((checkListItem, index) => (
                      <>
                        {checkListItem.header && (
                          <div
                            className={"checklistItemHeader"}
                            key={`header_${checkListItem.id}`}
                          >
                            <span>{checkListItem.header}</span>
                          </div>
                        )}

                        <div
                          className="interventionStepsBtnWithPlaceholder"
                          key={checkListItem.id}
                        >
                          <div>Step {index + 1}</div>
                          <div>
                            <strong>{checkListItem.title}</strong>
                            {checkListItem.details &&
                              checkListItem.details.trim() !== "-" && (
                                <>
                                  <br />
                                  {checkListItem.details}
                                </>
                              )}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}

            {this.state.showModifyInterventionPopup && (
              <ModifyInterventionPopup
                selectedIntervention={this.state.selectedIntervention}
                handleClose={this.handleModifyInterventionPopupClose}
                handleUseAsIs={this.handleAddInterventionsToGroup}
                handleInterventionModify={this.handleInterventionModify}
                loading={this.props.isLoading}
              />
            )}

            <hr />
            <div className="spaceBetween align-items-end">
              <div className="behavConLeftActions">
                {this.getDownloadButtonForIntervention(
                  this.state.selectedIntervention!
                )}
              </div>
              {!this.props.selectedIntervention && (
                <button
                  data-cy="use-this-intervention-btn"
                  className="blueBtnLg"
                  onClick={this.handleUseThisInterventionClick}
                >
                  Use this intervention
                </button>
              )}
            </div>
          </div>
        );
      }
      case 2: 
        return (
          <InterventionShared 
            shareByDistrict={this.state.shareByDistrict} 
            setShareByDistrict={this.setShareByDistrict as any}
            onClickNext={() => this.setState({ step: 3 })}
          />
        );
      case 3:
        return (
          <ModifyingInterventionBody
            selectedIntervention={this.state.selectedIntervention!}
            shareByDistrict={this.state.shareByDistrict} 
          />
        );
      default:
        return null;
    }
  };

  getModalTitleByStep = () => {
    switch (this.state.step) {
      case 0:
        return IS_READY_COACH ? (
          this.props.interventionGroup && (
            <>
              <div>
                <InterventionGroupTooltip
                  interventionGroup={this.props.interventionGroup}
                />
              </div>
              <Modal.Title>
                Intervention Library for {this.props.interventionGroup.name}
              </Modal.Title>
              <ModalCloseButton onClose={this.handleModalClose} />
            </>
          )
        ) : (
          <>
            <Modal.Title>Intervention Toolkit</Modal.Title>
          </>
        );
      case 1:
        return IS_READY_COACH ? (
          <>
            {this.props.selectedIntervention ? (
              <div />
            ) : (
              <button
                className="btnModalBack"
                onClick={this.handleBackToCatalogClick}
              >
                <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
                Back to Catalog
              </button>
            )}
            <Modal.Title>{this.state.selectedIntervention!.name}</Modal.Title>
            <ModalCloseButton onClose={this.handleModalClose} />
            {/*{IS_READY_COACH && (*/}
            {/*  <div className="d-flex justify-content-between">*/}
            {/*    <button className="bgInfoBtn ml-4">Background info</button>*/}
            {/*    <ModalCloseButton onClose={this.handleModalClose} />*/}
            {/*  </div>*/}
            {/*)}*/}
          </>
        ) : (
          <>
            <button
              className="btnModalBack"
              onClick={this.handleBackToCatalogClick}
            >
              <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
              <span style={{ marginLeft: "10px" }}>Back</span>
            </button>
            <Modal.Title>{this.state.selectedIntervention!.name}</Modal.Title>
            {/*{IS_READY_COACH && (*/}
            {/*  <button className="bgInfoBtn mr-4">Background info</button>*/}
            {/*)}*/}
            <div />
          </>
        );
      case 2:
        return (
          <>
            <button
              className="btnModalBack"
              onClick={this.handleBackToInterventionInfoClick}
            >
              <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
              Back
            </button>
            <Modal.Title>
              Modifying {this.state.selectedIntervention!.name}
            </Modal.Title>
            {/*{IS_READY_COACH && (*/}
            {/*  <button className="bgInfoBtn mr-4">Background info</button>*/}
            {/*)}*/}
            <div />
          </>
        );
      case 3:
        return (
          <>
            <button
              className="btnModalBack"
              onClick={this.handleBackToSharedInfoClick}
            >
              <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
              Back
            </button>
            <Modal.Title>
              Modifying {this.state.selectedIntervention!.name}
            </Modal.Title>
            {/*{IS_READY_COACH && (*/}
            {/*  <button className="bgInfoBtn mr-4">Background info</button>*/}
            {/*)}*/}
            <div />
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { showModal } = this.props;
    return (
      <Modal
        id="intervention-library-modal"
        onShow={this.handleModalShow}
        show={showModal}
        onHide={this.handleModalClose}
        animation={false}
        size="lg"
        backdropClassName="customDarkModalBackdrop in"
      >
        <Modal.Header
          closeButton={!IS_READY_COACH}
          className={
            IS_READY_COACH
              ? "purpleModalHeader centerModalHeader"
              : "orangeModalHeader"
          }
        >
          {this.getModalTitleByStep()}
        </Modal.Header>
        <Modal.Body>
          {this.props.isLoading.getInterventions ? (
            <LoadingIndicator />
          ) : (
            this.getModalBodyByStep()
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

// READ_REC_CORE_SUPPORT = "rr_core_support"
// READ_REC_LN = "rr_letter_naming"
// READ_REC_PA = "rr_phonemic_awareness"
// READ_REC_PHONICS = "rr_phonics"
// READ_REC_FLUENCY = "rr_fluency"
// READ_REC_SIGHT_WORDS = "rr_sight_words"
// READ_REC_DECODING = "rr_decoding"

const READ_REC_ID_MAP: {
  [read_rec: string]: string;
} = {
  rr_letter_naming: "diag_area_letter_names", // ??
  rr_phonemic_awareness: "diag_area_phonemic_awareness",
  rr_phonics: "diag_area_phonics",
  rr_fluency: "diag_area_fluency",
  rr_sight_words: "diag_area_phonics", // ??
  rr_decoding: "diag_area_phonics", // ??
  rr_comprehension: "diag_area_comprehension",
};

const BEHAVE_REC_ID_MAP: {
  [behave_rec: string]: string;
} = {
  br_self_awareness: "diag_area_self_awareness",
  br_self_management: "diag_area_self_management",
  br_social_awareness: "diag_area_social_awareness",
  br_relationship_skills: "diag_area_relationship_skills",
  br_goal_directed_behavior: "diag_area_goal_directed_behavior",
  br_personal_responsibility: "diag_area_personal_responsibility",
  br_decision_making: "diag_area_decision_making",
  br_optimistic_thinking: "diag_area_optimistic_thinking"
};

const mapStateToProps = ({
  auth,
  cases,
  groups,
}: ApplicationState): PropsFromState => {
  let intConcernArea:number = 0;
  if((cases.tabName == 'groups/behavior') || (cases.modalsState.interventionCalledFromFBA)){
    intConcernArea = 1;
  }
  let interventions = cases.interventions;
  if(window.location.pathname.includes('groups/reading')) {
    interventions = interventions.filter((intervention: Intervention) => [ConcernArea.ACADEMIC, ConcernArea.BOTH].includes(intervention.concern_area))
  }
  if(window.location.pathname.includes('groups/behavior')) {
    interventions = interventions.filter((intervention: Intervention) => [ConcernArea.BEHAVIOR, ConcernArea.BOTH].includes(intervention.concern_area))
  }
  return {
    callbacks: cases.modalsState.interventionLibraryModalCallbacks,
    interventionGroup: cases.selectedInterventionGroup,
    selectedIntervention: cases.selectedIntervention,
    userInfo: auth.userInfo,
    //interventions: cases.interventions.filter((intervention:Intervention) => (intervention.concern_area == intConcernArea) || intervention.concern_area == 2 ),
    interventions: interventions,
    skillFocusInfo: groups.skillFocusInfo,
    identifiersInfo: groups.identifiersInfo,
    showModal: cases.modalsState.interventionLibraryModal,
    interventionCalledFromFBA: cases.modalsState.interventionCalledFromFBA,
    isSaveInState: cases.modalsState.isSaveInState,
    createdIntervention: cases.createdIntervention,
    isLoading: {
      addInterventionsToGroup: cases.isLoading.addInterventionsToGroup,
      getInterventions: cases.isLoading.getInterventions,
    },
    errors: {
      addInterventionsToGroup: cases.errors.addInterventionsToGroup,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getInterventions: getInterventions,
      hideInterventionLibraryModal: hideInterventionLibraryModal,
      getSkillFocuses: getSkillFocuses,
      addInterventionsToGroup: addInterventionsToGroup,
      changeSelectedInterventionGroup: changeSelectedInterventionGroup,
      patchInterventionGroup: patchInterventionGroup,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InterventionLibraryModal));