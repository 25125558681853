import { Dropdown, Form, InputGroup, Nav, Spinner, Tab } from "react-bootstrap";
import React, { FunctionComponent, useEffect, useState } from "react";
import RostersInfoBar from "./RostersInfoBar";
import { ApplicationState } from "../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import {
  changeStudentsDisplaySetting,
  changeStudentsFilter,
  changeTeacherSearchFilter,
  clearStoredCsvFile,
  getDataSharingTableData,
  getSchools,
  getStudents,
  getTeachers,
  openInviteTeachersModal,
  openSchoolModal,
  openShareDataModal,
  openStudentInviteIndividuallyModal,
  openUploadStudentsRosterModal,
  showLoadingIndicator,
} from "../../../../../store/onboarding/actions";
import { push } from "connected-react-router";
import TeachersTable from "../../../../../containers/onboarding/second-step/teacher-details/TeachersTable";
import TeachersInvitePanel from "../teacher-details/TeachersInvitePanel";
import UploadStudentsRosterModal from "../../../../../containers/onboarding/second-step/students/UploadStudentsRosterModal";
import StudentsInviteIndividuallyModal from "../../../../../containers/onboarding/second-step/students/StudentsInviteIndividuallyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCog,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  DataSharingTableData,
  School,
  StudentsDisplaySettings,
} from "../../../../../store/onboarding/types";
import { IS_READY_COACH } from "../../../../../constants";
import DataSharing from "../../../reading-interventions/educators-and-sharing/DataSharing";
import SchoolsTable from "../schools/SchoolsTable";
import AddSchoolModal from "../schools/SchoolModal";
import UploadTeachersRosterModal from "../upload-educators/UploadTeachersRosterModal";
import StudentsPane from "../students/StudentsPane";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import usePrevProps from "../../../../../utils/hooks/usePrevProps";
import InviteEducatorDropDown from "./InviteEducatorDropDown";
import MyTeachersTable from "./my-teachers-table/MyTeachersTable";
import SharingNavigationActions from "./SharingNavigationActions";
import UploadStudentDemographicsCsvButton from "./UploadStudentDemographicsCsvButton";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import DistrictAccessDataSettingUpsertModal from "../../../../pages/district-owner-dashboard/tabs/data-access/DistrictAccessDataSettingUpsertModal";
import { callFromStudentDetail } from "../../../../../store/onboarding/cases/actions";
import { EventKey } from "react-bootstrap/esm/types";

export enum SecondStepTabKeys {
  TEACHERS = "teachers",
  STUDENTS = "students",
  EVIDENCE = "evidence",
  SHARING = "sharing",
  SCHOOLS = "schools",
}

type PropsFromState = {
  isDataManagerUser: boolean;
  teachersNotEmpty: boolean;
  studentsNotEmpty: boolean;
  schools: Array<School>;
  dataSharingTableData: Array<DataSharingTableData>;
  isLoading: {
    getTeachers: boolean;
    getStudents: boolean;
    getDataSharingTableData: boolean;
    getSchools: boolean;
  };
  errors: {
    getTeachers?: string;
    getStudents?: string;
  };
  studentsFilter: string;
  teachersFilter: string;
  studentsDisplaySettings: StudentsDisplaySettings;
};

type DispatchProps = {
  getTeachers: () => any;
  getStudents: () => any;
  getSchools: () => any;
  getDataSharingTableData: () => any;
  openInviteTeachersModal: () => any;
  openShareDataModal: () => any;
  openSchoolModal: (selectedSchool?: School) => any;
  clearStoredCsvFile: () => any;
  openUploadStudentsRosterModal: () => any;
  openStudentInviteIndividuallyModal: () => any;
  push: (location: string) => any;
  changeStudentsDisplaySetting: (
    studentsDisplaySettings: StudentsDisplaySettings
  ) => any;
  changeStudentsFilter: (studentFilter: string) => any;
  changeTeacherSearchFilter: (teacherFilter: string) => any;
  callFromStudentDetail: (isStudentDetail: boolean) => any;
  showLoadingIndicator: () => void;
};

type OwnProps = {
  activeKey: SecondStepTabKeys | null;
  id?: string;
};

type Props = OwnProps & PropsFromState & DispatchProps & RouteComponentProps;

const CustomDivToggle = React.forwardRef((props: any, ref: any) => {
  return (
    <div onClick={props.onClick} ref={ref}>
      {props.children}
    </div>
  );
});

const RostersTab: FunctionComponent<Props> = (props) => {
  const { isTeacher, isDistrictOwner} = useUserRole();
  const [isOnlyStudentChecked, setIsOnlyStudentChecked] = useState(false);
  if(!isTeacher && !isDistrictOwner){
    useEffect(() => {
      props.getTeachers();
    }, []);
  }
  useEffect(() => {
    updateDataTables(props.activeKey);
  }, [props.activeKey]);
  const history = useHistory();
  const handleNavLinkClick = (location: string) => () => {
    history.push(location);
  };
  
  const prevProps = usePrevProps({ prevPath: props.location.pathname });
  const prevPath = prevProps?.prevPath;
  
  useEffect(() => {
      props.changeTeacherSearchFilter('');
  },[prevPath])

  useEffect(() => {
    setIsOnlyStudentChecked(true);
    props.showLoadingIndicator();
    props.changeStudentsDisplaySetting(
      StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
    );
  },[])
  
  const handleActiveTabSelect = (activeTab: string | null) => {
    props.push("/rosters/" + activeTab);
  };

  const updateDataTables = (activeTab: string | null) => {
    switch (activeTab) {
      case SecondStepTabKeys.TEACHERS: {
        if (IS_READY_COACH) {
          props.getDataSharingTableData();
        }
        props.getTeachers();
        return;
      }
      case SecondStepTabKeys.STUDENTS: {
        props.getStudents();
        return;
      }
      case SecondStepTabKeys.SHARING: {
        props.getDataSharingTableData();
        return;
      }
      case SecondStepTabKeys.SCHOOLS: {
        props.getSchools();
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleStudentsFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    props.changeStudentsFilter(value);
  };

  const handleTeachersFilterChange = (event: React.FormEvent<any>) => {
    event.preventDefault();
    const { value } = event.target as HTMLInputElement;
    props.changeTeacherSearchFilter(value);
  };

  const handleGoBackFromStudentDetails = () => {
    if (prevPath) {
      props.history.push(prevPath);
    }
  };

  const getHeaderNavigation = (activeKey: SecondStepTabKeys | null) => {
    const { id } = props;
    if (id) {
      switch (activeKey) {
        case SecondStepTabKeys.STUDENTS:
          if (prevPath !== props.location.pathname) {
            return (
              <div className="d-flex">
                <Nav.Item>
                  <Nav.Link onClick={handleGoBackFromStudentDetails}>
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                    <span className="d-inline font-weight-semibold">Back</span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            );
          } else {
            return (
              <div className="d-flex">
                <Nav.Item>
                  {isDistrictOwner ? 
                    <Nav.Link onClick={handleNavLinkClick("/district/rosters/students")}>
                      <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                      <span className="d-inline font-weight-semibold">
                        To Students List
                      </span>
                    </Nav.Link>
                  :
                    <Nav.Link eventKey={SecondStepTabKeys.STUDENTS}>
                      <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                      <span className="d-inline font-weight-semibold">
                        To Students List
                      </span>
                    </Nav.Link>
                  }
                </Nav.Item>
              </div>
            );
          }
        default:
          return;
      }
    } else {
      return (
        <div className="d-flex">
          {!isTeacher && !isDistrictOwner && (
            <Nav.Item className="rosterTab">
              <Nav.Link eventKey={SecondStepTabKeys.TEACHERS}>
                {IS_READY_COACH ? "My Teachers" : "Educators"}
              </Nav.Link>
            </Nav.Item>

          )}
          <Nav.Item className="rosterTab">
            <Nav.Link eventKey={SecondStepTabKeys.STUDENTS}>Students</Nav.Link>
          </Nav.Item>
          {!IS_READY_COACH && (
            <>
              <Nav.Item className="rosterTab">
                <Nav.Link eventKey={SecondStepTabKeys.SHARING}>
                  Sharing
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="rosterTab">
                <Nav.Link eventKey={SecondStepTabKeys.SCHOOLS}>
                  Schools
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </div>
      );
    }
  };

  const getHeaderTitle = (activeKey: SecondStepTabKeys | null) => {
    const { id } = props;
    if (id) {
      switch (activeKey) {
        case SecondStepTabKeys.STUDENTS:
          return (
            <Nav.Item>
              <h3>
                <strong>Student Details</strong>
              </h3>
            </Nav.Item>
          );
        default:
          return;
      }
    }
    return;
  };

  const isLoadingUploadStudentDemographicsCsv = useSelector<
    ApplicationState,
    boolean | undefined
  >((s) => s.onboarding.isLoading.uploadStudentDemographics);

  const handleAssignedStudentSwitch = (isChecked: boolean) => {
    setIsOnlyStudentChecked(isChecked);
    props.showLoadingIndicator();
    setTimeout(() => {
      if(isChecked) {
        props.changeStudentsDisplaySetting(
          StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
        );
      } else {
        props.changeStudentsDisplaySetting(
          StudentsDisplaySettings.DISPLAY_ALL_STUDENTS
        )
      }
    }, 10);
  };
  const getHeaderActionButtons = (activeKey: SecondStepTabKeys | null) => {
    const { teachersNotEmpty, isDataManagerUser } = props;
    

    switch (activeKey) {
      case SecondStepTabKeys.TEACHERS: {
        return teachersNotEmpty ? (
          IS_READY_COACH ? (
            <div className="d-flex flex-row align-items-center row w-75">
              <InputGroup className="filterSearchDesktop col-4">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Filter teachers"
                  value={props.teachersFilter}
                  onChange={handleTeachersFilterChange}
                  className="filterSearchHeight"
                />
              </InputGroup>
              <div className="col-6 pl-0">
              <SharingNavigationActions />
              </div>
              <div className="col-2 pl-0">
              <InviteEducatorDropDown />
              </div>
            </div>
          ) : (
            <InviteEducatorDropDown />
          )
        ) : null;
      }
      case SecondStepTabKeys.STUDENTS:
        if (props.id) {
          return;
        } else {
          callFromStudentDetail(true);
          return (
            props.studentsNotEmpty && (
              <div className="d-flex flex-row align-items-center">
                <Nav.Item className="mr-2">
                  <div className="students">
                      <Form.Check
                        readOnly
                        checked={
                          isOnlyStudentChecked
                        }
                        id="Only-students-in-intervention-groups"
                        label="Only students in intervention groups"
                        type="radio"
                        className="text-wrap font-weight-bold only-students"
                        onClick={() => handleAssignedStudentSwitch(true)}
                      />
                      <Form.Check
                          readOnly
                          checked={
                            !isOnlyStudentChecked
                          }
                          id="All-students-in-the-district"
                          label="All students in the district"
                          type="radio"
                          className="text-wrap font-weight-bold all-students"
                          onClick={() => handleAssignedStudentSwitch(false)}
                        />
                  </div>
                {/* <div className="list-order-swither-checkbox d-flex">
                  <label className="font-weight-bold">Only students in intervention groups</label>  
                  <Form.Check
                    type="switch"
                    id="list-order-switch"
                    label="All students in the district "
                    className="font-weight-bold ml-2 cursor-pointer"
                    checked={
                      isOnlyStudentChecked
                    }
                    onChange={handleAssignedStudentSwitch}
                  />
                </div> */}
                </Nav.Item>
                <Nav.Item className="mr-2">
                  <InputGroup className="filterSearchDesktop">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Filter students"
                      value={props.studentsFilter}
                      onChange={handleStudentsFilterChange}
                      className="filterSearchHeight"
                    />
                  </InputGroup>
                </Nav.Item>
                {/* <Nav.Item className="mr-2 d-flex justify-content-center align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle id="settingsToggle" as={CustomDivToggle}>
                      <FontAwesomeIcon icon={faCog} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header className="rosterSettingsHeader">
                        Settings
                      </Dropdown.Header>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          props.changeStudentsDisplaySetting(
                            StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
                          )
                        }
                      >
                        <Form.Check
                          readOnly
                          checked={
                            props.studentsDisplaySettings ===
                            StudentsDisplaySettings.DISPLAY_ONLY_MY_STUDENTS
                          }
                          label="Only Assigned Students"
                          type="radio"
                          className="text-wrap"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          props.changeStudentsDisplaySetting(
                            StudentsDisplaySettings.DISPLAY_ALL_STUDENTS
                          )
                        }
                      >
                        <Form.Check
                          readOnly
                          checked={
                            props.studentsDisplaySettings ===
                            StudentsDisplaySettings.DISPLAY_ALL_STUDENTS
                          }
                          label="All Students in District"
                          type="radio"
                          className="text-wrap"
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item> */}
                <Nav.Item>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="secondary"
                      className="whiteDropdown"
                    >
                      ADD STUDENTS
                      {isLoadingUploadStudentDemographicsCsv && (
                        <Spinner
                          className="ml-1"
                          animation="border"
                          size="sm"
                        />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={props.openStudentInviteIndividuallyModal}
                      >
                        Add Individually
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={props.openUploadStudentsRosterModal}
                      >
                        Upload Roster
                      </Dropdown.Item>

                      <UploadStudentDemographicsCsvButton />
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              </div>
            )
          );
        }
      case SecondStepTabKeys.SHARING:
        return <SharingNavigationActions />;
      case SecondStepTabKeys.SCHOOLS:
        return (
          isDataManagerUser && (
            <div className="blueBtnMd" onClick={() => props.openSchoolModal()}>
              Add School
            </div>
          )
        );
      default:
        return;
    }
  };

  const {
    activeKey,
    id,
    teachersNotEmpty,
    dataSharingTableData,
    schools,
  } = props;
  return (
    <>
      {IS_READY_COACH && teachersNotEmpty && !id && <RostersInfoBar />}
      <div className="onboardingContainer">
        <Tab.Container
          activeKey={activeKey as EventKey}
          onSelect={handleActiveTabSelect}
          unmountOnExit
        >
          <Nav>
            <div className="btnActions flex-wrap">
              {getHeaderNavigation(activeKey)}
              {getHeaderTitle(activeKey)}
              {getHeaderActionButtons(activeKey)}
            </div>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey={SecondStepTabKeys.TEACHERS}>
              {teachersNotEmpty || props.isLoading.getTeachers ? (
                IS_READY_COACH ? (
                  <>
                  <MyTeachersTable />
                  <DistrictAccessDataSettingUpsertModal isShowDisabled={true} />
                  </>
                  
                ) : (
                  <div className="horizontalScrollContainer">
                    <TeachersTable />
                  </div>
                )
              ) : (
                <TeachersInvitePanel />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey={SecondStepTabKeys.STUDENTS}>
              <StudentsPane id={id} />
            </Tab.Pane>
            <Tab.Pane eventKey={SecondStepTabKeys.SHARING}>
              {dataSharingTableData.length > 0 ||
              props.isLoading.getDataSharingTableData ? (
                <DataSharing />
              ) : (
                <div className="displayFlex justify-content-center">
                  <h3 className="font-weight-bold m-2">No data</h3>
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey={SecondStepTabKeys.SCHOOLS}>
              {schools.length > 0 || props.isLoading.getSchools ? (
                <SchoolsTable />
              ) : (
                <div className="displayFlex justify-content-center">
                  <h3 className="font-weight-bold m-2">No data</h3>
                </div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <AddSchoolModal />
      <UploadTeachersRosterModal />
      {/*<TeacherInviteIndividuallyModal />*/}
      <StudentsInviteIndividuallyModal />
      <UploadStudentsRosterModal />
    </>
  );
};

const mapStateToProps = ({
  auth,
  onboarding,
}: ApplicationState): PropsFromState => {
  return {
    isDataManagerUser: auth.userInfo
      ? auth.userInfo.profile.current_role === "coach"
      : false, //TODO "coach" using only for testing, change "coach" to "data_manager"
    teachersNotEmpty: onboarding.teachersRoster.length > 0,
    studentsNotEmpty: onboarding.studentsRoster.length > 0,
    schools: onboarding.schools,
    dataSharingTableData: onboarding.dataSharingTableData,
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
      getStudents: onboarding.isLoading.getStudents,
      getDataSharingTableData: onboarding.isLoading.getDataSharingTableData,
      getSchools: onboarding.isLoading.getSchoolsList,
    },
    errors: {
      getTeachers: onboarding.errors.getTeachers,
      getStudents: onboarding.errors.getStudents,
    },
    studentsFilter: onboarding.studentsFilter,
    teachersFilter: onboarding.teachersFilter,
    studentsDisplaySettings: onboarding.studentsDisplaySettings,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      getStudents: getStudents,
      getSchools: getSchools,
      getDataSharingTableData: getDataSharingTableData,
      openShareDataModal: openShareDataModal,
      openSchoolModal: openSchoolModal,
      openInviteTeachersModal: openInviteTeachersModal,
      clearStoredCsvFile: clearStoredCsvFile,
      openUploadStudentsRosterModal: openUploadStudentsRosterModal,
      openStudentInviteIndividuallyModal: openStudentInviteIndividuallyModal,
      changeStudentsFilter: changeStudentsFilter,
      changeTeacherSearchFilter: changeTeacherSearchFilter,
      changeStudentsDisplaySetting: changeStudentsDisplaySetting,
      callFromStudentDetail: callFromStudentDetail,
      showLoadingIndicator: showLoadingIndicator,
      push: push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RostersTab));
