// @flow
import * as React from "react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  BasicUserInfo,
  EvidencePeriodData,
  InvitesResponse,
  ReactSelectOption,
  TeacherInfo,
} from "../../../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import { ApplicationState } from "../../../../../../../store";
import { bindActionCreators, Dispatch } from "redux";
import {
  changeTeachersFilter,
  clearClassesAssignedToTeacher,
  getClassesAssignedToTeacher,
  getTeachers,
  openTeacherInviteIndividuallyModal,
} from "../../../../../../../store/onboarding/actions";
import { connect, useSelector } from "react-redux";
import { getFullName } from "../../../../../../../utils/NamesUtils";
import { getBasicUserInfoFromString } from "../../../../../../../utils/StudentMapUtils";
import { USState } from "../../../../../States";

type PropsFromState = {
  teacherRoster: Array<TeacherInfo>;
  teacherIdFilter?: number;
  invites: InvitesResponse;
  errors: {
    getTeachers?: string;
  };
  isLoading: {
    getTeachers: boolean;
  };
};

type DispatchProps = {
  getTeachers: () => any;
  clearClassesAssignedToTeacher: () => any;
  changeTeachersFilter: (teacherIdFilter?: number) => any;
  getClassesAssignedToTeacher: (teacherId: number) => any;
  openTeacherInviteIndividuallyModal: (newTeacher?: BasicUserInfo) => any;
};

type Props = PropsFromState & DispatchProps;

const TeachersFilter: FunctionComponent<Props> = ({
  isLoading,
  getTeachers,
  teacherRoster,
  invites,
  teacherIdFilter,
  changeTeachersFilter,
  getClassesAssignedToTeacher,
  openTeacherInviteIndividuallyModal,
  clearClassesAssignedToTeacher,
}) => {
  const [isNewInvite, setIsNewInvite] = useState(false);

  const evidencePeriodData = useSelector<
    ApplicationState,
    EvidencePeriodData
  >((s) => s.onboarding.evidencePeriodData!);

  useEffect(() => {
    getTeachers();
  }, []);

  useEffect(() => {
    if (isNewInvite) {
      const { successful_invites } = invites;
      if (successful_invites.length) {
        const { user } = successful_invites[0];
        changeTeachersFilter(user.id);
      }
      setIsNewInvite(false);
    }
  }, [teacherRoster]);

  const handleSelectedTeacherChange = (
    option: ValueType<ReactSelectOption<BasicUserInfo>, false>
  ) => {
    if (option) {
      const teacherId = (option as ReactSelectOption<BasicUserInfo>).value
        .id!;
      changeTeachersFilter(teacherId);
      getClassesAssignedToTeacher(teacherId);
    } else {
      changeTeachersFilter();
      clearClassesAssignedToTeacher();
    }
  };

  const teachersByCurrentDataPeriod: any[] = useMemo(() => 
    evidencePeriodData!.student_rows.flatMap((std) => std.student?.teachers || []) || []
  ,[evidencePeriodData])

  const uniqueByKey = (array:BasicUserInfo[], key:any ='id') => {
    return Array.from(new Map(array.map((x:any) => [x[key], x])).values());
  }

  const options: Array<ReactSelectOption<BasicUserInfo>> = useMemo(() => {
    return (uniqueByKey(teachersByCurrentDataPeriod) || []).map((teacherInfo: BasicUserInfo) => ({
      label: getFullName(teacherInfo),
      value: teacherInfo,
    }));
  }, [teacherRoster, teachersByCurrentDataPeriod]);

  const handleInviteTeacher = (input: string) => {
    setIsNewInvite(true);
    openTeacherInviteIndividuallyModal(getBasicUserInfoFromString(input));
  };

  return (
    <>
      <h4 className="font-weight-bold m-0">Educator</h4>
      <CreatableSelect
        isClearable
        placeholder="All"
        name="teachersFilter"
        styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
        isLoading={isLoading.getTeachers}
        options={options}
        value={options.find((o) => o.value.id === teacherIdFilter)}
        onChange={handleSelectedTeacherChange}
        onCreateOption={handleInviteTeacher}
        formatCreateLabel={(input: string) => `Invite '${input}'`}
      />
    </>
  );
};

const mapStateToProps = ({ onboarding }: ApplicationState): PropsFromState => {
  return {
    teacherRoster: onboarding.teachersRoster,
    teacherIdFilter: onboarding.teacherIdFilter,
    invites: onboarding.invites,
    errors: {
      getTeachers: onboarding.errors.getTeachers,
    },
    isLoading: {
      getTeachers: onboarding.isLoading.getTeachers,
    },
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators(
    {
      getTeachers: getTeachers,
      changeTeachersFilter: changeTeachersFilter,
      openTeacherInviteIndividuallyModal: openTeacherInviteIndividuallyModal,
      getClassesAssignedToTeacher: getClassesAssignedToTeacher,
      clearClassesAssignedToTeacher: clearClassesAssignedToTeacher,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeachersFilter);
